import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProjectsPullList(props){
    //console.debug("RTS Break ProjectsPullList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoLoadPulls, setRepoLoadPulls] = useState([]);

    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/LoadPulls/GetLoadPullsByProjectId/" + pFilter;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoLoadPulls(response.data);
                setIsLoading(false);
            }
            else
            {
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData(props.idGuid);
    }, [fetchData, props.idGuid]); 


    const columns = [
        {
            field: 'pullDate', 
            headerName: "Date", 
            description:"Pull Date", 
            flex:1,
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null;
                }
                
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
        },
        {
            field: 'reelSerialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:1,
        },
        {
            field: 'pullLength', 
            headerName: "Amount (ft)", 
            description:"Feet Pulled",
            type:'number',
            flex:1,
        },        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {}
        }
        else{
            return {}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return (
        <>
        <Box sx={{textAlign:'left', mt:1}}>
            <Typography variant="h6" sx={{display:'inline'}}>Project:</Typography>
            <Typography inline variant="h6" sx={{display:'inline', ml:1}}>{props.display}</Typography>
        </Box>
        <Box sx={{width:'100%', minHeight:'200px', mt:1}}>        
            <DataGridPro
                autoHeight
                rows={repoLoadPulls} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    density: getDensity() 
                }}                
            />
        </Box>  
        </>        
    );
}
