import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import EditBack from '../components/CompanyProfile/EditBack';
import HelpEditOverview from '../components/CompanyProfile/HelpEditOverview';
import CompanyProfileEdit from '../components/CompanyProfile/CompanyProfileEdit';
import CompanyProfileUserList from '../components/CompanyProfile/CompanyProfileUserList';

export default function CompanyProfilePageEdit( props ){
    //console.debug("RTS Break CompanyProfilePageEdit");

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [helpEditOverview, setHelpEditOverview] = useState(false);

    const [formDirty, setFormDirty] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [newTabIndex, setNewTabIndex] = useState(0);


    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 


    const handleChange = (e, curIndex) => {
        setNewTabIndex(curIndex);
        if(formDirty===1){
            setShowConfirmModal(true);
        }
        else{
            switchTabs(curIndex);
        }
    };


    function switchTabs(curIndex){
        window.scrollTo(0,0);
        setShowConfirmModal(false);
        setFormDirty(0);
        // if(mode===1){
            setTabIndex(curIndex);
        // }
    }     


    return(
        <>
        <Dialog fullWidth={true} open={showConfirmModal}>
            <DialogTitle>Leave Tab?</DialogTitle>
            <DialogContent>
                <Typography>Changes will be lost.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {switchTabs(newTabIndex);}}>Yes</Button>
                <Button variant="contained" onClick={e => {setShowConfirmModal(false);}}>No</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={helpEditOverview} onClose={()=>{setHelpEditOverview(false);}}>
            <DialogTitle>Company Profile Help</DialogTitle>
            <DialogContent>
                <HelpEditOverview />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setHelpEditOverview(false);}}>Close</Button>
            </DialogActions>
        </Dialog>                 
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
                {formDirty===0
                ?
                    <Typography variant="h5" >Company Profile</Typography>
                :
                    <Typography variant="h5" sx={{fontStyle:'italic'}}>Company Profile *</Typography>
                }
                <Link onClick={() => {setHelpEditOverview(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                    <HelpOutlineIcon variant="h5" bold sx={{ml:1, mt:.5}}  />
                </Link>                
            </Box>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:1 }}>
                <Tabs value={tabIndex} onChange={handleChange}>
                    <Tab label="Overview" value={0}  />
                    <Tab label="Users" value={1} />
                </Tabs>
            </Box>

            {
            tabIndex === 1
            ?
            <>
            <Box>
                <CompanyProfileUserList formDirty={formDirty} setFormDirtyFx={setFormDirty} />
            </Box>
            </>
            :
                <>
                <Box>
                    <CompanyProfileEdit formDirty={formDirty} setFormDirtyFx={setFormDirty} />
                </Box>
                </>
            }
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
            </Box>               
      </Container>
      </>
    );
}
