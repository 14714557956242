import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import ReelTypesList from '../../components/ReelTypes/ReelTypesList';
import ReelTypesHeader from '../../components/ReelTypes/ReelTypesHeader';
import ReelTypesFilter from '../../components/ReelTypes/ReelTypesFilter';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelTypesPage( props ){
    //console.debug("RTS Break ReelTypesPage");

    const {curFilter, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [filter, setFilter] = useState(null);
    const [data, setData] = useState([]);

    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/ReelTypes/GetReelTypeSummary";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
            else{
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);
    

    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'ReelTypesPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({material:'Steel', status:-1, sortBy:0});
            fetchData({material:'Steel', status:-1, sortBy:0});
        }
    }, [props, curFilter, fetchData]); 

    
    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    };    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box id="pageBox">
            <ReelTypesHeader />
            <ReelTypesFilter filter={filter} setFilterFx={setFilter}/>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                <ReelTypesList data={data} />
            </Box>
        </Box>
    );
}
