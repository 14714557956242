import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

export default function TrackersList(props){
    //console.debug("RTS Break TrackersList");

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));


    function handleClick(e, cellValues){
        history("/TrackersEdit", {state:{idGuid:cellValues.row.idGuid, display:cellValues.row.esn, mode:1}});
    }


    const columns = [
        {
            field: '',
            description:"Edit Tracker", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'esn', 
            headerName: "ESN", 
            description:"Electronic Serial Number", 
            flex:2
        },
        {
            field: 'model', 
            headerName: "Model", 
            description:"Tracker Model", 
            flex:1
        },
        {
            field: 'purchaseDate', 
            headerName: "Purchase Date", 
            description:"Purchase Date", 
            flex:1,           
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null
                }

                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },          
        },
        {
            field: 'isActive', 
            headerName: "Activated", 
            description:"Tracker Is Activated", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value === 1){
                    return ('Yes');
                }
                else if(cellValues.value === 99){
                    return('No (Reporting)');
                }
                else{
                    return('No');
                }
            },            
        },
        {
            field: 'activateDate', 
            headerName: "Activate Date", 
            description:"Date Tracker Was Activated", 
            flex:1,           
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null
                }
                
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },            
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"Reel Status", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value === 1){
                    return ('Active');
                }
                else{
                    return('Disabled');
                }
            }, 
        },                        
        {
            field: 'batteryLevel', 
            headerName: "Battery Level", 
            description:"Tracker Battery Level", 
            flex:1,
            valueFormatter: (value) => {
                if (value == null) {
                  return '';
                }
                return `${value.toLocaleString()} %`;
            },
            align:'right',
            type:'number',
        }, 
        {
            field: 'lastUpdate', 
            headerName: "Last Update", 
            description:"Last Update", 
            flex:1,
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null
                }
                
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },           
        },
        {
            field: 'createUser', 
            headerName: "Create User", 
            description:"User who created reel", 
            flex:3,         
        },     
        {
            field: 'modifyDate', 
            headerName: "Modify Date", 
            description:"Last Modify Date", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null
                }
                
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },          
        },
        {
            field: 'modifyUser', 
            headerName: "Modify User", 
            description:"User who performed last modification", 
            flex:3,         
        },                        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {model:false, batteryLevel:false, lastUpdate:false, purchaseDate:false, activateDate:false, status:false, createUser:false, modifyDate:false, modifyUser:false}
        }
        else{
            return {purchaseDate:false, activateDate:false, status:false, createUser:false, modifyDate:false, modifyUser:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}
