import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

export default function PalletPlantDetailsHeader(props){
    //console.debug("RTS Break PalletPlantDetailsHeader");

    return (
        <Box>
            <Typography>Name: {props.data.name}</Typography>
            <Typography>Short Name: {props.data.shortName}</Typography>
            <Typography sx={{mr:1}}>SAP Id: {props.data.idSap}</Typography>
            <Box>
                <Typography>Address</Typography>
            </Box>
            <Box>
                <Typography>{props.data.address1}</Typography>
            </Box>
            <Box>
                <Typography>{props.data.address2}</Typography>
            </Box>
            <Box>
                <Typography>{props.data.city}, </Typography>
                <Typography>{props.data.region} </Typography>
                <Typography>{props.data.zip}</Typography>
            </Box>
        </Box>
    )
}

