import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function DashboardKPIProductList(props) {
    //console.debug("RTS Break DashboardProductList");
    
    const {user, sessionCustomer, setCurFilter, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoProducts, setRepoProducts] = useState();

    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/Dashboard/GetDashboardProducts";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoProducts(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]); 


    useEffect(() => {
        fetchData({"user":user, "customer":sessionCustomer.customer, "sortBy":0});
    }, [user, sessionCustomer.customer, fetchData]); 


    function handleClick(e, cellValues){
        setCurFilter({key:'LoadListPage', filter:{User:user, 
            Status:[],
            Customer:sessionCustomer.customer,
            Orders:[], 
            Projects:[], 
            Products:[cellValues.row.product], 
            ReelTypes:[],
            Reels:[],
            MinLength:0,
            NamedLocations:[],
            Pinned:-1,
            Locked:-1,
            SortBy:0}
        });        

        history("/LoadList", {state:{}});
    };


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    const columns = [
        {field: 'product', 
            headerName: "Product", 
            description:"Product Name", 
            flex:4,
            renderCell: (cellValues) => {
                return (
                <Link 
                    onClick={(event) => {handleClick(event, cellValues);}}
                    sx={{textDecoration:"none", fontWeight:"bold", cursor:"pointer"}}
                >
                    {cellValues.row.product.aliasName}
                </Link>
                );
            },
            align:"left"
        },
        {field: 'activeReels', 
            headerName: "Active Reels", 
            description:"Number of Active Reels", 
            flex:1, 
            type:"number", 
        },
        {field: 'reels', 
            headerName: "Reels", 
            description:"Number of Reels", 
            flex:1, 
            type:"number", 
        }
    ];

    return (
        <>
        <Box id="pageBox" sx={{width:'100%', height:400}}>
            <DataGridPro 
                rows={repoProducts} 
                columns={columns} 
                getRowId={row => row.product.aliasId} 
                sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal"
                    },
                    "& .MuiDataGrid-columnHeader": {
                      // Forced to use important since overriding inline styles
                      height: "unset !important"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      // Forced to use important since overriding inline styles
                      maxHeight: "168px !important"
                    }
                }}                
            />
        </Box>
        </>
    );
}
