import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CompanyProfileUserList(props){
    //console.debug("RTS Break CompanyProfileUserList");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState([]);

    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/CustomerUsers/GetCustomerActiveUsers/" + pFilter;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);
    
    
    useEffect(() => {
           fetchData(sessionCustomer.customer.idGuid);
    }, [fetchData, sessionCustomer.customer.idGuid]);  


    const columns = [
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"User First Name", 
            flex:3
        },        
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"User Last Name", 
            flex:3 
        },
        {
            field: 'email', 
            headerName: "Email", 
            description:"User Email", 
            flex:4 
        },
        {
            field: 'companyLinkName', 
            headerName: "Company", 
            description:"User Company", 
            flex:3 
        },
        {
            field: 'accessLevel', 
            headerName: "Role", 
            description:"User Role", 
            flex:3,
            valueGetter: (value, row) => {
                var retValue = 'Manager';

                switch(row.accessLevel){
                    case 1:
                        retValue='Crew Member';
                        break;

                    case 25:
                        retValue = 'Associate';
                        break;
    
                    case 50:
                        retValue = 'Manager';
                        break;

                    case 75:
                        retValue='Administrator';
                        break;

                    default:
                        retValue = 'Unknown';

                }

                return retValue;
            }   
        },                
    ];


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '60vh';
        }
        return retValue;
    };    



    function getVisibilityModel(){
        if(smallForm){
            return {firstName:false, lastName:false, companyLinkName:false}
        }
        else{
            return {companyLinkName:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <>
        <Box sx={{textAlign:'left', mt:1}}>
            <Typography variant="h6" sx={{display:'inline'}}>Company:</Typography>
            <Typography inline variant="h6" sx={{display:'inline', ml:1}}>{sessionCustomer.customer.name}</Typography>
        </Box>
        <Box sx={{width:'100%', height:setTableHeight(), minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={data} 
                columns={columns} 
                getRowId={row => row.email}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
        </>
    );
}
