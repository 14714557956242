import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import ReelTypesFilterStatus from './ReelTypesFilterStatus';
import ReelTypesFilterMaterialList from './ReelTypesFilterMaterialList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelTypesFilter(props){
    //console.debug("RTS Break ReelTypesFilter");

    const {setCurFilter} = useSessionContext();

    const [status, setStatus] = useState();
    const [material, setMaterial] = useState();
    const [formDirty, setFormDirty] = useState(0); 
    

    useEffect(() => {
        setStatus(props.filter.status);
        setMaterial(props.filter.material);
    }, [props.filter.status, props.filter.material]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'ReelTypesPage', filter:{material:material, status:status}, page:0, scroll:0});
        props.setFilterFx({material:material, status:status});
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <ReelTypesFilterMaterialList material={props.filter.material} setMaterialFx={setMaterial} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <ReelTypesFilterStatus status={props.filter.status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
