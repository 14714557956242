import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function TrackersFilterModelList(props) {
    //console.debug("RTS Break ReelsFilterModelList");

    const [model, setModel] = useState(props.model==null?'AT3':props.model);
    const [repoModels] = useState(['All', 'AT1', 'AT2.5', 'AT3']);


    function valChange(e, val){
        props.setModelFx(val);
        props.setFormDirtyFx(1);
        setModel(val);
    }
   

    return(
        <>
            <Autocomplete
                options={repoModels}
                renderInput={(params) => <TextField {...params} label="Models" size="small" />}
                value={model}
                onChange={valChange}
            />
        </>
    );
}

