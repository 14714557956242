import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import ProductAliasFilterType from './ProductAliasFilterType';
import ProductAliasFilterStatus from './ProductAliasFilterStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductAliasFilter(props){
    //console.debug("RTS Break ProductAliasFilter");

    const {setCurFilter, sessionCustomer} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [type, setType] = useState();
    const [status, setStatus] = useState();
    

    useEffect(() => {
        setType(props.filter.type);
        setStatus(props.filter.status);
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'ProductAliasPage', filter:{customer:sessionCustomer.customer, type:type, status:status}});
        props.setFilterFx({customer:sessionCustomer.customer, type:type, status:status});
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <ProductAliasFilterType type={type} setTypeFx={setType} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <ProductAliasFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
