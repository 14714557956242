import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import GaugeChart from 'react-gauge-chart'


export default function LoadListListItemIndexAge(props) {
    //console.debug("RTS Break LoadListListItemIndexAge");

    const daysOld = props.data.shipDateDays.toLocaleString();
    const ageIndexLimit = props.data.product.productAlias !== null?props.data.product.productAlias.length>0?props.data.product.productAlias[0].ageIndexLimit:365:365;

    const daysOldPercent = props.data.shipDateDays/ageIndexLimit>1?1:props.data.shipDateDays/ageIndexLimit;


    return (
        <Stack sx={{textAlign:'center'}}>
            <Box sx={{width:'100px'}}>
                <GaugeChart id="lengthIdx" 
                    nrOfLevels={10} 
                    percent={daysOldPercent}
                    hideText={true}
                    colors={["#00FF00", "#FF0000"]}
                    animate={!props.hideLinks}
                />
            </Box>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Days Old</Typography>
                <Typography variant="body2">{daysOld}</Typography>
        </Stack>
    );
}
