import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Loading from '../components/Loading';
import Error from '../components/Error';
import LocatorList from '../components/Locator/LocatorList';
import LocatorMap from '../components/Locator/LocatorMap';
import LocatorFilter from '../components/Locator/LocatorFilter';
import LocatorMode from '../components/Locator/LocatorMode';

import { useSessionContext } from '../contexts/SessionContext';

export default function LocatorPage( props ) {
    //console.debug("RTS Break LocatorPage");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    //const [baseMode, setBaseMode] = useState(0);
    const [mode, setMode] = useState(-2);
    const [data, setData] = useState([]);
    //const [childRecord, setChildRecord] = useState({});
    const [hasError, setHasError] = useState(false);

    const [locatorFilters, setLocatorFilters] = useState({});
    
    const [filter, setFilter] = useState(
        {
            "user":user, 
            "products":[], 
            "reelTypes":[],
            "tracking":0,
            "minLength":0,
            "maxAge":0,
            "sortBy":0 
        }
    );


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/Locator/GetLocatorList";
        axios
        .post(
            apiURL,
            filter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                if(response.data.length === 0)
                {
                    setMode(-1);
                }
                setIsLoading(false);
                setHasError(false);
            }
            else{
                setMode(-1);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token, filter]);


    useEffect(() => {
        props.setIsModalFx(false);
        if(mode > -2){
            fetchData();
        }
    }, [props, mode, filter, fetchData]);

    
    const fetchFilters = useCallback(() => {
        let apiURL = apiRoot + "/Locator/GetLocatorFilters";
        axios
        .post(
            apiURL,
            user,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setLocatorFilters(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });        
    },[apiRoot, userAuthToken.token, user]);


    useEffect(() => {
        props.setIsModalFx(false);
        fetchFilters();
    },[props, fetchFilters]);
    

    function dataFilter(args){
        setIsLoading(true);
        let locFilter = {"user": user,
                        "products": args.products,
                        "reelTypes": args.reelTypes, 
                        "tracking": args.tracking,
                        "minLength": args.minLength,
                        "maxAge": args.maxAge,
                        "sortBy": args.sortBy 
                    }

        setFilter(locFilter);
        setMode(0);
    }


    // function dataRefresh(){
    //     setIsLoading(true);
    //     fetchData();
    //     setMode(0);
    // }


    // function openChild(args){
    //     if(args['idx'] != null){
    //         let childIdx = args.idx;
    //         setChildRecord(data[childIdx]);
    //     }
    //     setMode(args.mode);
    // }


    function openBaseMode(args){
        setIsLoading(true);
        //setBaseMode(args);
        setMode(args);
        setIsLoading(false);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <Box id="pageBox">
            <Box id="filterBox">
                <LocatorFilter filter={filter} setFilterFx={dataFilter} locatorFilters={locatorFilters} />            
            </Box>
            <Box id="modeBox">
                <LocatorMode data={data} modeFx={openBaseMode} mode={mode} reelCount={data===null?0:data.length} />            
            </Box>            
            <Box id="contentBox">
                {mode===-2?<Box sx={{textAlign:"center", pt:2}}><Typography variant='h5'>Select the needed filters and click the Find button</Typography></Box>:""}
                {mode===-1?<Box sx={{textAlign:"center", pt:2}}><Typography variant='h5'>No Data Found</Typography></Box>:""}
                {mode===0?<LocatorList data={data} user={user} />:""}
                {mode===1?<LocatorMap data={data} user={user} />:""}
            </Box> 
        </Box>
    );
}

