import axios from 'axios';
import {useState} from 'react';
import Link from '@mui/material/Link';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { useSessionContext } from '../../contexts/SessionContext';


export default function LoadListListItemLoadPin(props) {
    //console.debug("RTS Break LoadListListItemLoadLock");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const[data, setData] = useState(props.data);
    const[loadLock, setLoadLock] = useState(props.data.loadLock);

    function SetLockStatus(){

        let curLock = 0;
        if(loadLock === 0){
            curLock = 1;
        }

        let apiObj = {
            AppUser: user,
            Customer: sessionCustomer.customer,
            Load: data,
            Action: curLock
        }


        let apiURL = apiRoot + "/LoadList/LoadLock";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                if(response.data === 1){
                    let locData = data;
                    locData.loadLock = 1;
                    setLoadLock(1);
                    setData(locData);
                    //props.setDataFx(locData);
                }
                else{
                    let locData = data;
                    locData.loadLock = 0;
                    setLoadLock(0);
                    setData(locData);
                    //props.setDataFx(locData);
                }
            }
            else{
                let locData = data;
                locData.loadPin = 0;
                setLoadLock(0);
                setData(locData);
                //props.setDataFx(locData);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
        });
    }


    return (
        <Link onClick={SetLockStatus} sx={{textDecoration:'none', cursor:'pointer', display:'flex', alignItems:'center'}}>
        {
        loadLock === 1
        ?
            <>
            <LockIcon sx={{fontSize:'1.5em', color:'red', ml:1, display:'flex', alignItems:'center'}}  />
            </>
        :
            <>
            <LockOpenIcon sx={{fontSize:'1.5em', color:'black', ml:1, display:'flex', alignItems:'center'}}  />
            </>
        }
        </Link>
    );
}
