import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import WorkSetsDelete from '../../components/WorkSets/WorkSetsDelete';

export default function WorkSetsDeletePage( props ){
    //console.debug("RTS Break WorkSetsDeletePage");

    const location = useLocation();
    const data = location.state.workSet;
    const mode = location.state.mode;

    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 

    return(
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box>
                 <WorkSetsDelete data={data} mode={mode} />
            </Box>
        </Container>
    );
}
