import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import ClearIcon from '@mui/icons-material/Clear';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function UsersEditCustomersList(props){
    //console.debug("RTS Break UsersEditCustomersList")

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));


    function handleClick(e, cellValues){
        props.removeCustomerFx(cellValues.row);
    }


    const columns = [
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:3,
            valueGetter: (value, row) => {
                return row.customer.name;
            }   
        },        
        {
            field: 'idSap', 
            headerName: "SAP Id", 
            description:"Customer SAP Id", 
            flex:2,
            valueGetter: (value, row) => {
                return row.customer.idSap;
            }   
        },
        {
            field: 'accessLevel', 
            headerName: "Role", 
            description:"User Role", 
            flex:2,
            valueGetter: (value, row) => {
                var retValue = 'Manager';

                switch(row.accessLevel){
                    case 1:
                        retValue='Crew Member';
                        break;

                    case 25:
                        retValue = 'Associate';
                        break;
    
                    case 50:
                        retValue = 'Representative';
                        break;

                    case 75:
                        retValue='Administrator';
                        break;

                    default:
                        retValue = 'Unknown';

                }

                return retValue;
            }   
        },
        {
            field: 'salesOffice', 
            headerName: "Office Id", 
            description:"Sales Office Id", 
            flex:3,
            valueGetter: (value, row) => {
                return row.customer.salesOffice;
            },
            hide:true                      
        },
        {
            field: 'salesGroup', 
            headerName: "Group Id", 
            description:"Sales Group Id", 
            flex:3,
            valueGetter: (value, row) => {
                return row.customer.salesGroup;
            },
            hide:true                
        },
        {
            field: '',
            description:"Remove Customer", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <ClearIcon sx={{color:"red"}} />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {salesOffice:false, salesGroup:false}
        }
        else{
            return {}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }


    return (
        <Box sx={{display:'flex', flexDirection:'column', width:'100%', minHeight:'300px'}}>        
            <DataGridPro
                disableColumnFilter
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },                        
                    }
                }} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.customer.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    density: getDensity() 
                }}                
            />
        </Box>
    );
}