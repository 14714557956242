import axios from 'axios';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ManuReelsEditRemoveTracker(props) {
    //console.debug("RTS Break ManuReelsEditRemoveTracker");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    
    function handleSubmit(e) {
        e.preventDefault();

        // Set the tracker for the reel to null.
        let apiObj = props.removeReel;
        apiObj.tracker = null;

        let apiURL = apiRoot + "/Reels/UpdateReel";
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.confirmRemoveFx();       
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateTracker Error", error);
            setShowErrorModal(true);
        });
    
    }


    function handleCancel(e){
        e.preventDefault();
        props.setShowRemoveTrackerModalFx(false);       
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box>
            <form id="trackerSequenceId" onSubmit={handleSubmit}>
                <Typography>Tracker {props.tracker.esn} is already associated with Reel {props.removeReel.serialNumber}.</Typography>
                <Typography sx={{mt:1}}>Would you like to remove it from that Reel?</Typography>
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Yes</Button>
                    <Button variant="contained" onClick={handleCancel}>No</Button>
                </Box>
            </form>
        </Box>
        </>
    );
}
