import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { format, parseISO } from 'date-fns';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';

import ReelTypeImage from '../ReelTypeDimensions.png';

import LoadListListItemReelInspection from './LoadListListItemReelInspection';
import LoadListListItemReelLock from './LoadListListItemReelLock';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListListItemReel(props){
    //console.debug("RTS Break LoadListListItemReel");

    const [showReelTypeModal, setShowReelTypeModal] = useState(false);

    function handleClick(e){
        setShowReelTypeModal(true);
    }

    function showDeferral(){
        if(props.data.deferralPeriod > 0 && props.data.status < 100){
            return true;
        }   
        else{
            return false;
        }
    }

    return (
    <>
        <Dialog open={showReelTypeModal} onClose={()=>{setShowReelTypeModal(false);}} fullWidth={true} maxWidth="md">
            <DialogTitle>{props.data.reelType.name}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">{props.data.reelType.desc}</Typography>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Material:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.material}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Aluminum Assoc. Id:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.alumAssoc}</Typography>
                        </Box>
                        <Typography variant="h6" sx={{mt:1}}>Dimensions (inches)</Typography>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Flange Size:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.flangeSize}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Traverse Size:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.traverseSize}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Drum Size:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.drumSize}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Max Overall Width:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.maxOverallWidth}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Arbor Hole Size:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.arborHoleSize}</Typography>
                        </Box>
                        <Typography variant="h6" sx={{mt:1}}>Weights (lbs)</Typography>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Weight Capacity:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.weightCapacity!=null?props.data.reelType.weightCapacity.toLocaleString():''}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Weight:</Typography>
                            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.approxWeight!=null?props.data.reelType.approxWeight.toLocaleString():''}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{mt:{xs:2, md:0}}}>
                        <CardMedia
                            component="img"
                            image={ReelTypeImage}
                            onLoad={() => console.log("this is loading")}
                            onError={() => console.log("this is error")}
                            alt="This is a sample image"
                            sx={{
                            maxWidth: {
                                xs: "100%",
                                sm: "500px",
                            },
                            objectFit: "cover",
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowReelTypeModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>    
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Serial Number:</Typography>
            {props.data.reel!=null
            ?
            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reel.serialNumber}</Typography>
            :
            <Typography variant="body2" sx={{ml:'2px'}}>None</Typography>
            }
        </Box>
        <Link onClick={(event) => {handleClick(event);}} sx={{textDecoration:'none', cursor:'pointer'}}>
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Type:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{props.data.reelType.name}</Typography>
            </Box>
        </Link>
        {props.data.tracker!=null
        ?
        <>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Tracker:</Typography>
            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.tracker.esn}</Typography>
        </Box>
        {props.data.status < 100
        ?
        <>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Battery Level:</Typography>
            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.tracker.batteryLevel}</Typography>
            <Typography variant="body2">%</Typography>
        </Box>
        <Box sx={{display:'flex'}}>
        {
            props.data.tracker.lastUpdate === null
            ?
            <>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Last Update:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:'red'}}>No Updates</Typography>
            </>
            :
            props.data.tracker.lastUpdateDays <2 
            ?
            <>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Last Update:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:'green'}}>{format(parseISO(props.data.tracker.lastUpdate), "MM-dd-yyyy")}</Typography>
            </>
            :
            props.data.tracker.lastUpdateDays <3
            ?
            <>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Last Update:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:'gold'}}>{format(parseISO(props.data.tracker.lastUpdate), "MM-dd-yyyy")}</Typography>
            </>
            :
            props.data.tracker.lastUpdateDays <4
            ?
            <>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Last Update:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:'orange'}}>{format(parseISO(props.data.tracker.lastUpdate), "MM-dd-yyyy")}</Typography>
            </>
            :
            props.data.tracker.lastUpdateDays <5
            ?
            <>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Last Update:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:'red'}}>{format(parseISO(props.data.tracker.lastUpdate), "MM-dd-yyyy")}</Typography>
            </>
            :
            <>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Last Update:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:'red'}}>{format(parseISO(props.data.tracker.lastUpdate), "MM-dd-yyyy")}</Typography>
            </>
            }
        </Box>
        <Box sx={{display:'flex', alignItems:'center'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Last Move:</Typography>
            {
            props.data.currentLocation !== null
            ?
                <>
                <Typography variant="body2" sx={{ml:'2px', display:'flex', alignItems:'center'}}>{format(parseISO(props.data.currentLocation.locTimeStamp), "MM-dd-yyyy")}</Typography>
                </>
            :
                <>
                <Typography variant="body2" sx={{ml:'2px', display:'flex', alignItems:'center'}}>Unknown</Typography>
                </>
            }
            <LoadListListItemReelLock data={props.data} />
        </Box> 
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Days Stationary:</Typography>
            <Typography variant="body2" sx={{ml:'2px'}}>{props.data.daysStationary}</Typography>
        </Box>
        </>
        :
        ''
        }        
        </>
        :
        <>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Tracker:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:"red"}}>Not Tracked</Typography>
        </Box>
        {props.data.status < 100
        ?
        <>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Battery Level:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:"red"}}>Not Tracked</Typography>
        </Box>
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Last Move:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:"red"}}>Not Tracked</Typography>
        </Box> 
        <Box sx={{display:'flex'}}>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Days Stationary:</Typography>
            <Typography variant="body2" sx={{ml:'2px', color:"red"}}>Not Tracked</Typography>
        </Box>
        </>
        :
        ''
        }        
        </>
        }
        <LoadListListItemReelInspection data={props.data}/>
        <Box sx={{display:'flex'}}>
                {props.data.status < 100
                ?            
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Deferral:</Typography>
                :
                ''
                }
            <Box>
                {props.data.deferralPeriod > 0
                ?
                    props.data.status < 100
                    ?
                    <>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.deferralPeriod}</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>Months</Typography>
                    </Box>
                    <Box sx={{display:'flex'}}>
                        {
                        props.data.deferralDaysLeft>90
                        ?
                        <>
                        <Typography variant="body2" sx={{ml:'2px', color:'green', fontWeight:'bold', fontSize:'1rem'}}>{props.data.deferralDaysLeft}</Typography>
                        <Typography variant="body2" sx={{ml:'2px', color:'green', fontWeight:'bold', fontSize:'1rem'}}>Days Remaining</Typography>
                        </>
                        :
                        props.data.deferralDaysLeft > 60
                        ?
                        <>
                        <Typography variant="body2" sx={{ml:'2px', color:'gold', fontWeight:'bold', fontSize:'1rem'}}>{props.data.deferralDaysLeft}</Typography>
                        <Typography variant="body2" sx={{ml:'2px', color:'gold', fontWeight:'bold', fontSize:'1rem'}}>Days Remaining</Typography>
                        </>
                        :
                        props.data.deferralDaysLeft > 30
                        ?
                        <>
                        <Typography variant="body2" sx={{ml:'2px', color:'orange', fontWeight:'bold', fontSize:'1rem'}}>{props.data.deferralDaysLeft}</Typography>
                        <Typography variant="body2" sx={{ml:'2px', color:'orange', fontWeight:'bold', fontSize:'1rem'}}>Days Remaining</Typography>
                        </>
                        :
                        props.data.deferralDaysLeft >= 0
                        ?
                        <>
                        <Typography variant="body2" sx={{ml:'2px', color:'red', fontWeight:'bold', fontSize:'1rem'}}>{props.data.deferralDaysLeft}</Typography>
                        <Typography variant="body2" sx={{ml:'2px', color:'red', fontWeight:'bold', fontSize:'1rem'}}>Days Remaining</Typography>
                        </>
                        :
                        <>
                        <Typography variant="body2" sx={{ml:'2px', color:'red', fontWeight:'bold', fontSize:'1rem'}}>{props.data.deferralDaysLeft*-1}</Typography>
                        <Typography variant="body2" sx={{ml:'2px', color:'red', fontWeight:'bold', fontSize:'1rem'}}>Days Overdue</Typography>
                        </>
                        }
                    </Box>
                    </>
                    :
                    ''
                :
                <>
                <Box sx={{display:'flex'}}>
                    <Typography variant="body2" sx={{ml:'2px'}}>No Deferral</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography variant="body2" sx={{ml:'2px'}}>{props.data.deferralDaysLeft*-1}</Typography>
                    <Typography variant="body2" sx={{ml:'2px', color:'green'}}>Days On Site</Typography>
                </Box>
                </>
                }
            </Box>
        </Box>
    </>
    )
}

