import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Loading from '../../components/Loading';
import Error from '../../components/Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PalletDashboardHeader(props){
    //console.debug("RTS Break PalletDashboardHeader");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/PalletDashboard/GetPalletSummary";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);
    

    useEffect(() => {
        fetchData();
    },[fetchData]);  
    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Grid container sx={{mb:1}}>
            <Grid item xs={3}>
                <Box sx={{textAlign:'center'}}>
                    <Box>
                        <Typography variant="h5">Useable Pallets</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5">{data.pallets}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box sx={{textAlign:'center'}}>
                    <Box>
                        <Typography variant="h5">Pallets In</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5">{data.palletsIn}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box sx={{textAlign:'center'}}>
                    <Box>
                        <Typography variant="h5">Pallets Out</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5">{data.palletsOut}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box sx={{textAlign:'center'}}>
                    <Box>
                        <Typography variant="h5">Disabled Pallets</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5">{data.palletsDisabled}</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

