import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import DashboardKPI from "../components/Dashboard/DashboardKPI";
import DashboardPinned from '../components/Dashboard/DashboardPinned';
import DashboardLocked from '../components/Dashboard/DashboardLocked';
import DashboardProject from '../components/Dashboard/DashboardProject';
import DashboardLocation from '../components/Dashboard/DashboardLocation';
import DashboardAlert from '../components/Dashboard/DashboardAlert';

import { useSessionContext } from '../contexts/SessionContext';

export default function DashboardPage( props ) {
    //console.debug("RTS Break DashboardPage");

    const {sessionCustomer} = useSessionContext();

    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        props.setIsModalFx(false);
    }, [props]); 

    const handleChange = (e, curIndex) => {
      setTabIndex(curIndex);
    };
    
    return(
        <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:1 }}>
            <Tabs value={tabIndex} onChange={handleChange}>
                <Tab label="Overview" value={0}  />
                <Tab label="Pinned" value={1} />
                {
                sessionCustomer.accessLevel >= 50
                ?
                    <Tab label="Locked" value={2} />
                :
                    ''
                }
                {
                sessionCustomer.accessLevel >= 50
                ?
                    <Tab label="Projects" value={3} />
                :
                    ''
                }
                {
                sessionCustomer.accessLevel >= 50
                ?
                    <Tab label="Locations" value={4} />
                    :
                    ''
                }
                {
                sessionCustomer.accessLevel >= 50
                ?
                    <Tab label="Alerts" value={5} />
                    :
                    ''
                }
            </Tabs>
        </Box>

        {
        tabIndex === 1
        ?
            <DashboardPinned IsModal />
        :
            tabIndex === 2
            ?
                <DashboardLocked IsModal />
            :
                tabIndex === 3
                ?
                    <DashboardProject IsModal />
                :
                    tabIndex === 4
                    ?   
                        <DashboardLocation IsModal />
                    :
                        tabIndex === 5
                        ?   
                            <DashboardAlert IsModal />
                        :
                            <DashboardKPI IsModal />
        }
        </>
    );
}
