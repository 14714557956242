import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import Error from '../Error';
import EditStatus from '../EditStatus';
import ReelsEditReelTypeLookup from './ReelsEditReelTypeLookup';
import ReelsEditTrackerLookup from './ReelsEditTrackerLookup';
import ReelsEditTrackerSequenceId from './ReelsEditTrackerSequenceId';
import ReelsEditRemoveTracker from './ReelsEditRemoveTracker';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelsEdit(props) {
    //console.debug("RTS Break ReelsEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    //const display = props.display;
    const mode = props.mode;
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showReelTypeLookup, setShowReelTypeLookup] = useState(false);
    const [showTrackerLookup, setShowTrackerLookup] = useState(false);
    const [showSequenceModal, setShowSequenceModal] = useState(false);
    const [showRemoveTrackerModal, setShowRemoveTrackerModal] = useState(false);
    const [removeReel, setRemoveReel] = useState(null);

    const [idGuid, setIdGuid] = useState('')
    const [reelType, setReelType] = useState(null);
    const [tracker, setTracker] = useState(null);
    const [status, setStatus] = useState(1);
    const [serialNumber, setSerialNumber] = useState('');
    const [idSap, setIdSap] = useState('');

    
    const fetchData = useCallback((idGuid) => {
        let apiURL = apiRoot + "/Reels/GetReelById/" + idGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);

                setIdGuid(response.data.idGuid)
                setReelType(response.data.reelType);
                setTracker(response.data.tracker);
                setStatus(response.data.status);
                setSerialNumber(response.data.serialNumber!==null?response.data.serialNumber:'');
                setIdSap(response.data.idSap!==null?response.data.idSap:'');
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);

    
    useEffect(() => {
        if(mode === 1){
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }

    },[mode, fetchData, props.idGuid]);  


    async function handleSubmit(e) {
        e.preventDefault();

        if(serialNumber === null){
            setErrorTitle("Validation Error");
            setErrorBody("Serial Number is required");
            setShowErrorModal(true);
            return;
        }

        if(reelType === null){
            setErrorTitle("Validation Error");
            setErrorBody("Reel Type is required");
            setShowErrorModal(true);
            return;
        }

        let serialNumberCheck = await validateSerialNumber();
        if(serialNumberCheck === -1){
            return;
        }

        if(tracker !== null){
            let trackerCheck = await validateTracker();

            if(trackerCheck === -1){
                return;
            }
        }

        saveData();
    };


    function validateSerialNumber(){
        return new Promise(function(resolve, reject){
            let apiURL = apiRoot + "/Reels/GetReelBySerialNumber/" + serialNumber
            axios
            .get(
                apiURL,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    if(response.data.idGuid && response.data.idGuid.length > 0 && response.data.idGuid !== idGuid){
                        setErrorTitle("Validation Error");
                        setErrorBody("A Reel with Serial Number " + serialNumber + " already exists");
                        console.log("RTS Error", "Reel already exists");
                        setShowErrorModal(true);
                        resolve(-1);
                    }
                    else{
                        resolve(0);
                    }                    
                }
                else{
                    resolve(0);
                }
            })
            .catch(error => {
                setErrorTitle("Validation Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS Tracker Validation Error", error);
                setShowErrorModal(true);
                reject(-1);
            });
        });
    };


    function validateTracker(){
        return new Promise(function(resolve, reject){
            let apiURL = apiRoot + "/Reels/ValidateReelTracker"
            axios
            .post(
                apiURL,
                tracker, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    if(response.data.idGuid && response.data.idGuid.length > 0 && response.data.idGuid !== idGuid){
                        setRemoveReel(response.data);
                        setShowRemoveTrackerModal(true);
                        resolve(-1);
                    }
                    else{
                        resolve(0);
                    }
                }
                else{
                    resolve(0);
                }
            })
            .catch(error => {
                setErrorTitle("Validation Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS Tracker Validation Error", error);
                setShowErrorModal(true);
                reject(-1);
            });
        });
    };


    function confirmRemove(){
        setShowRemoveTrackerModal(false);
        saveData();        
    }


    function saveData(){
        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            SerialNumber: serialNumber===''?null:serialNumber,
            ReelType: reelType,
            Tracker: tracker,
            Status: status,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Reels/UpdateReel";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateReelType Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Reels/CreateReel";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateReelType Error", error);
                setShowErrorModal(true);
            });
        }
    }; 


    function selectReelType(val){
        setShowReelTypeLookup(false);
        setReelType(val);
    };


    function clearReelType(){
        setShowTrackerLookup(false);
        setTracker(null);
    }    


    function selectTracker(val){
        setShowTrackerLookup(false);
        setTracker(val);
    };


    function clearTracker(){
        setShowTrackerLookup(false);
        setTracker(null);
    }


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };    


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showSequenceModal} onClose={()=>{setShowSequenceModal(false);}}>
            <DialogTitle>Change Sequnce Id</DialogTitle>
            <DialogContent>
                <ReelsEditTrackerSequenceId tracker={tracker} setTrackerFx={setTracker} setShowSequenceModalFx={setShowSequenceModal} />
            </DialogContent>
        </Dialog>
        <Dialog open={showReelTypeLookup} fullWidth={true} maxWidth="sm" onClose={()=>{setShowReelTypeLookup(false);}}>
            <DialogTitle>Select Reel Type</DialogTitle>
            <DialogContent>
                <ReelsEditReelTypeLookup reelType={reelType} setReelTypeFx={selectReelType} clearReelTypeFx={clearReelType} setFormDirtyFx={props.setFormDirtyFx} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowReelTypeLookup(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <Dialog open={showTrackerLookup} fullWidth={true} maxWidth="sm" onClose={()=>{setShowTrackerLookup(false);}}>
            <DialogTitle>Select Tracker</DialogTitle>
            <DialogContent>
                <ReelsEditTrackerLookup tracker={tracker} setTrackerFx={selectTracker} clearTrackerFx={clearTracker} setFormDirtyFx={props.setFormDirtyFx} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowTrackerLookup(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showRemoveTrackerModal} onClose={()=>{setShowRemoveTrackerModal(false);}}>
            <DialogTitle>Reel Tracker Association Found</DialogTitle>
            <DialogContent>
                <ReelsEditRemoveTracker removeReel={removeReel} tracker={tracker} confirmRemoveFx={confirmRemove} setShowRemoveTrackerModalFx={setShowRemoveTrackerModal} />
            </DialogContent>
        </Dialog>                         
        <Box>
            <form id="reelForm" onSubmit={handleSubmit} >
                <Box id="mdBox" maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbSerialNumber"
                    margin="normal"
                    fullWidth
                    label="Serial Number"
                    size="small"
                    required
                    value={serialNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setSerialNumber(e.target.value);}}
                />                                               
                <Box sx={{display:'flex', alignItems:'center', width:'100%'}}>
                    <TextField
                        id="tbReelType"
                        margin="normal"
                        fullWidth
                        label="ReelType"
                        size="small"
                        required
                        value={reelType==null?'':reelType.name}
                        onClick={setShowReelTypeLookup}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowReelTypeLookup}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <Box sx={{display:'flex', alignItems:'center', width:'100%'}}>
                    <TextField
                        id="tbTracker"
                        margin="normal"
                        fullWidth
                        label="Tracker"
                        size="small"
                        value={tracker==null?'':tracker.esn}
                        onClick={setShowTrackerLookup}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowTrackerLookup}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={6} sx={{display:"flex", justifyContent:"start", alignItems:"center"}}>
                        <Typography sx={{fontSize:'.75em'}}>Last Check In:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!=null?tracker.lastUpdate!=null?format(parseISO(tracker.lastUpdate), "MM-dd-yyyy"):'':''}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{display:"flex", justifyContent:{xs:"start", sm:"end"}, alignItems:"center"}}>
                        <Typography sx={{fontSize:'.75em'}}>Battery Level:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!=null?tracker.batteryLevel!=null?tracker.batteryLevel!=='-1'?tracker.batteryLevel+"%":'Unknown':'Unknown':''}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{display:"flex", justifyContent:{xs:"start", sm:"center"}, alignItems:"center", mt:1}}>
                        <Typography sx={{fontSize:'.75em'}}>Sequence Id:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!=null?tracker.idSeq!=null?tracker.idSeq:'-1':''}</Typography>
                        <Button variant="text" sx={{"p":0, "m":0, display:tracker!=null?"initial":"none", "pl":1}} onClick={()=>{setShowSequenceModal(true)}}>Change</Button>
                    </Grid>
                </Grid>
                <EditStatus status={status} setStatusFx={setStatus} setFormDirtyFx={props.setFormDirtyFx} /> 
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit">Save</Button>
                </Box>
                </Box>
                </Box>
            </form>
        </Box>
        </>
    );
}
