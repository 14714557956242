import axios from 'axios';
import {useState, useCallback, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Loading from '../Loading';
import Error from '../Error';
import LoadListList from '../LoadList/LoadListList';
import LoadListMap from '../LoadList/LoadListMap';
import LoadListMode from '../LoadList/LoadListMode';

import { useSessionContext } from '../../contexts/SessionContext';

export default function DashbaordPinned( props ) {
    //console.debug("RTS Break DashbaordPinned");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [mode, setMode] = useState(0);
    const [data, setData] = useState([]);
    const [hasError, setHasError] = useState(false);


    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/LoadList/GetLoadList";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                if(response.data.length === 0)
                {
                    setMode(-1);
                }
                setIsLoading(false);
                setHasError(false);
            }
            else{
                setMode(-1);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData({
            "user":user, 
            "status":[1,25,50,75,100],
            "customer":sessionCustomer.customer,
            "orders":[], 
            "projects":[], 
            "products":[], 
            "reelTypes":[],
            "reels":[],
            "minLength":0,
            "namedLocations":[],
            "pinned":1,
            "locked":-1, 
            "sortBy":0
        });
    }, [user, sessionCustomer.customer, fetchData]);


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    return(
        <Box id="pageBox">
            <Box id="modeBox">
                <LoadListMode data={data} mode={mode} modeFx={setMode} reelCount={data===null?0:data.length} />            
            </Box>            
            <Box id="contentBox">
                {mode===-1?<Box justifyContent={"center"} alignItems={"center"} display={"flex"}><Typography variant='h4'>No Records Found</Typography></Box>:""}
                {mode===0?<LoadListList data={data} user={user} />:""}
                {mode===1?<LoadListMap data={data} user={user} />:""}
            </Box> 
        </Box>
    );
}

