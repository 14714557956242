import axios from 'axios';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

import LoadListListItem from "../LoadList/LoadListListItem";

import Loading from '../Loading';
import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPullAdjustmentEdit(props) {
    console.debug('RTS Break LoadPullAdjustmentEdit');

    const history = useNavigate();

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [pullLength, setPullLength] = useState();
    const [pullDate, setPullDate] = useState(new Date());
    const [notes, setNotes] = useState("");
    const [crew, setCrew] = useState();
    const maxValue = props.data.amt;


    useEffect(() => {
        setPullLength(0);
        setPullDate(new Date());
        setNotes("");
        setCrew(props.data.crew);
        
        setIsLoading(false);
    }, [props.data.crew]);     


    function handleSubmit(e) {
        e.preventDefault();

        const pullVal = parseInt(pullLength);
        const pullSum = props.data.pullSum + pullVal;
        const valCheck = Math.floor(props.data.amt - pullSum);

        if(pullSum < 0){
            setErrorTitle("Validation Error");
            setErrorBody("The length entered exceeds the product shipped on the reel");
            setShowErrorModal(true);
            return;            
        }

        if(pullSum > props.data.amt){
            setErrorTitle("Validation Error");
            setErrorBody("The length entered exceeds the product remaining on the reel");
            setShowErrorModal(true);
            return;            
        }

        if(valCheck < 0){
            setErrorTitle("Validation Error");
            setErrorBody("The length pulled exceeds the product remaining on the reel");
            setShowErrorModal(true);
            return;            
        }

        let apiObj = {
            PullLoad: props.data,
            PullType: 'Adjustment',
            PullLength: pullLength,
            Uom: props.data.uom,
            PullDate: pullDate,
            Lat: null,
            Lng: null, 
            Notes: notes,
            AdjustmentFlag: 1,
            ConfirmedFlag: 1,
            CalculatedFlag: 0,
            Crew: crew,
            CompanyLink: null,
            createUser: user.userName,
            modifyUser: user.userName
        }

        let apiURL = apiRoot + "/LoadPulls/CreateLoadPull"

        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            window.scrollTo(0,0);
            history(-1);
        })
        .catch(error => {
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateLoadPull Error", error);
            setShowErrorModal(true);
        });
    } 


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 
            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit} style={{width:'400px'}}>
                <Typography variant="h6" sx={{mb:2, mt:1}}>Enter Pull Adjustment Details</Typography>
                <TextField
                        id="tbLength"
                        margin="normal"
                        required
                        fullWidth
                        label="Length"
                        type="number"
                        size="small"
                        value={pullLength}
                        onChange={(e) => {setPullLength(e.target.value);}}
                        InputProps={{inputProps:{max:{maxValue}}}}
                    />
                    <DatePicker
                        id="tbPullDate"
                        label="Adjustment Date"
                        inputFormat="MM/dd/yyyy"
                        value={pullDate}
                        onChange={(val) => setPullDate(val)}
                        renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                        maxDate={new Date()}
                        readOnly={true}
                    />   
                    <TextField
                        id="tbNotes"
                        margin="normal"
                        required
                        fullWidth
                        label="Notes"
                        size="small"
                        value={notes}
                        onChange={(e) => {setNotes(e.target.value);}}
                        multiline
                        rows={4}
                    />                                     
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit" variant="contained" sx={{mr:2}}>Record Adjustment</Button>
                        </Box>
                    </Box>
                </form>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
