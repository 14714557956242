import axios from 'axios';
import {useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Image from 'mui-image';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import Copyright from './Copyright';
import logo from '../pages/logo-Southwire.png';

import { useSessionContext } from '../contexts/SessionContext';

export default function PasswordReset(props) {
    //console.debug("RTS Break PasswordReset");    

    const {apiRoot} = useSessionContext();

    const mode = props.mode;
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);


    function handleSubmit(e) {
        e.preventDefault();
        let errorCount = 0;

        if(password.length < 8){
            errorCount++;
        }

        if(!/[A-Z]/.test(password)){
            errorCount++;
        }

        if(!/[a-z]/.test(password)){
            errorCount++;
        }

        if(!/[0-9]/.test(password)){
            errorCount++;
        }

        if(!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)){
            errorCount++;
        }

        if(password !== passwordConfirm){
            errorCount++;
        }

        if(errorCount > 0){
            setShowPasswordError(true);
            return;
        }     

        let apiObj = {
            Mode: mode,
            UserId: props.userName,
            Password: password,
            Token: props.passwordToken
        }
        
        let apiURL = apiRoot + "/Authentication/ResetAppUserPassword";
        axios
        .put(
            apiURL,
            apiObj, 
            {httpsAgent: {rejectUnauthorized: false}}
        )
        .then(response => {
            setShowMessage(true);                
        })
        .catch(error => {
            setShowErrorModal(true);
            console.log("Password Reset Unsuccessful", error);
        });
    } 

    function closeConfirm(){
        setShowMessage(false); 
        props.setShowPasswordModalFx(false);
        let url = window.location.origin;
        window.location.href = url;
    }    

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>Unable to Set Password</DialogTitle>
            <DialogContent>
                <div>
                    <Typography>The password cannot be set at this time.  Please review the console for more information.</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <Dialog open={showPasswordError} onClose={()=>{setShowPasswordError(false);}}>
            <DialogTitle>Password Not Validated</DialogTitle>
            <DialogContent>
                <div>
                    <Typography>The password must meet the following criteria:</Typography>
                    <ul>
                        <li>At least 8 characters long.</li>
                        <li>At least 1 capital (uppercase) letter.</li>
                        <li>At least 1 lower case letter.</li>
                        <li>At least 1 number.</li>
                        <li>At least 1 special character.</li>
                        <li>Password and Password Confirm must match.</li>
                    </ul>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowPasswordError(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showMessage} onClose={()=>{setShowMessage(false);}}>
            <DialogTitle>Password Set</DialogTitle>
            <DialogContent>
                <DialogContentText>The password for the account has been successfully set.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {closeConfirm();}}>Close</Button>
            </DialogActions>
        </Dialog>                 
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Image src={logo} style={{width: "65%"}} />
                <Typography component="h1" variant="h5">
                    Reel Tracking System
                </Typography>
                <Typography component="h1" variant="h5">
                    {mode === 1?'Password Reset':'Set Password'}
                </Typography>            
                <Box>
                    <Typography sx={{mt:1}}>Enter the new password for your account</Typography>
                </Box>            
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <TextField
                        id="tbPassword"
                        margin="normal"
                        fullWidth
                        label="User Password"
                        size="small"
                        required
                        value={password}
                        onChange={(e) => {setPassword(e.target.value);}}
                    />

                    <TextField
                        id="tbPasswordConfirm"
                        margin="normal"
                        fullWidth
                        label="Confirm User Password"
                        size="small"
                        required
                        value={passwordConfirm}
                        onChange={(e) => {setPasswordConfirm(e.target.value);}}
                    /> 
                    <Box display="flex" sx={{textAlign:'center', mb:2}}>
                        <Box sx={{textAlign:'left', display:'inline'}}>
                            <Typography variant="caption" sx={{display:'block', fontWeight:'bold'}}>Password Requirements:</Typography>
                            <Typography variant="caption" sx={{display:'block'}}>- Minimum 8 characters</Typography>
                            <Typography variant="caption" sx={{display:'block'}}>- At least 1 uppercase character</Typography>
							<Typography variant="caption" sx={{display:'block'}}>- At least 1 lower case letter.</Typography>
							<Typography variant="caption" sx={{display:'block'}}>- At least 1 number.</Typography>
							<Typography variant="caption" sx={{display:'block'}}>- At least 1 special character.</Typography>
                        </Box>
                    </Box>                                    
                    <Box sx={{textAlign:"center", mt:2}}>
                        <Button variant="contained" sx={{mr:2}} onClick={handleSubmit}>Set Password</Button>
                        <Button variant="contained" onClick={e => {props.setShowPasswordModalFx(false);}}>Cancel</Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 3, mb: 2 }} />
            </Box>
        </Container> 
        </>           
    );
}
