import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function CrewsFilterStatus(props) {
    //console.debug("RTS Break CrewsFilterStatus");

    const [status, setStatus] = useState(props.status==null?-1:props.status);
    const [repoStatus] = useState([{value:-1, label:"All"}, {value:0, label:"Disabled"}, {value:1, label:"Active"}]);


    function valChange(e, val){
        setStatus(val.value);
        props.setStatusFx(val.value);
        props.setFormDirtyFx(1);
    }

 
    return(
        <>
            <Autocomplete
                options={repoStatus}
                renderInput={(params) => <TextField {...params} label="Status"  size="small" />}
                value={repoStatus.find(v => v.value===status)}
                onChange={valChange}
                getOptionLabel={(repoStatus) => repoStatus.label}
                disableClearable={true}
                renderOption={(props, repoStatus) => (
                    <Box component="li" {...props} key={repoStatus.value}>
                        {repoStatus.label}
                    </Box>
                )}
            />
        </>
    );
}
