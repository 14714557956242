import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpOverview(props) {
    //console.debug('RTS Break HelpOverview');

    return (
        <Box>
            <Typography>
                The Manage Pulls screen allows a user to verify pulls that were automatically entered
				by the system based on detected reel rotation.  This screen also alows users to perform
				Pull Adjustments.
            </Typography>
        </Box>
    );

}
