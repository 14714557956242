import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LoadListMapListItem from "./LoadListMapListItem";

export default function LoadListMapList( props ) {
    return (
        <Box sx={{minHeight:"400px", maxHeight:"600px", overflowX:"auto"}}>
            {props.data.filter(l => l.currentLocation!=null).length > 0
            ?
            props.data.map((Load, idx) => (<LoadListMapListItem 
                                                key={idx} 
                                                childIdx={idx} 
                                                data={Load} 
                                                user={props.user} 
                                                openChildFx={props.openChildFx}
                                                />
                                            ))
            :
            <Box sx={{textAlign:"center"}}>
                <Typography variant="h6">No Records Found</Typography>
            </Box>
            }
        </Box>
    )
}
