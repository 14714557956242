import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function ReelTypesFilterMaterialList(props) {
    //console.debug("RTS Break ReelTypesFilterMaterialList");

    const [material, setMaterial] = useState(props.material==null?'Steel':props.material);
    const [repoMaterials] = useState([{value:"All", label:"All"}, {value:"Steel", label:"Steel"}, {value:"Wood", label:"Wood"}]);

    function valChange(e, val){
        props.setMaterialFx(val.value);
        props.setFormDirtyFx(1);
        setMaterial(val.value);
    }
    

    return(
        <>
            <Autocomplete
                options={repoMaterials}
                renderInput={(params) => <TextField {...params} label="Material" size="small" />}
                value={material}
                onChange={valChange}
                getOptionLabel={(material) => material}
                disableClearable={true}
                renderOption={(props, repoMaterials) => (
                    <Box component="li" {...props} key={repoMaterials.value}>
                        {repoMaterials.label}
                    </Box>
                )}
            />
        </>
    );
}

