import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import NamedLocationsFilterManagerList from './NamedLocationsFilterManagerList';
import NamedLocationsFilterStatus from './NamedLocationsFilterStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProjectsFilter(props){
    //console.debug("RTS Break NamedLocationsFilter");

    const {setCurFilter, sessionCustomer} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [manager, setManager] = useState();
    const [status, setStatus] = useState();
    

    useEffect(() => {
        setStatus(props.filter.status);
        setManager(props.filter.manager);
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'NamedLocationsPage', filter:{customer:sessionCustomer.customer, manager:manager, status:status, sortBy:0}});
        props.setFilterFx({customer:sessionCustomer.customer, manager:manager, status:status, sortBy:0});
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <NamedLocationsFilterManagerList manager={manager} setManagerFx={setManager} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <NamedLocationsFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
