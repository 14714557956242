import {useState,  useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsFilterStatus(props) {
    //console.debug("RTS Break LoadsFilterStatus");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    // This must come after the repoStatus is set because of the find.
    const [status, setStatus] = useState(props.status.map(s => getStatusObj(s)));
    const repoStatus = [{id:1, label:"Active"},
                        {id:25, label:"Marked for Pickup"},
                        {id:50, label:"On Pickup Request"},
                        {id:75, label:"Submitted for Pickup"},
                        {id:100, label:"Returned"}];


    function getStatusObj(status) {
        switch(status){
        case 1:
            return {id:1, label:"Active"};
            break;

        case 25:
            return {id:25, label:"Marked for Pickup"};
            break;

        case 50:
            return {id:50, label:"On Pickup Request"};
            break;

        case 75:
            return {id:75, label:"Submitted for Pickup"};
            break;

        case 100:
            return {id:100, label:"Returned"};
            break;
        }
    }


    function valChange(e, val){
        props.setStatusFx(val.map(v => v.id));
        setStatus(val);
        props.setFormDirtyFx(1);
    }    

    return(
        <>
            <Autocomplete
                options={repoStatus}
                renderInput={(params) => <TextField {...params} label="Status" size="small" />}
                value={status}
                onChange={valChange}
                getOptionLabel={(repoStatus) => repoStatus.label}
                renderOption={(props, repoStatus) => (
                    <Box component="li" {...props} key={repoStatus.id}>
                        {repoStatus.label}
                    </Box>
                )}
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
            />
        </>
    );
}

