import Box from '@mui/material/Box';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsProductRemainsDetailList(props){
    //console.debug("RTS Break LoadsProductRemainsDetailList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const columns = [
        {
            field: 'reelSerial', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.load.reel.serialNumber
            },
        },
        {
            field: 'reelType', 
            headerName: "Reel Type", 
            description:"Reel Type", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.load.reelType.name
            },
        },  
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Product Name", 
            flex:4,
            valueGetter: (cellValues) => {
                return cellValues.row.load.product.name
            },
        },
        {
            field: 'shipDate', 
            headerName: "Date Shipped", 
            description:"Reel Ship Date", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.load.shipDate
            },
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }              
        },
        {
            field: 'remains', 
            headerName: "Amt Remains", 
            description:"Amt of product remaining", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.remains.toLocaleString();
            }
        },                
        {
            field: 'remainsPercent', 
            headerName: "% Remains", 
            description:"Percentage of product remaining", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.remainsPercent.toLocaleString() + "%";
            }
        },                
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
