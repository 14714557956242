import axios from 'axios';
import {useState, useEffect, useCallback } from 'react';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import WorkItemsListBack from '../../components/WorkItems/WorkItemsListBack';
import WorkItemsHeader from '../../components/WorkItems/WorkItemsHeader';
import WorkItemsList1 from '../../components/WorkItems/WorkItemsList1';
import WorkItemsList2 from '../../components/WorkItems/WorkItemsList2';
import WorkItemsList3 from '../../components/WorkItems/WorkItemsList3';
import WorkItemsList4 from '../../components/WorkItems/WorkItemsList4';
import WorkItemsList5 from '../../components/WorkItems/WorkItemsList5';

import { useSessionContext } from '../../contexts/SessionContext';

export default function WorkItemsPage( props ){
    //console.debug("RTS Break WorkItemsPage");

    const {userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const location = useLocation();
    const workSet = location.state.data;

    const [data, setData] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/WorkItems/GetWorkItemsByWorkSet";
        axios
        .post(
            apiURL,
            workSet,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }, [apiRoot, userAuthToken.token, workSet]);
    

    useEffect(() => {
        props.setIsModalFx(true);
        fetchData();
    }, [props, fetchData]);  
    

    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '70vh';
        }
        return retValue;
    }; 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <Container component="main" sx={{mb:5}}>
            <CssBaseline />
            
            <WorkItemsHeader data={workSet} />    
            <Typography variant="h5">Work Set Items</Typography>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                {
                workSet.workSetType.code === 1
                ?
                    <WorkItemsList1 data={data} />
                :
                    workSet.workSetType.code === 2
                    ?
                        <WorkItemsList2 data={data} />
                    :
                        workSet.workSetType.code === 3
                        ?
                            <WorkItemsList3 data={data} />
                        :
                            workSet.workSetType.code === 4
                            ?
                                <WorkItemsList4 data={data} />
                            :
                                workSet.workSetType.code === 5
                                ?
                                    <WorkItemsList5 data={data} />
                                :
                                    <Box sx={{display:'flex', alignContent:'center', justifyContent:'center'}}>
                                        <Typography variant='h2'>Unknown Work Set Type</Typography>
                                    </Box>
                }

            </Box>
            <Box sx={{mt:2}}>
                <WorkItemsListBack />
            </Box>
        </Container>
    );
}
