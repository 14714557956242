import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import ProductsList from '../../components/Products/ProductsList';
import ProductsHeader from '../../components/Products/ProductsHeader';
import ProductsFilter from '../../components/Products/ProductsFilter';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductsPage( props ){
    //console.debug("RTS Break ProductsPage");

    const {curFilter, user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/Products/GetProductsSummary";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]); 


    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'ProductsPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({hierarchy:null});
            fetchData({hierarchy:null})
        }
    }, [props, user, sessionCustomer, curFilter, fetchData]); 


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    };    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box>
            <ProductsHeader />
            <ProductsFilter filter={filter} setFilterFx={setFilter}/>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                <ProductsList data={data} />
            </Box>
        </Box>
    );
}
