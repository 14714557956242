import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

export default function ProductsList(props){
    //console.debug("RTS Break ReelTypesList");

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    function handleClick(e, cellValues){
        history("/ProductsEdit", {state:{idGuid:cellValues.row.idGuid, display:cellValues.row.esn, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit Product", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Product", 
            description:"Product Name", 
            flex:4
        },
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Reel Type Id", 
            flex:1
        },        
        {
            field: 'productHierarchy', 
            headerName: "Product Hierarchy", 
            description:"Product Hierarchy", 
            flex:1
        }
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {model:false, batteryLevel:false, lastUpdate:false, purchaseDate:false, activateDate:false, status:false, createUser:false, modifyDate:false, modifyUser:false}
        }
        else{
            return {purchaseDate:false, activateDate:false, status:false, createUser:false, modifyDate:false, modifyUser:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}
