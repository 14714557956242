import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import NavButSystemReelManager from './NavButSystemReelManager';

import { useSessionContext } from '../../contexts/SessionContext';

export default function NavButSystem( props ){
    //console.debug("RTS Break NavButSystem");

    const {user, setCurFilter} = useSessionContext();
    const history = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorElSystem, setAnchorElSystem] = useState(null);

    const handleOpenSystemMenu = (e) => {
        setOpen(!open);
        setAnchorElSystem(e.currentTarget);
    };
      
    const handleCloseSystemMenu = () => {
        props.setOpenDrawerFx(false);
        setAnchorElSystem(null);
    };

    function handleSysUsersClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/Users");
    }  

    function handleSysTrackersClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/Trackers");
    }  

    function handleSysReelTypesClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/ReelTypes");
    }  

    
    function handleSysProductsClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/Products");
    }  

    function handleSysCustomersClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/Customers");
    }  

    function handleSysCustomerReelsClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/CustomerReels");
    }  

    function handleSysReelsClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/Reels");
    }  

    function handleSysLoadsClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/Loads");
    } 

    function handleSysNamedLocationsClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/SystemNamedLocations");
    } 

    function handleSysPickupRequestsAdminClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/PickupRequestsAdmin");
    } 

    function handleSysReelWipClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/ReelWip");
    }  
    
    
    // function handleWorkSetsClick(e){
    //     setCurFilter(null);
    //     handleCloseSystemMenu();
    //     history("/WorkSets");
    // }      

    // const isMobile = props.setOpenDrawerFx;

    if(user.adminFlag !== 1){
        return("");
    }


    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="System" onClick={handleOpenSystemMenu} sx={{cursor:"pointer" }}>
                <ListItemText primary="System" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding >
                    <NavButSystemReelManager 
                        mode="NavDrawer" 
                        handleSysTrackersClickFx={handleSysTrackersClick}
                        handleSysReelTypesClickFx={handleSysReelTypesClick}
                        handleSysReelsClickFx={handleSysReelsClick}
                        handleSysPickupRequestsAdminClickFx={handleSysPickupRequestsAdminClick}
                        handleSysReelWipClickFx={handleSysReelWipClick}
                    />
                    <ListItemButton key="Users" sx={{ pl:4, cursor:"pointer" }}  onClick={handleSysUsersClick}>
                        <ListItemText primary="Users" />
                    </ListItemButton>
                    <ListItemButton key="Products" sx={{ pl:4, cursor:"pointer" }}  onClick={handleSysProductsClick}>
                        <ListItemText primary="Products" />
                    </ListItemButton>
                    <ListItemButton key="Customers" sx={{ pl:4, cursor:"pointer" }}  onClick={handleSysCustomersClick}>
                        <ListItemText primary="Customers" />
                    </ListItemButton>
                    {/* <ListItemButton key="CustomerReels" sx={{ pl:4, cursor:"pointer" }}  onClick={handleSysCustomerReelsClick}>
                        <ListItemText primary="Customer Reels" />
                    </ListItemButton> */}
                    <ListItemButton key="Loads" sx={{ pl:4, cursor:"pointer" }}  onClick={handleSysLoadsClick}>
                        <ListItemText primary="Loads" />
                    </ListItemButton>
                    <ListItemButton key="NamedLocations" sx={{ pl:4, cursor:"pointer" }}  onClick={handleSysNamedLocationsClick}>
                        <ListItemText primary="Named Locations" />
                    </ListItemButton>
                    {/*<ListItemButton key="WorkSets" sx={{ pl: 4 }}  onClick={handleWorkSetsClick}>
                        <ListItemText primary="Work Sets" />
                    </ListItemButton>
                    <ListItem button key="ReelWip" sx={{ pl: 4 }}  onClick={handleSysReelWipClick}>
                        <ListItemText primary="Reel WIP" />
                    </ListItem>                                         */}
                </List>
            </Collapse>

            </>
        )
    }

    return (
        <>
        <Button
            key='System'
            onClick={handleOpenSystemMenu}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
            endIcon={<ArrowDropDownIcon />}
        >
            System
        </Button>
        <Menu
            id="menu-sys"
            anchorEl={anchorElSystem}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElSystem)}
            onClose={handleCloseSystemMenu}
            sx={{
                display: { xs: 'none', md: 'block' },
            }}
        >
            <NavButSystemReelManager 
                mode="NavBar"
                handleSysTrackersClickFx={handleSysTrackersClick}
                handleSysReelTypesClickFx={handleSysReelTypesClick}
                handleSysReelsClickFx={handleSysReelsClick}
                handleSysPickupRequestsAdminClickFx={handleSysPickupRequestsAdminClick}
                handleSysReelWipClickFx={handleSysReelWipClick}
            />
            <MenuItem key='SysUsers' onClick={handleSysUsersClick}>
                <Typography textAlign="center">Users</Typography>
            </MenuItem>

            <MenuItem key='SysProducts' onClick={handleSysProductsClick}>
                <Typography textAlign="Left">Products</Typography>
            </MenuItem>

            <MenuItem key='SysCustomers' onClick={handleSysCustomersClick}>
                <Typography textAlign="Left">Customers</Typography>
            </MenuItem>

            {/* <MenuItem key='SysCustomerReels' onClick={handleSysCustomerReelsClick}>
                <Typography textAlign="Left">Customer Reels</Typography>
            </MenuItem> */}
            
            <MenuItem key='SysLoads' onClick={handleSysLoadsClick}>
                <Typography textAlign="Left">Loads</Typography>
            </MenuItem>            

            <MenuItem key='SysNamedLocations' onClick={handleSysNamedLocationsClick}>
                <Typography textAlign="Left">Named Locations</Typography>
            </MenuItem> 

            {/*<MenuItem key='WorkSets' onClick={handleWorkSetsClick}>
                <Typography textAlign="Left">Work Sets</Typography>
            </MenuItem> 

            <MenuItem key='SysReelWipAdmin' onClick={handleSysReelWipClick}>
                <Typography textAlign="Left">Reel WIP</Typography>
            </MenuItem>                                     */}
        </Menu>
        </>
    );
}



