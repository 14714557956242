import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPro} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';

export default function CrewsMembersList(props){
    console.debug("RTS Break CrewMembersList")

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);


    function handleClick(e, cellValues){
        props.removeMemberFx(cellValues.row);
    }


    useEffect(() => {
        setData(props.data);
        setIsLoading(false);
    }, [props]); 

    
    const columns = [
        {
            field: 'name', 
            headerName: "Member", 
            description:"Member Name", 
            flex:2,
            valueGetter: (value, row) => {
                if(row==null){
                    return "";
                }
                return row.appUser.firstName + " " + row.appUser.lastName;
            },
            hideable:false,
        },        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {}
        }
        else{
            return {}
        }
    }


    function getDensity(){
        if(smallForm){
            return "compact";
        }
        else{
            return "compact";
        }
    }


    if(isLoading){
        return(
            <Loading />
        );
    };  


    return (
        <Box sx={{display:'flex', flexDirection:'column', width:'100%', minHeight:'200px', maxHeight:'300px', px:1, mt:2}}>        
            <Typography variant="body2" sx={{fontWeight:'bold'}}>Crew Members:</Typography>
            <Box sx={{width:'100%', height:'200px', minHeight:'200px', maxHeight:'300px'}}>        
                <DataGridPro
                    disableColumnFilter
                    rows={data} 
                    columns={columns} 
                    getRowId={row => row==null?1:row.appUser.userName}
                    initialState={{
                        columns:{
                            columnVisibilityModel: getVisibilityModel()
                        },
                        density: getDensity() 
                    }}
                    sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal"
                        },
                        "& .MuiDataGrid-columnHeader": {
                        // Forced to use important since overriding inline styles
                        height: "unset !important"
                        },
                        "& .MuiDataGrid-columnHeaders": {
                        // Forced to use important since overriding inline styles
                        maxHeight: "168px !important"
                        }
                    }}                                    
                />
            </Box>
        </Box>        



    );
}
