import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ClearIcon from '@mui/icons-material/Clear';

export default function CrewMembersList(props){
    //console.debug("RTS Break CrewMembersList")

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));
    

    function handleRemoveClick(e, cellValues){
        history("/CrewMembersEdit", {state:{data:cellValues.row, mode: 99}});
    }

    const columns = [
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"Crew Member First Name", 
            flex:3 
        },
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"Crew Member Last Name", 
            flex:3 
        },
        {
            field: 'email', 
            headerName: "Email Address", 
            description:"Crew Member Email Address", 
            flex:4,
            hideable:false,
        },
        {
            field: 'companyLinkName', 
            headerName: "Company", 
            description:"Crew Member Company", 
            flex:4,
            hideable:false,
        },
        {
            field: 'markAccess', 
            headerName: "Allow Marks", 
            description:"Allow Mark For Return", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value === 1){
                    return ('Yes');
                }
                else{
                    return('No');
                }
            }, 
        },   
        {
            field: "Remove",
            headerName: "",
            description:"Remove Crew Member", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleRemoveClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <ClearIcon sx={{color:"red"}} />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
    ];

    function getVisibilityModel(){
        if(smallForm){
            return {firstName:false, lastName:false, companyLink:false, markAccess:false}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.userName}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}
