import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListFilterReelList(props) {
    //console.debug("RTS Break LoadListFilterReelList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [reels, setReels] = useState(props.reels===null?[]:props.reels);
    const [repoReels, setRepoReels] = useState(props.data===null?[]:props.data);


    function valChange(e, val){
        props.setReelsFx(val);
        setReels(val);
    } 


    return(
        <Autocomplete
            options={repoReels}
            renderInput={(params) => <TextField {...params} label="Reels" size="small" />}
            value={reels}
            onChange={valChange}
            getOptionLabel={(reel) => reel.serialNumber}
            renderOption={(props, repoReels) => (
                <Box component="li" {...props} key={repoReels.idGuid}>
                    {repoReels.serialNumber}
                </Box>
            )}
            multiple
        />
    );
}

