import axios from 'axios';
import {useState, useCallback, useEffect} from 'react';
import { format, parseISO } from 'date-fns';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import FlagIcon from '@mui/icons-material/Flag';
import ClearIcon from '@mui/icons-material/Clear';

import Loading from '../Loading';
import Error from '../Error';

import GridToolbarRefreshButton from '../GridToolbarRefreshButton';
import { useSessionContext } from '../../contexts/SessionContext';

export default function DashbaordAlert( props ) {
    //console.debug("RTS Break DashbaordAlert");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState([]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody] = useState("");
    const [errorTitle] = useState("Validation Error");

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertBody, setAlertBody] = useState('');

    const fetchData = useCallback((pFilter) => {
        setIsLoading(false);
        let apiURL = apiRoot + "/Dashboard/GetAlerts";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            setData(response.data);
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData({customer:sessionCustomer.customer, user:user, sortBy: 0 });
    }, [user, sessionCustomer.customer, fetchData]);


    function handleClick(e, cellValues){
        setAlertTitle(cellValues.row.title);
        setAlertBody(cellValues.row.body);
        setShowAlertModal(true);

        let apiObj = cellValues.row;
        apiObj.status = 1;
        let apiURL = apiRoot + "/Dashboard/UpdateAlert";
        
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    };


    function handleDelClick(e, cellValues){
        let apiObj = cellValues.row;

        let apiURL = apiRoot + "/Dashboard/DeleteAlert";
        
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            fetchData();
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    };


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    function CustomToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <GridToolbarRefreshButton refreshEvent={() => {fetchData({customer:sessionCustomer.customer, user:user, sortBy: 0 });}} />
            </GridToolbarContainer>
        );
    };


    const columns = [
        {            
            field: 'sentDate', 
            headerName: "Date", 
            description:"Alert Date", 
            flex:1,           
            renderCell: (cellValues) => {
                return (
                    <Button onClick={(event) => {handleClick(event, cellValues);}}>
                    {
                        cellValues.row.status === 0
                        ?
                            <Typography sx={{fontWeight:'bold'}}>{format(parseISO(cellValues.row.sentDate), "MM-dd-yyyy")}</Typography>
                        :
                            <Typography sx={{fontWeight:'normal'}}>{format(parseISO(cellValues.row.sentDate), "MM-dd-yyyy")}</Typography>
                    }
                    </Button>
                );
            } 
        },
        {
            field: 'Severity', 
            headerName: "", 
            description:"Severity", 
            flex:.25,
            align:"center",
            renderCell: (cellValues) => {
                switch(cellValues.row.icon){
                    case(1):
                        return( <Button onClick={(event) => {handleClick(event, cellValues);}}>
                                    <FlagIcon sx={{color:"gold"}} />
                                </Button>
                            );
                        break;

                    case(2):
                        return( <Button onClick={(event) => {handleClick(event, cellValues);}}>
                                    <FlagIcon sx={{color:"orange"}} />
                                </Button>
                            );
                        break;

                    case(3):
                        return( <Button onClick={(event) => {handleClick(event, cellValues);}}>
                                    <FlagIcon sx={{color:"red"}} />
                                </Button>
                            );
                        break;

                    default:
                        return(<Typography></Typography>);
                        break;
                }
            },
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
        {field: 'title', 
            headerName: "Title", 
            description:"Alert Title", 
            flex:4,
            renderCell: (cellValues) => {
                return (
                <Button
                    onClick={(event) => {
                    handleClick(event, cellValues);
                    }}
                >
                    {
                        cellValues.row.status === 0
                        ?
                            <Typography sx={{fontWeight:'bold'}}>{cellValues.row.title}</Typography>
                        :
                            <Typography sx={{fontWeight:'normal'}}>{cellValues.row.title}</Typography>
                    }
                </Button>
                );
            },
            hideable:false,
        },
        {
            field: 'Delete',
            headerName: "", 
            description:"Delete", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleDelClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <ClearIcon sx={{color:"red"}} />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showAlertModal} onClose={()=>{setShowAlertModal(false);}}>
            <DialogTitle>{alertTitle}</DialogTitle>
            <Box dangerouslySetInnerHTML={{__html: alertBody}}>
            </Box>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowAlertModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: CustomToolbar}}
                rows={data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
        </>
    );
}

