import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import LoadsList from '../../components/Loads/LoadsList';
import LoadsHeader from '../../components/Loads/LoadsHeader';
import LoadsFilter from '../../components/Loads/LoadsFilter';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsPage( props ){
    console.debug("RTS Break LoadsPage");

    const {curFilter, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);
 

    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/Loads/GetLoadsSummary";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);

    
    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'LoadsPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({customer:null, product:null, reelType:null, customerVisibleFlag:-1, status:[1,25,50], maxRecords:1000});
            fetchData({customer:null, product:null, reelType:null, customerVisibleFlag:-1, status:[1,25,50], maxRecords:1000})
        }
    }, [props, curFilter, fetchData]); 


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '60vh';
        }
        return retValue;
    };      
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <>
        <Box>
            <LoadsHeader />
            <LoadsFilter filter={filter} setFilterFx={setFilter}/>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'400px'}}>           
                <LoadsList data={data} />
            </Box>
        </Box>
        </>
    );
}
