import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import CompanyLinksFilterStatus from './CompanyLinksFilterStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelsFilter(props){
    //console.debug("RTS Break ReelsFilter");

    const {setCurFilter, sessionCustomer, user} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [mailZip, setMailZip] = useState();
    const [status, setStatus] = useState();
    

    useEffect(() => {
        setMailZip(props.filter.mailZip);
        setStatus(props.filter.status);
        setIsLoading(false);
    },[props.filter.mailZip, props.filter.status]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'CompanyLinksPage', filter:{user:user, customer:sessionCustomer.customer, mailZip:mailZip, status:status, sortBy:0}});
        props.setFilterFx({user:user, customer:sessionCustomer.customer, mailZip:mailZip, status:status, sortBy:0});
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Box sx={{width:'100%'}}>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="tbText"
                            fullWidth
                            label="Zip Code"
                            size="small"
                            value={mailZip}
                            type="text"
                            onChange={(e) => {setFormDirty(1); setMailZip(e.target.value);}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                        <CompanyLinksFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                        <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                            <FilterListIcon />
                            <Typography sx={{ml:1}}>Filter</Typography>
                        </Button>
                    </Grid>            
                </Grid>
            </form>
        </Box>
    );
}
