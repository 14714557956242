import Box from '@mui/material/Box';
import {DataGridPro} from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import WorkSetListBody from './WorkSetsListBody';

export default function WorkSetsList(props){
    //console.debug("RTS Break WorkSetsList");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));


    function SessionCustomerToolbar(props) {
        return(
            <GridToolbarContainer {...props} sx={{display:'flex', width:'100%'}}>
                <Box sx={{width:'49%'}}>
                    <GridToolbarFilterButton />
                </Box>
                <Box sx={{width:'49%', textAlign:'right'}}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    }


    const columns = [
        {
            field: 'displayVal', 
            headerName: "", 
            description:"Work Package Item", 
            flex:10,
            renderCell: (cellValues) => {
                    return (
                        <WorkSetListBody data={cellValues.row} mode={0} />
                    );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true                       
        },
        {
            field: 'name', 
            headerName: "Name", 
            description:"Program Name", 
            flex:4,
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
        },
        {
            field: 'workSetType', 
            headerName: "Type", 
            description:"Work Set Type", 
            flex:3,
            valueGetter: (value, row) => {
                return row.workSetType.name
            },
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
        },           
        {
            field: 'createDate', 
            headerName: "Create Date", 
            description:"Create Date", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
				if(value == null){
                    return null;
                }

                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"Work Set Status", 
            flex:3,
            valueGetter: (value) => {
                let retValue = "New";
                switch(value){
                    case 100:
                        retValue = "Complete";
                        break;

                    case 50:
                        retValue = "In Progress";
                        break;

                    default:
                        retValue = "New";
                        break;
                }

                return retValue;
            },
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
        },        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {name:false, workSetType:false, createDate:false, status:false}
        }
        else{
            return {name:false, workSetType:false, createDate:false, status:false}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }


    return (
        <>
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro 
                pagination 
                slots={{toolbar: SessionCustomerToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
                getRowHeight={() => 'auto'}
                columnHeaderHeight={0}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 
                }}                     
            />
        </Box>
        </>        
    );
}
