import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';
import CrewsEditLeadLookup from './CrewsEditLeadLookup';
import CrewsEditMembersLookup from './CrewsEditMembersLookup';
import CrewsEditMembersList from './CrewsEditMembersList';
import CrewsEditStatus from './CrewsEditStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewsEdit(props) {
    console.debug("RTS Break CrewsEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const display = props.display;
    const mode = props.mode;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showMembersModal, setShowMembersModal] = useState(false);

    const [idGuid, setIdGuid] = useState(null);
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [notes, setNotes] = useState('');
    const [crewLead, setCrewLead] = useState(null);
    const [status, setStatus] = useState(1);
    const [crewMembers, setCrewMembers] = useState([]);

    const fetchData = useCallback((pIdGuid) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/Crews/GetCrewById/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIdGuid(response.data.idGuid);
                setName(response.data.name);
                setShortName(response.data.shortName);
                setNotes(response.data.notes);
                setCrewLead(response.data.crewLead);
                setStatus(response.data.status);
                setCrewMembers(response.data.crewMembers);

                setIsLoading(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]); 
    
    
    useEffect(() => {
        if(mode === 1){
            setIdGuid(props.idGuid);
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  


    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);

        let apiObj = {
            IdGuid: idGuid,
            Customer: sessionCustomer.customer,
            Name: name,
            ShortName: shortName,
            Notes: notes,
            CrewLead: crewLead,
            Status: status,
			CrewMembers: crewMembers,
            CreateUser: user.userName,
            ModifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Crews/UpdateCrew/";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                setIsLoading(false);
                history(-1);
            })
            .catch(error => {
                setIsLoading(false);
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateCrew Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Crews/CreateCrew";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                setIsLoading(false);
                history(-1);
            })
            .catch(error => {
                setIsLoading(false);
                setErrorTitle("Create Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateCrew Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    function addMembers(args){
        setShowMembersModal(false);
        let curMemberList = JSON.parse(JSON.stringify(crewMembers));

        args.forEach((item) => {

            let temp = curMemberList.filter(l => l.appUser.userName === item.userName); 


            if(curMemberList.filter(l => l.appUser.userName === item.userName).length > 0){
                return;
            }

            let memberObj = {appUser:item};

            //let curMemberList = MemberList.filter((l) => l.idGuid !== item.idGuid);
            curMemberList.push(memberObj);
        });
            
        setCrewMembers(curMemberList);
        props.setFormDirtyFx(1);
    }

    
    function removeMember(args){
        let curMemberList = crewMembers.filter((l) => l.appUser.userName !== args.appUser.userName);
        setCrewMembers(curMemberList);
        props.setFormDirtyFx(1);
    };


    function openMembersModal(){
        setShowMembersModal(true);
    };


    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };   


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Dialog fullWidth={true} maxWidth="md" open={showMembersModal}>
            <DialogTitle>Add Reel</DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:"400px"}}>
                    <CrewsEditMembersLookup display={display} addMembersFx={addMembers} setShowMembersModalFx={setShowMembersModal} />
                </Box>            
            </DialogContent>
        </Dialog>  
        <Box>
            <form id="CrewForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                />
                <TextField
                    id="tbShortName"
                    margin="normal"
                    fullWidth
                    label="Short Name"
                    size="small"
                    required
                    value={shortName}
                    onChange={(e) => {props.setFormDirtyFx(1); setShortName(e.target.value);}}
                />
                <CrewsEditLeadLookup crewLead={crewLead} setCrewLeadFx={setCrewLead} setFormDirtyFx={props.setFormDirtyFx} />
                <TextField
                    id="tbNotes"
                    margin="normal"
                    fullWidth
                    label="Notes"
                    size="small"
                    value={notes}
                    multiline
                    rows={3}
                    onChange={(e) => {props.setFormDirtyFx(1); setNotes(e.target.value);}}
                />
                <CrewsEditStatus status={status} setStatusFx={setStatus} setFormDirtyFx={props.setFormDirtyFx} />
                </Box>
                </Box>
                <Box sx={{mt:2}}>
                    <Grid container sx={{mb:1}}>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Crew Members</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:'right'}}>
                            <Button variant='contained' onClick={openMembersModal}>Add Members</Button>
                        </Grid>
                    </Grid>
                    <CrewsEditMembersList data={crewMembers} removeMemberFx={removeMember} />
                </Box>
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit" sx={{mr:2}}>Save</Button>
               </Box>
            </form>
        </Box>
        </>
    );

}
