import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import CrewMembersFilterMarkAccess from './CrewMembersFilterMarkAccess';
import CrewMembersFilterCompanyList from './CrewMembersFilterCompanyList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersFilter(props){
    console.debug("RTS Break CrewMembersFilter");

    const {setCurFilter, user, sessionCustomer} = useSessionContext();

    const [markAccess, setMarkAccess] = useState();
    const [company, setCompany] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0);


    useEffect(() => {
        setMarkAccess(props.filter.markAccess);
        setCompany(props.filter.company);
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'CrewMembersPage', filter:{user:user, customer:sessionCustomer.customer, company:company, markAccess:markAccess, sortBy:0}});
        props.setFilterFx({user:user, customer:sessionCustomer.customer, company:company, markAccess:markAccess, sortBy:0});
    }


    if(isLoading){
        return(
            <Loading />
        );
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <CrewMembersFilterCompanyList company={company} setCompanyFx={setCompany} setFormDirtyFx={setFormDirty} />
            </Grid>
            {
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <CrewMembersFilterMarkAccess markAccess={markAccess} setMarkAccessFx={setMarkAccess} setFormDirtyFx={setFormDirty} />
            </Grid>
            }
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
