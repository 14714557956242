import axios from 'axios';
import {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { format} from 'date-fns';

import EditCancel from '../EditCancel';
import PalletActionAvailablePalletsList from './PalletActionAvailablePalletsList';
import PalletActionReturnPalletsList from './PalletActionReturnPalletsList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PalletActionEdit(props) {
    //console.debug("RTS Break PalletActionEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [palletPlant] = useState(data);
    const [pallets, setPallets] = useState([]);
    const [returnPallets, setReturnPallets] = useState([]);
    const [actionDate, setActionDate] = useState(format( new Date(), 'MM-dd-yyyy'));


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = [];
        
        if(mode === 1){        
            pallets.forEach(function(pallet){
                apiObj.push(
                    {
                        PalletPlant: palletPlant,
                        Pallet: pallet,
                        SendDate: actionDate,
                        ReturnDate: null,
                        createUser: user.userName,
                        modifyUser: user.userName
                    }
                );
            })

            let apiURL = apiRoot + "/PalletActions/CreatePalletActions";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreatePalletAction Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            returnPallets.forEach(function(pallet){
                pallet.ReturnDate = actionDate
            })
            apiObj = returnPallets;

            let apiURL = apiRoot + "/PalletActions/UpdatePalletActions";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdatePalletAction Error", error);
                setShowErrorModal(true);
            });            
        }




    } 

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit} style={{width:'500px'}}>
                    <Typography variant='h4' sx={{pb:2}}>
                        {
                        mode===1
                        ?
                            'Send Pallet Action'
                        :
                            'Receive Pallet Action'
                        }
                    </Typography>

                    <DatePicker
                        id="tbActionDate"
                        label="Action Date"
                        inputFormat="MM/dd/yyyy"
                        value={actionDate}
                        onChange={(val) => setActionDate(val)}
                        renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                        maxDate={new Date()}
                    /> 

                    {
                        mode===1
                        ?
                        <PalletActionAvailablePalletsList setPalletsFx={setPallets} pallets={pallets} />
                        :
                        <PalletActionReturnPalletsList palletPlant={data} setReturnPalletsFx={setReturnPallets} returnPallets={returnPallets} />  
                    }

                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit" variant="contained" sx={{mr:2}}>Save</Button>
                            <EditCancel />
                        </Box>
                    </Box>
                </form>
                </LocalizationProvider>
            </Box>
        </>
    );
}

