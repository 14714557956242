import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../components/Loading';
import Error from '../components/Error';
import CompanyLinksFilter from '../components/CompanyLinks/CompanyLinksFilter';
import CompanyLinksList from '../components/CompanyLinks/CompanyLinksList';
import CompanyLinksHeader from '../components/CompanyLinks/CompanyLinksHeader';

import { useSessionContext } from '../contexts/SessionContext';

export default function CompanyLinksPage( props ){
    console.debug("RTS Break CompanyLinksPage");

    const {curFilter, user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/CompanyLinks/GetCompanyLinksSummary";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);
    

    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'CompanyLinksPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({user:user, customer:sessionCustomer.customer, mailZip:null, status:1, sortBy:0});
            fetchData({user:user, customer:sessionCustomer.customer, mailZip:null, status:1, sortBy:0})
        }
    }, [props, user, sessionCustomer, curFilter, fetchData]); 


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    };    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box>
            <CompanyLinksHeader />
            <CompanyLinksFilter filter={filter} setFilterFx={setFilter}/>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>   
                <CompanyLinksList data={data} />
            </Box>
        </Box>
    );
}
