import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import EditBack from '../../components/EditBack';
import ReelsEdit from '../../components/ManuReels/ManuReelsEdit';

export default function ManuReelsPageEdit( props ){
    //console.debug("RTS Break ReelsPageEdit");

    const location = useLocation();
    const idGuid = location.state.idGuid;
    const display = location.state.display;
    const mode = location.state.mode;

    const [formDirty, setFormDirty] = useState(0);


    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]);     

   
    return(
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
                {formDirty===0
                ?
                    <Typography variant="h5" >Edit Reel Tracker</Typography>
                :
                    <Typography variant="h5" sx={{fontStyle:'italic'}}>Edit Reel Tracker *</Typography>
                }
            </Box> 
            <Box>
                <ReelsEdit idGuid={idGuid} display={display} mode={mode} formDirty={formDirty} setFormDirtyFx={setFormDirty} />
            </Box>
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
            </Box>  
        </Container>
    );
}
