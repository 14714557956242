import {useState, useContext} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersAddCheck(props) {
    console.debug("RTS Break CrewMembersAddCheck");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [userId, setUserId] = useState();

    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            userName: userId,
            passwordHash: null,
            email: userId,
            firstName: null,
            lastName:  null,
            primaryuser: 0,
            status: 1,
            rtsuser: true,
            rtsadminrole:  false,
            southwireuserrole:  false,
            customeradminrole:  false,
            mManufacturerRepRole:  false,
            distributorRole:  false,
            crewMemberRole:  true,
            customers: []
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(apiObj.userName))){
            let args={};
            args.errorTitle = "InvalidEmail";
            args.errorBody = "A email entered is invalid.";
            args.data = null
            args.exist = false;
            props.userIdCheckFx(args);
            return;
        }
  

        if(apiObj.userName == null){
            let args={};
            args.errorTitle = "Email Check Required";
            args.errorBody = "A User Email is required to add Crew Member"
            args.data = null
            args.exist = false;
            props.userIdCheckFx(args);
            return;
        }

        
        let apiURL = apiRoot + "/AppUsers/GetAppUserByUserName";
        axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                let resArgs = {
                    data: null,
                    exist: false
                };

                if(response.data){
                    resArgs.data = response.data;
                    resArgs.exist = true;
                }
                else{
                    resArgs.data = apiObj
                    resArgs.exist = false;
                }

                props.userIdCheckFx(resArgs);
            })
            .catch(error => {
                let args={};
                args.errorTitle = "Error Checking User Email";
                args.errorBody = "An error has occured. Please review the console log for details.";
                //props.showUserIdModalFx(args);
                console.log("RTS CreateUser Error", error);
                return;                
            });
    }


    function onUserNameChange(val){
        setUserId(val);
        props.setUserIdValidFx(false);
    }


    return (
        <form id="userForm" onSubmit={handleSubmit}>
            <Typography sx={{fontWeight:'bold'}}>
                Please enter an email address for the Crew Member you wish to add and click the below Validate button:
            </Typography>
            <TextField
                id="tbEmail"
                margin="normal"
                fullWidth
                label="User Email Address"
                size="small"
                value={userId}
                onChange={(e) => {props.setFormDirtyFx(1); onUserNameChange(e.target.value);}}
                required
                sx={{minWidth:'100px'}}
            />
            <Box sx={{textAlign:'center'}}>
                <Button variant="contained" type="submit">Validate</Button>
            </Box>
        </form>
    );
}
