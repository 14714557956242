import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { QrReader } from 'react-qr-reader';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../components/Loading';
import Error from '../components/Error';

import { useSessionContext } from '../contexts/SessionContext';


export default function QRScanPage( props ) {
    console.debug("RTS Break QRScanPage");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {setCurFilter, user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showNotFoundModal, setShowNotFoundModal] = useState(false);

    const [qrFound, setQRFound] = useState(false);
    const [trackerIdSeq, setTrackerIdSeq] = useState('');
    const [tracker, setTracker] = useState(null);
    const [trackerFound, setTrackerFound] = useState(false);
    const [reelSerial, setReelSerial] = useState('');
    const [reel, setReel] = useState(null);
    const [reelFound, setReelFound] = useState(false);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/LoadList/GetLoadByTrackerIdSeq";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if(response.status === 200){
                setTrackerFound(true);
                setTracker(response.data.tracker);
                setTrackerIdSeq(response.data.tracker.idSeq);

                if(response.data.reel != null){
                    setReelFound(true);
                    setReel(response.data.reel);
                    setReelSerial(response.data.reel.serialNumber);
                }
            }
            else{
                setQRFound(false);
                setTrackerFound(false);
                setTracker(null);
                setTrackerIdSeq('');
                setReelFound(false);
                setReel(null);
                setReelSerial('');
                setShowNotFoundModal(true);
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]); 
        

    useEffect(() => {
        props.setIsModalFx(true);
        setIsLoading(false);
    }, [props]); 


    function handleSubmit(e) {
        e.preventDefault();

        setCurFilter({key:'LoadListPage', filter:{User:user, 
            Status:[],
            Customer:sessionCustomer.customer,
            Orders:[], 
            Projects:[], 
            Products:[], 
            ReelTypes:[],
            Reels:[reel],
            MinLength:0,
            NamedLocations:[],
            Trackers:[tracker],
            Pinned:-1,
            Locked:-1,
            SortBy:0}
        });        
    
        history("/LoadList");
    } 
    
    
    function onQRScanResult(result, error){
        if (!!result) {
            let curData = result?.text;

            if(curData.length > 0){
                setQRFound(true);

                let curQR = curData.substr(curData.indexOf("-")+1);
                fetchData({customerIdGuid:sessionCustomer.customer.idGuid, idSeq:curQR});
            }
        }
        
        if (!!error) {
            //console.info(error);
        }        
    }

 
    function onClearClicked(e){
        setQRFound(false);
        setTrackerIdSeq('');
        setTracker(null);
        setTrackerFound(false);
        setReelSerial('');
        setReel(null);
        setReelFound(false);
    }


    function handleCancel(e){
        e.preventDefault();
        history(-1);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Dialog fullWidth={true} maxWidth="sm" open={showNotFoundModal}>
            <DialogTitle>Tracker Not Found</DialogTitle>
            <DialogContent>
                <Typography>A QR Code was detected but the tracker associated with this QR Code was not found for this customer.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowNotFoundModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Container maxWidth="xs">
            <Box sx={{marginTop: 2}}>

                <QrReader
                    onResult = {(result, error) => onQRScanResult(result, error)}
                    constraints={{ facingMode: 'environment' }}
                    containerStyle={{backgroundColor:qrFound===true?'green':'red'}}
                    style={{ width: '100%' }}
                />

                <Box sx={{textAlign:'center', mt:1}}>
                    {
                    qrFound===true
                    ?
                    <Button variant="contained"  type="button" onClick={onClearClicked} >
                        Clear
                    </Button>
                    :
                    <Button variant="contained"  type="button" disabled={!qrFound} >
                        Clear
                    </Button>
                    }                
                </Box>                 

                <form id="qrScanForm" onSubmit={handleSubmit}>
                    <TextField
                        id="tbIdTracker"
                        margin="normal"
                        fullWidth
                        label="Tracker Id"
                        size="small"
                        value={trackerIdSeq}
                        onChange={(e) => {setTrackerIdSeq(e.target.value);}}
                        disabled
                    />

                    {qrFound===true
                    ?
                        trackerFound===true
                        ?
                        <Typography sx={{color:'green'}}>Tracker Found</Typography>
                        :
                        <Typography sx={{color:'red'}}>Tracker Not Found</Typography>
                    :
                    <Typography> </Typography>
                    }

                    <TextField
                        id="tbIdReel"
                        margin="normal"
                        fullWidth
                        label="Reel"
                        size="small"
                        value={reelSerial}
                        onChange={(e) => {setReelSerial(e.target.value);}}
                        disabled
                    />
                    {qrFound===true
                    ?
                        reelFound===true
                        ?
                        <Typography sx={{color:'green'}}>Reel Found</Typography>
                        :
                        <Typography sx={{color:'red'}}>Reel Not Found</Typography>
                    :
                    <Typography> </Typography>
                    }                                        

                    <Box sx={{textAlign:'center', mt:3}}>
                        <Button variant="contained"  type="submit" sx={{mr:2}} disabled={!reelFound} >
                            Show Reel
                        </Button>                
                        <Button variant="contained" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Box>                
                </form>
            </Box>
        </Container>
        </>
    );
}
