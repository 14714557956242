import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import CrewsFilterStatus from './CrewsFilterStatus';
import CrewsFilterLeadLookup from './CrewsFilterLeadLookup';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewsFilter(props){
    //console.debug("RTS Break CrewsFilter");

    const {setCurFilter, sessionCustomer} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [status, setStatus] = useState(-1);
    const [crewLead, setCrewLead] = useState();
    

    useEffect(() => {
        setStatus(props.filter.status);
        setCrewLead(props.filter.crewLead);
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'CrewsPage', filter:{customer:sessionCustomer.customer, crewLead:crewLead, status:status}});
        props.setFilterFx({customer:sessionCustomer.customer, crewLead:crewLead, status:status});
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <CrewsFilterLeadLookup crewLead={crewLead} setCrewLeadFx={setCrewLead} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <CrewsFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
