import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Loading from '../Loading';
import Error from '../Error';
import DashboardKPIOrderList from './DashboardKPIOrderList';
import DashboardKPIProjectList from './DashboardKPIProjectList';
import DashboardKPIProductList from './DashboardKPIProductList';
import DashboardKPIReelTypeList from './DashboardKPIReelTypeList';
import DashboardKPIStatusList from './DashboardKPIStatusList';


export default function DashboardKPI( props ) {
    //console.debug("RTS Break DashboardListBoxes");

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    

    useEffect(() => {
        setIsLoading(false);
        setHasError(false);
     }, []);    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    
    return(
        <Box id="pageBox">
            <Grid id="dashboardGrid" container spacing={2}>
                <Grid item xs={12} sm={6} md={5} xl={3} xxl={2}>
                    <Typography variant="h5">Purchase Orders</Typography>
                    <Box id="orderListBox" sx={{width:'100%', height:400}}>
                        <DashboardKPIOrderList />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5} xl={3} xxl={3}>
                    <Typography variant="h5">Projects</Typography>
                    <Box id="projectListBox" sx={{width:'100%', height:400}}>
                        <DashboardKPIProjectList />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5} xl={3} xxl={3}>
                    <Typography variant="h5">Products</Typography>
                    <Box id="productListBox" sx={{width:'100%', height:400}}>
                        <DashboardKPIProductList />
                    </Box>
                </Grid> 
                <Grid item xs={12} sm={6} md={5} xl={3} xxl={2}>
                    <Typography variant="h5">Reel Types</Typography>
                    <Box id="reelTypeListBox" sx={{width:'100%', height:400}}>
                        <DashboardKPIReelTypeList />
                    </Box>
                </Grid>  
                <Grid item xs={12} sm={6} md={5} xl={3} xxl={2}>
                    <Typography variant="h5">Reel Status</Typography>
                    <Box id="statusListBox" sx={{width:'100%', height:400}}>
                        <DashboardKPIStatusList />
                    </Box>
                </Grid>                                                           
            </Grid>
        </Box>
    );
}
