import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

export default function HelpOverview(props) {
    //console.debug('RTS Break HelpOverview');

    return (
        <Box>
            <Typography>
                Reel Ownership determines who can view and edit a load.  There are three levels of Reel Ownership.
            </Typography>
            <TableContainer sx={{mb:2, mt:1}}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Sold To</TableCell>
                            <TableCell>Cannot be changed and is determined when the Reel is shipped.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Assigned To</TableCell>
                            <TableCell>If this reel is purchased from a reseller this will equal the purchasing entity, otherwise it will equal the Sold To entity.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Contracted To</TableCell>
                            <TableCell>If the reel is transfered to a contractor to string the wire they can be listed as the Contracted To.</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography>
				If an entity is set to any of these values the Reel will be displayed within the Reel list for that entity.
            </Typography>
        </Box>
    );

}
