import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function LoadListDetailsProject(props){
    //console.debug("RTS Break LoadListDetailsProject");

    function showManagerName(){
        if(props.data.project==null){
            return "";
        }
        else{
            if(props.data.project.manager==null){
                return "";
            }
            else{
                return props.data.project.manager.firstName + " " + props.data.project.manager.lastName;
            }
        }
    }
    
    return (
        <Box sx={{border:'1px solid #e2e8f0', borderRadius:'5px', p:1}}>        
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Project Name:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{props.data.project==null?"No Project":props.data.project.name}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Description:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{props.data.project==null?"":props.data.project.desc}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Manager:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{showManagerName()}</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>External Id:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{props.data.project==null?"":props.data.project.extId}</Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Start Date:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.project==null?"":props.data.project.startDate!=null?(new Date(props.data.project.startDate)).toLocaleDateString("en-US"):""}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>End Date:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.project==null?"":props.data.project.endDate!=null?(new Date(props.data.project.endDate)).toLocaleDateString("en-US"):""}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

