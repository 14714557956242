import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import SearchIcon from '@mui/icons-material/Search';

import UsersEditCustomerAccessList from './UsersEditCustomerAccessList';
import UsersEditCustomerAccessCompanyLinkLookup from './UsersEditCustomerAccessCompanyLinkLookup';


export default function UsersEditAccessLevel(props) {
    console.debug("RTS Break UsersEditAccessLevel"); 
    
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle] = useState("Validation Error");
    const [showCompanyLinkModal, setShowCompanyLinkModal] = useState(false);

    const [accessLevel, setAccessLevel] = useState({id:50, label:'Customer Manager'});
    const [companyLink, setCompanyLink] = useState()
    const [markAccess, setMarkAccess] = useState(0)    

    function handleSubmit(e) {
        e.preventDefault();
        props.addCustomerFx(accessLevel.id, companyLink, markAccess);
    } 


    function setCompanyLinkVal(pValue){
        setCompanyLink(pValue);
        setShowCompanyLinkModal(false);
    };     


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showCompanyLinkModal} onClose={()=>{setShowCompanyLinkModal(false);}}>
            <DialogTitle>Crew Member Company</DialogTitle>
            <DialogContent>
                <Box>
                    <UsersEditCustomerAccessCompanyLinkLookup data={companyLink} setValueFx={setCompanyLinkVal} company={props.data} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowCompanyLinkModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>
        <form onSubmit={handleSubmit}>
            <Typography>Set the access level for customer:</Typography>
            <Typography sx={{fontWeight:'bold'}}>{props.data.name}</Typography>
            <Box sx={{mt:2}}>
                <UsersEditCustomerAccessList data={accessLevel} setAccessLevelFx={setAccessLevel} />
            </Box>
            <Box id="CompanyLink" sx={{display:'flex', alignItems:'center'}}>
                <TextField
                    id="tbCompanyLink"
                    margin="normal"
                    fullWidth
                    label="Company"
                    size="small"
                    value={companyLink==null?'':companyLink.name}
                    onClick={setShowCompanyLinkModal}
                />
                <Box sx={{ml:1, mt:1}}>            
                    <Button variant="contained" onClick={setShowCompanyLinkModal} >
                        <SearchIcon sx={{fontSize:'2em'}} />
                    </Button>
                </Box>            
            </Box>                
            <Box id="MarkAccess" sx={{display:"flex", mt:1}}>
                <FormControl component="fieldset" sx={{flexGrow:1}}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox checked={markAccess===1?true:false} 
                                        onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setMarkAccess(1):setMarkAccess(0);}} 
                                        name="cbxMarkAccess"
                                        disabled={accessLevel.id==1?false:true}
                                />
                            }
                            label="Allow Reel Marks"
                        />
                    </FormGroup> 
                </FormControl>
            </Box>             
            <Box sx={{textAlign:"center", mt:2}}>
                <Button variant="contained" onClick={handleSubmit} sx={{mr:2}}>Add Customer</Button>
                <Button variant="contained" onClick={e => {props.showAccessLevelModalFx(false);}}>Cancel</Button>
            </Box>
        </form>
        </>
    );
}
