import {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';

export default function CrewMembersFilterMarkAccess(props) {
    //console.debug("RTS Break ReelInspectionsFilterStatus");

    const [markAccess, setMarkAccess] = useState(props.markAccess==null?-1:props.markAccess);
    const [repoData] = useState([{value:-1, label:"All"}, {value:0, label:"No"}, {value:1, label:"Yes"}]);

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setMarkAccess(props.markAccess!=null?props.markAccess:-1);
        setIsLoading(false);
    }, [props.markAccess]); 


    function valChange(e, val){
        setMarkAccess(val.value);
        props.setMarkAccessFx(val.value);
        props.setFormDirtyFx(1);
    }


    if(isLoading){
        return(
            <Loading />
        );
    };
    
    
    return(
        <>
            <Autocomplete
                options={repoData}
                renderInput={(params) => <TextField {...params} label="Mark Access"  size="small" />}
                value={repoData.find(v => v.value===markAccess)}
                onChange={valChange}
                getOptionLabel={(curVal) => curVal.label}
                disableClearable={true}
                renderOption={(props, repoData) => (
                    <Box component="li" {...props} key={repoData.value}>
                        {repoData.label}
                    </Box>
                )}
            />
        </>
    );
}
