import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import CustomersFilterSubscribed from './CustomersFilterSubscribed';
import CustomersFilterTypeList from './CustomersFilterTypeList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelsFilter(props){
    //console.debug("RTS Break ReelsFilter");

    const {setCurFilter} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [subscribed, setSubscribed] = useState();
    const [customerType, setCustomerType] = useState();
    

    useEffect(() => {
        setSubscribed(props.filter.subscribed);
        setCustomerType(props.filter.customerType);
        setIsLoading(false);
    },[props.filter.subscribed, props.filter.customerType]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'CustomersPage', filter:{customerType:customerType, subscribed:subscribed}});
        props.setFilterFx({customerType:customerType, subscribed:subscribed});
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <CustomersFilterTypeList customerType={customerType} setCustomerTypeFx={setCustomerType} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <CustomersFilterSubscribed subscribed={subscribed} setSubscribedFx={setSubscribed} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
