import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

export default function PalletTurnsList(props){
    //console.debug("RTS Break PalletsList");

    function handleDrill(e, cellValues, pMode){
        props.openDetailsFx(cellValues.row);
    }

    const columns = [
        {
            field: 'name', 
            headerName: "Name", 
            description:"Pallet Name", 
            flex:2,
            renderCell: (cellValues) => {
                return (
                    <Link
                        onClick={(event) => {handleDrill(event, cellValues);}}
                        sx={{cursor:'pointer', fontWeight:'bold', textDecoration:'none'}}
                    >
                        {
                        cellValues.row.name
                        }
                    </Link>
                );
            },             
        },
        {
            field: 'esn', 
            headerName: "ESN", 
            description:"Electronic Serial Number", 
            flex:2
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"Pallet Status", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value === 0){
                    return ('Disabled');
                }
                else{
                    return('Active');
                }
            },            
        },        
        {
            field: 'turns', 
            headerName: "Turns", 
            description:"Number of Pallet Turns", 
            flex:1,
        },        
        {
            field: 'curPlant', 
            headerName: "Plant/Partner", 
            description:"Current Plant/Partner", 
            flex:1,
        },        
    ];

    return (
        <DataGridPro 
            autoHeight 
            pagination 
            components={{Toolbar: GridToolbar}} 
            componentsProps={{toolbar:{showQuickFilter:true}}}
            pageSize={50} 
            rows={props.data} 
            columns={columns} 
            getRowId={row => row.esn} 
        />
    );
}

