import {useState,  useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListFilterSortByList(props) {
    //console.debug("RTS Break LoadListFilterSortByList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    // This must come after the repoStatus is set because of the find.
    const [sortBy, setSortBy] = useState(props.sortBy);
    const repoSortBys = [{id:0, label:"Ship Date Increasing"},
                         {id:1, label:"Ship Date Decreasing"},
                         {id:2, label:"Remaining Product Increasing"},
                         {id:3, label:"Remaining Product Decreasing"},
                         {id:4, label:"Deferral Remaining Increasing"},
                         {id:5, label:"Deferral Remaining Decreasing"},
                         {id:6, label:"Battery Level Increasing"},
                         {id:7, label:"Battery Level Decreasing"},
                        ];


    function valChange(e, val){
        props.setSortByFx(val.id);
        setSortBy(val.id);
    }    

    return(
        <>
            <Autocomplete
                options={repoSortBys}
                renderInput={(params) => <TextField {...params} label="Sort By" size="small" />}
                value={repoSortBys.find(v => v.id==sortBy)}
                onChange={valChange}
                getOptionLabel={(repoSortBys) => repoSortBys.label}
                renderOption={(props, repoSortBys) => (
                    <Box component="li" {...props} key={repoSortBys.id}>
                        {repoSortBys.label}
                    </Box>
                )}
            />
        </>
    );
}

