import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

export default function ProjectsList(props){
    //console.debug("RTS Break ProjectList");
 
    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    
    function handleClick(e, cellValues){
        history("/ProjectsEdit", {state: {idGuid:cellValues.row.idGuid, display:cellValues.row.name, mode:1}});
    }


    const columns = [
        {
            field: 'Edit',
            headerName: '',
            description:"Edit Project", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Project Name", 
            description:"Project Name", 
            flex:4,
            hideable:false,
        },
        {
            field: 'managerName', 
            headerName: "Manager", 
            description:"Project Manager", 
            flex:3        
        },
        {
            field: 'loadCount', 
            headerName: "# Reels", 
            description:"Number of Reels",
            type: 'number', 
            flex:1    
        }, 
        {
            field: 'status', 
            headerName: "Status", 
            description:"Project Status",
            flex:2,    
            renderCell: (cellValues) => {
                if(cellValues.value === 0){
                    return ('Planning');
                }
                else if(cellValues.value === 50){
                    return('Started');
                }
                else{
                    return('Complete');
                }
            },     
        }, 
        {
            field: 'startDate', 
            headerName: "Start Date", 
            description:"Project Start Date", 
            type: 'date',
            flex:2,
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null;
                }
                
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
        },
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {managerName:false, loadCount:false, startDate:false}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}
