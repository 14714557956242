import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import EditBack from '../components/EditBack';
import ReelInspectionsEdit from '../components/ReelInspections/ReelInspectionsEdit';

export default function ReelInspectionsPageEdit( props ){
    //console.debug("RTS Break ReelInspectionsPageEdit");

    const location = useLocation();
    const reel = location.state.reel;
    const idGuid = location.state.idGuid;
    const mode = location.state.mode;

    const [formDirty, setFormDirty] = useState(0);


    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 

    
    return(
        <Container component="main" maxWidth="md" sx={{mb:5, minWidth:'600px'}}>
            <CssBaseline />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
                {formDirty===0
                ?
                    <Typography variant="h5" >Add Reel Inspection</Typography>
                :
                    <Typography variant="h5" sx={{fontStyle:'italic'}}>Add Reel Inspection *</Typography>
                }
            </Box>            
            <Box>
                <ReelInspectionsEdit reel={reel} idGuid={idGuid} mode={mode} formDirty={formDirty} setFormDirtyFx={setFormDirty} />
            </Box>
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
            </Box>              
        </Container>
    );
}
