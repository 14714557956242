import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import WorkItemsDelete1 from '../../components/WorkItems/WorkItemsDelete1';

export default function WorkItemsDeletePage( props ){
    //console.debug("RTS Break WorkItemsDeletePage");

    const location = useLocation();
    const data = location.state.workItem;
    const mode = location.state.mode;


    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 

    return(
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box>
                {
                data.workSet.workSetType.code === 1?
                 <WorkItemsDelete1 data={data} mode={mode} />
                 :<Box sx={{pt:3}}><Typography variant='h1'>Unknown Work Set Type</Typography></Box>
                }

            </Box>
        </Container>
    );
}
