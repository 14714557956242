import {useState, useEffect } from 'react';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import EditBack from '../../components/EditBack';
import UsersEdit from '../../components/Users/UsersEdit';

export default function UsersPageEdit( props ){
    //console.debug("RTS Break UsersPageEdit");

    const location = useLocation();
    const idGuid = location.state.idGuid;
    const display = location.state.display;
    const mode = location.state.mode;
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [formDirty, setFormDirty] = useState(0);
    // const [tabIndex, setTabIndex] = useState(0);
    // const [newTabIndex, setNewTabIndex] = useState(0);

    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 


    // function switchTabs(curIndex){
    //     setShowConfirmModal(false);
    //     setFormDirty(0);
    //     if(mode===1){
    //         setTabIndex(curIndex);
    //     }
    // }    

    return(
        <>
        <Dialog fullWidth={true} open={showConfirmModal}>
            <DialogTitle>Leave Tab?</DialogTitle>
            <DialogContent>
                <Typography>Changes will be lost.</Typography>
            </DialogContent>
            <DialogActions>
                {/* <Button variant="contained" onClick={e => {switchTabs(newTabIndex);}}>Yes</Button> */}
                <Button variant="contained" onClick={e => {setShowConfirmModal(false);}}>No</Button>
            </DialogActions>
        </Dialog>        
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
                {formDirty===0
                ?
                    <Typography variant="h5" >Edit User</Typography>
                :
                    <Typography variant="h5" sx={{fontStyle:'italic'}}>Edit User *</Typography>
                }
            </Box>
            <Box>
                <UsersEdit idGuid={idGuid} mode={mode} display={display} formDirty={formDirty} setFormDirtyFx={setFormDirty} />
            </Box>
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
            </Box>            
        </Container>
        </>
    );
}
