import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import LoadsProductRemainsDetailList from '../../components/Analytics/LoadsProductRemainsDetailList';
import LoadsProductRemainsDetailHeader from '../../components/Analytics/LoadsProductRemainsDetailHeader';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsProductRemainsDetailPage( props ){
    //console.debug("RTS Break LoadsProductRemainsDetailPage");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/Analytics/GetLoadProductRemainsDetails";
        axios
        .post(
            apiURL,
            sessionCustomer.customer,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token, sessionCustomer.customer]);

  
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, [props, fetchData]);  
    
    
    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box id="pageBox">
            <LoadsProductRemainsDetailHeader />
            <Box sx={{mt:2}}>           
                <LoadsProductRemainsDetailList data={data} />
            </Box>
        </Box>
    );
}
