import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PalletNavButAdmin( props ){
    //console.debug("RTS Break PalletNavButAdmin");

    const {setCurFilter} = useSessionContext();
    const history = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorElSystem, setAnchorElSystem] = useState(null);

    const handleOpenSystemMenu = (e) => {
        setOpen(!open);
        setAnchorElSystem(e.currentTarget);
    };
      
    const handleCloseSystemMenu = () => {
        props.setOpenDrawerFx(false);
        setAnchorElSystem(null);
    };
    
    
    function handlePalletsClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/Pallets");
    }      


    function handlePlantPartnerClick(e){
        setCurFilter(null);
        handleCloseSystemMenu();
        history("/PalletPlants");
    }

    // const isMobile = props.setOpenDrawerFx;


    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="li-PalletAdmin" onClick={handleOpenSystemMenu}>
                <ListItemText primary="Administration" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton key="li-AdminPallets" sx={{ pl: 4 }}  onClick={handlePalletsClick}>
                        <ListItemText primary="Pallets" />
                    </ListItemButton>
                    <ListItemButton key="li-AdminPlants" sx={{ pl: 4 }}  onClick={handlePlantPartnerClick}>
                        <ListItemText primary="Plants/Partners" />
                    </ListItemButton>
                </List>
            </Collapse>

            </>
        )
    }

    return (
        <>
        <Button
            key='but-PalletAdmin'
            onClick={handleOpenSystemMenu}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
            endIcon={<ArrowDropDownIcon />}
        >
            Administration
        </Button>
        <Menu
            id="menu-PalletAdmin"
            anchorEl={anchorElSystem}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElSystem)}
            onClose={handleCloseSystemMenu}
            sx={{
                display: { xs: 'none', md: 'block' },
            }}
        >
            <MenuItem key='menu-AdminPallets' onClick={handlePalletsClick}>
                <Typography textAlign="center">Pallets</Typography>
            </MenuItem>

            <MenuItem key='menu-AdminPlants' onClick={handlePlantPartnerClick}>
                <Typography textAlign="Left">Plants/Partners</Typography>
            </MenuItem>
       
        </Menu>
        </>
    );
}



