import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';


export default function ReelInspectionsFilterReelTypeList(props) {
    //console.debug("RTS Break UsersFilterLicensedByList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [licensedBy, setLicensedBy] = useState('');
    const [repoLicensedBy, setRepoLicensedBy] = useState([]);


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/AppUsers/GetActiveLicensedBy";

        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoLicensedBy(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        setLicensedBy(props.licensedBy);
        fetchData();
    }, [props, fetchData]); 


    function valChange(e, val){
        props.setLicensedByFx(val);
        props.setFormDirtyFx(1);
        setLicensedBy(val);
    }
    
    
    if(hasError){
        return(
            <Container className="fullScreen d-flex justify-content-center align-items-center">
                <Container style={{textAlign:"center"}}>
                    <Container>
                        <h3 style={{color:"red"}}>An error has occured</h3>
                    </Container>
                    <Container>
                        <span>Please review the browser console for details</span>                
                    </Container>
                </Container>
            </Container>
        );
    }


    if(isLoading){
        return(
            <Container className="d-flex justify-content-center align-items-center">
                <Container className="spinner-border" role="status">
                    <span className="sr-only">Loading Data...</span>
                </Container>
            </Container>
        );
    }


    return(
        <>
            <Autocomplete
                options={repoLicensedBy}
                renderInput={(params) => <TextField {...params} label="Licensed By" size="small" />}
                value={licensedBy || null}
                onChange={valChange}
                getOptionLabel={(licensedBy) => licensedBy==null?'':licensedBy.name + " (" + licensedBy.idSap + ")"}
                renderOption={(props, repoLicensedBy) => (
                    <Box component="li" {...props} key={repoLicensedBy.idGuid}>
                        {repoLicensedBy.name + " (" + repoLicensedBy.idSap + ")"}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.idGuid === value.idGuid}
            />
        </>
    );
}

