import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import Error from '../Error';
import LoadsEditCustomerLookup from './LoadsEditCustomerLookup';
import LoadsEditShipToLookup from './LoadsEditShipToLookup';
import LoadsEditPlantLookup from './LoadsEditPlantLookup';
import LoadsEditReelLookup from './LoadsEditReelLookup';
import LoadsEditProductLookup from './LoadsEditProductLookup';
import LoadsEditTrackerLookup from './LoadsEditTrackerLookup';
import LoadsEditProjectLookup from './LoadsEditProjectLookup';

import LoadsEditUomList from './LoadsEditUomList';
import EditRegion from '../EditRegion';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsEdit(props) {
    console.debug("RTS Break LoadsEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const display = props.display;
    const mode = props.mode;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showSoldToModal, setShowSoldToModal] = useState(false);
    const [showAssignedToModal, setShowAssignedToModal] = useState(false);
    const [showShipToModal, setShowShipToModal] = useState(false);
    const [showPlantModal, setShowPlantModal] = useState(false);
    const [showReelModal, setShowReelModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [showTrackerModal, setShowTrackerModal] = useState(false);
    const [showProjectModal, setShowProjectModal] = useState(false);

    const [status, setStatus] = useState(1);
    const [soldToCustomer, setSoldToCustomer] = useState(null);
    const [assignedToCustomer, setAssignedToCustomer] = useState(null);
    const [shipToCustomer, setShipToCustomer] = useState(null);
    const [assignedToLink, setAssignedToLink] = useState(null);
    const [contractToLink, setContractToLink] = useState(null);
    const [shipFromPlant, setShipFromPlant] = useState(null);
    const [product, setProduct] = useState(null);
    const [reel, setReel] = useState(null);
    const [tracker, setTracker] = useState(null);
    const [customerVisibleFlag, setCustomerVisibleFlag] = useState(0);
    const [customerReel, setCustomerReel] = useState(null);
    const [project, setProject] = useState(null);
    const [shipDate, setShipDate] = useState();
    const [amt, setAmt] = useState(0);
    const [uom, setUom] = useState('ft');
    const [price, setPrice] = useState(0)
    const [shipToName, setShipToName] = useState('');
    const [shipToAddress1, setShipToAddress1] = useState('');
    const [shipToAddress2, setShipToAddress2] = useState('');
    const [shipToCity, setShipToCity] = useState('');
    const [shipToRegion, setShipToRegion] = useState('');
    const [shipToZip, setShipToZip] = useState('');
    const [deferralPeriod, setDeferralPeriod] = useState(0);
    const [netWeight, setNetWeight] = useState('');
    const [tareWeight, setTareWeight] = useState('');
    const [grossWeight, setGrossWeight] = useState('');
    const [customerPo, setCustomerPo] = useState('');
    const [customerMaterialNo, setCustomerMaterialNo] = useState('');
    const [idSapPo, setIdSapPo] = useState('');
    const [idSapBol, setIdSapBol] = useState('');
    const [idSapPackingList, setIdSapPackingList] = useState('');
    const [idSapBatchNo, setIdSapBatchNo] = useState('');
    const [reelType, setReelType] = useState();
    const [markDate, setMarkDate] = useState();
    const [markUser, setMarkUser] = useState();
    const [markEmptyScrap, setMarkEmptyScrap] = useState();
    const [markWeight, setMarkWeight] = useState();
    const [pickupRequestDate, setPickupRequestDate] = useState();
    const [pickupRequest, setPickupRequest] = useState();
    const [returnedDate, setReturnedDate] = useState();


    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/Loads/GetLoadById/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setStatus(response.data.status);
                setSoldToCustomer(response.data.soldToCustomer);
                setAssignedToCustomer(response.data.assignedToCustomer);
                setShipToCustomer(response.data.shipToCustomer);
                setAssignedToLink(response.data.assignedToLink);
                setContractToLink(response.data.contractToLink);
                setShipFromPlant(response.data.shipFromPlant);
                setProduct(response.data.product);
                setReel(response.data.reel);
                setTracker(response.data.tracker);
                setCustomerVisibleFlag(response.data.customerVisibleFlag);
                setCustomerReel(response.data.customerReel);
                setProject(response.data.project);
                setShipDate(response.data.shipDate!=null?new Date(response.data.shipDate):null);
                setAmt(response.data.amt);
                setUom(response.data.uom != null?response.data.uom.toLowerCase():'ft');
                setPrice(response.data.price!=null?response.data.price:0)
                setShipToName(response.data.shipToName!=null?response.data.shipToName:'');
                setShipToAddress1(response.data.shipToAddress1!=null?response.data.shipToAddress1:'');
                setShipToAddress2(response.data.shipToAddress2!=null?response.data.shipToAddress2:'');
                setShipToCity(response.data.shipToCity!=null?response.data.shipToCity:'');
                setShipToRegion(response.data.shipToRegion!=null?response.data.shipToRegion:'');
                setShipToZip(response.data.shipToZip!=null?response.data.shipToZip:'');
                setDeferralPeriod(response.data.deferralPeriod);
                setNetWeight(response.data.netWeight!=null?response.data.netWeight:'');
                setTareWeight(response.data.tareWeight!=null?response.data.tareWeight:'');
                setGrossWeight(response.data.grossWeight!=null?response.data.grossWeight:'');
                setCustomerPo(response.data.customerPo!=null?response.data.customerPo:'');
                setCustomerMaterialNo(response.data.customerMaterialNo!=null?response.data.customerMaterailNo:'');
                setIdSapPo(response.data.idSapPo!=null?response.data.idSapPo:'');
                setIdSapBol(response.data.idSapBol!=null?response.data.idSapBol:'');
                setIdSapPackingList(response.data.idSapPackingList!=null?response.data.idSapPackingList:'');
                setIdSapBatchNo(response.data.idSapBatchNo!=null?response.data.idSapBatchNo:'');
                setReelType(response.data.reelType);
                setMarkDate(response.data.markDate);
                setMarkUser(response.data.markUser);
                setMarkEmptyScrap(response.data.markEmptyScrap);
                setMarkWeight(response.data.markWeight);
                setPickupRequestDate(response.data.pickupRequestDate);
                setPickupRequest(response.data.pickupRequest);
                setReturnedDate(response.data.returnedDate);

                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]); 
    
    
    useEffect(() => {
        if(mode === 1){
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  


    function handleSubmit(e) {
        e.preventDefault();
 
        let apiObj = {
            IdGuid: props.idGuid,
            Reel: reel,
            AssignedToCustomer: assignedToCustomer,
            SoldToCustomer: soldToCustomer,
            ShipToCustomer: shipToCustomer,
            AssignedToLink: assignedToLink,
            ContractToLink: contractToLink,
            ShipFromPlant: shipFromPlant,
            Product: product,
            CustomerProject: project,
            Tracker: tracker,
            CustomerVisibleFlag: customerVisibleFlag,
            CustomerReel: customerReel,
            Amt: amt,
            Uom: uom,
            Price: price,
            ShipDate: shipDate,
            ShipToName: shipToName,
            ShipToAddress1: shipToAddress1,
            ShipToAddress2: shipToAddress2,
            ShipToCity: shipToCity,
            ShipToRegion: shipToRegion,
            ShipToZip: shipToZip,
            ShipToGeoLat: null,
            ShipToGeoLng: null,
            DeferralPeriod: deferralPeriod,
            NetWeight: netWeight,
            TareWeight: tareWeight,
            GrossWeight: grossWeight,
            CustomerPo: customerPo,
            CustomerMaterialNo: customerMaterialNo,
            IdSapPo: idSapPo,
            IdSapBol: idSapBol,
            IdSapPackingList: idSapPackingList,
            IdSapBatchNo: idSapBatchNo,
            Status: status,
            ReelType: reelType,
            MarkDate: markDate,
            MarkUser: markUser,
            MarkEmptyScrap: markEmptyScrap,
            MarkWeight: markWeight,
            PickupRequestDate: pickupRequestDate,
            PickupRequest: pickupRequest, 
            ReturnedDate: returnedDate,
            modifyUser: user.userName
        }

        if(apiObj.AssignedToCustomer === null){
            setErrorTitle("Validation Error");
            setErrorBody("Assigned To Customer is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.SoldToCustomer === null){
            setErrorTitle("Validation Error");
            setErrorBody("Sold To Customer is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.ShipToCustomer === null){
            setErrorTitle("Validation Error");
            setErrorBody("Ship To Customer is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Reel === null){
            setErrorTitle("Validation Error");
            setErrorBody("Reel is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Product === null){
            setErrorTitle("Validation Error");
            setErrorBody("Product is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.ShipFromPlant === null){
            setErrorTitle("Validation Error");
            setErrorBody("Ship From Plant is required");
            setShowErrorModal(true);
            return;
        }


        if(apiObj.Amt === null || apiObj.Amt === 0){
            setErrorTitle("Validation Error");
            setErrorBody("Amount is required and must be greater than 0");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.Uom === null){
            setErrorTitle("Validation Error");
            setErrorBody("Unit of  Measure is required");
            setShowErrorModal(true);
            return;
        }

        if(apiObj.ShipDate === null){
            setErrorTitle("Validation Error");
            setErrorBody("Ship Date is required");
            setShowErrorModal(true);
            return;
        }



        if(mode > 0){
            let apiURL = apiRoot + "/Loads/UpdateLoad/" + props.idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateLoad Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Loads/CreateLoad";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateLoad Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    function setSoldTo(pCustomer){
        setSoldToCustomer(pCustomer);
        setShowSoldToModal(false);
        props.setFormDirtyFx(1);
    };      


    function setAssignedTo(pCustomer){
        setAssignedToCustomer(pCustomer);
        setShowAssignedToModal(false);
        props.setFormDirtyFx(1);
    };   
    
    
    function setShipTo(pCustomer){
        setShipToCustomer(pCustomer);
        setShowShipToModal(false);
        props.setFormDirtyFx(1);
    };  
    
    
    function setPlant(pPlant){
        setShipFromPlant(pPlant);
        setShowPlantModal(false);
        props.setFormDirtyFx(1);
    };      


    function setReelVal(pValue){
        setReel(pValue);
        setShowReelModal(false);
        props.setFormDirtyFx(1);
    };  

    
    function setProductVal(pValue){
        setProduct(pValue);
        setShowProductModal(false);
        props.setFormDirtyFx(1);
    }; 

    
    function setTrackerVal(pValue){
        setTracker(pValue);
        setShowTrackerModal(false);
        props.setFormDirtyFx(1);
    }; 
    
 
    function setProjectVal(pValue){
        setProject(pValue);
        setShowProjectModal(false);
        props.setFormDirtyFx(1);
    }; 
    

    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };   


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showSoldToModal} onClose={()=>{setShowSoldToModal(false);}}>
            <DialogTitle>Sold To Customer</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsEditCustomerLookup data={soldToCustomer} setValueFx={setSoldTo} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowSoldToModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Dialog fullWidth={true} maxWidth="md" open={showAssignedToModal} onClose={()=>{setShowAssignedToModal(false);}}>
            <DialogTitle>Assigned To Customer</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsEditCustomerLookup data={assignedToCustomer} setValueFx={setAssignedTo} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowAssignedToModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showShipToModal} onClose={()=>{setShowShipToModal(false);}}>
            <DialogTitle>Shipped To Customer</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsEditShipToLookup data={shipToCustomer} setValueFx={setShipTo} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowShipToModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Dialog fullWidth={true} maxWidth="md" open={showPlantModal} onClose={()=>{setShowPlantModal(false);}}>
            <DialogTitle>Shipped From Plant</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsEditPlantLookup data={shipFromPlant} setValueFx={setPlant} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowPlantModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>                        
        <Dialog fullWidth={true} maxWidth="md" open={showReelModal} onClose={()=>{setShowReelModal(false);}}>
            <DialogTitle>Reel</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsEditReelLookup data={reel} setValueFx={setReelVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowReelModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showProductModal} onClose={()=>{setShowProductModal(false);}}>
            <DialogTitle>Product</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsEditProductLookup data={product} setValueFx={setProductVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowProductModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Dialog fullWidth={true} maxWidth="sm" open={showTrackerModal} onClose={()=>{setShowTrackerModal(false);}}>
            <DialogTitle>Tracker</DialogTitle>
            <DialogContent>
                <LoadsEditTrackerLookup tracker={tracker} setValueFx={setTrackerVal} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowTrackerModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>                                       
        <Dialog fullWidth={true} maxWidth="sm" open={showProjectModal} onClose={()=>{setShowProjectModal(false);}}>
            <DialogTitle>Project</DialogTitle>
            <DialogContent>
                <LoadsEditProjectLookup project={project} setValueFx={setProjectVal} customer={assignedToCustomer} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowProjectModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>                                       
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="loadsForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <Box id="SoldTo" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbSoldTo"
                        margin="normal"
                        fullWidth
                        label="Sold To Customer"
                        size="small"
                        required
                        value={soldToCustomer==null?'':soldToCustomer.name + " (" + soldToCustomer.idSap + ")"}
                        onClick={setShowSoldToModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowSoldToModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <Box id="AssignedTo" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbAssignedTo"
                        margin="normal"
                        fullWidth
                        label="Assigned To Customer"
                        size="small"
                        required
                        value={assignedToCustomer==null?'':assignedToCustomer.name + " (" + assignedToCustomer.idSap + ")"}
                        onClick={setShowAssignedToModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowAssignedToModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <Box id="ShipTo" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbShipTo"
                        margin="normal"
                        fullWidth
                        label="Shipped To Customer"
                        size="small"
                        required
                        value={shipToCustomer==null?'':shipToCustomer.name + " (" + shipToCustomer.idSap + ")"}
                        onClick={setShowShipToModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowShipToModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <Box id="ShipFromPlant" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbPlant"
                        margin="normal"
                        fullWidth
                        label="Ship From Plant"
                        size="small"
                        required
                        value={shipFromPlant==null?'':shipFromPlant.name + " (" + shipFromPlant.idSap + ")"}
                        onClick={setShowPlantModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowPlantModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <Box id="Reel" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbReel"
                        margin="normal"
                        fullWidth
                        label="Reel"
                        size="small"
                        required
                        value={reel==null?'':reel.serialNumber + " (" + reel.reelType.name + ")"}
                        onClick={setShowReelModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowReelModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <Box id="Product" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbProduct"
                        margin="normal"
                        fullWidth
                        label="Product"
                        size="small"
                        required
                        value={product==null?'':product.name + " (" + product.idSap + ")"}
                        onClick={setShowProductModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowProductModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box> 
                <Box id="Tracker" sx={{display:'flex', alignItems:'center', width:'100%'}}>
                    <TextField
                        id="tbTracker"
                        margin="normal"
                        fullWidth
                        label="Tracker"
                        size="small"
                        value={tracker==null?'':tracker.esn}
                        onClick={setShowTrackerModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowTrackerModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>                                                
                <Box id="CustomerVisible" sx={{display:"flex"}}>
                    <FormControl component="fieldset" sx={{flexGrow:1}}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={customerVisibleFlag===1?true:false} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setCustomerVisibleFlag(1):setCustomerVisibleFlag(0);}} 
                                            name="cbxCustomerVisible" 
                                    />
                                }
                                label="Customer Visible"
                            />
                        </FormGroup> 
                    </FormControl>
                </Box>
                <Box id="Project" sx={{display:'flex', alignItems:'center', width:'100%'}}>
                    <TextField
                        id="tbProject"
                        margin="normal"
                        fullWidth
                        label="Project"
                        size="small"
                        value={project==null?'':project.name}
                        onClick={setShowProjectModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowProjectModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>                    
                <LoadsEditUomList uom={uom} setUomFx={setUom} />
                <TextField
                    id="tbAmount"
                    type="number"
                    margin="normal"
                    fullWidth
                    label="Length (ft)"
                    size="small"
                    required
                    value={amt}
                    onChange={(e) => {props.setFormDirtyFx(1); setAmt(e.target.value);}}
                    sx={{flex:1, mr:1}}
                />
                <TextField
                    id="tbPrice"
                    type="number"
                    margin="normal"
                    fullWidth
                    label="Price"
                    size="small"
                    value={price}
                    onChange={(e) => {props.setFormDirtyFx(1); setPrice(e.target.value);}}
                />
                <DatePicker
                    id="tbShipDate"
                    label="Ship Date"
                    inputFormat="MM/dd/yyyy"
                    value={shipDate}
                    onChange={(val) => setShipDate(val)}
                    renderInput={(params) => <TextField margin="normal" required size="small" {...params} />}
                    sx={{mt:2}}
                />  
                <Typography variant="h6" sx={{mt:2, mb:1}}>Shipping Address</Typography>
                <TextField
                    id="tbShipToName"
                    margin="none"
                    fullWidth
                    label="Customer Name"
                    size="small"
                    value={shipToName}
                    onChange={(e) => {props.setFormDirtyFx(1); setShipToName(e.target.value);}}
                    sx={{mb:2}}
                />
                <TextField
                    id="tbShipToAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={shipToAddress1}
                    onChange={(e) => {props.setFormDirtyFx(1); setShipToAddress1(e.target.value);}}
                />
                <TextField
                    id="tbShipToAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={shipToAddress2}
                    onChange={(e) => {props.setFormDirtyFx(1); setShipToAddress2(e.target.value);}}
                />
                <TextField
                    id="tbShipToCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={shipToCity}
                    onChange={(e) => {props.setFormDirtyFx(1); setShipToCity(e.target.value);}}
                />
                <Box sx={{display:"flex"}}>
                    <Box sx={{flex:1, mr:1}} >
                        <EditRegion region={shipToRegion} setShipToRegionFx={setShipToRegion} />
                    </Box>
                    <TextField
                        id="tbShipToZip"
                        margin="normal"
                        fullWidth
                        label="Zip Code"
                        size="small"
                        value={shipToZip}
                        onChange={(e) => {props.setFormDirtyFx(1); setShipToZip(e.target.value);}}
                        sx={{flex:1}}
                    />
                </Box>
                <Typography variant="h6" sx={{mt:2, mb:1}}>Reel Deferral</Typography>
                <TextField
                    id="tbDeferralPeriod"
                    margin="none"
                    fullWidth
                    label="Deferral Period (Months)"
                    size="small"
                    value={deferralPeriod}
                    onChange={(e) => {props.setFormDirtyFx(1); setDeferralPeriod(e.target.value);}}
                />
                <Typography variant="h6" sx={{mt:2, mb:1}}>Weights</Typography>
                <TextField
                    id="tbNetWeight"
                    margin="none"
                    fullWidth
                    label="Net Weight"
                    size="small"
                    value={netWeight}
                    onChange={(e) => {props.setFormDirtyFx(1); setNetWeight(e.target.value);}}
                />
                <TextField
                    id="tbTareWeight"
                    margin="normal"
                    fullWidth
                    label="Tare Weight"
                    size="small"
                    value={tareWeight}
                    onChange={(e) => {props.setFormDirtyFx(1); setTareWeight(e.target.value);}}
                />                
                <TextField
                    id="tbGrossWeight"
                    margin="normal"
                    fullWidth
                    label="Gross Weight"
                    size="small"
                    value={grossWeight}
                    onChange={(e) => {props.setFormDirtyFx(1); setGrossWeight(e.target.value);}}
                />                
                <Typography variant="h6" sx={{mt:2, mb:1}}>Customer References</Typography>
                <TextField
                    id="tbCustomerPo"
                    margin="none"
                    fullWidth
                    label="Customer Purchase Order"
                    size="small"
                    value={customerPo}
                    onChange={(e) => {props.setFormDirtyFx(1); setCustomerPo(e.target.value);}}
                />
                <TextField
                    id="tbCustomerMaterialNo"
                    margin="normal"
                    fullWidth
                    label="Customer Material Number"
                    size="small"
                    value={customerMaterialNo}
                    onChange={(e) => {props.setFormDirtyFx(1); setCustomerMaterialNo(e.target.value);}}
                />
                <Typography variant="h6" sx={{mt:2, mb:1}}>SAP Documents</Typography>
                <TextField
                    id="tbIdSapPo"
                    margin="none"
                    fullWidth
                    label="Purchase Order"
                    size="small"
                    value={idSapPo}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdSapPo(e.target.value);}}
                />
                <TextField
                    id="tbIdSapBol"
                    margin="normal"
                    fullWidth
                    label="Bill of Lading"
                    size="small"
                    value={idSapBol}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdSapBol(e.target.value);}}
                />
                <TextField
                    id="tbIdSapPackingList"
                    margin="normal"
                    fullWidth
                    label="Packing List"
                    size="small"
                    value={idSapPackingList}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdSapPackingList(e.target.value);}}
                />                
                <TextField
                    id="tbIdSapBatchNo"
                    margin="normal"
                    fullWidth
                    label="Batch Number"
                    size="small"
                    value={idSapBatchNo}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdSapBatchNo(e.target.value);}}
                />          
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true}  type="submit" sx={{mr:2}}>Save</Button>
                </Box>
                </Box>
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );

}
