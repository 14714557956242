import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';


import Loading from '../../components/Loading';
import Error from '../../components/Error';
import PalletTurnsList from '../../components/PalletTurns/PalletTurnsList';
import PalletDashboardHeader from '../../components/PalletDashboard/PalletDashboardHeader';
import PalletTurnDetails from '../../components/PalletTurnDetails/PalletTurnDetails';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PalletsPage( props ){
    //console.debug("RTS Break PalletsPage");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [data, setData] = useState([]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody] = useState("");
    const [errorTitle] = useState("Validation Error");

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [palletData, setPalletData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/PalletTurns/GetPalletTurnsList";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);

    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    },[props, fetchData]);  


    function openDetails(pPallet){
        setPalletData(pPallet);
        setShowDetailsModal(true);
    }    
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    
    return(
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <Dialog fullWidth={true} maxWidth="md" open={showDetailsModal}>
            <DialogTitle>Pallet Details</DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:"400px"}}>
                    <PalletTurnDetails data={palletData} setShowDetailsModalFx={setShowDetailsModal} />
                </Box>            
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowDetailsModal(false);}}>Close</Button>
            </DialogActions>            
        </Dialog> 
        <Box>
            <Box sx={{mt:2}}>
                <PalletDashboardHeader />
            </Box>
            <Box sx={{mt:2}}>         
                <PalletTurnsList data={data} openDetailsFx={openDetails} />
            </Box>
        </Box>
        </>
    );
}
