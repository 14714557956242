import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';

import LoadListListItemProductDetail from './LoadListListItemProductDetail';

import { useSessionContext } from '../../contexts/SessionContext';

function handleClick(e){

}

export default function LoadListListItemProduct(props){
    //console.debug("RTS Break LoadListListItemProduct");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    
    if(props.data.product.specsDoc!=null){
        return (
            <Link target="_blank" rel="nooperner" href={props.data.product.specsDoc} sx={{textDecoration:'none', cursor:'pointer'}}>
                <LoadListListItemProductDetail data={props.data} />
            </Link>
        )
    }
    else{
        return (
            <LoadListListItemProductDetail data={props.data} />
        ) 
    }   
}

