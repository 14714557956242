import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';


export default function LoadListFilterProductList(props) {
    //console.debug("RTS Break LoadListFilterProductList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [products, setProducts] = useState(props.products===null?[]:props.products);
    const [repoProducts, setRepoProducts] = useState(props.data===null?[]:props.data);


    function valChange(e, val){
        props.setProductsFx(val);
        setProducts(val);
    }


    return(
        <>
            <Autocomplete
                options={repoProducts}
                renderInput={(params) => <TextField {...params} label="Products" size="small" />}
                value={products}
                onChange={valChange}
                getOptionLabel={(product) => product.aliasName + " (" + product.aliasId + ")" }
                renderOption={(props, repoProducts) => (
                    <Box component="li" {...props} key={repoProducts.idGuid}>
                        {repoProducts.aliasName + " (" + repoProducts.aliasId + ")"}
                    </Box>
                )}
                multiple
            />
        </>
    );
}
