import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function LoadListListItemLinkDetails(props){
    //console.debug("RTS Break LoadListListItemLinkDetails");

    const history = useNavigate();

    function handleClick(e){
        history("/LoadListDetails", {state: {idGuid:props.idGuid}});
    };


    return (
        <Link onClick={handleClick} sx={{textDecoration:'none', cursor:'pointer'}}>
            <Box sx={{display:'flex'}}>
                <InfoOutlinedIcon />
                <Typography sx={{fontWeight:'bold', pl:'2px'}}>Show Details</Typography>
            </Box>
        </Link>
    )
}
