import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGridPro, GridToolbar, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPinsEditCustomerUserList(props){
    console.debug("RTS Break LoadPinsEditCustomerUserList");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [selectionModel, setSelectionModel] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoUsers, setRepoUsers] = useState([]);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/CustomerUsers/GetCustomerActiveUsers/" + pFilter;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoUsers(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData(sessionCustomer.customer.idGuid);
    }, [fetchData, sessionCustomer]); 


    function handleClick(e, cellValues){
        let selectedRowData = repoUsers.filter((row) => selectionModel.includes(row.email.toString()));
        props.addLoadPinsFx(selectedRowData);
    }


    const columns = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            hideable: false,
            headerName: 'Selection'
        },        
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"User First Name", 
            flex:3
        },        
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"User Last Name", 
            flex:3 
        },
        {
            field: 'email', 
            headerName: "Email", 
            description:"User Email", 
            flex:4,
            hideable:false,
        },
                {
            field: 'companyLinkName', 
            headerName: "Company", 
            description:"User Company", 
            flex:3 
        },	
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {firstName:false, lastName:false, companyLinkName:false}
        }
        else{
            return {companyLinkName:false}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }    


    return (
        <Box>
            <Box sx={{height:"400px"}}>
                <DataGridPro 
                    checkboxSelection={true} 
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar:{
                            showQuickFilter:true,
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },                        
                        }
                    }}
                    rows={repoUsers} 
                    columns={columns} 
                    getRowId={row => row.email}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    initialState={{
                        columns:{
                            columnVisibilityModel: getVisibilityModel()
                        },
                        pagination: { paginationModel: { pageSize: 50 } },
                        density: getDensity() 
    
                    }}                           
                />
            </Box>

            <Box sx={{mt:2, textAlign:'center'}}>
                <Button variant="contained" onClick={handleClick} sx={{mr:2}}>Add Pins</Button>
            </Box>
        </Box>
    );
}
