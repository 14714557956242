import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import CustomersFilter from '../../components/Customers/CustomersFilter';
import CustomersList from '../../components/Customers/CustomersList';
import CustomersHeader from '../../components/Customers/CustomersHeader';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CustomersPage( props ){
    console.debug("RTS Break CustomersPage");

    const {curFilter, user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/Customers/GetCustomersSummary";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);
    

    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'CustomersPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({subscribed:-1, customerType:null});
            fetchData({subscribed:-1, customerType:null})
        }
    }, [props, user, sessionCustomer, curFilter, fetchData]); 


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    };    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box>
            <CustomersHeader />
            <CustomersFilter filter={filter} setFilterFx={setFilter}/>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>   
                <CustomersList data={data} />
            </Box>
        </Box>
    );
}
