import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import LoadListListItem from "../LoadList/LoadListListItem";
import LoadListDetailsManagePullsEditList from './LoadListDetailsManagePullsEditList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPullManagementEdit(props) {
    //console.debug('RTS Break LoadPullAdjustmentEdit');

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 
            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', width:'100%', mt:2, alignItems:'center'}}>
                <Box sx={{textAlign:'left', width:'50%', pl:1}}>
                    <Typography variant="h6">Pulls</Typography>
                </Box>
                <Box sx={{textAlign:'right', width:'50%', pr:1}}>
                    <Button 
                        type="button" 
                        variant="contained"
                        onClick={()=>{history("/LoadListDetailsAdjustment", {state: {idGuid:props.data.idGuid}});}} 
                        >
                        Add Adjustment
                    </Button>
                    <Button 
                        type="button" 
                        variant="contained"
                        onClick={()=>{history("/LoadListAddPull", {state: {idGuid:props.data.idGuid}});}}
                        sx={{ml:1}} 
                        >
                        Add Pull
                    </Button>                    
                </Box>
            </Box>
            <Box sx={{pt:1}}>
                <LoadListDetailsManagePullsEditList data={props.data} user={props.user} rejectRefreshFx={props.rejectRefreshFx} />
            </Box>
        </Box>
    );

}
