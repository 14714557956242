import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

import Loading from '../Loading';
import Error from '../Error';

export default function CrewMembersFilterCompanyList(props) {
    //console.debug("RTS Break CrewMembersFilterCompanyList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [company, setCompany] = useState();
    const [repoCompanys, setRepoCompanys] = useState([]);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/CompanyLinks/GetActiveCompanyLinksByCustomer/" + pFilter;

        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoCompanys(response.data.map(r => r.name));
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        setCompany(props.company);
        fetchData(sessionCustomer.customer.idGuid);
    }, [props, sessionCustomer, fetchData]); 


    function valChange(e, val){
        props.setCompanyFx(val);
        props.setFormDirtyFx(1);
        setCompany(val);
    }
    
    
    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };

    return(
        <>
            {/* No need for extra attributes because return from API is just a list of strings. */}
            <Autocomplete
                options={repoCompanys}
                value={company}
                renderInput={(params) => <TextField {...params} label="Company" size="small" />}
                onChange={valChange}
            />
        </>
    );
}

