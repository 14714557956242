import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function FilterCustomerNamedLocationList(props) {
    //console.debug("RTS Break LoadListFilterNamedLocationList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    
    const [locations, setLocations] = useState(props.projects===null?[]:props.locations);
    const [repoLocations, setRepoLocations] = useState(props.data===null?[]:props.data);

    
    function valChange(e, val){
        props.setLocationsFx(val);
        setLocations(val);
    }


    return(
        <>
            <Autocomplete
                options={repoLocations}
                renderInput={(params) => <TextField {...params} label="Named Locations" size="small" />}
                value={locations}
                onChange={valChange}
                multiple
            />
        </>
    );
}


