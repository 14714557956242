import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { format, parseISO } from 'date-fns';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import Error from '../Error';
import ManuReelsEditTrackerLookup from './ManuReelsEditTrackerLookup';
import ManuReelsEditSequenceId from './ManuReelsEditSequenceId';
import ManuReelsEditRemoveTracker from './ManuReelsEditRemoveTracker';

import { useSessionContext } from '../../contexts/SessionContext';
import { Typography } from '@mui/material';

export default function ManuReelsEdit(props) {
    //console.debug("RTS Break ManuReelsEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const mode = props.mode;
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);    

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showSequenceModal, setShowSequenceModal] = useState(false);
    const [showTrackerLookup, setShowTrackerLookup] = useState(false);
    const [showRemoveTrackerModal, setShowRemoveTrackerModal] = useState(false);
    const [removeReel, setRemoveReel] = useState(null);

    const [idGuid, setIdGuid] = useState('')
    const [reelType, setReelType] = useState(null);
    const [tracker, setTracker] = useState(null);
    const [status, setStatus] = useState(1);
    const [serialNumber, setSerialNumber] = useState('');
    const [idSap, setIdSap] = useState('');
    

    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/Reels/GetReelById/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);

                setIdGuid(response.data.idGuid)
                setReelType(response.data.reelType);
                setTracker(response.data.tracker);
                setStatus(response.data.status);
                setSerialNumber(response.data.serialNumber!==null?response.data.serialNumber:'');
                setIdSap(response.data.idSap!==null?response.data.idSap:'');
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        if(mode === 1){
            setIdGuid(props.idGuid);
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  


    async function handleSubmit(e) {
        e.preventDefault();

        if(tracker !== null){
            let trackerCheck = await validateTracker();

            if(trackerCheck === -1){
                return;
            }
        }

        saveData();
    }


    function validateTracker(){
        return new Promise(function(resolve, reject){
            let apiURL = apiRoot + "/Reels/ValidateReelTracker"
            axios
            .post(
                apiURL,
                tracker, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    if(response.data.idGuid && response.data.idGuid.length > 0 && response.data.idGuid !== idGuid){
                        setRemoveReel(response.data);
                        setShowRemoveTrackerModal(true);
                        resolve(-1);
                    }
                    else{
                        resolve(0);
                    }
                }
                else{
                    resolve(0);
                }
            })
            .catch(error => {
                setErrorTitle("Validation Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS Tracker Validation Error", error);
                setShowErrorModal(true);
                reject(-1);
            });
        });
    }
    
    
    function confirmRemove(){
        setShowRemoveTrackerModal(false);
        saveData();        
    }


    function saveData(){
        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            SerialNumber: serialNumber===''?null:serialNumber,
            ReelType: reelType,
            Tracker: tracker,
            Status: status,
            modifyUser: user.userName
        }

        let apiURL = apiRoot + "/Reels/UpdateReel";
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateReelType Error", error);
            setShowErrorModal(true);
        });
    }
    

    function selectTracker(val){
        setShowTrackerLookup(false);
        setTracker(val);
    };


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }        


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showSequenceModal} onClose={()=>{setShowSequenceModal(false);}}>
            <DialogTitle>Change Sequnce Id</DialogTitle>
            <DialogContent>
                <ManuReelsEditSequenceId tracker={tracker} setTrackerFx={setTracker} setShowSequenceModalFx={setShowSequenceModal} />
            </DialogContent>
        </Dialog>
        <Dialog open={showTrackerLookup} fullWidth={true} maxWidth="xs" onClose={()=>{setShowTrackerLookup(false);}}>
            <DialogTitle>Select Tracker</DialogTitle>
            <DialogContent>
                <ManuReelsEditTrackerLookup tracker={tracker} setTrackerFx={selectTracker} setFormDirtyFx={props.setFormDirtyFx} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowTrackerLookup(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showRemoveTrackerModal} onClose={()=>{setShowRemoveTrackerModal(false);}}>
            <DialogTitle>Reel Tracker Association Found</DialogTitle>
            <DialogContent>
                <ManuReelsEditRemoveTracker removeReel={removeReel} tracker={tracker} confirmRemoveFx={confirmRemove} setShowRemoveTrackerModalFx={setShowRemoveTrackerModal} />
            </DialogContent>
        </Dialog>                 
        <Box>
            <form id="reelTrackerForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbSerialNumber"
                    margin="normal"
                    fullWidth
                    label="Serial Number"
                    size="small"
                    value={serialNumber}
                    disabled
                />                                               
                <TextField
                    id="tbReelType"
                    margin="normal"
                    fullWidth
                    label="Type"
                    size="small"
                    value={reelType.name}
                    disabled
                />
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbTracker"
                        margin="normal"
                        fullWidth
                        label="Tracker"
                        size="small"
                        value={tracker==null?'':tracker.esn}
                        onClick={setShowTrackerLookup}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowTrackerLookup}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={6} sx={{display:"flex", justifyContent:"start", alignItems:"center"}}>
                        <Typography sx={{fontSize:'.75em'}}>Last Check In:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!=null?tracker.lastUpdate!=null?format(parseISO(tracker.lastUpdate), "MM-dd-yyyy"):'':''}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{display:"flex", justifyContent:{xs:"start", sm:"end"}, alignItems:"center"}}>
                        <Typography sx={{fontSize:'.75em'}}>Battery Level:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!=null?tracker.batteryLevel!=null?tracker.batteryLevel!=='-1'?tracker.batteryLevel+"%":'Unknown':'Unknown':''}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{display:"flex", justifyContent:{xs:"start", sm:"center"}, alignItems:"center", mt:1}}>
                        <Typography sx={{fontSize:'.75em'}}>Sequence Id:</Typography>
                        <Typography sx={{fontSize:'.75em', pl:1}}>{tracker!=null?tracker.idSeq!=null?tracker.idSeq:'-1':''}</Typography>
                        <Button variant="text" sx={{"p":0, "m":0, display:tracker!=null?"initial":"none", "pl":1}} onClick={()=>{setShowSequenceModal(true)}}>Change</Button>
                    </Grid>
                </Grid>
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true}  type="submit" sx={{mr:2}}>Save</Button>
                </Box>
                </Box>
                </Box>
            </form>
        </Box>
        </>
    );
}
