import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import LocatorMapList from "./LocatorMapList";
import LocatorMapSpatial from "./LocatorMapSpatial";

export default function LocatorMap( props ) {
    return(
        <Box>
            <Box sx={{textAlign:'center', mb:1}}>
                <Typography variant="h4">Last Location</Typography>
            </Box>
            <Grid container>
                <Grid item xs={5} md={4} lg={3}>
                    <LocatorMapList data={props.data} user={props.user} openChildFx={props.openChildFx} />
                </Grid>
                <Grid item xs={7} md={8} lg={9}>
                    <LocatorMapSpatial data={props.data} user={props.user} openChildFx={props.openChildFx} />
                </Grid>
            </Grid>
        </Box>
    );
}
