import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import UsersFilterStatus from './UsersFilterStatus';
import UsersFilterLicensedByList from './UsersFilterLicensedByList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function UsersFilter(props){
    //console.debug("RTS Break UsersFilter");

    const {setCurFilter} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [status, setStatus] = useState();
    const [licensedBy, setLicensedBy] = useState();
    

    useEffect(() => {
        setStatus(props.filter.status);
        setLicensedBy(props.filter.licensedBy);
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'UsersPage', filter:{licensedBy:licensedBy, status:status}});
        props.setFilterFx({licensedBy:licensedBy, status:status});
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <UsersFilterLicensedByList licensedBy={licensedBy} setLicensedByFx={setLicensedBy} setFormDirtyFx={setFormDirty} />
            </Grid>
            {
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <UsersFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            }
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
