import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../components/Loading';
import Error from '../components/Error';
import PickupRequestsFilter from '../components/PickupRequests/PickupRequestsFilter';
import PickupRequestsList from '../components/PickupRequests/PickupRequestsList';
import PickupRequestsHeader from '../components/PickupRequests/PickupRequestsHeader';

import { useSessionContext } from '../contexts/SessionContext';

export default function PickupRequestsPage( props ){
    console.debug("RTS Break PickupRequestPage");

    const {curFilter, user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/PickupRequest/GetPickupRequestsSummary";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);
    

    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'PickupRequestsPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({user:user, customer:sessionCustomer.customer, status:50, sortBy:0});
            fetchData({user:user, customer:sessionCustomer.customer, status:50, sortBy:0})
        }
    }, [props, user, sessionCustomer, curFilter, fetchData]); 


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    }; 

    
    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    return(
        <Box>
            <PickupRequestsHeader />
            <PickupRequestsFilter filter={filter} setFilterFx={setFilter} />
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                <PickupRequestsList data={data} />
            </Box>
        </Box>
    );

}
