import {useState, useContext, useEffect} from 'react';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function GridToolbarRefreshButton( props ) {
    //console.debug("RTS Break GridToolbarRefreshButton");

    return(
        <Button startIcon={<RefreshIcon />} onClick={props.refreshEvent}>Refresh</Button>
    );
}


