import Box from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsDueDetailHeader(props){
    //console.debug("RTS Break LoadsDueDetailHeader");
    
    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    return (
        <Box sx={{textAlign:'center'}}>
            <Typography variant='h4'>Reels Due Detail</Typography>
            <Typography variant='h6'>{sessionCustomer.customer.name} - {sessionCustomer.customer.idSap}</Typography>
        </Box>
    )
}
