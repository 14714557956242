import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import PalletPlantsEdit from '../../components/PalletPlants/PalletPlantsEdit';

export default function PalletsPlantPageEdit( props ){
    //console.debug("RTS Break PalletsPlantPageEdit");

    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 

    
    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Typography variant="h5" sx={{my:2}}>Edit Plant/Partner</Typography>
            <Box>
                <PalletPlantsEdit />
            </Box>
      </Container>
    );
}
