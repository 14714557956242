import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGridPro, GridToolbar, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewsUnassignedLoadsLookup(props){
    //console.debug("RTS Break CrewsUnassignedLoadsLookup");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);

    const [repoData, setRepoData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/CustomerUsers/GetCustomerActiveUsers/" + pFilter;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoData(response.data);
            }
            setIsLoading(false);
       })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });  

    },[apiRoot, userAuthToken.token]); 
        

    useEffect(() => {
        fetchData(sessionCustomer.customer.idGuid);
    }, [fetchData, sessionCustomer]);  

    
    function handleClick(e, cellValues){
        let selectedRowData = repoData.filter((row) => selectionModel.includes(row.userName.toString()));
        props.addMembersFx(selectedRowData);
    }


    const columns = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            hideable: false,
            headerName: 'Selection'
        },         
        {
            field: 'name', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:smallForm?4:2,
            valueGetter: (value, row) => {
                return row.firstName + " " + row.lastName;
            },
            hideable:false,
        },        
                 
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {shipDate:false, age:false, remainingLength:false, deferralDaysLeft:false}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };    


    return (
        <Box>
            <Box sx={{height:"400px"}}>
                <DataGridPro 
                    checkboxSelection={true} 
                    pagination
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar:{
                            showQuickFilter:true,
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },                        
                        }
                    }}                    
                    rows={repoData} 
                    columns={columns} 
                    getRowId={row => row.userName}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    initialState={{
                        columns:{
                            columnVisibilityModel: getVisibilityModel()
                        },
                        pagination: { paginationModel: { pageSize: 50 } },
                        density: getDensity() 
    
                    }}                          
                />
            </Box>
            <Box sx={{mt:2, textAlign:'right'}}>
                <Button variant="contained" onClick={handleClick} sx={{mr:2}}>Add Members</Button>
                <Button variant="contained" onClick={e => {props.setShowMembersModalFx(false);}}>Cancel</Button>
            </Box>
        </Box>
    );
}
