import {useEffect, useRef, useCallback} from 'react';
import Box from '@mui/material/Box';

import mapboxgl from 'mapbox-gl';
import mapboxGlDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

export default function NamedLocationsMap( props ) {
    //console.debug("RTS Break NamedLocationsMap");

    const map = useRef(null);
    const draw = useRef(null);

    // All these functions must be initialized before the first useEffect()
    ///////////////////////////////////////////////////////////
    const setPoints = useCallback((evt) => {
        var latLons = draw.current.getAll().features;

        if (latLons.length > 0) {
            var pts = draw.current.getAll().features[0].geometry.coordinates[0];
            props.pointsFx(pts);
        }
        else {
            props.pointsFx([]);
        }
    }, [props]);


    const createDraw = useCallback((evt) => {
        setPoints(evt);
        props.setFormDirtyFx(1);
    }, [setPoints, props]);    


    const deleteDraw = useCallback((evt) => {
        setPoints(evt);
        props.setFormDirtyFx(1);
    }, [setPoints, props]);    


    const updateDraw = useCallback((evt) => {
        setPoints(evt);
        props.setFormDirtyFx(1);
    }, [setPoints, props]);    


    function sortByAttr(array, attr){
        return array.sort(function(a, b) {
            var x = a[attr]; var y = b[attr];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    };


    const drawPolygon = useCallback((points) => {
        const sortedArr = sortByAttr(points, "pointsequence");

        let ptsArr = [];
        for (let c = 0; c < sortedArr.length; c++) {

            ptsArr.push( [ parseFloat(sortedArr[c].lng), parseFloat(sortedArr[c].lat) ] );
        }

        var geoJSON =
            {
            "type": "FeatureCollection",
            "features":
                [
                    {
                        "type": "Feature",
                        "properties": { "class_id": 1 },
                        "geometry":
                        {
                            "type": "Polygon",
                            "coordinates":
                            [
                                ptsArr
                            ]
                        }
                    }
                ]
            };
          draw.current.set(geoJSON);

          setPoints(null);
    }, [setPoints]);
    ///////////////////////////////////////////////////////////


    useEffect(() => {
        if(map.current) return;

        map.current = new mapboxgl.Map({
            container: "mapContainer",
            accessToken: "pk.eyJ1IjoiamltcHRhayIsImEiOiJja3kzMXZyOHIwNnRvMnBwOGxrbWJ4ZDhiIn0.bWHzm3g7ZOvo1wcWf2w8fg",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [-85.0716746, 33.5666694],
            zoom: 9
        });

        if(props.mode === 1){
            var maxLng = 0, maxLat = 0, minLng = 0, minLat = 0;
            let points = props.data;
            points.forEach(function(item, index){
                if(index === 0){
                    maxLng = item.lng;
                    minLng = item.lng;
                    maxLat = item.lat;
                    minLat = item.lat;
                }
                else{
                    maxLng = item.lng > maxLng ? item.lng : maxLng;
                    minLng = item.lng < minLng ? item.lng : minLng;
                    maxLat = item.lat  > maxLat ? item.lat  : maxLat;
                    minLat = item.lat  < minLat ? item.lat  : minLat;
                }

            });

            var sw = new mapboxgl.LngLat(minLng, minLat);
            var ne = new mapboxgl.LngLat(maxLng, maxLat);
            var llb = new mapboxgl.LngLatBounds(sw, ne);

            map.current.fitBounds(llb,
                {
                    padding: { top: 100, bottom: 100, left: 100, right: 100 } ,
                    animate: false
                }
            );
        }           

    }, [props.mode, props.data]);


    useEffect(() => {
        if(!map.current) return;

        if(draw.current) return;

        draw.current = new mapboxGlDraw({
            displayControlsDefault: false,

            // Select which mapbox-gl-draw control buttons to add to the map.
            controls: {
                polygon: true,
                trash: true
            },

            // Set mapbox-gl-draw to draw by default.
            // The user does not have to click the polygon control button first.
            defaultMode: props.mode !== 1 ? 'draw_polygon' : 'simple_select'
        });

        map.current.addControl(draw.current);

        map.current.on('draw.create', createDraw);
        map.current.on('draw.delete', deleteDraw);
        map.current.on('draw.update', updateDraw);        

        map.current.on('draw.modechange', (e) => {

            const data = draw.current.getAll();

            if (draw.current.getMode() === 'draw_polygon') {

                var pids = []

              // ID of the added template empty feature
              const lid = data.features[data.features.length - 1].id

              data.features.forEach((f) => {

                if (f.geometry.type === 'Polygon' && f.id !== lid) {
                  pids.push(f.id)
                }
              })

              draw.current.delete(pids)
            }
        });

        if (props.mode === 1) {
            drawPolygon(props.data);
        }
    }, [props.data, props.mode, props.formDirty, deleteDraw, drawPolygon, updateDraw, createDraw]);


    return(
        //<Box id="mapContainer" style={{ width: "550px", minWdith: "400px", maxWidth: "700px", minHeight: "400px", maxHeight: "600px" }}></Box>
        <Box id="mapContainer" sx={{ width:{xs:"350px", sm:"450px", md:"550px", lg:"700px"}, height:{xs:"300px", sm:"350px", md:"400px", lg:"550px"}, border:"1px,solid,black"}}></Box>
    );
}
