import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';
import EditStatus from '../EditStatus';
import TrackersEditActive from './TrackersEditActive';

import { useSessionContext } from '../../contexts/SessionContext';

export default function TrackersEdit(props) {
    //console.debug("RTS Break TrackersEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    //const display = props.display;
    const mode = props.mode;

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [idGuid, setIdGuid] = useState('')
    const [esn, setEsn] = useState('');
    const [idSeq, setIdSeq] = useState('');
    const [purchaseDate, setPurchaseDate] = useState(new Date());
    const [status, setStatus] = useState(1);
    const [isActive, setIsActive] = useState(0);
    const [activateDate, setActivateDate] = useState(null);
    const [batteryLevel, setBatteryLevel] = useState('Unknown');
    const [lastUpdate, setLastUpdate] = useState('Unknown');


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/Trackers/GetTrackerById/" + props.idGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);

                setIdGuid(response.data.idGuid)
                setEsn(response.data.esn!=null?response.data.esn:'');
                setIdSeq(response.data.idSeq!==null?response.data.idSeq:'');
                setPurchaseDate(response.data.purchaseDate!==null?(new Date(response.data.purchaseDate)):null);
                setStatus(response.data.status);
                setIsActive(response.data.isActive);
                setActivateDate(response.data.activateDate!==null?(new Date(response.data.activateDate)):null);
                setBatteryLevel(response.data.batteryLevel===null?'Unknown':response.data.batteryLevel);
                setLastUpdate(response.data.lastUpdate===null?'Unknown':(new Date(response.data.lastUpdate)).toLocaleDateString("en-US"));
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token, props]);
    

    useEffect(() => {
        if(mode === 1){
            fetchData();
        }
        else{
            setIsLoading(false);
        }

    }, [mode, fetchData]);  



    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            IdGuid: idGuid===''?null:idGuid,
            ESN: esn===''?null:esn,
            IdSeq: idSeq===''?null:Number(idSeq),
            PurchaseDate: purchaseDate===''?null:purchaseDate,
            Status: status===''?null:status,
            BatteryLevel: batteryLevel==='Unknown'?null:batteryLevel,
            IsActive: isActive===''?null:isActive,
            ActivateDate: activateDate===''?null:activateDate,
            LastUpdate: lastUpdate==='Unknown'?null:lastUpdate,
            ModifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Trackers/UpdateTracker/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateTracker Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Trackers/CreateTracker";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateTracker Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }    


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="trackerForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbEsn"
                    margin="normal"
                    fullWidth
                    label="ESN"
                    size="small"
                    required
                    value={esn}
                    onChange={(e) => {props.setFormDirtyFx(1); setEsn(e.target.value);}}
                />
                <TextField
                    id="tbIdSeq"
                    margin="normal"
                    fullWidth
                    label="Sequence Id"
                    size="small"
                    value={idSeq}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdSeq(e.target.value);}}
                />
                <DatePicker
                    id="tbPurchaseDate"
                    label="Purchase Date"
                    inputFormat="MM/dd/yyyy"
                    value={purchaseDate}
                    onChange={(val) => {props.setFormDirtyFx(1); setPurchaseDate(val);}}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                    sx={{mt:1}}
                />
                <TrackersEditActive isActive={isActive} setIsActiveFx={setIsActive} setFormDirtyFx={props.setFormDirtyFx} />
                <DatePicker
                    id="tbActivateDate"
                    label="Activation Date"
                    inputFormat="MM/dd/yyyy"
                    value={activateDate}
                    onChange={(val) => {props.setFormDirtyFx(1); setActivateDate(val);}}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                    sx={{mt:1}}
                /> 
                <EditStatus status={status} setStatusFx={setStatus} setFormDirtyFx={props.setFormDirtyFx} /> 
                <TextField
                    id="tbBatteryLevel"
                    margin="normal"
                    fullWidth
                    label="Battery Level"
                    size="small"
                    value={batteryLevel}
                    onChange={(e) => {props.setFormDirtyFx(1); setBatteryLevel(e.target.value);}}
                    disabled
                />
                <TextField
                    id="tbLastUpdated"
                    margin="normal"
                    fullWidth
                    label="Last Update"
                    size="small"
                    value={lastUpdate}
                    onChange={(e) => {props.setFormDirtyFx(1); setLastUpdate(e.target.value);}}
                    disabled
                />
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit" sx={{mr:2}}>Save</Button>
                </Box>
                </Box>
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );
}

