import axios from 'axios';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import LoadListListItem from "../LoadList/LoadListListItem";
import LoadListDetailsAddPinsEditCustomerUserList from './LoadListDetailsAddPinsEditUserList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadPinsEdit(props) {
    console.debug('RTS Break LoadPinsEdit');

    const history = useNavigate();

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");


    function addLoadPins(pUsers) {

        let newLoadPins = [];

        pUsers.forEach((usr) =>{
            let newPin = {
                AppUser: usr,
                Customer: sessionCustomer.customer,
                Load: props.data,
                Action: 1
            }

            let existCheck = props.data.loadPins.filter((row) => {return row.appUser.userName === usr.userName});
            if(existCheck.length === 0){
                newLoadPins.push(newPin);
            }
        })

        let apiObj = newLoadPins;

        let apiURL = apiRoot + "/LoadList/AddLoadPins";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            window.scrollTo(0,0);
            history(-1);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateLoad Error", error);
            setShowErrorModal(true);
        });


    } 


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 
            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box maxWidth="md">
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography variant='h6' sx={{mb:2, mt:1}}>Select the users to Pin this reel to.</Typography>
                    </Box>
                    <LoadListDetailsAddPinsEditCustomerUserList data={props.data} addLoadPinsFx={addLoadPins} modeFx={props.modeFx}  dataRefreshFx={props.dataRefreshFx}  />                               
                </Box>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
