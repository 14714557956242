import axios from 'axios';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import SearchIcon from '@mui/icons-material/Search';

import CrewMembersEditCompanyLinkLookup from './CrewMembersEditCompanyLinkLookup';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersEditCreate(props) {
    console.debug("RTS Break CrewMembersEditCreate");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showPasswordValidationModal, setShowPasswordValidationModal] = useState(false);
    const [showCompanyLinkModal, setShowCompanyLinkModal] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyLink, setCompanyLink] = useState()
    const [markAccess, setMarkAccess] = useState(0)
   

    function handleSubmit(e) {
        e.preventDefault();

        let errorCount = 0;

        if(password.length < 8){
            errorCount++;
        }

        if(!/[A-Z]/.test(password)){
            //errorText = errorText + "<li>Password must contain at least 1 capital (uppercase) letter.</li>";
            errorCount++;
        }

        // if(!/[a-z]/.test(password)){
        //     errorText = errorText + "<li>Password must contain at least 1 lowercase letter.</li>";
        //     errorCount++;
        // }

        if(password !== passwordConfirm){
            //errorText = errorText + "<li>Password and Password Confirm must match.</li>";
            errorCount++;
        }

        if(errorCount > 0){
            setShowPasswordValidationModal(true);
            return;
        }            

        if(fName === null){
            setErrorTitle("Validation Error");
            setErrorBody("First Name is required");
            setShowErrorModal(true);
            return;
        }

        if(lName === null){
            setErrorTitle("Validation Error");
            setErrorBody("Last Name is required");
            setShowErrorModal(true);
            return;
        }

        executeSubmit();
    } 


    function executeSubmit(e) {
        let customers = [];
		customers.push({customer:sessionCustomer.customer, accessLevel:1, companyLink:companyLink, markAccess:markAccess});

        let apiObj = {
            userName: props.data.userName,
            passwordHash: password,
            email: props.data.email,
            firstName: fName,
            lastName: lName,
            phoneNumber: phoneNumber,
            status: 1,
            adminFlag: 0,
            southwireFlag: 0,
            manufacturerFlag: 0,
            distributorFlag: 0,
            licensedBy: null,
            licenseId: null,
            appUserCustomers: customers,
            createUser: user.userName,
            modifyUser: user.userName,
            clientUrl: window.location.origin,
            modReelsFlag: 1,
            modWorkSetsFlag: 0,
            modPalletsFlag: 0           
        }

        let apiURL = apiRoot + "/AppUsers/CreateAppUser";
        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setErrorTitle("InsertError");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateUser Error", error);
            setShowErrorModal(true);
        });
    } 


    function setCompanyLinkVal(pValue){
        setCompanyLink(pValue);
        setShowCompanyLinkModal(false);
        props.setFormDirtyFx(1);
    };     


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showCompanyLinkModal} onClose={()=>{setShowCompanyLinkModal(false);}}>
            <DialogTitle>Crew Member Company</DialogTitle>
            <DialogContent>
                <Box>
                    <CrewMembersEditCompanyLinkLookup data={companyLink} setValueFx={setCompanyLinkVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowCompanyLinkModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>         
        <Dialog open={showPasswordValidationModal} onClose={()=>{setShowPasswordValidationModal(false);}}>
            <DialogTitle>Password Not Validated</DialogTitle>
            <DialogContent>
                <div>
                    <Typography>The password must meet the following criteria:</Typography>
                    <ul>
                        <li>At least 8 characters long.</li>
                        <li>At least 1 capital (uppercase) letter.</li>
                        <li>At least 1 lower case letter.</li>
                        <li>At least 1 number.</li>
                        <li>At least 1 special character.</li>
                        <li>Password and Password Confirm must match.</li>
                    </ul>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowPasswordValidationModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <Box>
            <form id="requestForm" onSubmit={handleSubmit}>
                <TextField
                    id="tbPassword"
                    margin="normal"
                    fullWidth
                    label="Password"
                    size="small"
                    required
                    value={password}
                    onChange={(e) => {props.setFormDirtyFx(1); setPassword(e.target.value);}}
                />
                <TextField
                    id="tbPasswordConfirm"
                    margin="normal"
                    fullWidth
                    label="Confirm Password"
                    size="small"
                    required
                    value={passwordConfirm}
                    onChange={(e) => {props.setFormDirtyFx(1); setPasswordConfirm(e.target.value);}}
                />
                <Box display="flex" sx={{textAlign:'center', mb:2}}>
                    <Box sx={{textAlign:'left', display:'inline'}}>
                        <Typography variant="caption" sx={{display:'block', fontWeight:'bold'}}>Password Requirements:</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- Minimum 8 characters</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- At least 1 uppercase character</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- At least 1 lower case letter.</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- At least 1 number.</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- At least 1 special character.</Typography>
                    </Box>
                </Box>                 
                <TextField
                    id="tbFName"
                    margin="normal"
                    fullWidth
                    label="First Name"
                    size="small"
                    required
                    value={fName}
                    onChange={(e) => {props.setFormDirtyFx(1); setFName(e.target.value);}}
                />
                <TextField
                    id="tbLName"
                    margin="normal"
                    fullWidth
                    label="Last Name"
                    size="small"
                    required
                    value={lName}
                    onChange={(e) => {props.setFormDirtyFx(1); setLName(e.target.value);}}
                />
                <TextField
                    id="tbPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    size="small"
                    value={phoneNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setPhoneNumber(e.target.value);}}
                /> 
                <Box id="CompanyLink" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbCompanyLink"
                        margin="normal"
                        fullWidth
                        label="Company"
                        size="small"
                        value={companyLink==null?'':companyLink.name}
                        onClick={setShowCompanyLinkModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowCompanyLinkModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>                
                <Box id="MarkAccess" sx={{display:"flex", mt:1}}>
                    <FormControl component="fieldset" sx={{flexGrow:1}}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={markAccess===1?true:false} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setMarkAccess(1):setMarkAccess(0);}} 
                                            name="cbxMarkAccess" 
                                    />
                                }
                                label="Allow Reel Marks"
                            />
                        </FormGroup> 
                    </FormControl>
                </Box>                 
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                </Box>
            </form>
        </Box>
        </>
    );
}
