import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function LoadListDetailsPullList(props){
    //console.debug('RTS Break LoadListDetailsPullList');

    function handleClick(e, cellValues){
        props.showPullDetailFx(cellValues.row);
    }

    const columns = [
        {field: "pullDate",
            headerName:"Date",
            description:"Pull Date",
            flex:2,
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null;
                }

                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },         
        },
        {field: "pullType",
            headerName:"Pull Type",
            description:"Pull Entry Trype",
            flex:5
        },
        {field:"pullLength",
            headerName:"Length",
            description:"Lenght of Pull",
            flex:2,
            valueGetter: (value, row) => {
                return (
                    row.pullLength.toLocaleString() + " ft"
                )
            },
            align:"right"
        },
        {field: 'detailsLink', 
            description:"Show Pull Details", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:1}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <InfoOutlinedIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },
    ];

    return (
        <Box sx={{height:320}}>
            <DataGridPro 
                rows={props.data.pulls.filter(r => r.confirmedFlag != -1)} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
