import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';


export default function LoadListFilterReelTypeList(props) {
    //console.debug("RTS Break LoadListFilterReelTypeList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [reelTypes, setReelTypes] = useState(props.reelTypes===null?[]:props.reelTypes);
    const [repoReelTypes, setRepoReelTypes] = useState(props.data===null?[]:props.data);


    function valChange(e, val){
        props.setReelTypesFx(val);
        setReelTypes(val);
    }    


    return(
        <>
            <Autocomplete
                options={repoReelTypes}
                renderInput={(params) => <TextField {...params} label="Reel Types" size="small" />}
                value={reelTypes}
                onChange={valChange}
                getOptionLabel={(reelType) => reelType.name}
                renderOption={(props, repoReelTypes) => (
                    <Box component="li" {...props} key={repoReelTypes.idGuid}>
                        {repoReelTypes.name}
                    </Box>
                )}
                multiple
            />
        </>
    );
}

