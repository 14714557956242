import React, { useCallback } from "react";
import { createContext, useState, useEffect } from "react";

export const SessionContext = createContext(null);

export const SessionContextProvider = ({ children }) => {
  //console.debug("RTS Break SessionContextProvider");

  const [user, setUser] = useState(null);
  const [userAuthorized, setUserAuthorized] = useState(false);
  const [userAuthToken, setUserAuthToken] = useState(null);
  const [sessionCustomer, setSessionCustomer] = useState(null);
  const [prevSessionCustomer, setPrevSessionCustomer] = useState(null);
  const [appMode, setAppMode] = useState(null);
  const [loadPins, setLoadPins] = useState([]);
  const [curFilter, setCurFilter] = useState(null);

  const [projectList, setCustomerProjectList] = useState(null);

  const apiRoot = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const storedSession = sessionStorage.getItem("SWTrackSession");
    if (storedSession) {
      const session = JSON.parse(storedSession);
      setUser(session.user);
      setUserAuthorized(session.userAuthorized);
      setUserAuthToken(session.userAuthToken);
      setSessionCustomer(session.sessionCustomer);
      setPrevSessionCustomer(session.prevSessionCustomer);
      setAppMode(session.appMode);
      setLoadPins(session.loadPins);
      setCurFilter(session.curFilter);
      setCustomerProjectList(session.projectList);      
    }
  }, []);


  const userLogOut = () => {
    setUserAuthToken("");
    setUser(null);
    setUserAuthorized(false);
    setSessionCustomer(null);
    setPrevSessionCustomer(null);
    setAppMode(null);
    setLoadPins([]);
    setCurFilter(null);
    setCustomerProjectList(null);
  }


  const saveSession = useCallback(() => {
    const sessionObject = { user: user, 
                            userAuthorized: userAuthorized, 
                            userAuthToken: userAuthToken, 
                            sessionCustomer: sessionCustomer, 
                            prevSessionCustomer: prevSessionCustomer,
                            appMode: appMode,
                            loadPins: loadPins,
                            curFilter: curFilter,
                            projectList: projectList
                           };
    sessionStorage.setItem("SWTrackSession", JSON.stringify(sessionObject));  
  }, [
      appMode, 
      curFilter, 
      projectList, 
      loadPins, 
      prevSessionCustomer, 
      sessionCustomer, 
      user, 
      userAuthToken, 
      userAuthorized
    ]
  );


  useEffect(() => {
    saveSession();
  }, [
      user, 
      userAuthorized, 
      userAuthToken, 
      sessionCustomer, 
      prevSessionCustomer, 
      appMode, 
      loadPins, 
      curFilter,
      projectList,
      saveSession
    ]
  )


  const value = {
    user,
    setUser,
    userAuthorized,
    setUserAuthorized,
    userAuthToken,
    setUserAuthToken,
    sessionCustomer,
    setSessionCustomer,
    prevSessionCustomer,
    setPrevSessionCustomer,
    appMode,
    setAppMode,
    loadPins,
    setLoadPins,
    curFilter,
    setCurFilter,
    projectList,
    setCustomerProjectList,
    apiRoot,
    userLogOut,
    saveSession
  };


  return(
      <SessionContext.Provider value={value}> {children} </SessionContext.Provider>
  );

};

export const useSessionContext = () => React.useContext(SessionContext);
