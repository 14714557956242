import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import mapboxgl from 'mapbox-gl';

export default function LoadListAddPullSetLocationMap( props ) {
    //console.debug('LoadListAddPullSetLocationMap');

    const [startLat, setStartLat] = useState(props.startLat);
    const [startLng, setStartLng] = useState(props.startLng);
    const [endLat, setEndLat] = useState(props.endLat);
    const [endLng, setEndLng] = useState(props.endLng);
    const [zoom] = useState(15);

    useEffect(() => {

        let centerLat;
        let centerLng;
        centerLat = startLat;
        centerLng = startLng;

        const map = new mapboxgl.Map({
            container: "mapContainer",
            accessToken: "pk.eyJ1IjoiamltcHRhayIsImEiOiJja3kzMXZyOHIwNnRvMnBwOGxrbWJ4ZDhiIn0.bWHzm3g7ZOvo1wcWf2w8fg",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [centerLng, centerLat],
            zoom: zoom
        });

        const startCoord = {lat:startLat, lng:startLng};
        
        // const prevCoord = {lat:props.prevLat, lng:props.prevLng};

        const elStartMarker = document.createElement('div');
        elStartMarker.className = "rtsMarkerSetPrevious";
        const elStartText = document.createElement('span');
        elStartText.className = "rtsLightForeground";
        elStartText.innerHTML = "S";
        elStartMarker.appendChild(elStartText)

        new mapboxgl.Marker(elStartMarker)
             .setLngLat(startCoord)
             .addTo(map);


        if(endLat !== null && endLat !== "" && endLng !== null && endLng !== ""){
            const endCoord = {lat:endLat, lng:endLng};

            const elEndMarker = document.createElement('div');
            elEndMarker.className = "rtsMarkerSetNew";
            const elEndText = document.createElement('span');
            elEndText.className = "rtsLightForeground";
            elEndText.innerHTML = "E";
            elEndMarker.appendChild(elEndText)

            new mapboxgl.Marker(elEndMarker)
                .setLngLat(endCoord)
                .addTo(map);
        }

        map.on('click', moveMarker)

    }, [startLat, startLng, endLat, endLng]);  
    

    function moveMarker(e){
        if(props.mode==='start'){
            setStartLat(e.lngLat.lat);
            setStartLng(e.lngLat.lng);
        }
        else{
            setEndLat(e.lngLat.lat);
            setEndLng(e.lngLat.lng);
        }

        //props.setCoordsFx({lat: e.lngLat.lat, lng: e.lngLat.lng});
    }

    return(
        <Box>
            <Box 
                id="mapContainer"
                className="map" 
                fluid="true"
                style={{minHeight:"400px"}}  
            />
            <Button variant="contained" onClick={e => {props.setLocation(startLat, startLng, endLat, endLng)}}>Set</Button>
            <Button variant="contained" onClick={e => {props.setShowLocationModalFx(false);}}>Cancel</Button>            
        </Box>
    );
}
