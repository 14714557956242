import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ArrowBack from '@mui/icons-material/ArrowBack'; 

export default function EditBack(props) {
    //console.debug("RTS Break EditBack");

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody] = useState("");
    const [errorTitle] = useState("Validation Error");
    const [showConfirmModal, setShowConfirmModal] = useState(false);


    function handleCancel(e){
        e.preventDefault();

        if(props.formDirty===1){
            setShowConfirmModal(true);
        }
        else{
            window.scrollTo(0,0);
            history(-1);
        }
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} open={showConfirmModal}>
            <DialogTitle>Leave Page?</DialogTitle>
            <DialogContent>
                <Typography>Changes will be lost.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {history(-1);}}>Yes</Button>
                <Button variant="contained" onClick={e => {setShowConfirmModal(false);}}>No</Button>
            </DialogActions>
        </Dialog>
        <Button startIcon={<ArrowBack />} 
                variant="contained" 
                onClick={handleCancel} 
                sx={{mr:2}}
        >Back</Button>
        </>
    );

}
