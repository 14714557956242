import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export default function AnalyticsLoadRemainsPercentBucketsListItem(props){
    return (
        <TableRow>
            <TableCell>{props.data.desc}</TableCell>
            <TableCell align="right" sx={{width:"75px"}}>{props.data.reels}</TableCell>
        </TableRow>
    )
}

