import axios from 'axios';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';
import EditIcon from '@mui/icons-material/Edit';

import { useSessionContext } from '../../contexts/SessionContext';
import { Typography } from '@mui/material';

export default function ReelInspectionsReelsList(props){
    //console.debug("RTS Break ReelInspectionsReelsList");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const [hasError] = useState(false);
    const [isLoading] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [reel, setReel] = useState();
    const [load, setLoad] = useState();
    const [showReelValidationFailed, setShowReelValidationFailed] = useState(false);


    function handleClick(e, cellValues){
        setReel(cellValues.row);

        let apiURL = apiRoot + "/Loads/GetActiveLoadByReelId/" + cellValues.row.idGuid
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setLoad(response.data);
                setShowReelValidationFailed(true);
            }
            else{
                let sumReel ={
                    idGuid:cellValues.row.idGuid, 
                    reelTypeName:cellValues.row.reelTypeName, 
                    serialNumber:cellValues.row.serialNumber
                }  
                navigateToInspection(sumReel);
            }
        })
        .catch(error => {
            setErrorTitle("Validation Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS Tracker Validation Error", error);
            setShowErrorModal(true);
        });
    }
   
    
    function returnReel()
    {
        let apiURL = apiRoot + "/Loads/ReturnReel/" + load.idGuid
        axios
        .put(
            apiURL,
            null,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                let sumReel ={
                    idGuid:reel.idGuid, 
                    reelTypeName:reel.reelTypeName, 
                    serialNumber:reel.serialNumber
                }  
                navigateToInspection(sumReel);
            }
        })
        .catch(error => {
            setErrorTitle("Reel Return Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS Reel Return Error", error);
            setShowErrorModal(true);
        });

    }


    function navigateToInspection(sumReel){
        history("/ReelInsepctionsPageEdit", {state:{reel:sumReel, idGuid:null, mode:0}});
    }    


    const columns = [
        {
            field: '',
            description:"Add Inspection", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'serialNumber', 
            headerName: "Serial Number", 
            description:"Reel Serial Number", 
            flex:3,
        },
        {
            field: 'reelTypeName', 
            headerName: "Reel Type", 
            description:"Reel Type Name", 
            flex:2,
        },
        {
            field: 'trackerESN', 
            headerName: "Tracker", 
            description:"Tracker ESN", 
            flex:3,
        },
        {
            field: 'trackerIdSeq', 
            headerName: "Sequence", 
            description:"Tracker Sequence Id", 
            flex:1,
        },           
        {
            field: 'trackerBatteryLevel', 
            headerName: "Battery Level", 
            description:"Tracker Battery Level", 
            flex:2,
            valueFormatter: (value) => {
                if (value == null) {
                  return '';
                }
                return `${value.toLocaleString()} %`;
            },
            align:'right',
            type:'number',
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"Reel Status", 
            flex:2,
            renderCell: (cellValues) => {
                if(cellValues.value === 1){
                    return ('Active');
                }
                else{
                    return('Deleted');
                }
            },            
        }        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {trackerIdSeq:false, trackerESN:false, trackerBatteryLevel:false, status:false}
        }
        else{
            return {trackerIdSeq:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }    


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>        
        <Dialog open={showReelValidationFailed}>
            <DialogTitle>Reel Validation</DialogTitle>
            <DialogContent>
                <Typography>
                    Reel {reel!=null?reel.serialNumber:''} is currently showing as being at a customer.
                      Please verify the reel serial number and if accurate click on Continue.  
                      Otherwise, click on Cancel.
                </Typography>
                <Box sx={{mt:2, textAlign:'center'}}>    
                    <Typography variant='subtext' sx={{layout:'box', textJustify:'center', mt:2, with:'100%'}}>
                        Note: Continue will return the reel from the customer within Reel Tracking.
                    </Typography>
                </Box>    
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowReelValidationFailed(false);returnReel();}}>Continue</Button>
                <Button variant="contained" onClick={e => {setShowReelValidationFailed(false);}}>Cancel</Button>
            </DialogActions>
        </Dialog>         
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
        </>
    );
}
