import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpActions(props) {
    //console.debug('RTS Break HelpActions');

    return (
        <Box>
            <Typography>
                The Reel section of the item shows information regarding the physical reel that
                the product was shipped on.
            </Typography>
            <Typography sx={{mt:1}}>
                The fields displayed within the Reel section of the item include:
            </Typography>            
            <table>
                <tbody>
                <tr>
                    <td>
                        Serial Number
                    </td>
                    <td>
                        The Serial Number associated with the Reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Type
                    </td>
                    <td>
                        The type of reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Tracker
                    </td>
                    <td>
                        The electronic serial number (esn) of the tracker associated with the reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Deferral
                    </td>
                    <td>
                        The number of months after delivery of the product that the reel is due back to Southwire.  
                        In addition the number of days remaining before the reel is due back is displayed and color coded.
                    </td>
                </tr>
                <tr>
                    <td>
                        Owner
                    </td>
                    <td>
                        The owner of the reel.
                    </td>
                </tr>
                </tbody>
            </table>
        </Box>
    );

}
