import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export default function EditCancel(props) {
    //console.debug("RTS Break EditCancel");
    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} open={showConfirmModal}>
            <DialogTitle>Leave Page?</DialogTitle>
            <DialogContent>
                <Typography>Changes will be lost.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {history(-1);}}>Yes</Button>
                <Button variant="contained" onClick={e => {setShowConfirmModal(false);}}>No</Button>
            </DialogActions>
        </Dialog>
        <Button variant="contained" onClick={handleCancel}>Cancel</Button>
        </>
    );

    function handleCancel(e){
        e.preventDefault();

        if(props.formDirty===1){
            setShowConfirmModal(true);
        }
        else{
            history(-1);
        }
    }

}
