import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGridPro, GridToolbar, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsEditMarkedLoadsLookup(props){
    //console.debug("RTS Break PickupRequestsEditMarkedLoadsLookup");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);

    const [repoLoads, setRepoLoads] = useState([]);


    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/PickupRequest/GetMarkedLoads";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoLoads(response.data);
            }
            setIsLoading(false);
       })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });  

    },[apiRoot, userAuthToken.token]);
    
    
    useEffect(() => {
        fetchData(sessionCustomer.customer);
    }, [fetchData, sessionCustomer.customer]);  

    
    function handleClick(e, cellValues){
        let selectedRowData = repoLoads.filter((row) => selectionModel.includes(row.idGuid.toString()));
        props.addLoadsFx(selectedRowData);
    }


    const columns = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            hideable: false,
            headerName: 'Selection'
        },         
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:1,
            valueGetter: (value, row) => {
                return row.reel.serialNumber;
            },
            hideable:false,
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Southwire Product Id", 
            flex:3,
            valueGetter: (value, row) => {
                return row.product.name;
            },
 
        },
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Reel Ship Date", 
            flex:2,           
            valueFormatter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    format(parseISO(value), "MM-dd-yyyy")
                );
            },
            align:'center'
        },
        {
            field: 'project', 
            headerName: "Project", 
            description:"Project", 
            flex:2,
            valueGetter: (value, row) => {
                return row.project !== null?row.project.name:null;
            },             
        }, 
        {
            field: 'location', 
            headerName: "Location", 
            description:"Named Location", 
            flex:2,
            valueGetter: (value, row) => {
                return row.currentLocation !== null?row.currentLocation.namedLocation:null;
            },             
        },               

    ];


    function getVisibilityModel(){
        if(smallForm){
            return {shipDate:false, project:false, location:false}
        }
        else{
            return {}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }


    if(hasError){
        return(
            <Error />
        );
    }
    
    
    if(isLoading){
        return(
            <Loading />
        );
    }    


    return (
        <Box>
            <Box sx={{height:"400px"}}>
                <DataGridPro 
                    checkboxSelection={true} 
                    pagination
                    disableColumnFilter
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar:{
                            showQuickFilter:true,
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },                        
                        }
                    }}                    
                    rows={repoLoads} 
                    columns={columns} 
                    getRowId={row => row.idGuid}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    initialState={{
                        columns:{
                            columnVisibilityModel: getVisibilityModel()
                        },
                        pagination: { paginationModel: { pageSize: 50 } },
                        density: getDensity() 
    
                    }}                          
                />
            </Box>

            <Box sx={{mt:2, textAlign:'right'}}>
                <Button variant="contained" onClick={handleClick} sx={{mr:2}}>Add Reels</Button>
                <Button variant="contained" onClick={e => {props.setShowLoadsModalFx(false);}}>Cancel</Button>
            </Box>
        </Box>
    );
}
