import Box from '@mui/material/Box';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';


export default function PalletPlantDetailsList(props){
    //console.debug("RTS Break PalletPlantDetailsList");

    const columns = [
        {
            field: 'name', 
            headerName: "Name", 
            description:"Pallet Name", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.pallet.name
            }
        },
        {
            field: 'esn', 
            headerName: "ESN", 
            description:"Pallet Electronic Serial Number", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.pallet.esn
            }
        },
        {
            field: 'sendDate', 
            headerName: "Sent Date", 
            description:"Date Pallet Was Sent", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        }, 
        {
            field: 'returnDate', 
            headerName: "Return Date", 
            description:"Date Pallet Was Returned", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },                
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
