import {useState} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import LoadListDetailManagePullsConfirm from './LoadListDetailsManagePullsConfirm';
import LoadListDetailManagePullsReject from './LoadListDetailsManagePullsReject';
import { red } from '@mui/material/colors';

export default function LoadListDetailManagePullsEditList(props){
    console.debug('RTS Break LoadListDetailManagePullsEditList');

    const [showPullConfirm, setShowPullConfirm] = useState(false);
    const [showPullReject, setShowPullReject] = useState(false);
    const [curPull, setCurPull] = useState(null);
    const [repoPulls, setRepoPulls] = useState(props.data.pulls);


    function handleConfirmClick(e, cellValues){
        setCurPull(cellValues.row);
        setShowPullConfirm(true);
    }


    function handleRejectClick(e, cellValues){
        setCurPull(cellValues.row);
        setShowPullReject(true);
    }


    // function handleReject(dataRow){
    //     let curPulls = repoPulls.filter(function(el){ return el.idGuid !== dataRow.idGuid})
    //     setRepoPulls(curPulls);
    //     setShowPullReject(false);
    // }


    const columns = [
        {field: "pullDate",
            headerName:"Date",
            description:"Pull Date",
            flex:2,
            renderCell: (cellValues) => {
                if(cellValues.row.pullDate == null){
                    return '';
                }

                const formatObj = new Intl.DateTimeFormat("en-US");
                let dispValue = formatObj.format(new Date(cellValues.row.pullDate));                

                if(cellValues.row.confirmedFlag === -1){   
                    return (<Typography sx={{textDecoration:"line-through", fontStyle:'italic', color:'red', mt:2}}>{dispValue}</Typography>)
                }
                else{
                    return (<Typography sx={{mt:2}}>{dispValue}</Typography>)
                }                
            },
            align:"left",            
        },
        {field: "pullType",
            headerName:"Pull Type",
            description:"Pull Entry Type",
            flex:5,
            renderCell: (cellValues) => {
                if(cellValues.row.confirmedFlag === -1){   
                    return (<Typography sx={{textDecoration:"line-through", fontStyle:'italic', color:'red', mt:2}}>{cellValues.value}</Typography>)
                }
                else{
                    return (<Typography sx={{mt:2}}>{cellValues.value}</Typography>)
                }                
            },
            align:"left",
        },
        {field:"pullLength",
            headerName:"Length",
            description:"Lenght of Pull",
            flex:2,
            renderCell: (cellValues) => {
                if(cellValues.row.confirmedFlag === -1){   
                    return (<Typography sx={{textDecoration:"line-through", fontStyle:'italic', color:'red', mt:2}}>{cellValues.value.toLocaleString() + " ft"}</Typography>)
                }
                else{
                    return (<Typography sx={{mt:2}}>{cellValues.value.toLocaleString() + " ft"}</Typography>)
                }                
            },
            align:"right",
        },
        {field: 'confirmLink', 
            headerName:'Confirm/Reject',
            description:"Confirm/Reject Pull", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.row.confirmedFlag === 0){   
                    return (
                        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:1.5}}>
                            <Link onClick={(event) => {handleConfirmClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <Box sx={{display:'flex'}}>
                                    <CheckIcon sx={{color:'green', fontWeight:'bold'}} />
                                </Box>
                            </Link>
                            <Link onClick={(event) => {handleRejectClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <Box sx={{display:'flex'}}>
                                    <ClearIcon sx={{color:'red', fontWeight:'bold', ml:3}} />
                                </Box>
                            </Link>
                        </Box>
                    );
                }
                else{
                    return('');
                }
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },
    ];


    return (
        <>
        <Dialog open={showPullConfirm} onClose={()=>{setShowPullConfirm(false);}}>
            <DialogTitle>Confirm Pull</DialogTitle>
            <DialogContent>
                {
                curPull !== null
                ?
                <LoadListDetailManagePullsConfirm data={curPull} setShowPullConfirmFx={setShowPullConfirm} />
                :
                ''
                }
            </DialogContent>
        </Dialog>                    
        <Dialog open={showPullReject} onClose={()=>{setShowPullReject(false);}}>
            <DialogTitle>Reject Pull</DialogTitle>
            <DialogContent>
                {
                curPull !== null
                ?
                <LoadListDetailManagePullsReject data={curPull} setShowPullRejectFx={setShowPullReject} rejectRefreshFx={props.rejectRefreshFx}        
                />
                :
                ''
                }                
            </DialogContent>
        </Dialog>                    
        <Box sx={{height:'300px', minHeight:'300px'}}>
            <DataGridPro 
                rows={repoPulls} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
        </>
    );
}
