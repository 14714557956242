import {useState} from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { format, parseISO } from 'date-fns';
import Grid from '@mui/material/Grid';
import Image from 'mui-image';

import FRI1 from './FRI1.png';
import FRI2 from './FRI2.png';
import FRI3 from './FRI3.png';
import FRI41 from './FRI4-1.png';
import FRI42 from './FRI4-2.png';
import FRI43 from './FRI4-3.png';
import FRI5 from './FRI5.png';
import FRI61 from './FRI6-1.png';
import FRI62 from './FRI6-2.png';

import { Typography } from '@mui/material';

export default function LoadListInspectionView(props) {
    //console.debug("RTS Break LoadListInspectionView");

    const data = props.data.reelInspection;
 
    const [showZoomModal, setShowZoomModal] = useState(false);
    const [zoomTitle, setZoomTitle] = useState("Image Zoom");
    const [zoomMode, setZoomMode] = useState(null);
    
    function setZoom(mode){
        switch(mode){
            case 'FRI1':
                setZoomTitle('Cracks in the ribs of the flange');
                break;
            case 'FRI2':
                setZoomTitle('Abrasions in the flange');
                break;
            case 'FRI3':
                setZoomTitle('Burs on the lagging tire');
                break;
            case 'FRI41':
                setZoomTitle('Damages to crossarms and arbor');
                break;
            case 'FRI42':
                setZoomTitle('Damages to crossarms and arbor');
                break;
            case 'FRI43':
                setZoomTitle('Damages to crossarms and arbor');
                break;
            case 'FRI5':
                setZoomTitle('Straightness of Reel');
                break;
            case 'FRI61':
                setZoomTitle('Two D-rings in the starter pocket');
                break;
            case 'FRI62':
                setZoomTitle('Two gussets on crossarm');
                break;
            default:
                setZoomTitle('Unknown Value');
                break;
        }

        setZoomMode(mode);
        setShowZoomModal(true);        
    }




 

    return (
        <>
        <Dialog open={showZoomModal} onClose={()=>{setShowZoomModal(false);}}>
            <DialogTitle>{zoomTitle}</DialogTitle>
            <DialogContent>
                {
                    zoomMode==='FRI1'
                    ?
                        <Image src={FRI1} showLoading width={500} />
                    :
                    zoomMode==='FRI2'
                    ?               
                        <Image src={FRI2} showLoading width={500} />
                    :
                    zoomMode==='FRI3'
                    ?               
                        <Image src={FRI3} showLoading width={500} />
                    :
                    zoomMode==='FRI41'
                    ?               
                        <Image src={FRI41} showLoading width={500} />
                    :
                    zoomMode==='FRI42'
                    ?               
                        <Image src={FRI42} showLoading width={500} />
                    :
                    zoomMode==='FRI43'
                    ?               
                        <Image src={FRI43} showLoading width={500} />
                    :
                    zoomMode==='FRI5'
                    ?               
                        <Image src={FRI5} showLoading width={500} />
                    :
                    zoomMode==='FRI61'
                    ?               
                        <Image src={FRI61} showLoading width={500} />
                    :
                    zoomMode==='FRI62'
                    ?               
                        <Image src={FRI62} showLoading width={500} />
                    :
                        ''
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowZoomModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box>
            <Stack>
                <Box sx={{display:'flex'}}>
                    <Typography variant="h6" sx={{fontWeight:'bold'}}>Date:</Typography>
                    <Typography variant="h6" sx={{ml:'2px'}}>{format(parseISO(data.createDate), 'MM-dd-yyyy')}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography variant="h6" sx={{fontWeight:'bold'}}>Inspector:</Typography>
                    <Typography variant="h6" sx={{ml:'2px'}}>{data.appUser.firstName + ' ' + data.appUser.lastName}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography variant="h6" sx={{fontWeight:'bold'}}>Reel Size:</Typography>
                    <Typography variant="h6" sx={{ml:'2px'}}>{data.reel.reelType.name}</Typography>
                </Box>
                <Box sx={{display:'flex'}}>
                    <Typography variant="h6" sx={{fontWeight:'bold'}}>Reel Serial Number:</Typography>
                    <Typography variant="h6" sx={{ml:'2px'}}>{data.reel.serialNumber}</Typography>
                </Box>
            </Stack>

            <Stack spacing={1} sx={{pt:2}}>
                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={12} sm={5} sx={{textAlign:'left'}}>
                        <Image src={FRI1}  showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI1');}} />
                    </Grid>
                    <Grid item xs={8} sm={4} sx={{textAlign:'left', px:{xs:0, sm:1}}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Cracks in the ribs of the flange</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Grid container>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                {
                                    data.flangeRibCracks===1
                                    ?
                                        <Box sx={{backgroundColor: 'green', color:'white', borderRadius:'5px'}}>
                                            <Typography>Pass</Typography>
                                        </Box>
                                    :
                                        data.flangeRibCracks===-1
                                        ?
                                            <Box sx={{backgroundColor: 'red', color:'white', borderRadius:'5px'}}>
                                                <Typography>Fail</Typography>
                                            </Box>
                                        :
                                            ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left', pt:1}}>
                        <Typography>Notes:</Typography>
                        <Typography>{data.flangeRibCracksNotes}</Typography>
                    </Grid>
                </Grid>

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={12} sm={5} sx={{textAlign:'left'}}>
                        <Image src={FRI2}  showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI2');}} />
                    </Grid>
                    <Grid item xs={8} sm={4} sx={{textAlign:'left', px:{xs:0, sm:1}}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Abrasions in the flange</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Grid container>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                {
                                    data.flangeAbrasions===1
                                    ?
                                        <Box sx={{backgroundColor: 'green', color:'white', borderRadius:'5px'}}>
                                            <Typography>Pass</Typography>
                                        </Box>
                                    :
                                        data.flangeAbrasions===-1
                                        ?
                                            <Box sx={{backgroundColor: 'red', color:'white', borderRadius:'5px'}}>
                                                <Typography>Fail</Typography>
                                            </Box>
                                        :
                                            ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left', pt:4}}>
                        <Typography>Notes:</Typography>
                        <Typography>{data.flangeAbrasionsNotes}</Typography>
                    </Grid>
                </Grid>

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={12} sm={5} sx={{textAlign:'left'}}>
                        <Image src={FRI3}  showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI3');}} />
                    </Grid>
                    <Grid item xs={8} sm={4} sx={{textAlign:'left', px:{xs:0, sm:1}}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Burs on the lagging tire</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Grid container>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                {
                                    data.laggingTireBurs===1
                                    ?
                                        <Box sx={{backgroundColor: 'green', color:'white', borderRadius:'5px'}}>
                                            <Typography>Pass</Typography>
                                        </Box>
                                    :
                                        data.laggingTireBurs===-1
                                        ?
                                            <Box sx={{backgroundColor: 'red', color:'white', borderRadius:'5px'}}>
                                                <Typography>Fail</Typography>
                                            </Box>
                                        :
                                            ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left', pt:4}}>
                        <Typography>Notes:</Typography>
                        <Typography>{data.laggingTireBursNotes}</Typography>
                    </Grid>
                </Grid>

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={12} sm={5} sx={{textAlign:'left'}}>
                        <Box sx={{display:'flex'}}>
                            <Image src={FRI41} showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI41');}} />
                            <Image src={FRI42} showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI42');}} />
                            <Image src={FRI43} showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI43');}} />
                        </Box>
                    </Grid>
                    <Grid item xs={8} sm={4} sx={{textAlign:'left', px:{xs:0, sm:1}}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Damages to crossarms and arbor</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Grid container>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                {
                                    data.crossarmArborDamage===1
                                    ?
                                        <Box sx={{backgroundColor: 'green', color:'white', borderRadius:'5px'}}>
                                            <Typography>Pass</Typography>
                                        </Box>
                                    :
                                        data.crossarmArborDamage===-1
                                        ?
                                            <Box sx={{backgroundColor: 'red', color:'white', borderRadius:'5px'}}>
                                                <Typography>Fail</Typography>
                                            </Box>
                                        :
                                            ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left', pt:4}}>
                        <Typography>Notes:</Typography>
                        <Typography>{data.crossarmArborDamageNotes}</Typography>
                    </Grid>
                </Grid>          

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={12} sm={5} sx={{textAlign:'left'}}>
                        <Box sx={{display:'flex'}}>
                            <Image src={FRI5} showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI5');}} />
                        </Box>
                    </Grid>
                    <Grid item xs={8} sm={4} sx={{textAlign:'left', px:{xs:0, sm:1}}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Straightness of Reel</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Grid container>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                {
                                    data.reelStraightness===1
                                    ?
                                        <Box sx={{backgroundColor: 'green', color:'white', borderRadius:'5px'}}>
                                            <Typography>Pass</Typography>
                                        </Box>
                                    :
                                        data.reelStraightness===-1
                                        ?
                                            <Box sx={{backgroundColor: 'red', color:'white', borderRadius:'5px'}}>
                                                <Typography>Fail</Typography>
                                            </Box>
                                        :
                                            ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left', pt:4}}>
                        <Typography>Notes:</Typography>
                        <Typography>{data.reelStraightnessNotes}</Typography>
                    </Grid>
                </Grid>      

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={12} sm={5} sx={{textAlign:'left'}}>
                        <Box sx={{display:'flex'}}>
                            <Image src={FRI61} showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI61');}} />
                        </Box>
                    </Grid>
                    <Grid item xs={8} sm={4} sx={{textAlign:'left', px:{xs:0, sm:1}}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Two D-rings in the starter pocket</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Grid container>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                {
                                    data.dRings===1
                                    ?
                                        <Box sx={{backgroundColor: 'green', color:'white', borderRadius:'5px'}}>
                                            <Typography>Pass</Typography>
                                        </Box>
                                    :
                                        data.dRings===-1
                                        ?
                                            <Box sx={{backgroundColor: 'red', color:'white', borderRadius:'5px'}}>
                                                <Typography>Fail</Typography>
                                            </Box>
                                        :
                                            ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left', pt:4}}>
                        <Typography>Notes:</Typography>
                        <Typography>{data.dRingsNotes}</Typography>
                    </Grid>
                </Grid> 

                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={12} sm={5} sx={{textAlign:'left'}}>
                        <Image src={FRI62} showLoading sx={{px:.25, cursor:'pointer'}} width={75} onClick={() => {setZoom('FRI62');}} />
                    </Grid>
                    <Grid item xs={8} sm={4} sx={{textAlign:'left', px:{xs:0, sm:1}}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Two gussets on crossarm</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Grid container>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                {
                                    data.gussets===1
                                    ?
                                        <Box sx={{backgroundColor: 'green', color:'white', borderRadius:'5px'}}>
                                            <Typography>Pass</Typography>
                                        </Box>
                                    :
                                        data.gussets===-1
                                        ?
                                            <Box sx={{backgroundColor: 'red', color:'white', borderRadius:'5px'}}>
                                                <Typography>Fail</Typography>
                                            </Box>
                                        :
                                            ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left', pt:4}}>
                        <Typography>Notes:</Typography>
                        <Typography>{data.gussetsNotes}</Typography>
                    </Grid>
                </Grid> 


                <Grid container sx={{p:1, border:'1px solid #e2e8f0', borderRadius:'5px'}}>          
                    <Grid item xs={12} sm={5} sx={{textAlign:'left'}}>
                    </Grid>
                    <Grid item xs={8} sm={4} sx={{textAlign:'left', px:{xs:0, sm:1}}}>
                        <Typography variant='body1' sx={{fontWeight:'bold'}}>Is the drum stitch welded to the flange on both sides</Typography>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <Grid container>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                {
                                    data.drumSticthWeld===1
                                    ?
                                        <Box sx={{backgroundColor: 'green', color:'white', borderRadius:'5px'}}>
                                            <Typography>Pass</Typography>
                                        </Box>
                                    :
                                        data.drumSticthWeld===-1
                                        ?
                                            <Box sx={{backgroundColor: 'red', color:'white', borderRadius:'5px'}}>
                                                <Typography>Fail</Typography>
                                            </Box>
                                        :
                                            ''
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:'left', pt:4}}>
                        <Typography>Notes:</Typography>
                        <Typography>{data.drumSticthWeldNotes}</Typography>
                    </Grid>
                </Grid>                 
            </Stack>            
        </Box>
        </>
    );
}
