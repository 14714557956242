import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import Loading from '../Loading';
import Error from '../Error';
import AnalyticsTotalReelsListItem from './AnalyticsTotalReelsListItem';

import { useSessionContext } from '../../contexts/SessionContext';

export default function AnalyticsTotalReels(props) {
    //console.debug("RTS Break AnalyticsTotalReels");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoRows, setRepoRows] = useState([]);

    function fetchData(){
        let apiURL = apiRoot + "/Analytics/GetLoadsByStatus";
        axios
        .post(
            apiURL,
            sessionCustomer.customer,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoRows(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }


    useEffect(() => {
        fetchData();
    }, []); 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return (
        <Container maxWidth="xs" sx={{border:1, borderRadius:2}}>
            <Box sx={{textAlign:"center"}}>
                <Typography variant="h6">Reels On Hand</Typography>
            </Box>
            {/* <Box sx={{textAlign:"center"}}>
                <Typography variant="h5">{repoRows.length}</Typography>
            </Box> */}
            <Box sx={{px:2, textAlign:"center"}}>
                <Table size="small">
                    <TableBody>
                        {repoRows.map((row, idx) => (<AnalyticsTotalReelsListItem key={idx} data={row} />))}
                    </TableBody>
                </Table>
            </Box>
        </Container>
    );
}
