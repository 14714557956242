import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function ReelTypesActiveReelsList(props){
    //console.debug("RTS Break ReelTypesActiveReelsList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoInspections, setRepoInspections] = useState([]);

    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/Loads/GetActiveLoadsByReelTypeId/" + props.idGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoInspections(response.data);
                setIsLoading(false);
            }
            else
            {
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token, props.idGuid]);


    useEffect(() => {
        fetchData();
    }, [fetchData]); 


    const columns = [
        {
            field: 'reelSerialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:1,
        },
        {
            field: 'customerName', 
            headerName: "Customer", 
            description:"Customer Name", 
            flex:4,
        },
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Ship Date", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
				if(value == null){
                    return null;
                }

                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
        },
        {
            field: 'daysLeftInDeferral', 
            headerName: "Due Back (Days)", 
            description:"Deferral Period Remaining", 
            flex:1,
            align:'right',
        },
    ];


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return (
        <>
        <Box sx={{textAlign:'left', mt:1}}>
            <Typography variant="h6" sx={{display:'inline'}}>Reel Type:</Typography>
            <Typography inline variant="h6" sx={{display:'inline', ml:1}}>{props.display}</Typography>
        </Box>
        <Box id="pageBox" sx={{width:'100%', mt:1}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={repoInspections} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
        </>        
    );
}
