import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import UsersList from '../../components/Users/UsersList';
import UsersHeader from '../../components/Users/UsersHeader';
import UsersFilter from '../../components/Users/UsersFilter';

import { useSessionContext } from '../../contexts/SessionContext';

export default function UsersPage( props ){
    //console.debug("RTS Break UsersPage");

    const {curFilter, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);

    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/AppUsers/GetAppUsersSummary";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
            }
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }, [apiRoot, userAuthToken.token]);


    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'UsersPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({licensedBy:null, status:1});
            fetchData({licensedBy:null, status:1});
        }
    }, [props, curFilter, fetchData]); 


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    }    


     if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box>
            <UsersHeader />
            <UsersFilter filter={filter} setFilterFx={setFilter} />            
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                <UsersList data={data} />
            </Box>
        </Box>
    );


}
