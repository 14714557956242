import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import WorkItemsList1Body from './WorkItemsList1Body';

export default function WorkItemsList1(props){
    //console.debug("RTS Break WorkItemsList1");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody] = useState("");
    const [errorTitle] = useState("Validation Error");

    function SessionCustomerToolbar(props) {
        return(
            <GridToolbarContainer {...props} sx={{display:'flex', width:'100%'}}>
                <Box sx={{width:'49%'}}>
                    <GridToolbarFilterButton />
                </Box>
                <Box sx={{width:'49%', textAlign:'right'}}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    }


    const columns = [
        {
            field: 'displayVal', 
            headerName: "", 
            description:"Work Package Item", 
            flex:10,
            renderCell: (cellValues) => {
                    return (
                        <WorkItemsList1Body data={cellValues.row} mode={0} />
                    );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true                       
        },
        {
            field: 'value2', 
            headerName: "Circuit", 
            description:"Asset Circuit", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value3', 
            headerName: "Asset Id", 
            description:"Asset Identifier", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value4', 
            headerName: "Condition Item", 
            description:"Condition Item", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value5', 
            headerName: "Condition Problem", 
            description:"Condition Problem", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value6', 
            headerName: "Condition Action", 
            description:"Condition Action", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value7', 
            headerName: "Condition Comments", 
            description:"Condition Comments", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value8', 
            headerName: "Severity", 
            description:"Severity", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value9', 
            headerName: "Map Link", 
            description:"Google Map Link", 
            sortable:false,
            hideable:false,
            filterable:false,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value10', 
            headerName: "Lat/Lng", 
            description:"Latitude/Longitude", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {value2:false, value3:false, value4:false, value5:false, value6:false, value7:false, value8:false, value9:false, value10:false}
        }
        else{
            return {value2:false, value3:false, value4:false, value5:false, value6:false, value7:false, value8:false, value9:false, value10:false}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro 
                pagination 
                slots={{toolbar: SessionCustomerToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
                getRowHeight={() => 'auto'}
                columnHeaderHeight={0}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 
                }}                     
            />
        </Box>
        </>
    );
}
