import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import EditIcon from '@mui/icons-material/Edit'; 

export default function PalletsList(props){
    //console.debug("RTS Break PalletsList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/PalletsEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit Pallet", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Name", 
            description:"Pallet Name", 
            flex:2
        },
        {
            field: 'esn', 
            headerName: "ESN", 
            description:"Electronic Serial Number", 
            flex:2
        },
        {
            field: 'purchaseDate', 
            headerName: "Purchase Date", 
            description:"Purchase Date", 
            flex:1,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }, 
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"Pallet Status", 
            flex:1,
            renderCell: (cellValues) => {
                if(cellValues.value === 0){
                    return ('Disabled');
                }
                else{
                    return('Active');
                }
            },            
        },        
    ];

    return (
        <DataGridPro 
            autoHeight 
            pagination 
            components={{Toolbar: GridToolbar}} 
            componentsProps={{toolbar:{showQuickFilter:true}}}
            pageSize={50} 
            rows={props.data} 
            columns={columns} 
            getRowId={row => row.esn} 
        />
    );
}

