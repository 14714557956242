import {Box} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';



export default function LoadListExport(props){
    //console.debug("RTS Break LoadListExport");

    const columns = [
        {
            field: 'assignedCustomerName', 
            headerName: "Assigned Customer", 
            description:"Assgined Customer Name", 
            valueGetter: (value, row) => {
                return row.assignedToCustomer.name
            }             
        },
        {
            field: 'soldToCustomerName', 
            headerName: "Sold To Customer", 
            description:"Sold To Customer Name", 
            valueGetter: (value, row) => {
                return row.soldToCustomer.name
            }
        },
        {
            field: 'customerMaterialName',
            headerName: 'Customer Material Name',
            description: 'Customer Specific Material Name',
            valueGetter: (value, row) => {
                if(row.product.productAlias.length === 0)
                {
                    return '';
                }

                return row.product.productAlias[0].alias
            }
        },
        {
            field: 'customerMaterialNo',
            headerName: 'Customer Material No.',
            description: 'Customer Specific Material Number'
        },
        {
            field: 'productName', 
            headerName: "Southwire Product Name", 
            description: "Product Name for Southwire", 
            valueGetter: (value, row) => {
                return row.product.name
            }
        },
        {
            field: 'productNo', 
            headerName: "Southwire Product No.", 
            description:"Product Number for Southwire", 
            valueGetter: (value, row) => {
                return row.product.idSap
            }
        },
        {
            field: 'projectName', 
            headerName: "Project", 
            description:"Project Name", 
            valueGetter: (value, row) => {
                return row.project!==null?row.project.name:'';
            }
        }, 
        {
            field: 'amt', 
            headerName: "Length Purchased", 
            description:"The Length of Product Purchased in Feet", 
            valueGetter: (value, row) => {
                return row.amt.toLocaleString(undefined, { maximumFractionDigits: 2 });
            }
        },           
        {
            field: 'uom', 
            headerName: "Length Unit of Measure", 
            description:"The Unit of Measure for Length Purchased", 
            valueGetter: (value, row) => {
                return 'ft';
            }
        },
        {
            field: 'lengthRemaining', 
            headerName: "Remaining Length", 
            description:"The Remaining Length on the Reel", 
            valueGetter: (value, row) => {
                return Math.floor(row.amt - row.pullSum).toLocaleString(undefined, { maximumFractionDigits: 2 });
            }
        },
        {
            field: 'age', 
            headerName: "Age of Product (Days)", 
            description:"The Age of Product on the Reel in Days", 
            valueGetter: (value, row) => {
                return row.shipDateDays.toLocaleString();
            }
        },
        {
            field: 'customerPo', 
            headerName: "Purchase Order", 
            description:"The Customer Purchase Order", 
            valueGetter: (value, row) => {
                return row.customerPo;
            }
        }, 
        {
            field: 'shippingOrder', 
            headerName: "Shipping Order", 
            description:"The Shipping Order", 
            valueGetter: (value, row) => {
                return row.idSapOrder;
            }
        }, 
        {
            field: 'packingListNo', 
            headerName: "Packing List No", 
            description:"Packing List No", 
            valueGetter: (value, row) => {
                return row.idSapPackingList;
            }
        }, 
        {
            field: 'billOfLading', 
            headerName: "Bill of Lading", 
            description:"Bill of Lading", 
            valueGetter: (value, row) => {
                return row.idSapBol;
            }
        }, 
        {
            field: 'batchNo', 
            headerName: "Batch Number", 
            description:"Batch Number", 
            valueGetter: (value, row) => {
                return row.idSapBatchNo;
            }
        }, 
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"The Order Ship Date", 
            valueGetter: (value, row) => {
                return row.shipDate;
            }
        },
        {
            field: 'reelSerial', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            valueGetter: (value, row) => {
                return row.reel.serialNumber
            }
        },
        {
            field: 'reelType', 
            headerName: "Reel Type", 
            description:"The Class of Reel for the Load", 
            valueGetter: (value, row) => {
                return row.reel.reelType.name;
            }
        },
        {
            field: 'tracker', 
            headerName: "Tracker ESN", 
            description:"The Electronic Serial Number of Reel Tracker", 
            valueGetter: (value, row) => {
                return row.tracker==null?null:row.tracker.esn;
            }
        },          
        {
            field: 'deferralPeriod', 
            headerName: "Deferral Months", 
            description:"The Number of Reel Return Deferral Months", 
            valueGetter: (value, row) => {
                return row.deferralPeriod;
            }
        }, 
        {
            field: 'tareWeight', 
            headerName: "Tare Weight", 
            description:"Tare Weight", 
            valueGetter: (value, row) => {
                return row.tareWeight.toLocaleString();
            }
        }, 
        {
            field: 'reelOwner', 
            headerName: "Reel Owner", 
            description:"Reel Owner", 
            valueGetter: (value, row) => {
                return row.customerReel==null?"Southwire":props.data.customer.name;
            }
        }, 
        {
            field: 'returnedDate', 
            headerName: "Returned Date", 
            description:"Reel Returned Date", 
            valueGetter: (value, row) => {
                return row.returnedDate;
            }
        }      
    ];

    return (
        <Box style={{height: '600px'}}>
            <DataGridPro 
                rows={props.data} 
                columns={columns} 
                slots={{ toolbar: GridToolbar }}
                getRowId={row => row.idGuid}
            />
        </Box>
    );
}
