import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Error from '../Error';
import Loading from '../Loading';
import PickupRequestsAdminEditStatusList from './PickupRequestsAdminEditStatusList';
import PickupRequestAdminLoadsList from './PickupRequestAdminLoadsList';
import EditRegion from '../EditRegion';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsAdminEdit(props) {
    console.debug("RTS Break PickupRequestsAdminEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const display = props.display;
    const mode = props.mode; 
    
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showUnSubmitModal, setShowUnSubmitModal] = useState(false);

    const [idGuid, setIdGuid] = useState();
    const [customer, setCustomer] = useState();
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [altPhoneNumber, setAltPhoneNumber] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [hours, setHours] = useState('');
    const [daysAvailable, setDaysAvailable] = useState('');
    const [notes, setNotes] = useState('');
    const [siteCloseDate, setSiteCloseDate] = useState(null);
    const [loads, setLoads] = useState([]);
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [submitDate, setSubmitDate] = useState();
    const [submitUser, setSubmitUser] = useState();
    const [status, setStatus] = useState();


    const fetchData = useCallback((idGuid) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/PickupRequest/GetPickupRequestById/" + idGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIdGuid(response.data.idGuid);
                setCustomer(response.data.customer);
                setName(response.data.name);
                setContact(response.data.onSiteContactName);
                setPhoneNumber(response.data.phoneNumber);
                setAltPhoneNumber(response.data.altPhoneNumber);
                setContactEmail(response.data.contactEmail);
                setAddress1(response.data.address1);
                setAddress2(response.data.address2);
                setCity(response.data.city);
                setRegion(response.data.region);
                setZipCode(response.data.zip);
                setHours(response.data.shipRecHours);
                setDaysAvailable(response.data.daysAvailable);
                setNotes(response.data.notes);
                setSiteCloseDate(response.data.siteCloseDate==null?new Date(response.data.siteCloseDate):null);
                setLoads(response.data.loads);
                setLat(response.data.lat);
                setLng(response.data.lng);

                if(response.data.submitDate != null){
                    const formatObj = new Intl.DateTimeFormat("en-US");
                    let tmpDate = formatObj.format(new Date(response.data.submitDate));
                    setSubmitDate(tmpDate);
                }
                setSubmitUser(response.data.submitUser);
                setStatus(response.data.status);
            }
            setIsLoading(false);
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);     


    useEffect(() => {
        if(mode === 1){
            setIdGuid(props.idGuid);
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  

   
    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            IdGuid: idGuid,
            Customer:customer,
            Name: name,
            OnSiteContactName: contact,
            PhoneNumber: phoneNumber,
            AltPhoneNumber: altPhoneNumber,
            ShipRecHours: hours,
            DaysAvailable: daysAvailable,
            Notes: notes,
            SiteCloseDate: siteCloseDate,
            Lat: lat,
            Lng: lng,      
            Address1: address1,
            Address2: address2,
            City: city,
            Region: region,
            Zip: zipCode,
            Country: null,    
            SubmitUser: status<75?null:submitUser,
            SubmitDate: status<75?null:submitDate,
            Loads: loads,
            status: status,
            modifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/PickupRequest/UpdatePickupRequest";
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdatePickupRequest Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/PickupRequest/CreatePickupRequest";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreatePickupRequest Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    function confirmUnSubmit(){
        setIsLoading(true);

        if(loads.length <= 0){
            setErrorTitle("No Reels Detected");
            setErrorBody("Reels must be associated with the Picup Request prior to submitting.");
            setShowErrorModal(true);
            return;    
        }

        let apiObj = {
            idGuid: idGuid,
            Customer:customer,
            Name: name,
            OnSiteContactName: contact,
            PhoneNumber: phoneNumber,
            AltPhoneNumber: altPhoneNumber,
            ContactEmail: contactEmail,
            ShipRecHours: hours,
            DaysAvailable: daysAvailable,
            Notes: notes,
            SiteCloseDate: siteCloseDate,
            Lat: lat,
            Lng: lng,      
            Address1: address1,
            Address2: address2,
            City: city,
            Region: region,
            Zip: zipCode,
            Country: null,    
            Loads: loads,
            status: 50,
            modifyUser: user.userName,
            submitUser: null,
            submitDate: null
        }

        let apiURL = apiRoot + "/PickupRequest/UpdatePickupRequest";
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setShowUnSubmitModal(false);
            setIsLoading(false);
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS SubmitPickupRequest Error", error);
            setShowErrorModal(true);
        });
    };


    function setStatusVal(pVal){
        setStatus(pVal);
        props.setFormDirtyFx(1)
    }    


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showUnSubmitModal} onClose={()=>{setShowUnSubmitModal(false);}}>
            <DialogTitle>Confirm Pickup Request Un-Submit</DialogTitle>
            <DialogContent>
                <DialogContentText>Please confirm you wish to remove the submit for this Pickup Request.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {confirmUnSubmit()}}>Confirm</Button>
                <Button variant="contained" onClick={e => {setShowUnSubmitModal(false);}}>Cancel</Button>
            </DialogActions>
        </Dialog>        
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form id="projectForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>

                <Box sx={{my:1}}>
                    <Typography variant="h6">{display}</Typography>
                </Box>
                <Typography variant="h6" sx={{mt:2, mb:1}}>Status</Typography>
                <Grid container>
                    <Grid item xs={9}>
                        <PickupRequestsAdminEditStatusList status={status} setStatusFx={setStatusVal} />
                    </Grid>
                    <Grid item xs={3} sx={{pl:1}}>
                        <Box sx={{textAlign:"right", mb:2}}>
                            <Button variant="contained" onClick={e =>{setShowUnSubmitModal(true);}} disabled={status!=75?true:false}>Un-Submit</Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{pr:1}}>
                        <TextField
                            id="tbSubmitDate"
                            margin="normal"
                            fullWidth
                            label="Submit Date"
                            size="small"
                            value={submitDate}
                            onChange={(e) => {props.setFormDirtyFx(1); setSubmitDate(e.target.value);}}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{pl:1}}>
                        <TextField
                            id="tbSubmitBy"
                            margin="normal"
                            fullWidth
                            label="Submitted By"
                            size="small"
                            value={submitUser}
                            onChange={(e) => {props.setFormDirtyFx(1); setSubmitUser(e.target.value);}}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h6" sx={{mt:2}}>Overview</Typography>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                />           
                <TextField
                    id="tbContact"
                    margin="normal"
                    fullWidth
                    label="On-Site Contact"
                    size="small"
                    value={contact}
                    onChange={(e) => {props.setFormDirtyFx(1); setContact(e.target.value);}}
                />
                <TextField
                    id="tbPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    size="small"
                    value={phoneNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setPhoneNumber(e.target.value);}}
                />
                <TextField
                    id="tbAltPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Alt. Phone Number"
                    size="small"
                    value={altPhoneNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setAltPhoneNumber(e.target.value);}}
                />
                <TextField
                    id="tbContactEmail"
                    margin="normal"
                    fullWidth
                    label="Email"
                    size="small"
                    value={contactEmail}
                    onChange={(e) => {props.setFormDirtyFx(1); setContactEmail(e.target.value);}}
                />                
                <Typography variant="h6" sx={{mt:2, mb:1}}>Pickup Address</Typography>
                <TextField
                    id="tbAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={address1}
                    onChange={(e) => {props.setFormDirtyFx(1); setAddress1(e.target.value);}}
                />
                <TextField
                    id="tbAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={address2}
                    onChange={(e) => {props.setFormDirtyFx(1); setAddress2(e.target.value);}}
                />
                <TextField
                    id="tbCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={city}
                    onChange={(e) => {props.setFormDirtyFx(1); setCity(e.target.value);}}
                />
                <Grid container>
                    <Grid item xs={6} sx={{pr:1}}>
                        <EditRegion disable={submitDate==null?0:1} region={region} setRegionFx={setRegion} />
                    </Grid>
                    <Grid item xs={6} sx={{pl:1}}>
                        <TextField
                            id="tbZip"
                            margin="normal"
                            fullWidth
                            label="Zip Code"
                            size="small"
                            value={zipCode}
                            onChange={(e) => {props.setFormDirtyFx(1); setZipCode(e.target.value);}}
                            disabled={submitDate==null?false:true}
                        />
                    </Grid>
                </Grid>
                <TextField
                    id="tbHours"
                    margin="normal"
                    fullWidth
                    label="Shipping/Receiving Hours"
                    size="small"
                    value={hours}
                    onChange={(e) => {props.setFormDirtyFx(1); setHours(e.target.value);}}
                />
                <TextField
                    id="tbDaysAvailable"
                    margin="normal"
                    fullWidth
                    label="Days Available"
                    size="small"
                    value={daysAvailable}
                    onChange={(e) => {props.setFormDirtyFx(1); setDaysAvailable(e.target.value);}}
                />
                <DatePicker
                    id="tbSiteCloseDate"
                    label="Site Close Date"
                    inputFormat="MM/dd/yyyy"
                    value={siteCloseDate}
                    onChange={(val) => {props.setFormDirtyFx(1); setSiteCloseDate(val);}}
                    renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                />    
                <TextField
                    id="tbNotes"
                    margin="normal"
                    fullWidth
                    label="Notes"
                    size="small"
                    value={notes}
                    onChange={(e) => {props.setFormDirtyFx(1); setNotes(e.target.value);}}
                />
                </Box>
                </Box>
                <Box sx={{mt:2}}>
                    <Grid container sx={{mb:1}}>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Pickup Request Reels</Typography>
                        </Grid>
                    </Grid>
                    <PickupRequestAdminLoadsList data={loads} />
                </Box>
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" disabled={props.formDirty===1?false:true} sx={{mr:2}}>Save</Button>
                </Box>
            </form>
            </LocalizationProvider>
        </Box>
        </>
    );

}
