import axios from 'axios';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import SearchIcon from '@mui/icons-material/Search';

import CrewMembersEditCompanyLinkLookup from './CrewMembersEditCompanyLinkLookup';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersEdit(props) {
    console.debug("RTS Break CrewMemebersEditAdd");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle] = useState("Validation Error");
    const [showCompanyLinkModal, setShowCompanyLinkModal] = useState(false);

    const [fName, setFName] = useState(props.data.firstName);
    const [lName, setLName] = useState(props.data.lastName);
    const [companyLink, setCompanyLink] = useState()
    const [markAccess, setMarkAccess] = useState(0)

    
    function handleSubmit(e) {
        e.preventDefault();

        let curIdGuid = sessionCustomer.customer.idGuid;

        if(props.data.appUserCustomers.filter(c => c.customer.idGuid === curIdGuid).length > 0){
            setErrorBody("User is already associated with this customer");
            setShowErrorModal(true);
            return;
        }

        let curAppUserCustomers = JSON.parse(JSON.stringify(props.data.appUserCustomers));
		curAppUserCustomers.push({customer:sessionCustomer.customer, accessLevel:1, companyLink:companyLink, markAccess:markAccess});

        let apiObj = {
            userName: props.data.email,
            email: props.data.email,
            firstName: props.data.firstName,
            lastName: props.data.lastName,
            status: props.data.status,
            adminFlag: props.data.adminFlag,
            manufacturerFlag: props.data.manufacturerFlag,
            distributorFlag: props.data.distributorFlag,
            appUserCustomers: curAppUserCustomers,
            licensedBy: props.data.licensedBy,
            licensedId: props.data.licensedId,
            modifyUser: user.userName,
            modReelsFlag: 1
        }

        let apiURL = apiRoot + "/AppUsers/UpdateAppUser";
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateUser Error", error);
            setShowErrorModal(true);

        });
    } 


    function setCompanyLinkVal(pValue){
        setCompanyLink(pValue);
        setShowCompanyLinkModal(false);
        props.setFormDirtyFx(1);
    }; 


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showCompanyLinkModal} onClose={()=>{setShowCompanyLinkModal(false);}}>
            <DialogTitle>Crew Member Company</DialogTitle>
            <DialogContent>
                <Box>
                    <CrewMembersEditCompanyLinkLookup data={companyLink} setValueFx={setCompanyLinkVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowCompanyLinkModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>          
        <Box>
            <form id="requestForm" onSubmit={handleSubmit}>
                <Box>
                    <Typography sx={{fontWeight:'bold', pb:1}}>
                        A user with this email address already exists in the system.  
                        To associate this user with your organization click the save 
                        button or type a new email address in the above field and re-validate.
                    </Typography>
                    <Typography>{sessionCustomer.name}</Typography>
                </Box>
                <TextField
                    id="tbFName"
                    margin="normal"
                    fullWidth
                    label="First Name"
                    size="small"
                    required
                    value={fName}
                    onChange={(e) => {setFName(e.target.value);}}
                    disabled
                />
                <TextField
                    id="tbLName"
                    margin="normal"
                    fullWidth
                    label="Last Name"
                    size="small"
                    required
                    value={lName}
                    onChange={(e) => {setLName(e.target.value);}}
                    disabled
                />
                <Box id="CompanyLink" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbCompanyLink"
                        margin="normal"
                        fullWidth
                        label="Company"
                        size="small"
                        value={companyLink==null?'':companyLink.name}
                        onClick={setShowCompanyLinkModal}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowCompanyLinkModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>                
                <Box id="MarkAccess" sx={{display:"flex", mt:1}}>
                    <FormControl component="fieldset" sx={{flexGrow:1}}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={markAccess===1?true:false} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setMarkAccess(1):setMarkAccess(0);}} 
                                            name="cbxMarkAccess" 
                                    />
                                }
                                label="Allow Reel Marks"
                            />
                        </FormGroup> 
                    </FormControl>
                </Box>                
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                </Box>
            </form>
        </Box>
        </>
    );
}
