import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AddIcon from '@mui/icons-material/Add';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function UsersEditCustomersAddList(props){
    //console.debug("RTS Break UsersEditCustomersAddList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [repoCustomers, setRepoCustomers] = useState([]);


    const fetchData = useCallback((pFilter) => {
        let apiCustomersURL = apiRoot + "/Customers/GetAllCustomersTypeCustomer";
        axios
        .get(
            apiCustomersURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoCustomers(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {     
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);     

    
    useEffect(() => {
        fetchData();
    }, [fetchData]);  


    function handleClick(e, cellValues){
        props.setAccessLevelFx(cellValues.row);
    }    


    const columns = [
        {
            field: '',
            description:"Add Reel", 
            flex:1,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <AddIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },          
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:4
        }, 
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Customer SAP Id", 
            flex:3
        },   
        {
            field: 'salesOffice', 
            headerName: "Office Id", 
            description:"Sales Office Id", 
            flex:3 
        },
        {
            field: 'salesGroup', 
            headerName: "Group Id", 
            description:"Sales Group Id", 
            flex:3 
        }
    ];



    function getVisibilityModel(){
        if(smallForm){
            return {salesOffice:false, salesGroup:false}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };    


    return (
        <Box sx={{height:"400px"}}>
            <DataGridPro 
                pagination
                disableColumnFilter
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },                        
                    }
                }}                    
                rows={repoCustomers} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                          
            />
        </Box>
    );
}
