import axios from 'axios';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import LoadListListItem from "../LoadList/LoadListListItem";
import LoadListAddPullSetLocationMap from './LoadListAddPullSetLocationMap';
import LoadListAddPullCrewLookup from './LoadListAddPullCrewLookup';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListAddPullEdit(props) {
    console.debug('RTS Break LoadListAddPullEdit');

    const history = useNavigate();

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showCrewModal, setShowCrewModal] = useState(false);
    const [locationMode] = useState(null);

    const [pullLength, setPullLength] = useState(0);
    const [pullWeight, setPullWeight] = useState(0);
    const [pullDate, setPullDate] = useState(new Date());
    const [startLat, setStartLat] = useState(props.data.currentLocation.lat);
    const [startLng, setStartLng] = useState(props.data.currentLocation.lng);
    const [endLat, setEndLat] = useState("");
    const [endLng, setEndLng] = useState("");
    const [notes, setNotes] = useState("");
    const [crew, setCrew] = useState();
    const [calculatedFlag, setCalculatedFlag] = useState(0);

    const maxValueLength = Math.floor(props.data.amt - props.data.pullSum);
    const maxValueWeight = Math.floor(props.data.grossWeight);


    useEffect(() => {
        setPullLength(0);
        setPullWeight(0);
        setPullDate(new Date());
        setStartLat(props.data.currentLocation.lat);
        setStartLng(props.data.currentLocation.lng);
        setEndLat("");
        setEndLng("");
        setNotes("");
        setCrew(props.data.crew);
        setCalculatedFlag(0);
        
        setIsLoading(false);

    }, [props.data.crew]); 


    function handleSubmit(e) {
        e.preventDefault();

        var lat = null;
        var lng = null;

        if(props.data.tracker !== null){
            lat = props.data.currentLocation!==null?props.data.currentLocation.lat:null;
            lng = props.data.currentLocation!==null?props.data.currentLocation.lng:null;        
        }
        else{
            if(props.data.setLocations !== null && props.data.setLocations.length > 0)
            {
                lat = props.data.currentLocation.lat;
                lng = props.data.currentLocation.lng;        
            }
        }

        if(pullLength > maxValueLength){
            setErrorTitle("Validation Error");
            setErrorBody("The length pulled exceeds the product remaining on the reel");
            setShowErrorModal(true);
            return;            
        }

        let apiObj = {
            PullLoad: props.data,
            PullType: 'Manual',
            PullLength: pullLength,
            Uom: props.data.uom,
            PullDate: pullDate,
            Lat: lat,
            Lng: lng, 
            Notes: notes,
            AdjustmentFlag: 0,
            ConfirmedFlag: 1,
            CalculatedFlag: calculatedFlag,
            Crew: crew,
            CompanyLink: null,
            createUser: user.userName,
            modifyUser: user.userName
        }

        let apiURL = apiRoot + "/LoadPulls/CreateLoadPull"

        axios
        .post(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            window.scrollTo(0,0);
            history(-1);
        })
        .catch(error => {
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS CreateLoadPull Error", error);
            setShowErrorModal(true);
        });
    } 


    function setPullValue(uom, value){
        if(value.length > 0){
            let repoReelWeight = props.data.reelType.approxWeight!==null?Number(props.data.reelType.approxWeight):0;
            let repoTareWeight = props.data.tareWeight!==null?Number(props.data.tareWeight):0;

            let repoNetWeight = props.data.netWeight!==null?Number(props.data.netWeight):0;
            if(repoNetWeight <= 0){
                setErrorTitle("Validate Error [Net Weight]");
                setErrorBody("Unable to perform calculations for this reel");
                setShowErrorModal(true);
                return;                    
            }            

            let repoWeightPerFt = props.data.product.weightPerFt!==null?Number(props.data.product.weightPerFt):0;
            if(repoWeightPerFt <= 0){
                setErrorTitle("Validate Error [Product Wt]");
                setErrorBody("Unable to perform calculations for this reel");
                setShowErrorModal(true);
                return;                    
            }            

            let repoPullSum = props.data.pullSum!==null?Number(props.data.pullSum):0;
            let repoAmt = props.data.amt!==null?Number(props.data.amt):0;

            // Start Calculations.
            let repoPullWeight = repoPullSum * repoWeightPerFt;
            let prevWeight = repoNetWeight - repoPullWeight;
            let prevLength = repoAmt - repoPullSum;

            let tareWeight = 0;
            if(repoTareWeight > 0){
                tareWeight = repoTareWeight;
            }
            else{
                if(repoReelWeight > 0){
                    tareWeight = repoReelWeight;
                }
                else{
                    setErrorTitle("Validate Error [Tare Weight]");
                    setErrorBody("Unable to perform calculations for this product");
                    setShowErrorModal(true);
                    return;                    
                }
            }

            if(uom === 0){
                let pullLength = Number(value);

                if(pullLength < 0){
                    setErrorBody("The pull Length cannot be 0");
                    setShowErrorModal(true);
                    return;                    
                }

                if(pullLength > prevLength){
                    setErrorBody("The pull Length exceeds available product");
                    setShowErrorModal(true);
                    return;                    
                }

                setPullLength(pullLength);
                let pullWeight = pullLength * repoWeightPerFt;
                let newWeight = Math.floor(prevWeight - pullWeight + tareWeight);
                setPullWeight(newWeight);
                setCalculatedFlag(0);
            }
            else{
                let pullWeight = Number(value);

                let pullLength = 0;
                if(pullWeight < tareWeight){
                    pullLength = prevLength;  
                }
                else{
                    let remainingWeight = pullWeight - tareWeight;
                    let remainingLength = Math.ceil(remainingWeight/repoWeightPerFt);
                    pullLength = prevLength - remainingLength;
                }

                setPullWeight(pullWeight);
                setPullLength(pullLength);
                setCalculatedFlag(1);
            }
        }
        else{
            setErrorTitle("Validation Error");
            setErrorBody("Pulls are used to record the consumption of wire from a reel.  If a previous value needs to be changed please enter an Adjustment.");
            setShowErrorModal(true);
            return;    
        }
    }


    function setLocation(sLat, sLng, eLat, eLng){
        setStartLat(sLat);
        setStartLng(sLng);
        setEndLat(eLat);
        setEndLng(eLng);
        setShowLocationModal(false);
    }


    
    function getCrewDisplay(){
        if(crew != null){
            return crew.name;
        }
        else{
            return '';
        }
    }


    function setCrewVal(pVal){
        setCrew(pVal);
        setShowCrewModal(false);
        props.setFormDirtyFx(1);
    }    



    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog> 
            <Dialog open={showLocationModal} onClose={()=>{setShowLocationModal(false);}}>
                <DialogTitle>{locationMode==='start'?'Pull Start Point':'Pull End Point'}</DialogTitle>
                <DialogContent>
                    <Box sx={{width:'350px'}}>
                        <LoadListAddPullSetLocationMap mode={locationMode} startLat={startLat} startLng={startLng} endLat={endLat} endLng={endLng} setLocationFx={setLocation} setShowLocationModalFx={setShowLocationModal} />
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth={true} maxWidth="md" open={showCrewModal} onClose={()=>{setShowCrewModal(false);}}>
                <DialogTitle>Crews</DialogTitle>
                <DialogContent>
                    <Box>
                        <LoadListAddPullCrewLookup crew={crew} setCrewFx={setCrewVal} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowCrewModal(false);}} sx={{mr:2}}>Close</Button>
                </DialogActions>
            </Dialog>                                
            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit} style={{width:'400px'}}>
                    <Typography variant="h6" sx={{mt:1}}>Enter Pull Details</Typography>
                    <Box id="Crew" sx={{display:'flex', alignItems:'center'}}>
                        <TextField
                            id="tbCrew"
                            margin="normal"
                            fullWidth
                            label="Crew"
                            size="small"
                            value={getCrewDisplay()}
                            onClick={setShowCrewModal}
                        />
                        <Box sx={{ml:1, mt:1}}>            
                            <Button variant="contained" onClick={setShowCrewModal}>
                                <SearchIcon sx={{fontSize:'2em'}} />
                            </Button>
                        </Box>            
                    </Box>                    
                    <Box sx={{pb:2}}>
                        <Typography variant='h6'>Pull Value</Typography>
                        <Box sx={{"border":'1px solid silver', p:2, "borderRadius": "5px"}}>
                            <TextField
                                id="tbLength"
                                margin="none"
                                required
                                fullWidth
                                label="Length (ft)"
                                type="number"
                                size="small"
                                value={pullLength}
                                onChange={(e) => {props.setFormDirtyFx(1); setPullValue(0, e.target.value);}}
                                InputProps={{inputProps:{min:1, max:{maxValueLength}}}}
                            />
                            <Typography variant='caption' sx={{display:calculatedFlag===0?'none':'block'}}>(Calculated)</Typography>

                            <Typography variant='h6'>OR</Typography>
                            <TextField
                                id="tbWeight"
                                margin="none"
                                required
                                fullWidth
                                label="Current Weight (Product + Reel Weight)"
                                type="number"
                                size="small"
                                value={pullWeight}
                                onChange={(e) => {props.setFormDirtyFx(1); setPullValue(1, e.target.value);}}
                                InputProps={{inputProps:{min:1, max:{maxValueWeight}}}}
                            />
                        </Box>
                    </Box>
                    <DatePicker
                        id="tbPullDate"
                        label="Pull Date"
                        inputFormat="MM/dd/yyyy"
                        value={pullDate}
                        onChange={(e) => {props.setFormDirtyFx(1); setPullDate(e);}}
                        renderInput={(params) => <TextField margin="normal" size="small" {...params} />}
                        maxDate={new Date()}
                    /> 
                   <TextField
                        id="tbNotes"
                        margin="normal"
                        fullWidth
                        label="Notes"
                        size="small"
                        value={notes}
                        onChange={(e) => {props.setFormDirtyFx(1); setNotes(e.target.value);}}
                        multiline
                        rows={4}
                    />                                     
                    {/* <Box sx={{pb:2}}>
                        <Typography variant='h6'>Location End Points</Typography>
                        <Box sx={{"border":'1px solid silver', p:2, "borderRadius": "5px"}}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography variant='caption'>Starting Point</Typography>
                                    <TextField
                                        id="tbStartLat"
                                        margin="none"
                                        required
                                        fullWidth
                                        label="Latitude"
                                        type="text"
                                        size="small"
                                        value={startLat}
                                        onChange={(e) => {setStartLat(0, e.target.value);}}
                                    />
                                    <TextField
                                        id="tbStartLng"
                                        margin="dense"
                                        required
                                        fullWidth
                                        label="Longitude"
                                        type="text"
                                        size="small"
                                        value={startLng}
                                        onChange={(e) => {setStartLng(0, e.target.value);}}
                                    />
                                </Grid>
                                <Grid item xs={3} alignItems="center" justifyContent="center" display="flex">
                                    <Link component="button" onClick={() => {openSetLocation('start');}}>
                                        <AddLocationIcon sx={{fontSize:'300%'}} />
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography variant='caption'>Ending Point</Typography>
                                    <TextField
                                        id="tbEndLat"
                                        margin="none"
                                        required
                                        fullWidth
                                        label="Latitude"
                                        type="text"
                                        size="small"
                                        value={endLat}
                                        onChange={(e) => {setEndLat(0, e.target.value);}}
                                    />
                                    <TextField
                                        id="tbEndLng"
                                        margin="dense"
                                        required
                                        fullWidth
                                        label="Longitude"
                                        type="text"
                                        size="small"
                                        value={endLng}
                                        onChange={(e) => {setEndLng(0, e.target.value);}}
                                    />
                                </Grid>
                                <Grid item xs={3} alignItems="center" justifyContent="center" display="flex">
                                    <Link component="button" onClick={() => {openSetLocation('end');}}>
                                        <AddLocationIcon sx={{fontSize:'300%'}} />
                                    </Link>
                                </Grid>
                            </Grid>                        
                        </Box>                        
                    </Box>             */}
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit" disabled={props.formDirty===1?false:true} variant="contained" sx={{mr:2}}>Record Pull</Button>
                        </Box>
                    </Box>
                </form>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
