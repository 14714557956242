import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListDetailProjectEditProjectList(props) {
    //console.debug("RTS Break LoadListDetailsProjectEditProjectList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [project, setProject] = useState(props.project);
    const [repoProjects, setRepoProjects] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/Projects/GetProjectsByCustomer";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setRepoProjects(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData({
                User:user,
                Customer:sessionCustomer.customer, 
                Manager:null,
                Status:0,
                SortBy:0 
                }
        );
    }, [user, sessionCustomer, fetchData]); 

    
    function valChange(e, val){
        props.setProjectFx(val);
        setProject(val);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoProjects}
                renderInput={(params) => <TextField {...params} label="Project" margin="normal" size="small" />}
                value={project}
                onChange={valChange}
                getOptionLabel={(repoValue) => repoValue!==''?repoValue.name:''}
                renderOption={(props, repoProjects) => (
                    <Box component="li" {...props} key={repoProjects.idGuid}>
                        {repoProjects.name}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.idGuid === value.idGuid}
            />
        </>
    );
}

