// import axios from 'axios';
import {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

// import { useSessionContext } from '../../contexts/SessionContext';


export default function LocatorFilterReelTypeList(props) {
    //console.debug("RTS Break LocatorFilterReelTypeList");

    // const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [reelTypes, setReelTypes] = useState([]);
    const [repoReelTypes, setRepoReelTypes] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    // let filter = {};

    // function fetchData(){
    //     let apiURL = apiRoot + "/Locator/GetLocatorReelTypesFilter";
    //     axios
    //     .post(
    //         apiURL,
    //         filter, 
    //         {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
    //     )
    //     .then(response => {
    //         if (response.data) {
    //             setRepoReelTypes(response.data);
    //             setIsLoading(false);
    //         }
    //     })
    //     .catch(error => {
    //         setHasError(true);
    //         console.log("RTS Data Error", error);
    //         setHasError(true);
    //     });
    // }


    function getData() {
        // const curData = props.data.map(function(i){return i.reelType});
        // const result = [];
        // const map = new Map();
        // for(const item of curData){
        //     if(!map.has(item.idGuid)){
        //         map.set(item.idGuid, true);
        //         result.push(item);
        //     }
        // }

        setRepoReelTypes(props.data);
        setIsLoading(false);
        setHasError(false);        
    }   


    useEffect(() => {

        // filter = {"user":user, 
        //             "status": [],
        //             "customers": [sessionCustomer.customer], 
        //             "products": [], 
        //             "projects":[],
        //             "reelTypes":[], 
        //             "reels":[], 
        //             "sortBy":0 
        //         }

        getData();
        //fetchData();
    }, []); 


    function valChange(e, val){
        props.setReelTypesFx(repoReelTypes);
        setReelTypes(val);
    }    


    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoReelTypes}
                renderInput={(params) => <TextField {...params} label="Reel Types" size="small" />}
                value={reelTypes}
                onChange={valChange}
                getOptionLabel={(reelType) => reelType.name}
                renderOption={(props, repoReelTypes) => (
                    <Box component="li" {...props} key={repoReelTypes.idGuid}>
                        {repoReelTypes.name}
                    </Box>
                )}
                multiple
            />
        </>
    );
}

