import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import EditIcon from '@mui/icons-material/Edit'; 

export default function PalletPlantsList(props){
    //console.debug("RTS Break CustomerList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/PalletPlantsEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Select Customer", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Name", 
            description:"Plant/Partner Name", 
            flex:4
        },
        {
            field: 'shortName', 
            headerName: "Short Name", 
            description:"Plant/Partner Short Name", 
            flex:3
        },        
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Plant/Partner SAP Id", 
            flex:2
        },
        {
            field: 'city', 
            headerName: "City", 
            description:"Plant/Partner City", 
            flex:3
        },
        {
            field: 'region', 
            headerName: "State", 
            description:"Plant/Partner State", 
            flex:1
        }
    ];
    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pagination 
                pageSize={25} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
