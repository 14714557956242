import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

import WorkItemsList3Body from './WorkItemsList3Body';

export default function WorkItemsList3(props){
    //console.debug("RTS Break WorkItemsList3");

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody] = useState("");
    const [errorTitle] = useState("Validation Error");

    function SessionCustomerToolbar(props) {
        return(
            <GridToolbarContainer {...props}>
                <Box sx={{width:"50%"}}>
                    <GridToolbarFilterButton />
                </Box>
                <Box sx={{width:"50%", display:"flex", justifyContent:"right"}}>
                    <GridToolbarQuickFilter />
                </Box>
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'displayVal', 
            headerName: "", 
            description:"Work Package Item", 
            flex:10,
            renderCell: (cellValues) => {
                    return (
                        <WorkItemsList3Body data={cellValues.row} mode={0} />
                    );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true                       
        },
        {
            field: 'value2', 
            headerName: "Circuit", 
            description:"Asset Circuit", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value3', 
            headerName: "Asset Id", 
            description:"Asset Identifier", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value4', 
            headerName: "Condition Item", 
            description:"Condition Item", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value5', 
            headerName: "Condition Problem", 
            description:"Condition Problem", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value6', 
            headerName: "Condition Action", 
            description:"Condition Action", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value7', 
            headerName: "Condition Comments", 
            description:"Condition Comments", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value8', 
            headerName: "Severity", 
            description:"Severity", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value9', 
            headerName: "Map Link", 
            description:"Google Map Link", 
            sortable:false,
            hideable:false,
            filterable:false,
            disableColumnMenu:true,
            hide:true                       
        },
        {
            field: 'value10', 
            headerName: "Lat/Lng", 
            description:"Latitude/Longitude", 
            sortable:false,
            hideable:false,
            filterable:true,
            disableColumnMenu:true,
            hide:true                       
        },
    ];

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: SessionCustomerToolbar,  }}
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
                getRowHeight={() => 'auto'}
                headerHeight={0}
                sx={{
                    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                       outline: "none !important",
                       border: "none !important"
                    },
                 }}
            />
        </Box>
        </>
    );
}
