import {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import PickupRequestsAdminFilterCustomerLookup from './PickupRequestsAdminFilterCustomerLookup';
import PickupRequestsAdminFilterStatus from './PickupRequestsAdminFilterStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsAdminFilter(props) {
    //console.debug("RTS Break PickupRequestsAdminFilter");

    const {setCurFilter, user} = useSessionContext();

    const [showCustomerModal, setShowCustomerModal] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [status, setStatus] = useState(50);
    const [customer, setCustomer] = useState();

    useEffect(() => {
        setCustomer(props.filter.customer);
        setStatus(props.filter.status);
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'PickupRequestsAdminPage', filter:{user:user, customer:customer, status:status, sortBy:0}});
        props.setFilterFx({user:user, customer:customer, status:status, sortBy:0});
    }


    function setCustomerVal(pValue){
        setCustomer(pValue);
        setShowCustomerModal(false);
        setFormDirty(1);
    };


    if(isLoading){
        return(
            <Loading />
        );
    }
        
    return (
        <>
        <Dialog fullWidth={true} maxWidth="md" open={showCustomerModal} onClose={()=>{setShowCustomerModal(false);}}>
            <DialogTitle>Customer</DialogTitle>
            <DialogContent>
                <Box>
                    <PickupRequestsAdminFilterCustomerLookup customer={customer} setCustomerFx={setCustomerVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowCustomerModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
                <Box id="Customer" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbCustomer"
                        margin="small"
                        fullWidth
                        label="Customer"
                        size="small"
                        value={customer==null?'':customer.name + " (" + customer.idSap + ")"}
                        onClick={setShowCustomerModal}
                    />
                    <Box sx={{ml:1}}>            
                        <Button variant="contained" onClick={setShowCustomerModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>            
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <PickupRequestsAdminFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>                
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>  
        </Grid>
        </>
    );
}

