import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

export default function LoadProjectEditHelp(props) {
    //console.debug('RTS Break LoadProjectEditHelp');

    return (
        <Box>
            <Typography>
				The Reel Details screen gives access to all the information regarding a specific reel.
            </Typography>

            <Typography>
				The Reel summary information panel at the top of the page has some additional data fields
                that are not displayed within the base Reels lists.
            </Typography>

            <Typography>
				Below the Reel Summary are the following panels:
            </Typography>
            <TableContainer sx={{mb:2, mt:1}}>
                <Table>
                    <TableBody>
                        <TableRow sx={{verticalAlign:'top'}}>
                            <TableCell><Typography sx={{fontWeight:'bold'}}>Location History</Typography></TableCell>
                            <TableCell>
                                <Typography>
                                    Shows a point for every recorded location for the
                                    reel.  The points are color coded to indicate the state of 
                                    the reel at the time the locaiton was recorded.
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{verticalAlign:'top'}}>
                            <TableCell><Typography sx={{fontWeight:'bold'}}>Pulls</Typography></TableCell>
                            <TableCell>
                                <Typography>
                                    Shows the history of the consumption of the wire for the reel.
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{verticalAlign:'top'}}>
                            <TableCell><Typography sx={{fontWeight:'bold'}}>Ownership</Typography></TableCell>
                            <TableCell>
                                <Typography>
                                    Shows who has possession of the reel.
                                </Typography>
                            </TableCell>
                        </TableRow>                                                
                        <TableRow sx={{verticalAlign:'top'}}>
                            <TableCell><Typography sx={{fontWeight:'bold'}}>Project Details</Typography></TableCell>
                            <TableCell>
                                <Typography>
                                    Shows a summary of the project that the reel is currently 
                                    associated with.
                                </Typography>
                            </TableCell>
                        </TableRow>                                                
                        <TableRow sx={{verticalAlign:'top'}}>
                            <TableCell><Typography sx={{fontWeight:'bold'}}>Users With Reel Pinned</Typography></TableCell>
                            <TableCell>
                                <Typography>
                                    Shows a listing of every user that has the reel pinned so it 
                                    will show up on their dashboard.
                                </Typography>
                            </TableCell>
                        </TableRow>  
                    </TableBody>
                </Table>
            </TableContainer>            

        </Box>
    );

}
