import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function Loading(props) {
  return (
    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', height:'100%', p:0, m:0}}>
      <CircularProgress />
      <Typography variant="caption">Loading...</Typography>
    </Box>
  );
}
