import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import Error from '../Error';
import UsersEditCustomersAddList from './UsersEditCustomersAddList';
import UsersEditAccessLevel from './UsersEditAccessLevel';
import UsersEditCustomersList from './UsersEditCustomersList';
import PasswordReset from '../PasswordReset';
import UsersEditLicensedByLookup from './UsersEditLicensedByLookup';
import EditStatus from '../EditStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function UsersEdit(props) {
    console.debug("RTS Break UsersEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user,  userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    //const display = props.display;
    const mode = props.mode;
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
    const [accessLevelCust, setAccessLevelCust] = useState(null);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showPasswordErrorModal, setShowPasswordErrorModal] = useState(false);
    const [showLicenseWarningModal, setShowLicenseWarningModal] = useState(false);
    const [showLicensedByList, setShowLicensedByList] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState();
    const [passwordConfirm, setPasswordConfirm] = useState();
    const [passwordToken, setPasswordToken] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [status, setStatus] = useState(1);
    const [adminFlag, setAdminFlag] = useState(0);
    const [reelAdminFlag, setReelAdminFlag] = useState(0);
    const [southwireFlag, setSouthwireFlag] = useState(0);
    const [manufacturerFlag, setManufacturerFlag] = useState(0);
    const [distributorFlag, setDistributorFlag] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [licensedBy, setLicensedBy] = useState(null);
    const [licenseId, setLicenseId] = useState('');
    const [modReelsFlag, setModReelsFlag] = useState(1);
    const [modWorkSetsFlag, setModWorkSetsFlag] = useState(0);
    const [modPalletsFlag, setModPalletsFlag] = useState(0);
    const [modManufacturingFlag, setModManufacturingFlag] = useState(0);
    const [modReelInspectionsFlag, setModReelInspectionsFlag] = useState(0);


    const fetchData = useCallback((idGuid) => {
        let apiURL = apiRoot + "/AppUsers/GetAppUserById/" + idGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);

                setEmail(response.data.email);
                setFirstName(response.data.firstName);
                setLastName(response.data.lastName);
                setPasswordToken(response.data.passwordToken);
                setStatus(response.data.status);
                setAdminFlag(response.data.adminFlag);
                setReelAdminFlag(response.data.reelAdminFlag);
                setSouthwireFlag(response.data.southwireFlag);
                setManufacturerFlag(response.data.manufacturerFlag);
                setDistributorFlag(response.data.distributorFlag);
                setCustomers(response.data.appUserCustomers);
                setLicensedBy(response.data.licensedBy!==null?response.data.licensedBy:'');
                setLicenseId(response.data.licenseId!==null?response.data.licenseId:'');
                setModReelsFlag(response.data.modReelsFlag);
                setModWorkSetsFlag(response.data.modWorkSetsFlag);
                setModPalletsFlag(response.data.modPalletsFlag);
                setModManufacturingFlag(response.data.modManufacturingFlag);
                setModReelInspectionsFlag(response.data.modReelInspectionsFlag);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);

    
    useEffect(() => {
        if(mode === 1){
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }

    }, [mode, fetchData, props.idGuid]);  


    function handleSubmit(e) {
        e.preventDefault();

        if(mode===0){
            let errorCount = 0;

             if(password.length < 8){
                errorCount++;
            }
    
            if(!/[A-Z]/.test(password)){
                errorCount++;
            }
    
            if(!/[a-z]/.test(password)){
                errorCount++;
            }
    
            if(!/[0-9]/.test(password)){
                errorCount++;
            }
    
            if(!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)){
                errorCount++;
            }
    
            if(password !== passwordConfirm){
                errorCount++;
            }
    

            if(errorCount > 0){
                setShowPasswordErrorModal(true);
                return;
            }            
        }

        if(email === null){
            setErrorTitle("Validation Error");
            setErrorBody("User Email is required");
            setShowErrorModal(true);
            return;
        }

        if(licensedBy !== null && licensedBy !== '')
        {
            let apiURL = apiRoot + "/CustomerUsers/GetCustomerLicensedUsers/" + licensedBy.idGuid;
            axios
            .get(
                apiURL,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    let repoLicenseCount = response.data.length;

                    if(repoLicenseCount >= licensedBy.licenseCount){
                        setShowLicenseWarningModal(true);
                    }
                    else{
                        executeSubmit();
                    }
                }
                else{
                    executeSubmit();
                }
            })
            .catch(error => {
                console.log("RTS Data Error", error);
            });
        }
        else{
            setLicenseId(null);
            executeSubmit();
        }

    }; 


    function executeSubmit() {

        let apiObj = {
            userName: email,
            passwordHash: password,
            email: email,
            firstName: firstName,
            lastName: lastName,
            status: status,
            adminFlag: adminFlag,
            reelAdminFlag: reelAdminFlag,
            southwireFlag: southwireFlag,
            manufacturerFlag: manufacturerFlag,
            distributorFlag: distributorFlag,
            licensedBy: licensedBy,
            licenseId: licenseId===''?null:licenseId,
            appUserCustomers: customers,
            modifyUser: user.userName,
            clientUrl: window.location.origin,
            modReelsFlag: modReelsFlag,
            modWorkSetsFlag: modWorkSetsFlag,
            modPalletsFlag: modPalletsFlag,
            modManufacturingFlag: modManufacturingFlag,
            modReelInspectionsFlag: modReelInspectionsFlag
        }

        if(mode===0){
            apiObj.createUser = user.userName;
            apiObj.TOUFlag = 0;

            let apiURL = apiRoot + "/AppUsers/CreateAppUser";
            axios
                .post(
                    apiURL,
                    apiObj, 
                    {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
                )
                .then(response => {
                    history(-1);
                })
                .catch(error => {
                    setErrorTitle("InsertError");
                    setErrorBody("An error has occured.  Please review the console log for details.");
                    console.log("RTS CreateUser Error", error);
                    setShowErrorModal(true);
    
                });

        }
        else{
            let apiURL = apiRoot + "/AppUsers/UpdateAppUser";
            axios
                .put(
                    apiURL,
                    apiObj, 
                    {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
                )
                .then(response => {
                    history(-1);
                })
                .catch(error => {
                    setErrorTitle("Update Error");
                    setErrorBody("An error has occured.  Please review the console log for details.");
                    console.log("RTS UpdateUser Error", error);
                    setShowErrorModal(true);
                });
            }
    }; 


    function setAccessLevel(pCustomer){
        if(customers.filter(c => c.customer.idGuid === pCustomer.idGuid).length !== 0){
            setAccessLevelCust(null);
            setErrorTitle("Already Associated");
            setErrorBody("This user is already associated with this customer");
            setShowCustomerModal(false);
            setShowErrorModal(true);
        }
        else{
            setAccessLevelCust(pCustomer);
            setShowCustomerModal(false);
            setShowAccessLevelModal(true);
        }
    };


    function addCustomer(pAccessLevel, pCompanyLink, pMarkAccess){
        let curCustomers = customers.map(c => Object.assign({}, c));
        let newCustomer = {customer: accessLevelCust, accessLevel:pAccessLevel, companyLink:pCompanyLink, markAccess:pMarkAccess};
        curCustomers.push(newCustomer);

        setCustomers(curCustomers);
        setShowAccessLevelModal(false);
        props.setFormDirtyFx(1);
    };


    function removeCustomer(pCustomer){
        let newCustList = [];

        customers.forEach(cc => {
            if(cc.customer.idGuid !== pCustomer.customer.idGuid)
            {
                newCustList.push(cc)
            }
        });

        setCustomers(newCustList);
        props.setFormDirtyFx(1);
    };


    function setLicensedByCustomer(pLicensedBy){
        setLicensedBy(pLicensedBy);
        setShowLicensedByList(false);
        props.setFormDirtyFx(1);
        
        if(customers.filter(c => c.customer.idGuid === pLicensedBy.idGuid).length === 0){
            setAccessLevelCust(pLicensedBy);
            setShowCustomerModal(false);
            setShowAccessLevelModal(true);
        }        
    };


    function getToken(){
        if(mode===1){
            if(passwordToken==null){
                return -1;
            }
            else{
                return passwordToken;
            }
        }
        else{
            return -1;
        }
    };


    if(hasError){
        return(
            <Error />
        );
    };


    if(isLoading){
        return(
            <Loading />
        );
    };    


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <div>
                    {errorBody}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showCustomerModal} onClose={()=>{setShowCustomerModal(false);}}>
            <DialogTitle>Add Customer</DialogTitle>
            <DialogContent>
                <Box style={{minHeight:"400px"}}>
                    <UsersEditCustomersAddList setAccessLevelFx={setAccessLevel} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowCustomerModal(false);}} sx={{mt:2}}>Cancel</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="sm" open={showAccessLevelModal} onClose={()=>{setShowAccessLevelModal(false);}}>
            <DialogTitle>Set Access Level</DialogTitle>
            <DialogContent>
                <Box>
                    <UsersEditAccessLevel data={accessLevelCust} addCustomerFx={addCustomer} showAccessLevelModalFx={setShowAccessLevelModal} />
                </Box>
            </DialogContent>
        </Dialog>           
        <Dialog open={showPasswordModal} onClose={()=>{setShowPasswordModal(false);}}>
            <DialogContent>
                <Box>
                    <PasswordReset mode={1} userName={email} passwordToken={getToken()} setShowPasswordModalFx={setShowPasswordModal} />
                </Box>
            </DialogContent>
        </Dialog>
        <Dialog open={showPasswordErrorModal} onClose={()=>{setShowPasswordErrorModal(false);}}>
            <DialogTitle>Password Not Validated</DialogTitle>
            <DialogContent>
                <div>
                    <Typography>The password must meet the following criteria:</Typography>
                    <ul>
                        <li>At least 8 characters long.</li>
                        <li>At least 1 capital (uppercase) letter.</li>
                        <li>At least 1 lower case letter.</li>
                        <li>At least 1 number.</li>
                        <li>At least 1 special character.</li>
                        <li>Password and Password Confirm must match.</li>
                    </ul>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowPasswordErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showLicenseWarningModal} onClose={()=>{setShowLicenseWarningModal(false);}}>
            <DialogTitle>Customer License Issue</DialogTitle>
            <DialogContent>
                <DialogContentText>This customer has exceeded their available licenses.  Your changes will still be saved and the customer should be contacted.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowLicenseWarningModal(false); executeSubmit();}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showLicensedByList} fullWidth={true} maxWidth="md" onClose={()=>{setShowLicensedByList(false);}}>
            <DialogTitle>Select Customer</DialogTitle>
            <DialogContent>
                <UsersEditLicensedByLookup licensedBy={licensedBy} setLicensedByFx={setLicensedByCustomer} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowLicensedByList(false);}}>Close</Button>
            </DialogActions>
        </Dialog>                            
        <Box>
            {mode===1
            ?
            <Box sx={{textAlign:"right"}}>
                <Button variant="contained" onClick={e =>{setShowPasswordModal(true);}}>Reset Password</Button>
            </Box>
            :
            ''
            }

            <form id="userForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbEmail"
                    margin="normal"
                    fullWidth
                    label="User Email Address"
                    size="small"
                    required
                    value={email}
                    onChange={(e) => {props.setFormDirtyFx(1); setEmail(e.target.value);}}
                />
                {mode===0
                ?
                <>
                <TextField
                    id="tbPassword"
                    margin="normal"
                    fullWidth
                    label="User Password"
                    size="small"
                    required
                    value={password}
                    onChange={(e) => {props.setFormDirtyFx(1); setPassword(e.target.value);}}
                />
                <TextField
                    id="tbPasswordConfirm"
                    margin="normal"
                    fullWidth
                    label="Confirm User Password"
                    size="small"
                    required
                    value={passwordConfirm}
                    onChange={(e) => {props.setFormDirtyFx(1); setPasswordConfirm(e.target.value);}}
                />
                <Box display="flex" sx={{textAlign:'center', mb:2}}>
                    <Box sx={{textAlign:'left', display:'inline'}}>
                        <Typography variant="caption" sx={{display:'block', fontWeight:'bold'}}>Password Requirements:</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- Minimum 8 characters</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- At least 1 uppercase character</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- At least 1 lower case letter.</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- At least 1 number.</Typography>
                        <Typography variant="caption" sx={{display:'block'}}>- At least 1 special character.</Typography>
                    </Box>
                </Box> 
                </>
                :
                ""
                }      
                <TextField
                    id="tbFirstName"
                    margin="normal"
                    fullWidth
                    label="First Name"
                    size="small"
                    value={firstName}
                    onChange={(e) => {props.setFormDirtyFx(1); setFirstName(e.target.value);}}
                />
                <TextField
                    id="tbLastName"
                    margin="normal"
                    fullWidth
                    label="Last Name"
                    size="small"
                    value={lastName}
                    onChange={(e) => {props.setFormDirtyFx(1); setLastName(e.target.value);}}
                />
                <EditStatus status={status} setStatusFx={setStatus} setFormDirtyFx={props.setFormDirtyFx} /> 
                <Typography variant="h6" sx={{mt:2}}>User Modules</Typography>
                <Box sx={{display:"flex"}}>
                    <FormControl component="fieldset" sx={{flexGrow:1}}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={modReelsFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setModReelsFlag(1):setModReelsFlag(0);}} 
                                            name="cbxModReels" 
                                    />
                                }
                                label="Reel Tracking"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={modWorkSetsFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setModWorkSetsFlag(1):setModWorkSetsFlag(0);}} 
                                            name="cbxModWorkSets" 
                                    />
                                }
                                label="Work Tracking"
                            />                            
                            <FormControlLabel
                                control={
                                    <Checkbox checked={modPalletsFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setModPalletsFlag(1):setModPalletsFlag(0);}} 
                                            name="cbxModPallets" 
                                    />
                                }
                                label="Pallet Tracking"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={modManufacturingFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setModManufacturingFlag(1):setModManufacturingFlag(0);}} 
                                            name="cbxModManufacturing" 
                                    />
                                }
                                label="Manufacturing"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={modReelInspectionsFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setModReelInspectionsFlag(1):setModReelInspectionsFlag(0);}} 
                                            name="cbxModReelInspections" 
                                    />
                                }
                                label="Reel Inspections"
                            />                                                        
                        </FormGroup> 
                    </FormControl>
                </Box>

                <Typography variant="h6" sx={{mt:2}}>User Roles</Typography>
                <Box sx={{display:"flex"}}>
                    <FormControl component="fieldset" sx={{flexGrow:1}}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={adminFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setAdminFlag(1):setAdminFlag(0);}} 
                                            name="cbxAdminRole" 
                                    />
                                }
                                label="System Admin"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={reelAdminFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setReelAdminFlag(1):setReelAdminFlag(0);}} 
                                            name="cbxReelAdminRole" 
                                    />
                                }
                                label="Reel Admin"
                            />                            
                            <FormControlLabel
                                control={
                                    <Checkbox checked={southwireFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setSouthwireFlag(1):setSouthwireFlag(0);}} 
                                            name="cbxSouthwireRole" 
                                    />
                                }
                                label="Cross Company User"
                            />                            
                        </FormGroup> 
                    </FormControl>
                </Box>
                <Typography variant="h6" sx={{mt:2}}>User Tags</Typography>
                <Box sx={{display:"flex"}}>
                    <FormControl component="fieldset" sx={{flexGrow:1}}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={manufacturerFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setManufacturerFlag(1):setManufacturerFlag(0);}} 
                                            name="cbxManuRole" 
                                    />
                                }
                                label="Manufacturer Rep"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={distributorFlag} 
                                            onChange={(e) => {props.setFormDirtyFx(1); e.target.checked?setDistributorFlag(1):setDistributorFlag(0);}} 
                                            name="cbxDistributorRole" 
                                    />
                                }
                                label="Distributor"
                            />
                        </FormGroup> 
                    </FormControl>
                </Box>
                <Typography variant="h6" sx={{mt:2}}>User License</Typography>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbLicensedBy"
                        margin="normal"
                        fullWidth
                        label="Customer License"
                        size="small"
                        value={licensedBy==null?'':licensedBy.name + " (" + licensedBy.idSap + ")"}
                        onClick={setShowLicensedByList}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button variant="contained" onClick={setShowLicensedByList}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                <TextField
                    id="tbLicneseId"
                    margin="normal"
                    fullWidth
                    label="License Id"
                    size="small"
                    value={licenseId}
                    onChange={(e) => {props.setFormDirtyFx(1); setLicenseId(e.target.value);}}
                />
                </Box>
                </Box>                
                <Box sx={{display:"flex", mt:3, mb:1}}>
                    <Box sx={{flexGrow:1, textAlign:'left'}}>
                        <Typography variant="h6">User Customers</Typography>
                    </Box>
                    <Box sx={{textAlign:'right', flexGrow:1}}>
                        {adminFlag===1
                        ?
                        <Button variant="contained" disabled>Add Customers</Button>
                        :
                        <Button variant="contained" onClick={e => {setShowCustomerModal(true);}}>Add Customers</Button>
                        }                        
                    </Box>
                </Box>
                {adminFlag===1
                ?
                <Box sx={{textAlign:"center"}}>
                    <Typography variant="h6">System Admin</Typography>
                </Box>
                :
                <Box>
                    <UsersEditCustomersList data={customers} removeCustomerFx={removeCustomer} />
                </Box>
                }
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit">Save</Button>
                </Box>
            </form>
        </Box>
        </>
    );
}

