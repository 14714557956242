import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import ProductsFilterHierarchy from './ProductsFilterHierarchy';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductsFilter(props){
    //console.debug("RTS Break ProductsFilter");

    const {setCurFilter} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [hierarchy, setHierarchy] = useState(null);
    

    useEffect(() => {
        setHierarchy(props.filter.hierarchy);        
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'ProductsPage', filter:{hierarchy:hierarchy}});
        props.setFilterFx({hierarchy:hierarchy});
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <ProductsFilterHierarchy hierarchy={hierarchy} setHierarchyFx={setHierarchy} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
