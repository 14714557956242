import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import LoadListListItem from '../LoadList/LoadListListItem';
import LoadListDetailsMap from './LoadListDetailsMap';
import LoadListDetailsPullList from './LoadListDetailsPullList';
import LoadListDetailsPinList from './LoadListDetailsPinList';
import LoadListDetailsPullListDetail from './LoadListDetailsPullListDetail';
import LoadListDetailsProject from './LoadListDetailsProject';
import LoadListDetailsCrew from './LoadListDetailsCrew';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListDetails(props){
    //console.debug("RTS Break LoadListDetails");

    const history = useNavigate();

    const {sessionCustomer} = useSessionContext();

    const [showPullDetailModal, setShowPullDetailModal] = useState(false);
    const [pullDetail, setPullDetail] = useState({});

    function showPullDetail(args){
        setPullDetail(args);
        setShowPullDetailModal(true);
    }


    function getAssignedToLinkDisplay(){
        if(props.data.assignedToLink != null){
            if(props.data.assignedToLink.linkCustomer != null){
                return props.data.assignedToLink.name + " (" + props.data.assignedToLink.linkCustomer.idSap + ")";
            }
            else{
                return props.data.assignedToLink.name;
            }
        }
        else{
            return '';
        }
    }


    function getContractToLinkDisplay(){
        if(props.data.contractToLink != null){
            if(props.data.contractToLink.linkCustomer != null){
                return props.data.contractToLink.name + " (" + props.data.contractToLink.linkCustomer.idSap + ")";
            }
            else{
                return props.data.contractToLink.name;
            }
        }
        else{
            return '';
        }
    }

    
    return (
        <>
        <Dialog fullWidth={true} maxWidth="sm" open={showPullDetailModal} onClose={()=>{setShowPullDetailModal(false);}}>
            <DialogTitle>Pull Detail</DialogTitle>
            <DialogContent>
                <LoadListDetailsPullListDetail data={pullDetail} />
            </DialogContent>
            <DialogActions>
                <Button type="button" 
                    title="Ok" 
                    className="rtsButton rtsDarkBackground"
                    onClick={e => {setShowPullDetailModal(false);}}>
                        Close
                </Button>
            </DialogActions>
        </Dialog>
        <Grid container>
            <Grid item xs={12}>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true} />
            </Grid>

            <Grid item xs={12} md={6} sx={{pr:{xs:0, md:1}, pt:2}}>
                <Stack>
                    <Typography variant="h6">Location History</Typography>
                    <Container>
                        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', height:30}}>
                            <Box sx={{display:'flex', mr:1}}>
                                <Box className="rtsMarkerSmallInitLegend" sx={{mt:.6}}></Box>
                                <Typography>In Transit</Typography>
                            </Box>
                            <Box sx={{display:'flex', mr:1}}>
                                <Box className="rtsMarkerSmallOnSiteLegend" sx={{mt:.6}}></Box>
                                <Typography>On Site</Typography>
                            </Box>
                            <Box sx={{display:'flex', mr:1}}>
                                <Box className="rtsMarkerSmallWipLegend" sx={{mt:.6}}></Box>
                                <Typography>Work In Progress</Typography>
                            </Box>
                            <Box sx={{display:'flex', mr:1}}>
                                <Box className="rtsMarkerSmallRetLegend " sx={{mt:.6}}></Box>
                                <Typography>Returning</Typography>
                            </Box>
                            <Box sx={{display:'flex'}}>
                                <Box className="rtsMarkerSmallCurLegend" sx={{mt:.6}}></Box>
                                <Typography>Current</Typography>
                            </Box>
                        </Box>
                    </Container>
                </Stack>
                <LoadListDetailsMap data={props.data} user={props.user} />
            </Grid>

            <Grid item xs={12} md={6} sx={{pl:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{mb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Pulls</Typography>
                    </Grid>
                    {sessionCustomer.accessLevel > 25 && props.data.status === 1
                    ?
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Button 
                            type="button" 
                            variant="contained"
                            onClick={()=>{history("/LoadListDetailsManagePulls", {state: {idGuid:props.data.idGuid}});}} 
                        >
                            Manage Pulls
                        </Button>
                    </Grid>
                    :
                    ""
                    }
                </Grid>
                <LoadListDetailsPullList data={props.data} user={props.user} showPullDetailFx={showPullDetail} />
            </Grid>

            <Grid item xs={12} md={6} sx={{pr:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{pb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Ownership</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        {
                            sessionCustomer.accessLevel > 25 
                            && props.data.status === 1 
                            && (sessionCustomer.customer.idGuid === props.data.soldToCustomer.idGuid 
                                || sessionCustomer.customer.idGuid === props.data.assignedToCustomer.idGuid)
                            ?
                            <Grid item sx={{textAlign:'right'}}>
                                <Button 
                                    type="button" 
                                    variant="contained"
                                    onClick={()=>{history("/LoadListDetailsOwnership", {state: {idGuid:props.data.idGuid}});}} 
                                >
                                    Change Ownership
                                </Button>
                            </Grid>
                            :
                            ""
                        }
                    </Grid>
                </Grid>
                <Box sx={{border:'1px solid #e2e8f0', borderRadius:'5px', p:1}}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Sold To:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{props.data.soldToCustomer.name} ({props.data.soldToCustomer.idSap})</Typography>
                    </Box>                
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Assigned To:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{getAssignedToLinkDisplay()}</Typography>
                    </Box>                
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Contracted To:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{getContractToLinkDisplay()}</Typography>
                    </Box>
                </Box>                
            </Grid>

            <Grid item xs={12} md={6} sx={{pl:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{mb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Project Details</Typography>
                    </Grid>
                    {sessionCustomer.accessLevel > 25
                    ?
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Button 
                            type="button" 
                            variant="contained"
                            onClick={()=>{history("/LoadListDetailsProject", {state: {idGuid:props.data.idGuid}});}} 
                            >
                            Change Project
                        </Button>
                    </Grid>
                    :
                    ""
                    }
                </Grid>                
               <LoadListDetailsProject data={props.data} user={props.user} />
            </Grid>

            <Grid item xs={12} md={6} sx={{pl:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{mb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Users With Reel Pinned</Typography>
                    </Grid>
                    {sessionCustomer.accessLevel > 1
                    ?
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Button 
                            type="button" 
                            variant="contained"
                            onClick={()=>{history("/LoadListDetailsAddPins", {state: {idGuid:props.data.idGuid}});}} 
                            >
                            Add Pin
                        </Button>
                    </Grid>
                    :
                    ""
                    }
                </Grid>                
               <LoadListDetailsPinList data={props.data} user={props.user} />
            </Grid> 

            <Grid item xs={12} md={6} sx={{pl:{xs:0, md:1}, pt:2}}>
                <Grid container sx={{mb:1}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Crew</Typography>
                    </Grid>
                    {sessionCustomer.accessLevel > 25 && props.data.status === 1
                    ?
                    <Grid item xs={6} sx={{textAlign:'right'}}>
                        <Button 
                            type="button" 
                            variant="contained"
                            onClick={()=>{history("/LoadListSetCrew", {state: {idGuid:props.data.idGuid}});}} 
                            >
                            Set Crew
                        </Button>
                    </Grid>
                    :
                    ""
                    }
                </Grid>                
               <LoadListDetailsCrew data={props.data.crew} user={props.user} />
            </Grid>                                       
        </Grid>
        </>
    )
}
