import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function FilterCustomerProjectList(props) {
    //console.debug("RTS Break LoadListFilterProjectList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    
    const [projects, setProjects] = useState(props.projects===null?[]:props.projects);
    const [repoProjects, setRepoProjects] = useState(props.data===null?[]:props.data);

    
    function valChange(e, val){
        props.setProjectsFx(val);
        setProjects(val);
    }


    return(
        <>
            <Autocomplete
                options={repoProjects}
                renderInput={(params) => <TextField {...params} label="Projects" size="small" />}
                value={projects}
                onChange={valChange}
                getOptionLabel={(project) => project?.name}
                renderOption={(props, repoProjects) => (
                    <Box component="li" {...props} key={repoProjects?.idGuid}>
                        {repoProjects?.name}
                    </Box>
                )}
                multiple
            />
        </>
    );
}


