import {useState,  useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function LocatorFilterTrackList(props) {
    //console.debug("RTS Break LocatorFilterTrackList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    // This must come after the repoStatus is set because of the find.
    const [tracking, setTracking] = useState(props.tracking);
    const repoTrackings = [{id:0, label:"Both"},
                        {id:1, label:"Tracked"},
                        {id:2, label:"Untracked"}];


    function valChange(e, val){
        props.setTrackingFx(val.id);
        setTracking(val.id);
    }    

    return(
        <>
            <Autocomplete
                options={repoTrackings}
                renderInput={(params) => <TextField {...params} label="Tracking Status" size="small" />}
                value={repoTrackings.find(v => v.id==tracking)}
                onChange={valChange}
                getOptionLabel={(repoTrackings) => repoTrackings.label}
                renderOption={(props, repoTrackings) => (
                    <Box component="li" {...props} key={repoTrackings.id}>
                        {repoTrackings.label}
                    </Box>
                )}
            />
        </>
    );
}

