import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Loading from '../components/Loading';
import Error from '../components/Error';
import EditBack from '../components/EditBack';
import LoadListDetailsManagePullsEdit from '../components/LoadListDetailsManagePulls/LoadListDetailsManagePullsEdit';
import HelpOverview from '../components/LoadListDetailsManagePulls/HelpOverview';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useSessionContext } from '../contexts/SessionContext';


export default function LoadListDetailsManagePullsPage( props ) {
    console.debug("RTS Break LoadListDetailsManagePullsPage");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const location = useLocation();
    const idGuid = location.state.idGuid;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [helpOverview, setHelpOverview] = useState(false);

    const [formDirty, setFormDirty] = useState(0);
    const [data, setData] = useState([]);

   
    const fetchData = useCallback((filter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/LoadList/GetLoadListDetails";
        axios
        .post(
            apiURL,
            filter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                setIsLoading(false);
                setHasError(false);
            }
            else{

                setIsLoading(false);
                setHasError(true);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        props.setIsModalFx(true);
        fetchData({IdGuid:idGuid, Customer:sessionCustomer.customer, User:user});
    }, [props, fetchData, idGuid, sessionCustomer, user]);


    function rejectRefresh(){
        fetchData({IdGuid:idGuid, Customer:sessionCustomer.customer, User:user});
    }

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <>
        <Dialog open={helpOverview} onClose={()=>{setHelpOverview(false);}}>
            <DialogTitle>Pull Management Help</DialogTitle>
            <DialogContent>
                <HelpOverview />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setHelpOverview(false);}}>Close</Button>
            </DialogActions>
        </Dialog>         
        <Container sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty}/>
                {formDirty===0
                ?
                    <Typography variant="h5" >Manage Pulls</Typography>
                :
                    <Typography variant="h5" sx={{fontStyle:'italic'}}>Edit Reel Ownership *</Typography>
                }
                <Link onClick={() => {setHelpOverview(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                    <HelpOutlineIcon variant="h5" bold sx={{ml:1, mt:.5}}  />
                </Link>                
            </Box>
            <LoadListDetailsManagePullsEdit data={data} rejectRefreshFx={rejectRefresh} formDirty={formDirty} setFormDirtyFx={setFormDirty} />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty}/>
            </Box>
        </Container>
        </>
    );
}

