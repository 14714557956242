import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function TrackersEditActive(props) {
    //console.debug("RTS Break TrackersEditActive");

    const [isActive, setIsActive] = useState(props.isActive);
    const [repoIsActive] = useState([{value:0, label:"Not Activated"}, {value:1, label:"Activated"}]);


    function valChange(e, val){
        setIsActive(val.value);
        props.setIsActiveFx(val.value);
        props.setFormDirtyFx(1);
    }

 
    return(
        <>
            <Autocomplete
                options={repoIsActive}
                renderInput={(params) => <TextField {...params} label="Active" margin='normal' required size="small" />}
                value={repoIsActive.find(v => v.value===isActive)}
                onChange={valChange}
                getOptionLabel={(repoIsActive) => repoIsActive.label + ' (' + repoIsActive.value + ')'}
                renderOption={(props, repoIsActive) => (
                    <Box component="li" {...props} key={repoIsActive.value}>
                        {repoIsActive.label + ' (' + repoIsActive.value + ')'}
                    </Box>
                )}
            />
        </>
    );
}
