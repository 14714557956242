import axios from 'axios';
import {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function WorkItemsEdit1StatusList(props) {
    //console.debug("RTS Break WorkItemsEdit1StatusList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [status, setStatus] = useState(props.status);
    const [repoStatus, setRepoStatus] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    function fetchData(){
        let apiURL = apiRoot + "/WorkItemStatus/GetWorkItemStatusByGroup/Group1";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoStatus(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }

    
    useEffect(() => {
        fetchData();
    }); 


    function valChange(e, val){
        props.setStatusFx(val);
        setStatus(val);
    }


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoStatus}
                renderInput={(params) => <TextField {...params} label="Status" required margin="normal" size="small" />}
                value={status}
                onChange={valChange}
                required
                getOptionLabel={(repoValue) => repoValue!=null?repoValue!==''?repoValue.name:'':''}
                renderOption={(props, repoStatus) => (
                    <Box component="li" {...props} key={repoStatus.idGuid}>
                        {repoStatus.name}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.idGuid === value.idGuid}
            />
        </>
    );
}

