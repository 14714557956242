import axios from 'axios';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import LoadListListItem from "../LoadList/LoadListListItem";
import LoadListSetCrewLookup from './LoadListSetCrewLookup';
import LoadListSetCrewDetails from './LoadListSetCrewDetails';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListSetCrewEdit(props) {
    console.debug('RTS Break LoadListSetCrewEdit');

    const history = useNavigate();

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showCrewModal, setShowCrewModal] = useState(false);

    const [crew, setCrew] = useState();


    useEffect(() => {
        setCrew(props.data.crew);
        setIsLoading(false);
    }, [props.data.crew]); 


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = props.data;
        apiObj.crew = crew;

        let apiURL = apiRoot + "/Loads/UpdateLoad/" + apiObj.idGuid;
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            window.scrollTo(0,0);
            history(-1);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateLoad Error", error);
            setShowErrorModal(true);
        });
    } 


    function getCrewDisplay(){
        if(crew != null){
            return crew.name;
        }
        else{
            return '';
        }
    }


    function setCrewVal(pVal){
        setCrew(pVal);
        setShowCrewModal(false);
        props.setFormDirtyFx(1);
    }    


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth="md" open={showCrewModal} onClose={()=>{setShowCrewModal(false);}}>
                <DialogTitle>Crews</DialogTitle>
                <DialogContent>
                    <Box>
                        <LoadListSetCrewLookup crew={crew} setCrewFx={setCrewVal} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowCrewModal(false);}} sx={{mr:2}}>Close</Button>
                </DialogActions>
            </Dialog>
            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <form onSubmit={handleSubmit} style={{width:'400px'}}>
                    <Box id="Crew" sx={{display:'flex', alignItems:'center'}}>
                        <TextField
                            id="tbCrew"
                            margin="normal"
                            fullWidth
                            label="Crew"
                            size="small"
                            value={getCrewDisplay()}
                            onClick={setShowCrewModal}
                        />
                        <Box sx={{ml:1, mt:1}}>            
                            <Button variant="contained" onClick={setShowCrewModal}>
                                <SearchIcon sx={{fontSize:'2em'}} />
                            </Button>
                        </Box>            
                    </Box>
                    <Box sx={{mt:2}}>
                        <LoadListSetCrewDetails data={crew} />
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit"  disabled={props.formDirty===1?false:true} variant="contained" sx={{mr:2}}>Set Crew</Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Box>
    );

}
