import React from 'react'
import axios from 'axios';
import {useState} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditRegion from '../EditRegion';
import EditCountry from '../EditCountry';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CompanyProfileEdit(props) {
    //console.debug("RTS Break CompanyProfileEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;
 
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [name] = useState(mode===1?data.name:'');
    const [shortName] = useState(mode===1?data.shortName:'');
    const [customerType] = useState(mode===1?data.customerType:'CUSTOMER');
    const [parentCustomer] = useState(mode===1?data.parentCustomer:null);
    const [mailAddress1, setMailAddress1] = useState(mode===1?data.mailAddress1!==null?data.mailAddress1:'':'');
    const [mailAddress2, setMailAddress2] = useState(mode===1?data.mailAddress2!==null?data.mailAddress2:'':'');
    const [mailCity, setMailCity] = useState(mode===1?data.mailCity!==null?data.mailCity:'':'');
    const [mailRegion, setMailRegion] = useState(mode===1?data.mailRegion:null);
    const [mailZip, setMailZip] = useState(mode===1?data.mailZip!==null?data.mailZip:'':'');
    const [mailCountry, setMailCountry] = useState(mode===1?data.mailCountry:null);
    const [phoneNumber, setPhoneNumber] = useState(mode===1?data.phoneNumber!==null?data.phoneNumber:'':'');
    const [deferralEmail, setDeferralEmail] = useState(mode===1?data.deferralEmail!==null?data.deferralEmail:'':'');
    const [showUntracked] = useState(mode===1?data.showUntracked:0);
    const [servicesContractFlag] = useState(mode===1?data.servicesContractFlag:0);
    const [servicesContractLimit] = useState(mode===1?data.servicesContractLimit:0);
    const [idSap] = useState(mode===1?data.idSap!==null?data.idSap:'':'');
    const [salesGroup] = useState(mode===1?data.salesGroup!==null?data.salesGroup:'':'');
    const [salesOffice] = useState(mode===1?data.salesOffice!==null?data.salesOffice:'':'');
    const [salesOrg] = useState(mode===1?data.salesOrg!==null?data.salesOrg:'':'');
    const [distributionChannel] = useState(mode===1?data.distributionChannel!==null?data.distributionChannel:'':'');
    const [division] = useState(mode===1?data.division!==null?data.division:'':'');
    const [customerLinks] = useState(mode===1?data.customerLinks:[]);
    const [licenseCount] = useState(mode===1?data.licenseCount:0);



    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            Name: name===''?null:name,
            ShortName: shortName===''?null:shortName,
            CustomerType: customerType,
            ParentCustomer: parentCustomer,
            MailAddress1: mailAddress1===''?null:mailAddress1,
            MailAddress2: mailAddress2===''?null:mailAddress2,
            MailCity: mailCity===''?null:mailCity,
            MailRegion: mailRegion,
            MailZip: mailZip===''?null:mailZip,
            MailCountry: mailCountry,
            PhoneNumber: phoneNumber===''?null:phoneNumber,
            DeferralEmail: deferralEmail===''?null:deferralEmail,
            ShowUntracked: showUntracked,
            ServicesContractFlag: servicesContractFlag,
            ServicesContractLimit: servicesContractLimit,
            LicenseCount: licenseCount, 
            SalesGroup: salesGroup===''?null:salesGroup,
            SalesOffice: salesOffice===''?null:salesOffice,
            SalesOrg: salesOrg===''?null:salesOrg,
            DistributionChannel: distributionChannel===''?null:distributionChannel,
            Division: division===''?null:division,
            ModifyUser: user.userName,
            CustomerLinks: customerLinks
        }

        let apiURL = apiRoot + "/Customers/UpdateCustomer/" + idGuid;
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history("/Dashboard");
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateCustomer Error", error);
            setShowErrorModal(true);
        });
    } 

    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box sx={{width:'100%'}}>
            <form id="adminCustomerForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>                
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    value={name}
                    disabled={true}
                />
                <TextField
                    id="tbDeferralEmail"
                    margin="normal"
                    fullWidth
                    label="Deferral Notice Email"
                    size="small"
                    value={deferralEmail}
                    onChange={(e) => {props.setFormDirtyFx(1); setDeferralEmail(e.target.value);}}
                />   
                <Typography variant="h6" sx={{mt:2, mb:2}}>Address</Typography>
                <TextField
                    id="tbAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={mailAddress1}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailAddress1(e.target.value);}}
                />
                <TextField
                    id="tbAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={mailAddress2}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailAddress2(e.target.value);}}
                />
                <TextField
                    id="tbCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={mailCity}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailCity(e.target.value);}}
                />
                <Grid container>
                    <Grid item xs={6} sx={{pr:1}}>
                        <EditRegion region={mailRegion} setRegionFx={setMailRegion} />
                    </Grid>
                    <Grid item xs={6} sx={{pl:1}}>
                        <TextField
                            id="tbZip"
                            margin="normal"
                            fullWidth
                            label="Zip Code"
                            size="small"
                            value={mailZip}
                            onChange={(e) => {props.setFormDirtyFx(1); setMailZip(e.target.value);}}
                        />
                    </Grid>
                </Grid>
                <EditCountry country={mailCountry} setCountryFx={setMailCountry} setFormDirtyFx={props.setFormDirtyFx} />
                <TextField
                    id="tbPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    size="small"
                    value={phoneNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setPhoneNumber(e.target.value);}}
                /> 

                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit" sx={{mr:2}}>Save</Button>
                 </Box>
                </Box>
                </Box>
            </form>
        </Box>
        </>
    );
}

