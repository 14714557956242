import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function PickupRequestsAdminEditStatusList(props) {
    //console.debug("RTS Break PickupRequestAdminEditStatusList");

    const [status, setStatus] = useState(props.status);
    const [repoStatus] = useState([{value:50, label:"Work in Progress"}, {value:75, label:"Submitted"}, {value:100, label:"Completed"}]);


    function valChange(e, val){
        setStatus(val.value);
        props.setStatusFx(val.value);
    }

 
    return(
        <>
            <Autocomplete
                options={repoStatus}
                renderInput={(params) => <TextField {...params} label="Status" required size="small" />}
                value={repoStatus.find(v => v.value===status)}
                onChange={valChange}
                getOptionLabel={(repoStatus) => repoStatus.label}
                renderOption={(props, repoStatus) => (
                    <Box component="li" {...props} key={repoStatus.value}>
                        {repoStatus.label}
                    </Box>
                )}
                disabled={true}
            />
        </>
    );
}
