import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function LoadsFilterCustomerVisible(props) {
    //console.debug("RTS Break LoadsFilterCustomerVisible");

    const [visible, setVisible] = useState(props.visible==null?-1:props.visible);
    const [repoVisible] = useState([{value:-1, label:"All"}, {value:1, label:"Visible"}, {value:0, label:"Hidden"}]);


    function valChange(e, val){
        setVisible(val.value);
        props.setCustomerVisibleFx(val.value);
        props.setFormDirtyFx(1);
    }

 
    return(
        <>
            <Autocomplete
                options={repoVisible}
                renderInput={(params) => <TextField {...params} label="Customer Visible"  size="small" />}
                value={repoVisible.find(v => v.value===visible)}
                onChange={valChange}
                getOptionLabel={(curOption) => curOption.label}
                disableClearable={true}
                renderOption={(props, curOption) => (
                    <Box component="li" {...props} key={curOption.value}>
                        {curOption.label}
                    </Box>
                )}
            />
        </>
    );
}
