import {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { format, parseISO } from 'date-fns';

import ReelInspectionView from '../ReelInspections/ReelInspectionView';

export default function LoadListListItemReelInspection(props){
    //console.debug("RTS Break LoadListListItemReelInspection");

    const[showInspectionModal, setShowInspectionModal] = useState(false);

    function handleClick(e){
        setShowInspectionModal(true);
    }

    return (
    <>
        <Dialog open={showInspectionModal} fullWidth={true} maxWidth="md" onClose={()=>{setShowInspectionModal(false);}}>
            <DialogTitle>Reel Inspection</DialogTitle>
            <DialogContent>
                <ReelInspectionView data={props.data}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowInspectionModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        {
            props.data.reelInspection !== null
            ?
                <Link onClick={(event) => {handleClick(event);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Inspection:</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>{format(parseISO(props.data.createDate), 'MM-dd-yyyy')}</Typography>
                    </Box>
                </Link>
            :
                ''
        }
    </>
    )
}

