import {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import Image from 'mui-image';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import Copyright from '../Copyright';
import logo from '../../pages/logo-Southwire.png';
import Loading from '../Loading';

import { useSessionContext } from '../../contexts/SessionContext';


const ForgotPassword = (props) => {
    //console.debug("RTS Break ForgotPassword");

    const userRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState("");
    const {setUserAuthorized, apiRoot } = useSessionContext();
    const [userId, setUserId] = useState("");
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() =>{
        userRef.current.focus();
    }, [])

    function handleSubmit(e) {
        e.preventDefault();

        if(userId === ''){
            setErrorText('User Email Address is required');
            return;
        }

        let apiObj = {
            UserId: userId,
            UrlPath: window.location.origin
        }        

        let apiURL = apiRoot + "/Authentication/RequestPasswordReset";
        axios
            .put(
                apiURL,
                apiObj, 
                {headers: {'Content-Type': 'application/json'}, httpsAgent: {rejectUnauthorized: false}}
            )
            .then(res => {
                setIsLoading(false);
                setShowMessage(true);                
            })
            .catch(error => {
                setIsLoading(false);
                setUserAuthorized(false);
                setErrorText("Unable to validate user");
                console.log("Authentification Error", error);
            });
    } 

    function closeConfirm(){
        setShowMessage(false); 
        props.setShowForgotPasswordModalFx(false);
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
    <>
    <Dialog open={showMessage} onClose={()=>{setShowMessage(false);}}>
        <DialogTitle>Request Submitted</DialogTitle>
        <DialogContent>
            <DialogContentText>An email with a password reset link has been sent to the email address associated with the account</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={e => {closeConfirm();}}>Close</Button>
        </DialogActions>
    </Dialog>  
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Image src={logo} style={{width: "65%"}} />
            <Typography component="h1" variant="h5">
                Reel Tracking System
            </Typography>
            <Typography component="h1" variant="h5">
                Password Reset
            </Typography>            
            <Box>
                <Typography sx={{mt:1}}>Enter the email address associated with the account</Typography>
            </Box>            
            <Box>
                {errorText===""? "": <Typography color="red" variant="h6" sx={{mt:1}}>{errorText}</Typography>}
            </Box> 
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={userId}
                    onChange={(e) => {setUserId(e.target.value);}}
                    ref={userRef}
                    sx={{minWidth:'350px'}}
                />
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="button" sx={{mr:2}} onClick={handleSubmit}>Request</Button>
                    <Button variant="contained"  type="button" sx={{mr:2}} onClick={() => {props.setShowForgotPasswordModalFx(false)}}>Cancel</Button>
                </Box>
            </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
    </>
    );
}

export default ForgotPassword;
