import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useLocation} from 'react-router-dom';


export default function PalletssHeader(props){
    //console.debug("RTS Break PalletActionHeader");

    const location = useLocation();
    const data = location.state.data;
    //const mode = location.state.mode;

    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{pt:2}}>
            <Box>
                <Typography variant='h5'>Plant/Partner</Typography>
                <Box>
                    <Typography sx={{fontWeight:'bold'}}>{data.name}</Typography>
                </Box>
                <Box>
                    <Typography>{data.shortName}</Typography>
                </Box>
                <Box>
                    <Typography>{data.address1}</Typography>
                </Box>
                <Box>
                    <Typography>{data.address2}</Typography>
                </Box>
                <Box display="flex">
                    <Box>
                        <Typography sx={{pr:1}}>{data.city}{data.city===null?'':','}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{pr:1}}>{data.region}</Typography>
                    </Box>
                    <Box>
                        <Typography>{data.zip}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
