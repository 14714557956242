import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import LoadListListItemLinkDetails from "./LoadListListItemLinkDetails";

export default function LoadListMapListItem( props ) {
    const remains = props.data.amt - props.data.pullSum;
    const daysOld = props.data.shipDateDays;

    return(
        <Paper elevation={3} sx={{p:1, mb:1}}>
                <Box>
                    <Box>
                        {props.data.tracker!=null
                        ?
                        <Box>
                            <Typography className="rtsTrackerMarker">{props.childIdx+1}</Typography>
                        </Box>
                        :
                        <Box>
                            <Typography className="rtsMarker">{props.childIdx+1}</Typography>
                        </Box>
                        }
                        <Box>
                            <Typography>{props.data.reel.serialNumber}</Typography>
                        </Box>
                        {props.data.tracker!=null
                        ?                        
                        <Box>
                            <Typography variant="caption">{props.data.tracker.esn}</Typography>
                        </Box>
                        :
                        ""
                        }                        
                    </Box>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold', pr:'2px'}}>Product:</Typography>
                        <Typography variant="body2">{props.data.product.name}</Typography>
                    </Box>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold', pr:'2px'}}>Remaining:</Typography>
                        <Typography variant="body2">{remains.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                    </Box>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold', pr:'2px'}}>Days Old:</Typography>
                        <Typography variant="body2">{daysOld.toLocaleString()}</Typography>
                    </Box> 
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <LoadListListItemLinkDetails idGuid={props.data.idGuid} />
                    </Box>                                       
                </Box>
 
        </Paper>
    );
}
