import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpOverview(props) {
    //console.debug('RTS Break HelpOverview');

    return (
        <Box>
            <Typography>
                The Add Pull screen allows you to document the removal of wire from a reel.  This screen 
                is used to enter pulls that are not recorded using AutoPull.  When using this method you 
                can either enter a length that was pulled, or you can wiegh the reel and the system will
                calculate the amount that was pulled off.
            </Typography>
        </Box>
    );

}
