import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function CrewsLoadList(props){
    console.debug("RTS Break CrewsLoadList");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoData, setRepoData] = useState([]);

    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/Loads/GetLoadsByCrewId/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoData(response.data);
                setIsLoading(false);
            }
            else{
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData(props.idGuid);
    }, [props, fetchData]); 


    const columns = [
        {
            field: 'soldToCustomerName', 
            headerName: "Sold To Customer", 
            description:"Sold To Customer Name", 
            flex:4,
            valueGetter: (value, row) => {
                return row.soldToCustomerName + " (" + row.soldToCustomerIdSap + ")";
            },
        },
        {
            field: 'assignedToLinkName', 
            headerName: "Assigned To", 
            description:"Assigned To Company Name", 
            flex:4,
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                if(row.assignedToLinkLinkCustomerIdSap != null){
                    return value + " (" + row.assignedToLinkLinkCustomerIdSap + ")";
                }
                else{
                    return value;
                }
            },
        },
        {
            field: 'contractToLinkName', 
            headerName: "Contract To", 
            description:"Contract To Company Name", 
            flex:4,
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                if(row.contractToLinkLinkCustomerIdSap != null){
                    return value + row.contractToLinkLinkCustomerIdSap==null?'':" (" + row.contractToLinkLinkCustomerIdSap + ")";
                }
                else{
                    return value;
                }
            },
        },                        
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Date Shipped", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null;
                }

                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
        },
        {
            field: 'returnedDate', 
            headerName: "Return Date", 
            description:"Date Returned", 
            flex:2,           
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null;
                }

                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },
        },
        {
            field: 'reelSerialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:4,
        },         


    ];


    function getVisibilityModel(){
        if(smallForm){
            return {soldToCustomerName:false, reelSerialNumber:false}
        }
        else{
            return {soldToCustomerName:false, reelSerialNumber:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return (
        <>
        <Box sx={{textAlign:'left', mt:1}}>
            <Typography variant="h6" sx={{display:'inline'}}>Crew:</Typography>
            <Typography inline variant="h6" sx={{display:'inline', ml:1}}>{props.display}</Typography>
        </Box>        
        <Box sx={{width:'100%', minHeight:'200px', mt:1}}>
            <DataGridPro
                autoHeight
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={repoData} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 25 } },
                    density: getDensity() 

                }}                
            />
        </Box>
        </>
    );
}
