import { useState } from 'react';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CrewMembersAddCheck from './CrewMembersAddCheck';
import CrewMembersEditAdd from './CrewMembersEditAdd';
import CrewMembersEditCreate from './CrewMembersEditCreate';
import CrewMembersRemove from './CrewMembersRemove';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CrewMembersEdit(props) {
    console.debug("RTS Break CrewMembersEdit");

    const {sessionCustomer} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const location = useLocation();

    const [mode, setMode] = useState(location.state.mode);
    const [user, setUser] = useState(location.state.data);
    const [userIdValid, setUserIdValid] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle] = useState("Validation Error");


    function userIdCheck(args){
        if(args.exist){
            // This is an existing email address.
            let curIdGuid = sessionCustomer.customer.idGuid;
            if(args.data.appUserCustomers.filter(c => c.customer.idGuid === curIdGuid).length > 0){
                setErrorBody("User is already associated with this customer");
                setShowErrorModal(true);
                return;
            }

            setUser(args.data);
            setUserIdValid(true);
            setMode(1);
        }
        else{
            if(args.data == null){
                // There is an error with the email address.
                setErrorBody(args.errorBody)
                setUserIdValid(false);
                setShowErrorModal(true);
            }
            else{
                // This is a new email address.
                setUser(args.data);
                setUserIdValid(true);
                setMode(0);
                props.setFormDirtyFx(1);
            } 
        }
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Box maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>      
        <Box>
            {mode===99
            ?
            <CrewMembersRemove data={user} />
            :
            <>
                <Box sx={{mb:3}}>
                    <CrewMembersAddCheck 
                        userIdCheckFx={userIdCheck} 
                        setUserIdValidFx={setUserIdValid}
                        formDirty={props.formDirty} 
                        setFormDirtyFx={props.setFormDirtyFx} 
                    />
                </Box>
                {userIdValid===true
                    ?
                        mode===1
                        ?
                        <CrewMembersEditAdd data={user}  formDirty={props.formDirty} setFormDirtyFx={props.setFormDirtyFx} />
                        :
                        <CrewMembersEditCreate data={user}  formDirty={props.formDirty} setFormDirtyFx={props.setFormDirtyFx} />
                    :
                        ''
                }
            </>
            }
        </Box>
        </Box>
        </Box>
        </>
    );

}
