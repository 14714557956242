import axios from 'axios';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import LoadListListItem from "../LoadList/LoadListListItem";
import LoadListDetailsOwnershipAssignedToLookup from './LoadListDetailsOwnershipAssignedToLookup';
import LoadListDetailsOwnershipContractToLookup from './LoadListDetailsOwnershipContractToLookup';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadListDetailsOwnershipEdit(props) {
    console.debug('RTS Break LoadListDetailsOwnershipEdit');

    const history = useNavigate();

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showAssignedToModal, setShowAssignedToModal] = useState(false);
    const [showContractToModal, setShowContractToModal] = useState(false);

    const [assignedToLink, setAssignedToLink] = useState();
    const [contractToLink, setContractToLink] = useState();


    useEffect(() => {
        setAssignedToLink(props.data.assignedToLink);
        setContractToLink(props.data.contractToLink)
        setIsLoading(false);
    }, [props.data.assignedToLink, props.data.contractToLink]); 


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = props.data;
        apiObj.assignedToLink = assignedToLink;
        apiObj.contractToLink = contractToLink;

        let apiURL = apiRoot + "/Loads/UpdateLoad/" + apiObj.idGuid;
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            window.scrollTo(0,0);
            history(-1);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateLoad Error", error);
            setShowErrorModal(true);
        });
    } 


    function checkAssignedvsSoldTo(){
        if(assignedToLink != null){
            if(props.data.soldToCustomer.idGuid != props.data.assignedToCustomer.idGuid){
                return true;
            }
            else{
                return false;
            }
        }
        else{
            return false;
        }

    }    

    function getAssignedToLinkDisplay(){
        if(assignedToLink != null){
            if(assignedToLink.linkCustomer!=null){
                 return assignedToLink.name + " (" + assignedToLink.linkCustomer.idSap + ")";
            }
            else{
                return assignedToLink.name;
            }

        }
        else{
            return '';
        }
    }


    function getContractToLinkDisplay(){
        if(contractToLink != null){
            if(contractToLink.linkCustomer!=null){
                 return contractToLink.name + " (" + contractToLink.linkCustomer.idSap + ")";
            }
            else{
                return contractToLink.name;
            }

        }
        else{
            return '';
        }
    }


    function setAssignedToLinkVal(pVal){
        setAssignedToLink(pVal);
        setShowAssignedToModal(false);
        props.setFormDirtyFx(1);
    }


    function setContractToLinkVal(pVal){
        setContractToLink(pVal);
        setShowContractToModal(false);
        props.setFormDirtyFx(1);
    }    


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth="md" open={showAssignedToModal} onClose={()=>{setShowAssignedToModal(false);}}>
                <DialogTitle>Assign To</DialogTitle>
                <DialogContent>
                    <Box>
                        <LoadListDetailsOwnershipAssignedToLookup assignedToLink={assignedToLink} setAssignedToLinkFx={setAssignedToLinkVal} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowAssignedToModal(false);}} sx={{mr:2}}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} maxWidth="md" open={showContractToModal} onClose={()=>{setShowContractToModal(false);}}>
                <DialogTitle>Contract To</DialogTitle>
                <DialogContent>
                    <Box>
                        <LoadListDetailsOwnershipContractToLookup contractToLink={contractToLink} setContractToLinkFx={setContractToLinkVal} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowContractToModal(false);}} sx={{mr:2}}>Close</Button>
                </DialogActions>
            </Dialog>            
            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit} style={{width:'400px'}}>
                    <Box>
                        <Typography variant='h6'>Sold To: {props.data.soldToCustomer.name}</Typography>
                    </Box>
                    {checkAssignedvsSoldTo()!=true
                    ?
                    <Box id="AssignedToLink" sx={{display:'flex', alignItems:'center'}}>
                        <TextField
                            id="tbAssignedTo"
                            margin="normal"
                            fullWidth
                            label="Assigned To"
                            size="small"
                            value={getAssignedToLinkDisplay()}
                            onClick={setShowAssignedToModal}
                        />
                        <Box sx={{ml:1, mt:1}}>            
                            <Button variant="contained" onClick={setShowAssignedToModal}>
                                <SearchIcon sx={{fontSize:'2em'}} />
                            </Button>
                        </Box>            
                    </Box>
                    :
                    <Box id="AssignedToLinkDis" sx={{display:'flex', alignItems:'center'}}>
                        <TextField
                            id="tbAssignedToDis"
                            margin="normal"
                            fullWidth
                            label="Assigned To"
                            size="small"
                            value={getAssignedToLinkDisplay()}
                            disabled={true}
                        />
                    </Box>
                    }                    
                    <Box id="ContractToLink" sx={{display:'flex', alignItems:'center'}}>
                        <TextField
                            id="tbContractTo"
                            margin="normal"
                            fullWidth
                            label="Contract To"
                            size="small"
                            value={getContractToLinkDisplay()}
                            onClick={setShowContractToModal}
                        />
                        <Box sx={{ml:1, mt:1}}>            
                            <Button variant="contained" onClick={setShowContractToModal}>
                                <SearchIcon sx={{fontSize:'2em'}} />
                            </Button>
                        </Box>            
                    </Box>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit"  disabled={props.formDirty===1?false:true} variant="contained" sx={{mr:2}}>Change Ownership</Button>
                        </Box>
                    </Box>
                </form>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
