import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';


export default function LoadListListItemLinkMark(props){
    //console.debug("RTS Break LoadListListItemLinkMark");

    const history = useNavigate();

    function handleClick(e){
        history("/LoadListMark", {state: {idGuid:props.idGuid}});
    };


    if(props.status === 1){
        return (
            <Link onClick={handleClick}  sx={{textDecoration:'none', cursor:'pointer'}}>
                <Box sx={{display:'flex'}}>
                    <LocalShippingOutlinedIcon />
                    <Typography sx={{fontWeight:'bold', pl:'2px'}}>Mark for Pickup</Typography>
                </Box>
            </Link>
        )
    }
    else{
        return (
            <Link onClick={handleClick} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Box sx={{display:'flex'}}>
                    <LocalShippingOutlinedIcon sx={{color:"red"}} />
                    <ClearOutlinedIcon sx={{color:"red", verticalAlign:"super", fontSize:'.90em', ml:-1, fontWeight:'bold'}} />
                    <Typography color="red" sx={{fontWeight:'bold', pl:'2px'}}>Remove Pickup Mark</Typography>
                </Box>
            </Link>
        )
    }
}
