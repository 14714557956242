import Box from '@mui/material/Box';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsBatteryLevelList(props){
    //console.debug("RTS Break LoadsBatteryLevelList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const columns = [
        {
            field: 'reelSerial', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.reel.serialNumber
            },
        },
        {
            field: 'reelType', 
            headerName: "Reel Type", 
            description:"Reel Type", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.reelType.name
            },
        },  
        {
            field: 'shipDate', 
            headerName: "Date Shipped", 
            description:"Reel Ship Date", 
            flex:1,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }              
        },
        {
            field: 'esn', 
            headerName: "Tracker", 
            description:"Tracker ESN", 
            flex:4,
            valueGetter: (cellValues) => {
                return cellValues.row.tracker.esn
            },
        },
        {
            field: 'batteryLevel', 
            headerName: "Battery Level", 
            description:"Tracker Battery Level", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.tracker.batteryLevel?cellValues.row.tracker.batteryLevel.toLocaleString() + "%":'Unknown'
            },
        },
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro autoHeight pagination components={{Toolbar: GridToolbar}} pageSize={50} rows={props.data} columns={columns} getRowId={row => row.idGuid} />
        </Box>
    );
}
