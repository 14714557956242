import axios from 'axios';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import EditCancel from '../EditCancel';
import WorkItemsEdit2StatusList from './WorkItemsEdit2StatusList';
import WorkItemsList2Body from './WorkItemsList2Body';

import { useSessionContext } from '../../contexts/SessionContext';

export default function WorkItemsEdit1(props) {
    //console.debug("RTS Break WorkItemsEdit1");

    const history = useNavigate();

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [status, setStatus] = useState(props.mode===1?props.data.workItemStatus:'');
    const [hours, setHours] = useState(props.mode===1?props.data.hours:0);
    const [notes, setNotes] = useState(props.mode===1?props.data.notes:'');

    function handleSubmit(e) {
        e.preventDefault();

        // This is done differently for this case because of 
        // all the value columns.  I did not want to have to set all of them.
        let apiObj = props.data;
        apiObj.CompletedBy = user.userName;
        apiObj.Hours = hours;
        apiObj.Notes = notes;
        apiObj.WorkItemStatus = status;
        apiObj.ModifyUser = user.userName;


        let apiURL = apiRoot + "/WorkItems/CompleteWorkItem/" + props.data.idGuid;
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            history(-1);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateWorkSet Error", error);
            setShowErrorModal(true);
        });
    } 

    // function handleCancel(e){
    //     props.setShowEditModalFx(false);
    // }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <div>
                    {errorBody}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>            
        <Box sx={{mt:2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <WorkItemsList2Body data={props.data} mode={1} />
            <Box sx={{mt:2}}  maxWidth="sm">
                <form id="WorkItemsForm_1" onSubmit={handleSubmit}>
                    <WorkItemsEdit2StatusList status={status} setStatusFx={setStatus} />
                    <TextField
                        id="tbHours"
                        margin="normal"
                        fullWidth
                        label="Hours"
                        size="small"
                        required
                        value={hours}
                        onChange={(e) => {setHours(e.target.value);}}
                    />
                    <TextField
                        id="tbNotes"
                        margin="normal"
                        fullWidth
                        label="Notes"
                        size="small"
                        required
                        value={notes}
                        onChange={(e) => {setNotes(e.target.value);}}
                    />
                    <Box sx={{textAlign:'center', mt:3}}>
                        <Button variant="contained" type="submit" sx={{mr:2}}>Complete</Button>
                        <EditCancel />
                    </Box>
                </form>
            </Box>
        </Box>
        </>
    );
}


