import axios from 'axios';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import LoadListListItem from '../LoadList/LoadListListItem';
import LoadListMarkEmptyScrap from './LoadListMarkEmptyScrap';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadMarkEdit(props) {
    //console.debug("RTS Break LoadMarkEdit");

    const history = useNavigate();

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");

    const [emptyScrap, setEmptyScrap] = useState(props.data.pullSum<Math.floor(props.data.amt)?1:0);
    const [markWeight, setMarkWeight] = useState(calcWeight());

    const data = props.data;
    
    function calcWeight(){
        let prodWeight = 0;
        let perFootWeight = 0;

        perFootWeight = props.data.netWeight/props.data.amt;

        if(props.data.pullSum < Math.floor(props.data.amt)){
            prodWeight = Math.ceil((props.data.amt - props.data.pullSum) * perFootWeight);
        }

        return props.data.tareWeight + prodWeight; 
    }


    function setWeightValue(val){
        if(val === 0){
            setMarkWeight(props.data.tareWeight);
        }

        setEmptyScrap(val);
    }


    function handleSubmit(e) {
        e.preventDefault();
 
        // let markDate;
        // let status;
        // let curMarkWeight;
        // let curEmptyScrap;
        // let markUser;

        let apiObj = data;

        if(data.status === 25){
            apiObj.Status = 1;
            apiObj.MarkDate = null;
            apiObj.MarkWeight = null;
            apiObj.MarkEmptyScrap = null;
            apiObj.MarkUser = null;
        }
        else{
            apiObj.Status = 25;
            apiObj.MarkDate = new Date();
            apiObj.MarkWeight = markWeight;
            apiObj.MarkEmptyScrap = emptyScrap;
            apiObj.MarkUser = user;
        }

        let apiURL = apiRoot + "/Loads/UpdateLoad/" + data.idGuid;
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            window.scrollTo(0,0);
            history(-1);
        })
        .catch(error => {
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateLoad Error", error);
            setShowErrorModal(true);
        });
    } 

    return (
        <Box>
            <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
                <DialogTitle>Validation Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorBody}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
                </DialogActions>
            </Dialog>        

            <Box>
                <LoadListListItem data={props.data} user={props.user} hideLinks={true}/>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit} style={{width:'400px'}}>
                    {props.data.status===25
                    ?
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Typography variant="h4">Remove Pickup Mark on this Reel?</Typography>
                    </Box>
                    :
                    <>
                        <Typography variant="h6" sx={{mb:2, mt:1}}>Enter Reel Details</Typography>
                        <LoadListMarkEmptyScrap  setEmptyScrapFx={setWeightValue} emptyScrap={emptyScrap} />
                        <TextField
                            id="tbWeight"
                            margin="normal"
                            fullWidth
                            label="Weight"
                            type="number"
                            size="small"
                            value={markWeight}
                            onChange={(e) => {setMarkWeight(e.target.value);}}
                            disabled={emptyScrap===1?false:true}
                        />
                    </>
                    }

                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', pt:2}}>
                        <Box xs={{display:'flex'}}>
                            <Button type="submit" variant="contained" sx={{mr:2}}>
                                {props.data.status === 25
                                ?
                                    <span>Remove Mark</span>
                                :
                                    <span>Mark for Pickup</span>
                                }
                            </Button>
                        </Box>
                    </Box>
                </form>
                </LocalizationProvider>
            </Box>
        </Box>
    );

}
