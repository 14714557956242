import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography>
                The Loads page allows for the addition and maintenance of Loads within the system.
            </Typography>
            <Typography sx={{mt:1}}>
                To edit an existing record simply find the Load in the list and click on the 
                pencil icon.   
            </Typography>
            <Typography sx={{mt:1}}>
                To create a new Load click the New Load button in the upper right hand corner of the 
                screen.   
            </Typography>
            <Typography sx={{mt:1}}>
                To filter the list you use the quick filter drop downs at the top of the 
                window, enter search text into the search bar at the top right of the 
                table or choose the filter option from the menu for more advanced filtering capabilities.   
            </Typography>            
        </Box>
    );

}
