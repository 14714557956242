import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ManuNavButDash( props ){
    //console.debug("RTS Break ManuNavButDash");

    const history = useNavigate();
    const {setCurFilter} = useSessionContext();

    function handleDashboardClick(e) {
        setCurFilter(null);
        props.setOpenDrawerFx(false);
        history("/");
    }

    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="WorkSets" onClick={handleDashboardClick}>
                <ListItemText primary="Reel Trackers" />
            </ListItem>
            </>
        )
    }

    return (
        <>
        <Button
            key='WorkSets'
            onClick={handleDashboardClick}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
        >
            Reel Trackers
        </Button>
        </>
    );
}



