import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpProduct(props) {
    //console.debug('RTS Break HelpProduct');

    return (
        <Box>
            <Typography>
                The Product section of the Reel shows information regarding the product that was loaded 
                onto the reel when delivered as well as the current known status of that product.
            </Typography>
            <Typography sx={{mt:1}}>
                The text fields displayed within the Product section of the Reel include:
            </Typography>
            <table>
                <tbody>
                <tr>
                    <td>
                        Product Name
                    </td>
                    <td>
                        The product name will either display the Product Alias defined within a Customer Part
                        or the Southwire product name.  The Product Name is enlarged for easy identification of the 
                        product on the reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Customer Part Number
                    </td>
                    <td>
                        The customer specific part number.
                    </td>
                </tr>
                <tr>
                    <td>
                        Purchased
                    </td>
                    <td>
                        The amount of product on the reel when delivered.
                    </td>
                </tr>
                <tr>
                    <td>
                        Purchase Order
                    </td>
                    <td>
                        The customer Purchase Order Number.
                    </td>
                </tr>
                <tr>
                    <td>
                        Project
                    </td>
                    <td>
                        If the reel is included in a project the name of the project will be displayed.
                    </td>
                </tr>
                <tr>
                    <td>
                        Location
                    </td>
                    <td>
                        The last known location of the reel.  This field is a link allowing the user to 
                        open another window to further explore the location.
                    </td>
                </tr>
                <tr>
                    <td>
                        Ship Date
                    </td>
                    <td>
                        The date the reel was shipped from the manufacturing facility.
                    </td>
                </tr>
                <tr>
                    <td>
                        Length Index
                    </td>
                    <td>
                        The Length Index is an indicator of the amount of product that remains on the reel according
                        to the original purchased amount less the sum of the pulls recorded against the reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Age Index
                    </td>
                    <td>
                        The Age Index is an indicator of how old product on a reel is and is calculated based on
                        the Current Date less the Ship Date.
                    </td>
                </tr>
                </tbody>
            </table>
        </Box>
    );

}
