import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpReel(props) {
    //console.debug('RTS Break HelpReel');

    return (
        <Box>
            <Typography>
                The actions a user can perform on a reel.
            </Typography>
            <table>
                <tbody>
                <tr>
                    <td>
                        Record A Pull
                    </td>
                    <td>
                        Add a new Pull of product for this reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Mark for Pickup
                    </td>
                    <td>
                        When a user marks a reel for pickup the ability to record pulls or set 
                        the location of the reel is removed.  By marking a reel for pickup a userAuthToken
                        with permissions can then add the reel to a Pickup Request.
                    </td>
                </tr>
                <tr>
                    <td>
                        Show Details
                    </td>
                    <td>
                        Opens a window showing more information about he reel including all locations
                        for the reel that the system is aware of as well as all pulls recorded against
                        the reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        Set Location
                    </td>
                    <td>
                        This action is only available for reels that do not have trackers associated with 
                        them in the system.  This will open a window allowing the end user to manually set
                        the location of the reel.
                    </td>
                </tr>
                </tbody>
            </table>
        </Box>
    );

}
