import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import TrackersFilterStatus from './TrackersFilterStatus';
import TrackersFilterIsActive from './TrackersFilterIsActive';
import TrackersFilterModelList from './TrackersFilterModelList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function TrackersFilter(props){
    //console.debug("RTS Break TrackersFilter");

    const {setCurFilter} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [status, setStatus] = useState();
    const [model, setModel] = useState();
    const [isActive, setIsActive] = useState();
    

    useEffect(() => {
        setStatus(props.filter.status);
        setModel(props.filter.model);
        setIsActive(props.filter.isActive);
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'TrackersPage', filter:{model:model, status:status, isActive:isActive}});
        props.setFilterFx({model:model, status:status, isActive:isActive});
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <TrackersFilterModelList model={model} setModelFx={setModel} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <TrackersFilterIsActive isActive={isActive} setIsActiveFx={setIsActive} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <TrackersFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
