import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import LoadsFilterCustomerLookup from './LoadsFilterCustomerLookup';
import LoadsFilterProductLookup from './LoadsFilterProductLookup';
import LoadsFilterStatus from './LoadsFilterStatus';
import LoadsFilterCustomerVisible from './LoadsFilterCustomerVisible'
import LoadsFilterReelTypeLookup from './LoadsFilterReelTypeLookup';
import LoadsFilterMaxRecords from './LoadsFilterMaxRecords';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsFilter(props){
    //console.debug("RTS Break LoadsFilter");

    const {setCurFilter} = useSessionContext();

    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [showReelTypeModal, setShowReelTypeModal] = useState(false);

    const [customer, setCustomer] = useState();
    const [status, setStatus] = useState();
    const [customerVisible, setCustomerVisible] = useState();
    const [reelType, setReelType] = useState();
    const [product, setProduct] = useState();
    const [maxRecords, setMaxRecords] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0);
    

    useEffect(() => {
        setCustomer(props.filter.customer);
        setProduct(props.filter.product);
        setStatus(props.filter.status);
        setReelType(props.filter.reelType);
        setCustomerVisible(props.filter.customerVisibleFlag);
        setMaxRecords(props.filter.maxRecords);
        setIsLoading(false);
    }, []);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'LoadsPage', filter:{customer:customer, product:product, reelType:reelType, customerVisibleFlag:customerVisible, status:status, maxRecords:maxRecords}});
        props.setFilterFx({customer:customer, product:product, reelType:reelType, customerVisibleFlag:customerVisible, status:status, maxRecords:maxRecords});
    }


    function setCustomerVal(pValue){
        setCustomer(pValue);
        setShowCustomerModal(false);
        setFormDirty(1);
    };

    
    function setProductVal(pValue){
        setProduct(pValue);
        setShowProductModal(false);
        setFormDirty(1);
    };

    
    function setReelTypeVal(pValue){
        setReelType(pValue);
        setShowReelTypeModal(false);
        setFormDirty(1);
    };

    
    
    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <>
        <Dialog fullWidth={true} maxWidth="md" open={showCustomerModal} onClose={()=>{setShowCustomerModal(false);}}>
            <DialogTitle>Customer</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsFilterCustomerLookup customer={customer} setValueFx={setCustomerVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowCustomerModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showProductModal} onClose={()=>{setShowProductModal(false);}}>
            <DialogTitle>Product</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsFilterProductLookup product={product} setProductFx={setProductVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowProductModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={showReelTypeModal} onClose={()=>{setShowReelTypeModal(false);}}>
            <DialogTitle>Reel Type</DialogTitle>
            <DialogContent>
                <Box>
                    <LoadsFilterReelTypeLookup reelType={reelType} setReelTypeFx={setReelTypeVal} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowReelTypeModal(false);}} sx={{mr:2}}>Close</Button>
            </DialogActions>
        </Dialog>                   
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Box id="Customer" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbCustomer"
                        margin="small"
                        fullWidth
                        label="Customer"
                        size="small"
                        value={customer==null?'':customer.name + " (" + customer.idSap + ")"}
                        onClick={setShowCustomerModal}
                    />
                    <Box sx={{ml:1}}>            
                        <Button variant="contained" onClick={setShowCustomerModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>            
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box id="Product" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbProduct"
                        margin="small"
                        fullWidth
                        label="Product"
                        size="small"
                        value={product==null?'':product.name + " (" + product.idSap + ")"}
                        onClick={setShowProductModal}
                    />
                    <Box sx={{ml:1}}>            
                        <Button variant="contained" onClick={setShowProductModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box id="ReelType" sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbReelType"
                        margin="small"
                        fullWidth
                        label="Reel Type"
                        size="small"
                        value={reelType==null?'':reelType.name + " (" + reelType.idSap + ")"}
                        onClick={setShowReelTypeModal}
                    />
                    <Box sx={{ml:1}}>            
                        <Button variant="contained" onClick={setShowReelTypeModal}>
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <LoadsFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <LoadsFilterCustomerVisible customerVisible={customerVisible} setCustomerVisibleFx={setCustomerVisible} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <LoadsFilterMaxRecords maxRecords={maxRecords} setMaxRecordsFx={setMaxRecords} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
        </>
    );
}
