import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';

export default function LoadListDetailsPinList(props){
    //console.debug('RTS Break LoadListDetailsPinList');

    const columns = [
        {field:"pinUser",
            headerName:"User",
            description:"User With Load Pinned",
            flex:12,
            valueGetter: (value, row) => {
                return (
                    row.appUser.firstName + ' ' + row.appUser.lastName
                )
            },
        }
    ];

    return (
        <Box sx={{height:363}}>
            <DataGridPro 
                rows={props.data.loadPins} 
                columns={columns} 
                getRowId={row => row.appUser.userName} 
            />
        </Box>
    );
}
