import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function ProductAliasFilterType(props) {
    //console.debug("RTS Break ProductAliasFilterType");

    const [type, setType] = useState(props.type==null?1:props.type);
    const [repoTypes] = useState([{value:-1, label:"All"}, {value:0, label:"Standard"}, {value:1, label:"Wildcard"}]);


    function valChange(e, val){
        setType(val.value);
        props.setTypeFx(val.value);
        props.setFormDirtyFx(1);
    }

 
    return(
        <>
            <Autocomplete
                options={repoTypes}
                renderInput={(params) => <TextField {...params} label="Type"  size="small" />}
                value={repoTypes.find(v => v.value===type)}
                onChange={valChange}
                getOptionLabel={(repoTypes) => repoTypes.label}
                disableClearable={true}
                renderOption={(props, repoTypes) => (
                    <Box component="li" {...props} key={repoTypes.value}>
                        {repoTypes.label}
                    </Box>
                )}
            />
        </>
    );
}
