import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import EngineeringIcon from '@mui/icons-material/Engineering';

export default function LoadListListItemLinkSetCrew(props){
    //console.debug("RTS Break LoadListListItemLinkSetCrew");

    const history = useNavigate();

    function handleClick(e){
        history("/LoadListSetCrew", {state: {idGuid:props.idGuid}});
    };

    return(
        <Link onClick={handleClick} sx={{textDecoration:'none', cursor:'pointer'}}>
            <Box sx={{display:'flex'}}>
                <EngineeringIcon />
                <Typography sx={{fontWeight:'bold', pl:'2px'}}>Set Crew</Typography>
            </Box>
        </Link>
    )
}
