import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductsFilterHierarchy(props) {
    //console.debug("RTS Break ProductsFilterHierarchy");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [hierarchy, setHierarchy] = useState(props.hierarchy==null?1:props.hierarchy);
    const [repoHierarchy, setRepoHierarchy] = useState([]);


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/Products/GetProductHierarchyLookup";

        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoHierarchy(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        setHierarchy(props.hierarchy);
        fetchData();
    }, [props, fetchData]); 


    function valChange(e, val){
        setHierarchy(val);
        props.setHierarchyFx(val);
        props.setFormDirtyFx(1);
    }

    
    if(hasError){
        return(
            <Container className="fullScreen d-flex justify-content-center align-items-center">
                <Container style={{textAlign:"center"}}>
                    <Container>
                        <h3 style={{color:"red"}}>An error has occured</h3>
                    </Container>
                    <Container>
                        <span>Please review the browser console for details</span>                
                    </Container>
                </Container>
            </Container>
        );
    }


    if(isLoading){
        return(
            <Container className="d-flex justify-content-center align-items-center">
                <Container className="spinner-border" role="status">
                    <span className="sr-only">Loading Data...</span>
                </Container>
            </Container>
        );
    }

 
    return(
        <>
            <Autocomplete
                options={repoHierarchy}
                renderInput={(params) => <TextField {...params} label="Hierarchy"  size="small" />}
                value={hierarchy}
                onChange={valChange}
            />
        </>
    );
}
