import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function ReelInspectionsEditTypeList(props) {
    //console.debug("RTS Break ReelIsnepctionsEditTypeList");

    const [inspType, setInspType] = useState(props.inspType);
    const [repoInspType] = useState([
        {value:0, label:"Damage Checklist"}, 
        {value:100, label:"Final Checklist"},
    ]);


    function valChange(e, val){
        setInspType(val.value);
        props.setInspTypeFx(val.value);
    }

 
    return(
        <>
            <Autocomplete
                options={repoInspType}
                renderInput={(params) => <TextField {...params} label="Type" margin='normal' required size="small" />}
                value={repoInspType.find(v => v.value===inspType)}
                onChange={valChange}
                getOptionLabel={(repoInspType) => repoInspType.label}
                renderOption={(props, repoInspType) => (
                    <Box component="li" {...props} key={repoInspType.value}>
                        {repoInspType.label}
                    </Box>
                )}
            />
        </>
    );
}
