import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CustomerReelsEditReelTypeList(props) {
    //console.debug("RTS Break CustomerReelsEditReelTypeList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [reelType, setReelType] = useState(props.reelType);
    const [repoReelTypes, setRepoReelTypes] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    function fetchData(){
        let apiURL = apiRoot + "/ReelTypes/GetAllReelTypes";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoReelTypes(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }

    useEffect(() => {
        fetchData();
    }, []); 


    function valChange(e, val){
        setReelType(val);
        props.setReelTypeFx(val);
    }

 
    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoReelTypes}
                renderInput={(params) => <TextField {...params} label="Reel Type" margin="normal" required size="small" />}
                value={reelType}
                onChange={valChange}
                getOptionLabel={(repoValue) => repoValue.name + ' (' + repoValue.idSap + ')'}
                renderOption={(props, repoReelTypes) => (
                    <Box component="li" {...props} key={repoReelTypes.idGuid}>
                        {repoReelTypes.name + ' (' + repoReelTypes.idSap + ')'}
                    </Box>
                )}
            />
        </>
    );
}

