import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function LoadsEditUomList(props) {
    //console.debug("RTS Break LoadsEditUomList");

    const [uom, setUom] = useState(props.uom);
    const [repoUom] = useState([
        {value:"ft", label:"Feet"},
        {value:"m", label:"Meters"},
        {value:"lb", label:"Pounds"}
    ]);


    function valChange(e, val){
        setUom(val.value);
        props.setUomFx(val.value);
    }

 
    return(
        <>
            <Autocomplete
                options={repoUom}
                renderInput={(params) => <TextField {...params} label="Order UOM" margin='normal' required size="small" />}
                value={repoUom.find(v => v.value===uom)}
                onChange={valChange}
                getOptionLabel={(repoUom) => repoUom.label}
                renderOption={(props, repoUom) => (
                    <Box component="li" {...props} key={repoUom.value}>
                        {repoUom.label}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
            />
        </>
    );
}
