import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function CustomersEditType(props) {
    //console.debug("RTS Break CustomersEditType");

    const [customerType, setCustomerType] = useState(props.customerType);
    const [repoTypes] = useState([{value:"CUSTOMER", label:"Customer"}
                                ,{value:"SHIPTO", label:"Ship To Address"}
                                ,{value:"SUB", label:"Channel Customer"}
                                ,{value:"VIRTUAL", label:"Virtual/Link Customer"}
                            ]);


    function valChange(e, val){
        setCustomerType(val.value);
        props.setCustomerTypeFx(val.value);
    }

 
    return(
        <>
            <Autocomplete
                options={repoTypes}
                renderInput={(params) => <TextField {...params} label="Customer Type" autoComplete='new-password' margin='normal' required size="small" />}
                value={repoTypes.find(v => v.value===customerType)}
                onChange={valChange}
                getOptionLabel={(repoTypes) => repoTypes.label}
                renderOption={(props, repoTypes) => (
                    <Box component="li" {...props} key={repoTypes.value}>
                        {repoTypes.label}
                    </Box>
                )}
            />
        </>
    );
}
