import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { useSessionContext } from '../../contexts/SessionContext';

export default function NavButLocator( props ){
    //console.debug("RTS Break NavButLocator");

    const history = useNavigate();
    const {user, sessionCustomer, setCurFilter} = useSessionContext();

    function handleLocatorClick(e) {
        setCurFilter(null);

        if(props.setOpenDrawerFx){
            props.setOpenDrawerFx(false);
        };

        let filterObj = {"user":user, 
        "status":[1,25,50],
        "customers":sessionCustomer.customer,
        "orders":[], 
        "projects":[], 
        "products":[], 
        "reelTypes":[],
        "Locator":[], 
        "sortBy":0 
        };

        history("/Locator", {state:{filter:filterObj}});        
    }


    if(user.southwireFlag !== 1 && user.adminFlag !== 1){
        return("");
    }   


    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="Locator" onClick={handleLocatorClick} sx={{cursor:"pointer"}}>
                <ListItemText primary="Locator" />
            </ListItem>
            </>
        )
    }    


    return (
        <>
        <Button
            key='Locator'
            onClick={handleLocatorClick}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
        >
            Locator
        </Button>
        </>
    );
}



