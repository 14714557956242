import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';

import Loading from '../Loading';
import LoadListDetailsCrewDetails from './LoadListDetailsCrewDetails';
import LoadListDetailsCrewMembersList from './LoadListDetailsCrewMembersList';

export default function LoadListDetailsCrew(props){
    console.debug("RTS Break LoadListDetailsCrew");

    const [crew, setCrew] = useState();

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setCrew(props.data);
        setIsLoading(false);
    }, [props.data]); 

   
    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <>
        <Box sx={{border:'1px solid #e2e8f0', borderRadius:'5px', p:1}}>        
            <LoadListDetailsCrewDetails data={crew} />
            <LoadListDetailsCrewMembersList data={crew==null?[]:crew.crewMembers!=null?crew.crewMembers:[]} />
        </Box>
        </>
    )
}

