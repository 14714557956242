import {useState, useEffect } from 'react';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import EditBack from '../../components/EditBack';
import ReelTypesEdit from '../../components/ReelTypes/ReelTypesEdit';
import ReelTypesActiveReelsList from '../../components/ReelTypes/ReelTypesActiveReelsList';

export default function ReelTypesPageEdit( props ){
    //console.debug("RTS Break ReelTypesPageEdit");

    const location = useLocation();
    const idGuid = location.state.idGuid;
    const display = location.state.display;
    const mode = location.state.mode;
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [formDirty, setFormDirty] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [newTabIndex, setNewTabIndex] = useState(0);

    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]);  

    
    const handleChange = (e, curIndex) => {
        setNewTabIndex(curIndex);
        if(formDirty===1){
            setShowConfirmModal(true);
        }
        else{
            switchTabs(curIndex);
        }
    };


    function switchTabs(curIndex){
        window.scrollTo(0,0);
        setShowConfirmModal(false);
        setFormDirty(0);
        if(mode===1){
            setTabIndex(curIndex);
        }
    }    

    return(
        <>
        <Dialog fullWidth={true} open={showConfirmModal}>
            <DialogTitle>Leave Tab?</DialogTitle>
            <DialogContent>
                <Typography>Changes will be lost.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {switchTabs(newTabIndex);}}>Yes</Button>
                <Button variant="contained" onClick={e => {setShowConfirmModal(false);}}>No</Button>
            </DialogActions>
        </Dialog>         
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
                {formDirty===0
                ?
                    <Typography variant="h5" >Edit Reel Type</Typography>
                :
                    <Typography variant="h5" sx={{fontStyle:'italic'}}>Edit Reel Type *</Typography>
                }
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:1 }}>
                <Tabs value={tabIndex} onChange={handleChange}>
                    <Tab label="Overview" value={0}  />
                    {mode===1?<Tab label="Active Reels" value={1} />:''}
                </Tabs>
            </Box>

            {
            tabIndex === 1
            ?
                <ReelTypesActiveReelsList idGuid={idGuid} display={display} mode={mode} formDirty={formDirty} setFormDirtyFx={setFormDirty} />
            :
                <>
                <Box>
                    <ReelTypesEdit idGuid={idGuid} display={display} mode={mode} formDirty={formDirty} setFormDirtyFx={setFormDirty} />
                </Box>
                </>
            }
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
            </Box>              
        </Container>
        </>
    );
}
