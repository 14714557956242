import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import TrackersList from '../../components/Trackers/TrackersList';
import TrackersFilter from '../../components/Trackers/TrackersFilter';
import TrackersHeader from '../../components/Trackers/TrackersHeader';

import { useSessionContext } from '../../contexts/SessionContext';

export default function TrackersPage( props ){
    //console.debug("RTS Break TrackersPage");

    const {curFilter, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/Trackers/GetTrackersSummary";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
            else{
                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'TrackersPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({model:'AT3', status:1, isActive:1});
            fetchData({model:'AT3', status:1, isActive:1})
        }
    }, [props, curFilter, fetchData]); 


    
    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    };    


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <>
        <Box id="pageBox">
            <TrackersHeader />
            <TrackersFilter filter={filter} setFilterFx={setFilter}/>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                <TrackersList data={data} />
            </Box>
        </Box>
        </> 
    );   
}
