import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';


export default function ReelInspectionsFilterReelTypeList(props) {
    //console.debug("RTS Break ReelInspectionsFilterReelTypeList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [reelType, setReelType] = useState();
    const [repoReelTypes, setRepoReelTypes] = useState([]);


    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/ReelTypes/GetActiveReelTypes";

        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoReelTypes(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        setReelType(props.reelType);
        fetchData();
    }, [props.reelType, fetchData]); 


    function valChange(e, val){
        props.setReelTypeFx(val);
        props.setFormDirtyFx(1);
        setReelType(val);
    }
    
    
    if(hasError){
        return(
            <Container className="fullScreen d-flex justify-content-center align-items-center">
                <Container style={{textAlign:"center"}}>
                    <Container>
                        <h3 style={{color:"red"}}>An error has occured</h3>
                    </Container>
                    <Container>
                        <span>Please review the browser console for details</span>                
                    </Container>
                </Container>
            </Container>
        );
    }


    if(isLoading){
        return(
            <Container className="d-flex justify-content-center align-items-center">
                <Container className="spinner-border" role="status">
                    <span className="sr-only">Loading Data...</span>
                </Container>
            </Container>
        );
    }


    return(
        <>
            <Autocomplete
                options={repoReelTypes}
                renderInput={(params) => <TextField {...params} label="Reel Types" size="small" />}
                value={reelType || null}
                onChange={valChange}
                getOptionLabel={(reelType) => reelType.name}
                renderOption={(props, repoReelTypes) => (
                    <Box component="li" {...props} key={repoReelTypes.idGuid}>
                        {repoReelTypes.name}
                    </Box>
                )}
            />
        </>
    );
}

