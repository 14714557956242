import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export default function AnalyticsTotalReelsListItem(props){
    return (
        <TableRow>
            <TableCell sx={{fontWeight:props.data.desc==='Total'?'bold':'normal'}}>{props.data.desc}</TableCell>
            <TableCell align="right" sx={{width:"75px", fontWeight:props.data.desc==='Total'?'bold':'normal'}}>{props.data.reels}</TableCell>
        </TableRow>
    )
}

