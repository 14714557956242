import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function CustomersFilterTypeList(props) {
    //console.debug("RTS Break ReelsFilterTypeList");

    const [type, setType] = useState(props.customerType==null?null:props.customerType);
    const [repoTypes] = useState([  {value:"CUSTOMER", label:"Customer"}, 
                                    {value:"SHIPTO", label:"Ship To"},
                                    {value:"PARENT", label:"Parent"},
                                    {value:"BILLTO", label:"Bill To"},
                                ]);


    function valChange(e, val){
        setType(val.value);
        props.setCustomerTypeFx(val.value);
        props.setFormDirtyFx(1);
    }

 
    return(
        <>
            <Autocomplete
                options={repoTypes}
                renderInput={(params) => <TextField {...params} label="Type"  size="small" />}
                value={repoTypes.find(v => v.value===type)}
                onChange={valChange}
                getOptionLabel={(repoType) => repoType.label}
                renderOption={(props, repoType) => (
                    <Box component="li" {...props} key={repoType.value}>
                        {repoType.label}
                    </Box>
                )}
            />
        </>
    );
}
