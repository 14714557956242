import Box from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsReturnedLast30DaysHeader(props){
    //console.debug("RTS Break LoadsReturnedLast30DaysHeader");
    
    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    return (
        <Box sx={{textAlign:'center'}}>
            <Typography variant='h4'>Reels Returned Last 30 Days</Typography>
            <Typography variant='h6'>{sessionCustomer.customer.name} - {sessionCustomer.customer.idSap}</Typography>
        </Box>
    )
}
