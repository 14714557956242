import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { useSessionContext } from '../../contexts/SessionContext';


export default function LoadListFilterOrderList(props) {
    //console.debug("RTS Break LoadListFilterOrderList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [orders, setOrders] = useState(props.orders===null?[]:props.orders);
    const [repoOrders, setRepoOrders] = useState(props.data===null?[]:props.data);


    function valChange(e, val){
        props.setOrdersFx(val);
        setOrders(val);
    }


    return(
        <>
            <Autocomplete
                options={repoOrders}
                renderInput={(params) => <TextField {...params} label="Purchase Orders" size="small" />}
                value={orders}
                onChange={valChange}
                getOptionLabel={(order) => order}
                renderOption={(props, repoOrders) => (
                    <Box component="li" {...props} key={repoOrders}>
                        {repoOrders}
                    </Box>
                )}
                multiple
            />
        </>
    );
}

