import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export default function WorkItemsHeader(props){
    //console.debug("RTS Break WorkItemsHeader");

    const history = useNavigate();

    function handleBack(e){
        e.preventDefault();
        history(-1);
    }

    return (
        <Box sx={{mt:2}}>
            <Box>
                <Button variant="contained" onClick={handleBack} startIcon={<ArrowLeftIcon />}>Back To Work Sets</Button>
            </Box>
            <Box sx={{mt:2}}>
                <Typography variant="h5">{props.data.name}</Typography>
            </Box>
        </Box>
    )
}

