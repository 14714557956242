import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

export default function LoadListMarkEmptyScrap(props) {
    //console.debug("RTS Break LoadListMarkEmptyScrap");
    
    const emptyScrapList = [
        {id:0, label:"Empty"},
        {id:1, label:"Scrap"},
    ]

    const [emptyScrap, setEmptyScrap] = useState(emptyScrapList.find(es => {return es.id === props.emptyScrap}));

    function valChange(e, val){
        props.setEmptyScrapFx(val.id);
        setEmptyScrap(val);
    }    


    return(
        <>
            <Autocomplete
                margin="normal"
                options={emptyScrapList}
                renderInput={(params) => <TextField {...params} label="Empty/Scrap" required size="small" />}
                value={emptyScrap}
                onChange={valChange}
                getOptionLabel={(opt) => opt.label}
                renderOption={(props, emptyScrapList) => (
                    <Box component="li" {...props} key={emptyScrapList.id}>
                        {emptyScrapList.label}
                    </Box>
                )}
            />
        </>
    );
}

