import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function LoadProjectEditHelp(props) {
    //console.debug('RTS Break LoadProjectEditHelp');

    return (
        <Box>
            <Typography>
				This screen allows users to select the project that a reel is associated with.  
                Select the project from the list and click the Set Project button.
            </Typography>
        </Box>
    );

}
