import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { format, parseISO } from 'date-fns';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { useSessionContext } from '../../contexts/SessionContext';


function handleClick(e){

}

export default function LoadListListItemProduct(props){
    //console.debug("RTS Break LoadListListItemProductDetail");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    
    return (
        <Stack>
            {props.data.product.productAlias !== null && props.data.product.productAlias.length > 0
            ?
                <>
                <Box>
                    <Tooltip title={props.data.product.idSap}>
                        <Typography variant="h6" >
                            {props.data.product.productAlias[0].alias}
                        </Typography>
                    </Tooltip>
                </Box>
                <Box>
                    <Typography variant="caption" >
                        {props.data.product.productAlias[0].customerMaterialNo}
                    </Typography>
                </Box>
                </>
            :
                <>
                <Tooltip title={props.data.product.idSap}>
                    
                    <Typography variant="h6" >
                        {props.data.product.name}
                    </Typography>
                </Tooltip>
                <Typography variant="caption" >
                    {props.data.customerMaterialNo===null?props.data.product.idSap:props.data.customerMaterialNo}
                </Typography>
                </>                                    
            }
        </Stack>
    );   
}

