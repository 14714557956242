import {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import PickupRequestsFilterStatus from './PickupRequestsFilterStatus';

import { useSessionContext } from '../../contexts/SessionContext';

export default function PickupRequestsFilter(props) {
    //console.debug("RTS Break PickupRequestsFilter");

    const {user, setCurFilter, sessionCustomer} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [status, setStatus] = useState(50);

    useEffect(() => {
        setStatus(props.filter.status);
        setIsLoading(false);
    }, [props]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'PickupRequestsPage', filter:{user:user, customer:sessionCustomer.customer, status:status, sortBy:0}});
        props.setFilterFx({user:user, customer:sessionCustomer.customer, status:status, sortBy:0});
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <PickupRequestsFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={3} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
