import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

export default function PalletTurnDetailsHeader(props){
    //console.debug("RTS Break PalletTurnDetailsHeader");

    return (
        <Box>
            <Typography>Name: {props.data.name}</Typography>
            <Typography>ESN: {props.data.esn}</Typography>
            <Typography sx={{mr:1}}>Status: {props.data.status===0?'Disabled':'Active'}</Typography>
        </Box>
    )
}

