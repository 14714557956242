import {useState} from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useSessionContext } from '../../contexts/SessionContext';

export default function NavButSystemReelManager( props ){
    //console.debug("RTS Break NavButSystemReelManager");

    const {sessionCustomer} = useSessionContext();
    const [open, setOpen] = useState(false);
    //const [anchorElSystemReelManager, setAnchorElSystemReelManager] = useState(null);

    const handleOpenMenu = (event) => {
        setOpen(!open);
        //setAnchorElSystemReelManager(event.currentTarget);
    };
      
    function handleCloseMenu(e) {
        setOpen(false);
        //setAnchorElSystemReelManager(null);
    };

    function handleSysTrackersClick(e){
        handleCloseMenu(e);
        props.handleSysTrackersClickFx(e);
    }  

    function handleSysReelTypesClick(e){
        handleCloseMenu(e);
        props.handleSysReelTypesClickFx(e);
    }  

    function handleSysReelsClick(e){
        handleCloseMenu(e);
        props.handleSysReelsClickFx(e);
    }  


    function handleSysPickupRequestsAdminClick(e){
        handleCloseMenu(e);
        props.handleSysPickupRequestsAdminClickFx(e);
    } 


    if(sessionCustomer.accessLevel !== 100){
        return("");
    }


    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItemButton key="ReelManager" sx={{ pl:4, cursor:"pointer" }}  onClick={handleOpenMenu}>
                <ListItemText primary="Reel Manager" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List  component="div" disablePadding>
                    <ListItemButton key="Trackers" sx={{ pl:6, cursor:"pointer" }} onClick={handleSysTrackersClick}>
                        <ListItemText primary="Trackers" />
                    </ListItemButton>
                    <ListItemButton key="ReelTypes" sx={{ pl:6, cursor:"pointer" }} onClick={handleSysReelTypesClick}>
                        <ListItemText primary="Reel Types" />
                    </ListItemButton>
                    <ListItemButton key="Reels" sx={{ pl:6, cursor:"pointer" }} onClick={handleSysReelsClick}>
                        <ListItemText primary="Reels" />
                    </ListItemButton>
                    <ListItemButton key="PickupRequestsAdmin" sx={{ pl:6, cursor:"pointer" }} onClick={handleSysPickupRequestsAdminClick}>
                        <ListItemText primary="Pickup Requests Admin" />
                    </ListItemButton>  
                </List>
            </Collapse>
            </>
        )
    }

    return (
        <>
        <MenuItem key='SystemReelManager' onClick={handleOpenMenu}>
            <Typography textAlign="Left">Reel Manager</Typography>
            <ArrowDropDownIcon />
        </MenuItem>        
        <Collapse in={open} timeout="auto" unmountOnExit>
            <MenuItem key='SysTrackers' onClick={handleSysTrackersClick}>
                <Typography textAlign="Left" sx={{ml:1}}>Trackers</Typography>
            </MenuItem>

            <MenuItem key='SysReelTypes' onClick={handleSysReelTypesClick}>
                <Typography textAlign="Left" sx={{ml:1}}>Reel Types</Typography>
            </MenuItem>

            <MenuItem key='SysReels' onClick={handleSysReelsClick}>
                <Typography textAlign="Left" sx={{ml:1}}>Reels</Typography>
            </MenuItem>            

            <MenuItem key='SysPickupRequestsAdmin' onClick={handleSysPickupRequestsAdminClick}>
                <Typography textAlign="Left" sx={{ml:1}}>Pickup Requests Admin</Typography>
            </MenuItem> 

            {/* <MenuItem key='SysReelWipAdmin' onClick={handleSysReelWipClick}>
                <Typography textAlign="Left">Reel WIP</Typography>
            </MenuItem>                                     */}
        </Collapse>
        </>
    );
}



