import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';
import CustomersEditType from './CustomersEditType';
import CustomersEditParentCustomer from './CustomersEditParentCustomer';
import CustomersShowUntracked from './CustomersShowUntracked';
import CustomersServicesContract from './CustomersServicesContract';
import EditRegion from '../EditRegion';
import EditCountry from '../EditCountry';


import { useSessionContext } from '../../contexts/SessionContext';

export default function CustomersEdit(props) {
    console.debug("RTS Break CustomersEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    //const display = props.display;
    const mode = props.mode;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showCustomerLinksModal, setShowCustomerLinksModal] = useState(false);

    const [idGuid, setIdGuid] = useState(null);
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [customerType, setCustomerType] = useState('CUSTOMER');
    const [parentCustomer, setParentCustomer] = useState(null);
    const [mailAddress1, setMailAddress1] = useState('');
    const [mailAddress2, setMailAddress2] = useState('');
    const [mailCity, setMailCity] = useState('');
    const [mailRegion, setMailRegion] = useState(null);
    const [mailZip, setMailZip] = useState('');
    const [mailCountry, setMailCountry] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [deferralEmail, setDeferralEmail] = useState('');
    const [showUntracked, setShowUntracked] = useState(0);
    const [servicesContractFlag, setServicesContractFlag] = useState(0);
    const [servicesContractLimit, setServicesContractLimit] = useState(0);
    const [idSap, setIdSap] = useState('');
    const [salesGroup, setSalesGroup] = useState('');
    const [salesOffice, setSalesOffice] = useState('');
    const [salesOrg, setSalesOrg] = useState('');
    const [distributionChannel, setDistributionChannel] = useState('');
    const [division, setDivision] = useState('');
    const [licenseCount, setLicenseCount] = useState(0);


    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/Customers/GetCustomerById/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIdGuid(response.data.idGuid);
                setIdSap(response.data.idSap);
                setName(response.data.name);
                setShortName(response.data.shortName);
                setCustomerType(response.data.customerType);
                setParentCustomer(response.data.parentCustomer);
                setMailAddress1(response.data.mailAddress1);
                setMailAddress2(response.data.mailAddress2);
                setMailCity(response.data.mailCity);
                setMailRegion(response.data.mailRegion);
                setMailZip(response.data.mailZip);
                setMailCountry(response.data.mailCountry);
                setPhoneNumber(response.data.phoneNumber);
                setDeferralEmail(response.data.deferralEmail);
                setShowUntracked(response.data.showUntracked);
                setServicesContractFlag(response.data.servicesContractFlag);
                setServicesContractLimit(response.data.servicesContractLimit);
                setSalesGroup(response.data.salesGroup);
                setSalesOffice(response.data.salesOffice);
                setSalesOrg(response.data.salesOrg);
                setDistributionChannel(response.data.distributionChannel);
                setDivision(response.data.division);
                setLicenseCount(response.data.licenseCount);

                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]); 
    
    
    useEffect(() => {
        if(mode === 1){
            setIdGuid(props.idGuid);
            fetchData(props.idGuid);
        }
        else{
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            Name: name===''?null:name,
            ShortName: shortName===''?null:shortName,
            CustomerType: customerType,
            ParentCustomer: parentCustomer,
            MailAddress1: mailAddress1===''?null:mailAddress1,
            MailAddress2: mailAddress2===''?null:mailAddress2,
            MailCity: mailCity===''?null:mailCity,
            MailRegion: mailRegion,
            MailZip: mailZip===''?null:mailZip,
            MailCountry: mailCountry,
            PhoneNumber: phoneNumber===''?null:phoneNumber,
            DeferralEmail: deferralEmail===''?null:deferralEmail,
            ShowUntracked: showUntracked,
            ServicesContractFlag: servicesContractFlag,
            ServicesContractLimit: servicesContractLimit,
            LicenseCount: licenseCount, 
            SalesGroup: salesGroup===''?null:salesGroup,
            SalesOffice: salesOffice===''?null:salesOffice,
            SalesOrg: salesOrg===''?null:salesOrg,
            DistributionChannel: distributionChannel===''?null:distributionChannel,
            Division: division===''?null:division,
            ModifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/Customers/UpdateCustomer/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateCustomer Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/Customers/CreateCustomer";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateCustomer Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    // function addCustomerLink(pCustomer){
    //     let curCustomerLinks = customerLinks.map(c => Object.assign({}, c));



    //     if(customerLinks.filter(c => c.child.idGuid === pCustomer.idGuid).length === 0)
    //     {
    //         curCustomerLinks.push({child: pCustomer, 
    //                                 customer: sessionCustomer, 
    //                                 createUser: user.userName, 
    //                                 modifyUser: user.userName
    //                             });
    //     }

    //     setCustomerLinks(curCustomerLinks);
    //     setShowCustomerLinksModal(false);
    // }


    // function removeCustomerLink(pCustomer){
    //     let newCustList = [];

    //     customerLinks.forEach(cc => {
    //         if(cc.idGuid !== pCustomer.idGuid)
    //         {
    //             newCustList.push(cc)
    //         }
    //     });

    //     setCustomerLinks(newCustList);
    // }    


    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };   


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        {/* <Dialog fullWidth={true} maxWidth="md" open={showCustomerLinksModal} onClose={()=>{setShowCustomerLinksModal(false);}}>
            <DialogTitle>Add Customer Link</DialogTitle>
            <DialogContent>
                <Box style={{minHeight:"400px"}}>
                    <CustomersEditLinksAddList addCustomerLinkFx={addCustomerLink} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowCustomerLinksModal(false);}} sx={{mt:2}}>Cancel</Button>
            </DialogActions>
        </Dialog>   */}
        <Box sx={{width:'100%'}}>
            <form id="customersForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                />
                <TextField
                    id="tbShortName"
                    margin="normal"
                    fullWidth
                    label="Short Name"
                    size="small"
                    required
                    value={shortName}
                    onChange={(e) => {props.setFormDirtyFx(1); setShortName(e.target.value);}}
                />
                <CustomersEditType customerType={customerType} setCustomerTypeFx={setCustomerType} setFormDirtyFx={props.setFormDirtyFx} />
                <CustomersEditParentCustomer parentCustomer={parentCustomer} setParentCustomerFx={setParentCustomer} setFormDirtyFx={props.setFormDirtyFx} />
                <TextField
                    id="tbIdSap"
                    margin="normal"
                    fullWidth
                    label="SAP Id"
                    size="small"
                    value={idSap}
                    onChange={(e) => {props.setFormDirtyFx(1); setIdSap(e.target.value);}}
                />
                <TextField
                    id="tbDeferralEmail"
                    margin="normal"
                    fullWidth
                    label="Deferral Notice Email"
                    size="small"
                    value={deferralEmail}
                    onChange={(e) => {props.setFormDirtyFx(1); setDeferralEmail(e.target.value);}}
                />   
                <CustomersShowUntracked showUntracked={showUntracked} setShowUntrackedFx={setShowUntracked} setFormDirtyFx={props.setFormDirtyFx} /> 
                <TextField
                    id="tbLicenseCount"
                    type="number"
                    margin="normal"
                    fullWidth
                    label="User Licenses"
                    size="small"
                    value={licenseCount}
                    onChange={(e) => {props.setFormDirtyFx(1); setLicenseCount(e.target.value);}}
                /> 
                <CustomersServicesContract servicesContractFlag={servicesContractFlag} setServicesContractFlagFx={setServicesContractFlag} setFormDirtyFx={props.setFormDirtyFx} /> 
                <TextField
                    id="tbServicesContractLimit"
                    type="number"
                    margin="normal"
                    fullWidth
                    label="Contract Limit"
                    size="small"
                    value={servicesContractLimit}
                    onChange={(e) => {props.setFormDirtyFx(1); setServicesContractLimit(e.target.value);}}
                /> 
                <Typography variant="h6" sx={{mt:2, mb:2}}>Address</Typography>
                <TextField
                    id="tbAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={mailAddress1}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailAddress1(e.target.value);}}
                />
                <TextField
                    id="tbAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={mailAddress2}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailAddress2(e.target.value);}}
                />
                <TextField
                    id="tbCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={mailCity}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailCity(e.target.value);}}
                />
                <Grid container>
                    <Grid item xs={6} sx={{pr:1}}>
                        <EditRegion region={mailRegion} setRegionFx={setMailRegion} />
                    </Grid>
                    <Grid item xs={6} sx={{pl:1}}>
                        <TextField
                            id="tbZip"
                            margin="normal"
                            fullWidth
                            label="Zip Code"
                            size="small"
                            value={mailZip}
                            onChange={(e) => {props.setFormDirtyFx(1); setMailZip(e.target.value);}}
                        />
                    </Grid>
                </Grid>
                <EditCountry country={mailCountry} setCountryFx={setMailCountry} setFormDirtyFx={props.setFormDirtyFx} />
                <TextField
                    id="tbPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    size="small"
                    value={phoneNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setPhoneNumber(e.target.value);}}
                /> 
                <Typography variant="h6" sx={{mt:2}}>Sales Organization</Typography>
                <TextField
                    id="tbSapGroup"
                    margin="normal"
                    fullWidth
                    label="Group"
                    size="small"
                    value={salesGroup}
                    onChange={(e) => {props.setFormDirtyFx(1); setSalesGroup(e.target.value);}}
                />                
                <TextField
                    id="tbSapOffice"
                    margin="normal"
                    fullWidth
                    label="Office"
                    size="small"
                    value={salesOffice}
                    onChange={(e) => {props.setFormDirtyFx(1); setSalesOffice(e.target.value);}}
                />                
                <TextField
                    id="tbSapOrg"
                    margin="normal"
                    fullWidth
                    label="Organization"
                    size="small"
                    value={salesOrg}
                    onChange={(e) => {props.setFormDirtyFx(1); setSalesOrg(e.target.value);}}
                />
                <TextField
                    id="tbDistributionChannel"
                    margin="normal"
                    fullWidth
                    label="Distribution Channel"
                    size="small"
                    value={distributionChannel}
                    onChange={(e) => {props.setFormDirtyFx(1); setDistributionChannel(e.target.value);}}
                /> 
                <TextField
                    id="tbDivision"
                    margin="normal"
                    fullWidth
                    label="Division"
                    size="small"
                    value={division}
                    onChange={(e) => {props.setFormDirtyFx(1); setDivision(e.target.value);}}
                />
                </Box>
                </Box> 

                {/* <Box sx={{display:"flex", mt:3}}>
                    <Box sx={{flexGrow:1, textAlign:'left'}}>
                        <Typography variant="h6">Customer Links</Typography>
                    </Box>
                    <Box sx={{textAlign:'right', flexGrow:1}}>
                        {customerType.toUpperCase()!=='CUSTOMER'
                        ?
                        <Button variant="contained" disabled>Add Customers</Button>
                        :
                        <Button variant="contained" onClick={e => {setShowCustomerLinksModal(true);}}>Add Customers</Button>
                        }                        
                    </Box>
                </Box>
                {customerType.toUpperCase()!=='CUSTOMER'
                ?
                <Box sx={{textAlign:"center"}}>
                    <Typography variant="h6">Not Available</Typography>
                </Box>
                :
                <Box sx={{minHeight:"100px"}}>
                    <CustomersEditLinksList data={customerLinks} removeCustomerLinkFx={removeCustomerLink} />
                </Box>
                } */}


                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit" sx={{mr:2}}>Save</Button>
                </Box>
            </form>
        </Box>
        </>
    );
}

