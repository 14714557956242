import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import HelpListOverview from './HelpListOverview';

export default function ProductAliasHeader(props){
    const history = useNavigate();

    const [helpListOverview, setHelpListOverview] = useState(false);


    function handleClick(e){
        history("/ProductAliasEdit", {state:{data:{}, mode: 0}});
    }


    return (
        <>
        <Dialog open={helpListOverview} onClose={()=>{setHelpListOverview(false);}}>
            <DialogTitle>Customer Parts Help</DialogTitle>
            <DialogContent>
                <HelpListOverview />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setHelpListOverview(false);}}>Close</Button>
            </DialogActions>
        </Dialog> 
        <Grid container sx={{mb:1}}>
            <Grid item xs={6}>
                <Box display="flex">
                    <Typography variant="h5">Customer Parts</Typography>
                    <Link onClick={() => {setHelpListOverview(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <HelpOutlineIcon variant="h5" bold sx={{ml:1, mt:.5}}  />
                    </Link>
                </Box>                 
            </Grid>
            <Grid item xs={6} sx={{textAlign:'right'}}>
                <Button type="button" variant="contained" onClick={handleClick}>
                    New Part
                </Button>
            </Grid>
        </Grid>
        </>
    )
}
