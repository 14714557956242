import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import WorkItemsEdit1 from '../../components/WorkItems/WorkItemsEdit1';
import WorkItemsEdit2 from '../../components/WorkItems/WorkItemsEdit2';

export default function WorkItemsEditPage( props ){
    //console.debug("RTS Break WorkItemsEditPage");

    const location = useLocation();
    const data = location.state.workItem;
    const mode = location.state.mode;


    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 

    return(
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box>
                {
                data.workSet.workSetType.code === 1
                    ?
                    <WorkItemsEdit1 data={data} mode={mode} />
                    :
                    data.workSet.workSetType.code === 2
                        ?
                        <WorkItemsEdit2 data={data} mode={mode} />
                        :
                        <Box sx={{pt:3}}><Typography variant='h1'>Unknown Work Set Type</Typography></Box>
                }

            </Box>
        </Container>
    );
}
