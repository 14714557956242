import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';

import Loading from '../Loading';
import Error from '../Error';
import EditStatus from '../EditStatus';
import EditRegion from '../EditRegion';
import EditCountry from '../EditCountry';
import CompanyLinksEditLinkedLookup from './CompanyLinksEditLinkedLookup';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CompanyLinksEdit(props) {
    console.debug("RTS Break CompanyLinksEdit");

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    //const display = props.display;
    const mode = props.mode;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");
    const [showLinkedToList, setShowLinkedToList] = useState(false);

    const [idGuid, setIdGuid] = useState(null);
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [mailAddress1, setMailAddress1] = useState('');
    const [mailAddress2, setMailAddress2] = useState('');
    const [mailCity, setMailCity] = useState('');
    const [mailRegion, setMailRegion] = useState(null);
    const [mailZip, setMailZip] = useState('');
    const [mailCountry, setMailCountry] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [status, setStatus] = useState(null);
    const [linkCustomer, setLinkCustomer] = useState(null);
    const [linkCustomerName, setLinkCustomerName] = useState('');

    const fetchData = useCallback((pIdGuid) => {
        let apiURL = apiRoot + "/CompanyLinks/GetCompanyLinkById/" + pIdGuid;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIdGuid(response.data.idGuid);
                setName(response.data.name);
                setShortName(response.data.shortName);
                setMailAddress1(response.data.mailAddress1);
                setMailAddress2(response.data.mailAddress2);
                setMailCity(response.data.mailCity);
                setMailRegion(response.data.mailRegion);
                setMailZip(response.data.mailZip);
                setMailCountry(response.data.mailCountry);
                setPhoneNumber(response.data.phoneNumber);
                setStatus(response.data.status);
                setLinkCustomer(response.data.linkCustomer);
                setLinkCustomerName(response.data.linkCustomer!=null?response.data.linkCustomer.name:'');
                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]); 
    
    
    useEffect(() => {
        if(mode === 1){
            setIdGuid(props.idGuid);
            fetchData(props.idGuid);
        }
        else{
            setStatus(1);
            setIsLoading(false);
        }
    }, [mode, fetchData, props.idGuid]);  


    function handleSubmit(e) {
        e.preventDefault();

        let apiObj = {
            IdGuid: idGuid,
            Customer: sessionCustomer.customer,
            Name: name===''?null:name,
            ShortName: shortName===''?null:shortName,
            MailAddress1: mailAddress1===''?null:mailAddress1,
            MailAddress2: mailAddress2===''?null:mailAddress2,
            MailCity: mailCity===''?null:mailCity,
            MailRegion: mailRegion,
            MailZip: mailZip===''?null:mailZip,
            MailCountry: mailCountry,
            PhoneNumber: phoneNumber===''?null:phoneNumber,
            Status: status,
            LinkType: 1,
            LinkCustomer: linkCustomer,
            ModifyUser: user.userName
        }

        if(mode > 0){
            let apiURL = apiRoot + "/CompanyLinks/UpdateCompanyLink/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdateCustomer Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/CompanyLinks/CreateCompanyLink";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreateCustomer Error", error);
                setShowErrorModal(true);
            });
        }
    }; 


    function setLinkedToCustomer(pCustomer){
        setLinkCustomer(pCustomer);
        setLinkCustomerName(pCustomer.name);
        setShowLinkedToList(false);
        props.setFormDirtyFx(1);
    };


    function checkParentRelationship(){
        if(linkCustomer!=null){
            if(linkCustomer.idGuid !== sessionCustomer.customer.idGuid){
                return false;
            }
            else{
                return true;
            }
        }
        else{
            return false;
        }
    }


    if(hasError){
        return(
            <Error />
        );
    };
    
    
    if(isLoading){
        return(
            <Loading />
        );
    };   


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showLinkedToList} fullWidth={true} maxWidth="md" onClose={()=>{setShowLinkedToList(false);}}>
            <DialogTitle>Select Customer</DialogTitle>
            <DialogContent>
                <CompanyLinksEditLinkedLookup linkCustomer={linkCustomer} setLinkCustomerFx={setLinkedToCustomer} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowLinkedToList(false);}}>Close</Button>
            </DialogActions>
        </Dialog>            
        <Box sx={{width:'100%'}}>
            <form id="CompanyLinksForm" onSubmit={handleSubmit}>
                <Box maxWidth="md" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Box id="smBox" maxWidth="sm" sx={{width:smallForm?'auto':'600px'}}>
                 <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {props.setFormDirtyFx(1); setName(e.target.value);}}
                />
                <TextField
                    id="tbShortName"
                    margin="normal"
                    fullWidth
                    label="Short Name"
                    size="small"
                    required
                    value={shortName}
                    onChange={(e) => {props.setFormDirtyFx(1); setShortName(e.target.value);}}
                />
                <EditStatus 
                    status={status} 
                    setStatusFx={setStatus} 
                    setFormDirtyFx={props.setFormDirtyFx} 
                    disabled={checkParentRelationship()}
                />
                {sessionCustomer.accessLevel === 100
                ?
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <TextField
                        id="tbLinkedTo"
                        margin="normal"
                        fullWidth
                        label="Linked Customer"
                        size="small"
                        value={linkCustomerName}
                        onClick={setShowLinkedToList}
                        disabled={checkParentRelationship()}
                    />
                    <Box sx={{ml:1, mt:1}}>            
                        <Button 
                        variant="contained" 
                        onClick={setShowLinkedToList}
                        disabled={checkParentRelationship()}
                        >
                            <SearchIcon sx={{fontSize:'2em'}} />
                        </Button>
                    </Box>            
                </Box>
                :
                ''
                }                 
                <Typography variant="h6" sx={{mt:2, mb:2}}>Address</Typography>
                <TextField
                    id="tbAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={mailAddress1}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailAddress1(e.target.value);}}
                />
                <TextField
                    id="tbAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={mailAddress2}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailAddress2(e.target.value);}}
                />
                <TextField
                    id="tbCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={mailCity}
                    onChange={(e) => {props.setFormDirtyFx(1); setMailCity(e.target.value);}}
                />
                <Grid container>
                    <Grid item xs={6} sx={{pr:1}}>
                        <EditRegion region={mailRegion} setRegionFx={setMailRegion} />
                    </Grid>
                    <Grid item xs={6} sx={{pl:1}}>
                        <TextField
                            id="tbZip"
                            margin="normal"
                            fullWidth
                            label="Zip Code"
                            size="small"
                            value={mailZip}
                            onChange={(e) => {props.setFormDirtyFx(1); setMailZip(e.target.value);}}
                        />
                    </Grid>
                </Grid>
                <EditCountry country={mailCountry} setCountryFx={setMailCountry} setFormDirtyFx={props.setFormDirtyFx} />
                <TextField
                    id="tbPhoneNumber"
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    size="small"
                    value={phoneNumber}
                    onChange={(e) => {props.setFormDirtyFx(1); setPhoneNumber(e.target.value);}}
                />
                </Box>
                </Box> 

                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained" disabled={props.formDirty===1?false:true} type="submit" sx={{mr:2}}>Save</Button>
                </Box>
            </form>
        </Box>
        </>
    );
}

