import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function LoadsFilterMaxRecords(props) {
    console.debug("RTS Break LoadsFilterMaxRecords");

    const [maxRecords, setMaxRecords] = useState(props.maxRecords==null?1000:props.maxRecords);
    const [repoValues] = useState([{value:-1, label:"All"}, {value:100, label:"100"}, {value:500, label:"500"}, {value:1000, label:"1,000"}, {value:2000, label:"2,000"}]);


    // useEffect(() => {
    //     setMaxRecords(props.maxRecords)
    // }, [props]);     

    function valChange(e, val){
        setMaxRecords(val.value);
        props.setMaxRecordsFx(val.value);
        props.setFormDirtyFx(1);
    }

 
    return(
        <>
            <Autocomplete
                options={repoValues}
                renderInput={(params) => <TextField {...params} label="Max Records"  size="small" />}
                value={repoValues.find(v => v.value===maxRecords)}
                onChange={valChange}
                getOptionLabel={(repoValues) => repoValues.label}
                disableClearable={true}
                renderOption={(props, repoValues) => (
                    <Box component="li" {...props} key={repoValues.value}>
                        {repoValues.label}
                    </Box>
                )}
            />
        </>
    );
}
