import {useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import LoadListExport from './LoadListExport';

export default function LoadListMode(props) {

    const [showExportModal, setShowExportModal] = useState(false);


    function showMap(e){
        if(props.mode !== 1){
            props.modeFx(1);
        }
    }

    function showList(e){
        if(props.mode !== 0){
            props.modeFx(0);
        }
    }

    function clickDownload(e){
        setShowExportModal(true);
    }


    return (
        <>
        <Dialog fullWidth={true} maxWidth="md" open={showExportModal}>
            <DialogTitle>Export Data</DialogTitle>
            <DialogContent>
                <Box sx={{minHeight:"400px"}}>
                    <LoadListExport data={props.data} />
                </Box>            
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowExportModal(false);}}>Close</Button>
            </DialogActions>            
        </Dialog> 
        <Grid container spacing={2} id="mainGrid" sx={{mb:2}}>
            <Grid item xs={6} sx={{textAlign:'right'}}>
                {props.mode===0
                ?
                <Button 
                    variant="outlined" 
                    startIcon={<FormatListBulletedOutlinedIcon />} 
                    disabled 
                >
                    List
                </Button>
                :
                <Button 
                    variant="outlined" 
                    startIcon={<FormatListBulletedOutlinedIcon />} 
                    onClick={showList} 
                >
                    List
                </Button>
                }
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={6}>
                        {props.mode===1
                        ?
                        <Button 
                            variant="outlined" 
                            startIcon={<MapOutlinedIcon />} 
                            disabled
                            >
                            Map
                        </Button>
                        :
                        <Button 
                            variant="outlined" 
                            startIcon={<MapOutlinedIcon />} 
                            onClick={showMap}
                            >
                            Map
                        </Button>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container sx={{justifyContent:'center', alignItems:'center'}}>
                            <Grid item xs={8} sx={{textAlign:{xs:'center', sm:'right'}, justifyContent:'center', alignItems:'center'}}> 
                                <Typography variant="h6" sx={{mr:1, fontSize:{xs:'.80em', md:'1em'}}}>{props.reelCount + ' Reels'}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{textAlign:'center'}}>
                                <Link onClick={clickDownload} sx={{textDecoration:'none', cursor:'pointer'}}>
                                    <FileDownloadIcon />
                                    <Typography sx={{fontSize:'.5em', mt:-1, pt:-1}} >Download</Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>
    );
}

