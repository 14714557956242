import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography variant="h6">Overview</Typography>
            <Typography>
                Crew Members are users with limited functionality that do not count as a licensed seat.  A 
                Crew Member might be a lineperson that will perform pulls from a reel.  By giving them access you
                allow them to have read access to all reels within your organization and the ability 
                to record pulls from them.
            </Typography>
            <Typography sx={{mt:1}}>
                A user can remove an existing Crew Member by clicking on the red X icon in the far right column of the 
                list.
            </Typography> 
            <Typography sx={{mt:1}}>
                To create a new crew member click the New Crew Member button in the upper right hand corner of the 
                screen.   
            </Typography>
            <Typography variant="h6" sx={{mt:1}}>Filtering</Typography>
            <Typography >
                To filter the list of records you can use the quick filter drop down selections 
                above the list just below the page title.  Once you have entered the desired filter 
                criteria click the Filter button.  These filters will persist if you open any of 
                the records within the list.
            </Typography>
            <Typography sx={{mt:1}}>
                For more advanced control over the list you can use the menu items directly above
                the list of records.  Any options set within these menu options will not persist if 
                you open a record within the list.
            </Typography>
            <Typography sx={{mt:1}}>
                The last option is to use the search panel in the upper right hand corner of the 
                list.  Simply enter search text into the search bar and the table will filter the 
                list of records with any field that contains that text.   
            </Typography>

        </Box>
    );

}
