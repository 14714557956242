import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function CustomersShowUntracked(props) {
    //console.debug("RTS Break CustomerShowUntracked");

    const [showUntracked, setShowUntracked] = useState(props.showUntracked);
    const [repoShowUntracked] = useState([{value:0, label:"No"}, {value:1, label:"Yes"}]);


    function valChange(e, val){
        setShowUntracked(val.value);
        props.setShowUntrackedFx(val.value);
    }

 
    return(
        <>
            <Autocomplete
                options={repoShowUntracked}
                renderInput={(params) => <TextField {...params} label="Show Untracked" margin='normal' required size="small" />}
                value={repoShowUntracked.find(v => v.value==showUntracked)}
                onChange={valChange}
                getOptionLabel={(repoShowUntracked) => repoShowUntracked.label + ' (' + repoShowUntracked.value + ')'}
                renderOption={(props, repoShowUntracked) => (
                    <Box component="li" {...props} key={repoShowUntracked.value}>
                        {repoShowUntracked.label + ' (' + repoShowUntracked.value + ')'}
                    </Box>
                )}
            />
        </>
    );
}
