import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import Loading from '../Loading';
import ReelsFilterStatus from './ReelsFilterStatus';
import ReelsFilterReelTypeList from './ReelsFilterReelTypeList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelsFilter(props){
    //console.debug("RTS Break ReelsFilter");

    const {setCurFilter} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [formDirty, setFormDirty] = useState(0); 

    const [status, setStatus] = useState();
    const [reelType, setReelType] = useState();
    

    useEffect(() => {
        setStatus(props.filter.status);
        setReelType(props.filter.reelType);
        setIsLoading(false);
    },[props.filter.status, props.filter.reelType]);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'ReelsPage', filter:{reelType:reelType, status:status}});
        props.setFilterFx({reelType:reelType, status:status});
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <ReelsFilterReelTypeList reelType={reelType} setReelTypeFx={setReelType} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <ReelsFilterStatus status={status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
