import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography variant="h6">Overview</Typography>
            <Typography>
                The Reel Inspections page allows for the quick creation of a Reel Damage 
                Checklist or Reel Final Checklist.
            </Typography>
            <Typography sx={{mt:1}}>
                To start a new inspection simply find the reel to inspect in the list and click on the 
                pencil icon for the reel.   
            </Typography>

            <Typography variant="h6" sx={{mt:1}}>Filtering</Typography>
            <Typography >
                To filter the list of records you can use the quick filter drop down selections 
                above the list just below the page title.  Once you have entered the desired filter 
                criteria click the Filter button.  These filters will persist if you open any of 
                the records within the list.
            </Typography>
            <Typography sx={{mt:1}}>
                For more advanced control over the list you can use the menu items directly above
                the list of records.  Any options set within these menu options will not persist if 
                you open a record within the list.
            </Typography>
            <Typography sx={{mt:1}}>
                The last option is to use the search panel in the upper right hand corner of the 
                list.  Simply enter search text into the search bar and the table will filter the 
                list of records with any field that contains that text.   
            </Typography>

            <Typography variant="h6" sx={{mt:1}}>Details</Typography>
            <Typography>
                A Steel Reel should have the following inspections performed prior to shipping to a customer:
            </Typography>
            <table>
                <tbody>
                <tr>
                    <td>
                        1. Check for cracks in the ribs of the reel flange.
                    </td>
                </tr>
                <tr>
                    <td>
                        2. Check for abrasions in the flange.
                    </td>
                </tr>
                <tr>
                    <td>
                        3. Check for burs on the lagging tire.
                    </td>
                </tr>
                <tr>
                    <td>
                        4. Check for damage to the corssarms or the arbor.
                    </td>
                </tr>
                <tr>
                    <td>
                        5. Check the straightness of the reel.
                    </td>
                </tr>
                <tr>
                    <td>
                        6. Check the two D-rings in the starter pocket.
                    </td>
                </tr>                
                <tr>
                    <td>
                        7. Check the two gussets on the crossarm.
                    </td>
                </tr>                
                <tr>
                    <td>
                        8. Check that the drum stitch is welded to the flange on both sides.
                    </td>
                </tr>                
                </tbody>
            </table>          
        </Box>
    );

}
