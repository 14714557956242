import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

export default function CustomersList(props){
    console.debug("RTS Break CustomerList");

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));


    function handleClick(e, cellValues){
        history("/CustomersEdit", {state:{idGuid:cellValues.row.idGuid, display:cellValues.row.name, mode:1, tab:0}});
    }

    const columns = [
        {
            field: '',
            description:"Select Customer", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:4
        },
        {
            field: 'idSap', 
            headerName: "Id", 
            description:"Customer Id", 
            flex:2
        },
        {
            field: 'customerType', 
            headerName: "Type", 
            description:"Customer Type", 
            flex:2
        },        
        {
            field: 'mailCity', 
            headerName: "City", 
            description:"Customer City", 
            flex:3
        },
        {
            field: 'mailRegion', 
            headerName: "State", 
            description:"Customer State", 
            flex:1
        },
        {
            field: 'distributionChannel',
            headerName: "Dist. Channel",
            description: "Distribution Channel",
            flex:1
        },
        {
            field: 'salesOrg',
            headerName: "Sales Org.",
            description: "Sales Organization",
            flex:1
        },
        {
            field: 'division',
            headerName: "Division",
            description: "Division",
            flex:1
        },
        {
            field: 'salesOffice',
            headerName: "Office",
            description: "Sales Office",
            flex:1
        },
        {
            field: 'salesGroup',
            headerName: "Group",
            description: "Sales Group",
            flex:1
        },
    ];

    

    function getVisibilityModel(){
        if(smallForm){
            return {customerType:false, mailCity:false, mailRegion:false, distributionChannel:false, salesOrg:false, division:false, salesOffice:false, salesGroup:false}
        }
        else{
            return {distributionChannel:false, salesOrg:false, division:false, salesOffice:false, salesGroup:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}
