import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { useSessionContext } from '../../contexts/SessionContext';

export default function NavButAnalytics( props ){
    const history = useNavigate();
    const {sessionCustomer, setCurFilter} = useSessionContext();

    function handleAnalyticsClick(e){
        setCurFilter(null);
        props.setOpenDrawerFx(false);
        history("/Analytics");
    }

    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="Analytics" onClick={handleAnalyticsClick} sx={{cursor:"pointer"}}>
                <ListItemText primary="Analytics" />
            </ListItem>
            </>
        )
    }

    if(sessionCustomer.accessLevel < 25){
        return("");
    }

    return (
        <>
        <Button
            key='Analytics'
            onClick={handleAnalyticsClick}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
        >
            Analytics
        </Button>
        </>
    );
}



