import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';

export default function LoadListListItemLinkAddPull(props){
    //console.debug("RTS Break LoadListListItemLinkAddPull");

    const history = useNavigate();

    function handleClick(e){
        history("/LoadListAddPull", {state: {idGuid:props.idGuid}});
    };

    return(
        <Link onClick={handleClick} sx={{textDecoration:'none', cursor:'pointer'}}>
            <Box sx={{display:'flex'}}>
                <HandymanOutlinedIcon />
                <Typography sx={{fontWeight:'bold', pl:'2px'}}>Record A Pull</Typography>
            </Box>
        </Link>
    )
}
