import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import ManuReelsList from '../../components/ManuReels/ManuReelsList';
import ManuReelsFilter from '../../components/ManuReels/ManuReelsFilter';
import ManuReelsHeader from '../../components/ManuReels/ManuReelsHeader';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ManuReelsPage( props ){
    //console.debug("RTS Break ManuReelsPage");

    const {curFilter, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
 
    const [filter, setFilter] = useState();
    const [data, setData] = useState([]);
  

    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/Reels/GetReelsSummary";
        axios
        .post(
            apiURL,
            pFilter,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);

        
    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'ManuReelsPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({reelType:null, status:1});
            fetchData({reelType:null, status:1})
        }
    }, [props, curFilter, fetchData]); 


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '75vh';
        }
        return retValue;
    };    
  
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    
    return(
        <>
        <Box>
            <ManuReelsHeader />
            <ManuReelsFilter filter={filter} setFilterFx={setFilter}/>
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                <ManuReelsList data={data} />
            </Box>
        </Box>
        </>
    );
}
