import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import WorkSetsList from '../../components/WorkSets/WorkSetsList';
import WorkSetsHeader from '../../components/WorkSets/WorkSetsHeader';

import { useSessionContext } from '../../contexts/SessionContext';


export default function WorkSetsPage( props ){
    console.debug("RTS Break WorkSetsPage");

    const {sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [data, setData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        setIsLoading(true);
        let apiURL = apiRoot + "/WorkSets/GetWorkSetsByCustomer";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }, [apiRoot, userAuthToken.token]);
    

    useEffect(() => {
        props.setIsModalFx(false);
        fetchData(sessionCustomer.customer);
    }, [props, sessionCustomer, fetchData]);  


    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '80vh';
        }
        return retValue;
    }; 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <Box>
            <WorkSetsHeader />
            <Box sx={{mt:2, height:setTableHeight(), minHeight:'500px'}}>           
                <WorkSetsList data={data} />
            </Box>
        </Box>
    );
}
