import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function CustomersFilterSubscribed(props) {
    //console.debug("RTS Break ReelsFilterSubscribed");

    const [subscribed, setSubscribed] = useState(props.subscribed==null?-1:props.subscribed);
    const [repoSubscribed] = useState([{value:-1, label:"All"}, {value:0, label:"Not Subscribed"}, {value:1, label:"Subscribed"}]);


    function valChange(e, val){
        setSubscribed(val.value);
        props.setSubscribedFx(val.value);
        props.setFormDirtyFx(1);
    }

 
    return(
        <>
            <Autocomplete
                options={repoSubscribed}
                renderInput={(params) => <TextField {...params} label="Subscribed"  size="small" />}
                value={repoSubscribed.find(v => v.value===subscribed)}
                onChange={valChange}
                getOptionLabel={(repoSubscribed) => repoSubscribed.label}
                disableClearable={true}
                renderOption={(props, repoSubscribed) => (
                    <Box component="li" {...props} key={repoSubscribed.value}>
                        {repoSubscribed.label}
                    </Box>
                )}
            />
        </>
    );
}
