import Box from '@mui/material/Box';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import { useSessionContext } from '../../contexts/SessionContext';

export default function LoadsDueDetailList(props){
    //console.debug("RTS Break LoadsDueDetailList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const columns = [
        {
            field: 'reelSerial', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.reel.serialNumber
            },
        },
        {
            field: 'reelType', 
            headerName: "Reel Type", 
            description:"Reel Type", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.reelType.name
            },
        },  
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Product Name", 
            flex:4,
            valueGetter: (cellValues) => {
                return cellValues.row.product.name
            },
        },
        {
            field: 'customerPo', 
            headerName: "P.O.", 
            description:"Purchase Order", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.customerPo
            },
        },

        {
            field: 'deferralPeriod', 
            headerName: "Deferral (Months)", 
            description:"Deferral Period", 
            flex:1,
            valueGetter: (cellValues) => {
                return cellValues.row.deferralPeriod.toLocaleString()
            },
        },
        {
            field: 'shipDate', 
            headerName: "Date Shipped", 
            description:"Reel Ship Date", 
            flex:1,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            }              
        },
        {
            field: 'deferralDaysLeft', 
            headerName: "Days Left", 
            description:"Deferral Days Left", 
            flex:1,
            valueGetter: (cellValues) => {
            
                let curVal = '';
                if(cellValues.row.deferralDaysLeft>=0){
                    curVal = cellValues.row.deferralDaysLeft.toLocaleString();
                }
                else{
                    curVal = cellValues.row.deferralDaysLeft.toLocaleString();
                }
                return curVal;
            }
        }                
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
