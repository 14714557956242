import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export default function EditCancel(props) {
    const history = useNavigate();

    return (
        <Button variant="contained" onClick={handleCancel} startIcon={<ArrowLeftIcon />}>Back To Work Sets</Button>
    );

    function handleCancel(e){
        e.preventDefault();
        history(-1);
    }

}
