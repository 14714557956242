import {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import LoadListListItemProjectSummary from "./LoadListListItemProjectSummary";

export default function LoadListListItemProject(props){
    //console.debug("RTS Break LoadListListItemProject");

    const[data, setData] = useState(props.data);
    const[showProjectDialog, setShowProjectDialog] = useState(false);

    function openProject(){
        if(data.project==null){

        }
        else{
            setShowProjectDialog(true);
        }
    }

    
    if(data.project!=null){
        return (
            <>
            <Dialog sx={{"& .MuiDialog-container": {"& .MuiPaper-root": {width: "100%", maxWidth: "400px",},},}} open={showProjectDialog} onClose={()=>{setShowProjectDialog(false);}}>
                <DialogTitle>Project Details</DialogTitle>
                <DialogContent>
                        <LoadListListItemProjectSummary data={data} setDataFx={setData} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => {setShowProjectDialog(false);}}>Close</Button>
                </DialogActions>
            </Dialog>             
            <Link onClick={() => {openProject();}} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Box sx={{display:'flex'}}>
                    <Typography variant="body2" sx={{fontWeight:'bold'}}>Project:</Typography>
                    <Typography variant="body2" sx={{ml:'2px'}}>{data.project==null?"No Project":data.project.name}</Typography>
                </Box>
            </Link>
            </>
        )
    }
    else{
        return (
            <Box sx={{display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Project:</Typography>
                <Typography variant="body2" sx={{ml:'2px'}}>{data.project==null?"No Project":data.project.name}</Typography>
            </Box>
        )

    }
}

