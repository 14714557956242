import { useNavigate } from 'react-router-dom';
import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { format, parseISO } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Typography } from '@mui/material';
import { Tooltip } from '@mui/material';

import WorkItemsEdit2 from './WorkItemsEdit2';

export default function WorkItemsList2Body(props){
    //console.debug("RTS Break WorkItemsList2Body");

    const history = useNavigate();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody] = useState("");
    const [errorTitle] = useState("Validation Error");
    const [showEditModal, setShowEditModal] = useState(false);
    const [curWorkItem] = useState(null);

    function handleClickComplete(e, cellValues){
        history("/WorkItemsEdit", {state:{mode:0, workItem:props.data}});
    }

    function handleClickUpdate(e, cellValues){
        history("/WorkItemsEdit", {state:{mode:1, workItem:props.data}});
    }    

    function handleClickDelete(e, cellValues){
        history("/WorkItemsDelete", {state:{mode:1, workItem:props.data}});
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Dialog open={showEditModal} onClose={()=>{setShowEditModal(false);}}>
            <DialogContent>
                <WorkItemsEdit2 workSet={props.data} workItem={curWorkItem} setShowEditModalFx={setShowEditModal} />
            </DialogContent>
        </Dialog>  
        <Grid container sx={{py:1, px:1, my:1, border:'1px solid black', borderRadius:'10px', backgroundColor:props.data.workItemStatus !== null?'#3242':'inherit'}}>
            <Grid item xs={12} sm={props.mode===1?12:9}>
                <Grid container>
                    <Grid item xs={12} sm={5}>
                        <Box>
                            <Typography variant='h5' sx={{textAlign:'center'}}>Lateral Fuse to Replace</Typography>
                        </Box>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Id:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography sx={{ml:1}}>{props.data.value2}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4}>
                                <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Phase:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography sx={{ml:1}}>{props.data.value3}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={4}>
                                <Typography sx={{fontWeight:'bold', textAlign:'right'}}>Size:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography sx={{ml:1}}>{props.data.value4}</Typography>
                            </Grid>
                        </Grid>

                        <Box>
                            <Typography variant='h5' sx={{textAlign:'center', mt:1}}>Location</Typography>
                        </Box>
                        <Box>
                            <a target="_blank" rel="noreferrer" 
                                sx={{textDecoration:'none', cursor:'pointer', color:'#1976d2', ml:1}} 
                                href={props.data.value6} 
                            >
                                <Typography sx={{textAlign:'center'}}>{props.data.value5}</Typography>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Box sx={{textAlign:'center', mt:{xs:1, sm:0}}}>
                            <Typography variant='h5'>Network Lineage</Typography>
                        </Box>
                        <Box sx={{textAlign:'center', mt:{xs:1, sm:0}}}>
                            <Typography sx={{textAlign:'center'}}>{props.data.value7}</Typography>
                        </Box>
                       
                    </Grid>
                </Grid>
            </Grid>
            {props.mode === 0
            ?
            <Grid item xs={12} sm={3} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    {
                    props.data.workItemStatus !== null
                    ?
                        <>
                        <Box sx={{textAlign:'left', pt:1, pb:1}}>
                            <Typography sx={{display:'block', fontSize:'10px', p:0, m:0}}>{props.data.workItemStatus==null?'':props.data.workItemStatus.name}</Typography>
                            <Typography sx={{display:'block', fontSize:'10px', p:0, m:0}} >{format(parseISO(props.data.modifyDate), "MM-dd-yyyy")}</Typography>
                            <Typography sx={{display:'block', fontSize:'10px', p:0, m:0}}>{props.data.completedBy}</Typography>
                        </Box>
                        <Box sx={{textAlign:'center'}}>
                            <Link onClick={(event) => {handleClickUpdate(event, props.data);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
                                    <EditIcon sx={{mx:1}} />
                                </Box>
                            </Link>                                
                        </Box>
                        </>
                    :
                        <>
                        <Button
                            variant='contained'
                            key='Receive'
                            onClick={(event) => {handleClickComplete(event, props.data);}}
                            sx={{ my:{xs:1,sm:0}, mx:{xs:0,sm:1}}}
                        >
                            Complete
                        </Button>
                        <Box sx={{textAlign:'right', pl:3}}>
                        <Tooltip title="Delete">
                            <Link onClick={(event) => {handleClickDelete(event, props.data);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
                                    <DeleteForeverIcon sx={{mx:1, color:"red"}} />
                                </Box>
                            </Link>
                        </Tooltip>                                
                    </Box>
                    </>
                    }
                    </Box>
            </Grid>
        :
            ''
        }
        </Grid>
        </>
);



}
