import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import EditBack from '../components/EditBack';
import NamedLocationsEdit from '../components/NamedLocations/NamedLocationsEdit';

export default function NamedLocationsPageEdit( props ){
    //console.debug("RTS Break NamedLocationsPageEdit");

    const location = useLocation();
    const idGuid = location.state.idGuid;
    const display = location.state.display;
    const mode = location.state.mode;

    const [formDirty, setFormDirty] = useState(0);
    
    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]); 


    return(
        <Container component="main" maxWidth="md" sx={{mb:5}}>
            <CssBaseline />
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
                {formDirty===0
                ?
                    <Typography variant="h5" >Edit Named Location</Typography>
                :
                    <Typography variant="h5" sx={{fontStyle:'italic'}}>Edit Named Location *</Typography>
                }
            </Box>
            <Box>
                <NamedLocationsEdit idGuid={idGuid} display={display} mode={mode} formDirty={formDirty} setFormDirtyFx={setFormDirty} />
            </Box>
            <Box sx={{my:2, width:'100%', display:'flex', justifyContent:'start', alignContent:'center'}}>
                <EditBack formDirty={formDirty} />
            </Box>              
        </Container>
    );
}
