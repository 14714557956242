import React from 'react'
import axios from 'axios';
import {useState} from 'react';
import {useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import EditCancel from '../EditCancel';
import EditRegion from '../EditRegion';


import { useSessionContext } from '../../contexts/SessionContext';

export default function PalletPlantsEdit(props) {
    //console.debug("RTS Break PalletPlantsEdit");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const mode = location.state.mode;

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [name, setName] = useState(mode===1?data.name:'');
    const [shortName, setShortName] = useState(mode===1?data.shortName:'');
    const [address1, setAddress1] = useState(mode===1?data.address1!==null?data.address1:'':'');
    const [address2, setAddress2] = useState(mode===1?data.address2!==null?data.address2:'':'');
    const [city, setCity] = useState(mode===1?data.city!==null?data.city:'':'');
    const [region, setRegion] = useState(mode===1?data.region:null);
    const [zip, setZip] = useState(mode===1?data.zip!==null?data.zip:'':'');
    const [idSap, setIdSap] = useState(mode===1?data.idSap!==null?data.idSap:'':'');


    function handleSubmit(e) {
        e.preventDefault();

        let idGuid = "";
        if(mode > 0){
            idGuid = data.idGuid;
        }

        let apiObj = {
            IdGuid: idGuid,
            IdSap: idSap===''?null:idSap,
            Name: name===''?null:name,
            ShortName: shortName===''?null:shortName,
            Address1: address1===''?null:address1,
            Address2: address2===''?null:address2,
            City: city===''?null:city,
            Region: region,
            Zip: zip===''?null:zip,
            ModifyUser: user.userName,
        }

        if(mode > 0){
            let apiURL = apiRoot + "/PalletPlants/UpdatePalletPlant/" + idGuid;
            axios
            .put(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Update Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS UpdatePalletPlant Error", error);
                setShowErrorModal(true);
            });
        }
        else{
            apiObj.createUser = user.userName;
            let apiURL = apiRoot + "/PalletPlants/CreatePalletPlant";
            axios
            .post(
                apiURL,
                apiObj, 
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                history(-1);
            })
            .catch(error => {
                setErrorTitle("Insert Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS CreatePalletPlant Error", error);
                setShowErrorModal(true);
            });
        }
    } 


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>  
        <Box>
            <form id="PalletPlantsForm" onSubmit={handleSubmit}>
                <TextField
                    id="tbName"
                    margin="normal"
                    fullWidth
                    label="Name"
                    size="small"
                    required
                    value={name}
                    onChange={(e) => {setName(e.target.value);}}
                />
                <TextField
                    id="tbShortName"
                    margin="normal"
                    fullWidth
                    label="Short Name"
                    size="small"
                    required
                    value={shortName}
                    onChange={(e) => {setShortName(e.target.value);}}
                />
                <TextField
                    id="tbIdSap"
                    margin="normal"
                    fullWidth
                    label="SAP Id"
                    size="small"
                    value={idSap}
                    onChange={(e) => {setIdSap(e.target.value);}}
                />
                <Typography variant="h6" sx={{mt:2, mb:2}}>Address</Typography>
                <TextField
                    id="tbAddress1"
                    margin="none"
                    fullWidth
                    label="Street Address"
                    size="small"
                    value={address1}
                    onChange={(e) => {setAddress1(e.target.value);}}
                />
                <TextField
                    id="tbAddress2"
                    margin="dense"
                    fullWidth
                    size="small"
                    value={address2}
                    onChange={(e) => {setAddress2(e.target.value);}}
                />
                <TextField
                    id="tbCity"
                    margin="normal"
                    fullWidth
                    label="City"
                    size="small"
                    value={city}
                    onChange={(e) => {setCity(e.target.value);}}
                />
                <Grid container>
                    <Grid item xs={6} sx={{pr:1}}>
                        <EditRegion region={region} setRegionFx={setRegion} />
                    </Grid>
                    <Grid item xs={6} sx={{pl:1}}>
                        <TextField
                            id="tbZip"
                            margin="normal"
                            fullWidth
                            label="Zip Code"
                            size="small"
                            value={zip}
                            onChange={(e) => {setZip(e.target.value);}}
                        />
                    </Grid>
                </Grid>

                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <EditCancel />
                </Box>
            </form>
        </Box>
        </>
    );
}

