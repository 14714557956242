import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function LoadProjectEditHelp(props) {
    //console.debug('RTS Break LoadProjectEditHelp');

    return (
        <Box>
            <Typography>
				The Mark Reel screen allows you to add a flag indicating that the reel is 
                ready to be returned.  Adding a mark to a reel is for internal use only with 
                no external communication performed.  The next step in the 
                reel's life cycle is to add the reel to a Pickup Request.  
            </Typography>
        </Box>
    );

}
