import {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Loading from '../Loading';

export default function LoadListDetailsCrew(props){
    console.debug("RTS Break LoadListDetailsCrew");

    const [crew, setCrew] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const [showNotesModal, setShowNotesModal] = useState(false);


    useEffect(() => {
        setCrew(props.data);
        setIsLoading(false);
    }, [props.data]); 


    function getCrewLeadName(){
        if(crew==null){
            return "";
        }
        else{
            if(crew.crewLead==null){
                return "";
            }
            else{
                return crew.crewLead.firstName + " " + crew.crewLead.lastName;
            }
        }
    }


    function getCrewLeadPhone(){
        if(crew==null){
            return "";
        }
        else{
            if(crew.crewLead==null){
                return "";
            }
            else{
                return crew.crewLead.phoneNumber;
            }
        }
    }    

    
    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <>
        <Dialog open={showNotesModal} onClose={()=>{setShowNotesModal(false);}}>
            <DialogTitle>Crew Notes</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowNotesModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>                  
        <Box sx={{p:1}}>        
            <Box sx={{mt:.5, display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Crew Name:</Typography>
                <Typography variant="body2" sx={{ml:1}}>{crew==null?'':crew.name}</Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Crew Lead:</Typography>
                        <Typography variant="body2" sx={{ml:1}}>{getCrewLeadName()}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Phone:</Typography>
                        <Typography variant="body2" sx={{ml:1}}>{getCrewLeadPhone()}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{mt:.5, display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Short Name:</Typography>
                <Typography variant="body2" sx={{ml:1}}>{crew==null?'':crew.shortName}</Typography>
            </Box>
            <Box sx={{mt:.5, display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Notes:</Typography>
                <Typography variant="body2" sx={{ml:1}}>{crew==null?'':crew.notes}</Typography>
            </Box>
        </Box>
        </>
    )
}

