import { BrowserRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Main from './pages/Main';
import { SessionContextProvider } from './contexts/SessionContext';

import './index.css';

import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  'c232c582f8ee3fe9b47f29bb60880fbeTz05OTM1MCxFPTE3NTk2MDQ3NjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
);


const theme = createTheme({
  palette: {
    //mode:'dark',
    // primary: {
    //   main: '#96694c'
    // }
  },
  overrides: {
    MuiButton:{
      minWidth:'150px'
    }
  }
});


export default function App(props) {

  const pwr = props.pwReset===null?0:props.pwReset;
  const pwu = props.pwUser===null?'':props.pwUser;
  const pwt = props.pwToken===null?'':props.pwToken;

  return (
    <ThemeProvider theme={theme}>
      <SessionContextProvider>
        <BrowserRouter>
          <Box id="appBox">
            <Main pwr={pwr} pwu={pwu} pwt={pwt} />
          </Box>
        </BrowserRouter>
      </SessionContextProvider>      
    </ThemeProvider>
  );  
}
