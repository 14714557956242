import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

export default function LoadsList(props){
    console.debug("RTS Break LoadsList");
    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    
    function handleClick(e, cellValues){
        history("/LoadsEdit", {state: { idGuid:cellValues.row.idGuid, 
                                        display:cellValues.row.reel!=null?cellValues.row.reel:'N/A',
                                        displayExt:cellValues.row.tracker!=null?cellValues.row.tracker:'N/A', 
                                        mode:1}});
    }


    const columns = [
        {
            field: '',
            description:"Edit Load", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'soldToCustomerName', 
            headerName: "Sold To Customer", 
            description:"Sold To Customer Name", 
            flex:4,
        },
        {
            field: 'soldToCustomerIdSap', 
            headerName: "Sold To Id", 
            description:"Sold To Customer Id", 
            flex:3,
        },
        {
            field: 'assignedToCustomerName', 
            headerName: "Assigned Customer", 
            description:"Assgined Customer Name", 
            flex:4,
        },
        {
            field: 'assignedToCustomerIdSap', 
            headerName: "Assigned Id", 
            description:"Assgined Customer Id", 
            flex:3,
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Product Name", 
            flex:4,
        },
        {
            field: 'productIdSap', 
            headerName: "Product Id", 
            description:"Product Id", 
            flex:4,
        },
        {
            field: 'reel', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:3,
        },
        {
            field: 'reelType', 
            headerName: "Reel Type", 
            description:"Reel Type", 
            flex:3,
        },
        {
            field: 'tracker', 
            headerName: "Tracker", 
            description:"Tracker ESN", 
            flex:3,
        },
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Load Ship Date", 
            flex:2,
            valueGetter: (value, row) => {
                if(value == null){
                    return null;
                }

                return (
                    new Date(value)
                );
            },
            type:"date",
            valueFormatter: (value) => {
                if(value == null){
                    return null;
                }
                
                const formatObj = new Intl.DateTimeFormat("en-US");
                return (
                    formatObj.format(new Date(value))
                );
            },

        },                           
        {
            field: 'status', 
            headerName: "Status", 
            description:"Load Status", 
            flex:3,
            renderCell: (cellValues) => {
                let retValue = 'Unknown';
                switch(cellValues.value){
                    case 1:
                        retValue = 'Active (1)';
                        break;
                    case 25:
                        retValue = 'Marked for Pickup (25)';
                        break;
                    case 50:
                        retValue = 'On Pickup Request (50)';
                        break;
                    case 75:
                        retValue = 'Submitted for Pickup (75)';
                        break;
                    case 100:
                        retValue = 'Returned (100)';
                        break;
                    default:
                        retValue = 'Unknown';
                        break;
                }

                return retValue;
            },
        },
        {
            field: 'customerVisibleFlag', 
            headerName: "Visible", 
            description:"Customer Visible", 
            flex:3,
            renderCell: (cellValues) => {
                let retValue = 'Unknown';
                switch(cellValues.value){
                    case 0:
                        retValue = 'Not Visible';
                        break;
                    case 1:
                        retValue = 'Visible';
                        break;
                    default:
                        retValue = 'Unknown';
                        break;
                }

                return retValue;
            },
        },                           
        {
            field: 'customerPo', 
            headerName: "Customer PO", 
            description:"Customer PO", 
            flex:3,
        },
        {
            field: 'idSapOrder', 
            headerName: "SAP Order", 
            description:"SAP Order", 
            flex:3,
        },
        {
            field: 'idSapBol', 
            headerName: "BOL", 
            description:"Bill of Lading", 
            flex:3,
        },
        {
            field: 'idSapPackingList', 
            headerName: "Packing List", 
            description:"Packing List", 
            flex:3,
        },
        {
            field: 'idSapBatchNo', 
            headerName: "Batch No.", 
            description:"Batch Number", 
            flex:3,
        }          


    ];


    function getVisibilityModel(){
        if(smallForm){
            return {productIdSap:false, reelType:false, soldToCustomerIdSap:false, assignedToCustomerName:false, assignedToCustomerIdSap:false, tracker:false, status:false, customerVisibleFlag:false, customerPo:false, idSapOrder:false, idSapBol:false, idSapPackingList:false, idSapBatchNo:false}
        }
        else{
            return {reelType:false, soldToCustomerIdSap:false, assignedToCustomerName:false, assignedToCustomerIdSap:false, tracker:false, status:false, customerVisibleFlag:false, customerPo:false, idSapOrder:false, idSapBol:false, idSapPackingList:false, idSapBatchNo:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{width:'100%', height:'100%', minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}