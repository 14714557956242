import {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import Loading from '../Loading';
import LoadListSEtCrewDetailsMembersList from './LoadListSetCrewDetailsMembersList';

export default function LoadListSetCrewDetails(props){
    console.debug("RTS Break LoadListSetCrewDetails");

    const [crew, setCrew] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const [showMembersModal, setShowMembersModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);


    useEffect(() => {
        setCrew(props.data);
        setIsLoading(false);
    }, [props.data]); 


    function getCrewLeadName(){
        if(crew==null){
            return "";
        }
        else{
            if(crew.crewLead==null){
                return "";
            }
            else{
                return crew.crewLead.firstName + " " + crew.crewLead.lastName;
            }
        }
    }


    function getCrewLeadPhone(){
        if(crew==null){
            return "";
        }
        else{
            if(crew.crewLead==null){
                return "";
            }
            else{
                return crew.crewLead.phoneNumber;
            }
        }
    }    

    
    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <>
        <Dialog open={showMembersModal} fullWidth={true} maxWidth="sm" onClose={()=>{setShowMembersModal(false);}}>
            <DialogTitle>Crew Members</DialogTitle>
            <DialogContent>
                <LoadListSEtCrewDetailsMembersList data={crew==null?[]:crew.crewMembers} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowMembersModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showNotesModal} onClose={()=>{setShowNotesModal(false);}}>
            <DialogTitle>Crew Notes</DialogTitle>
            <DialogContent>
                <LoadListSEtCrewDetailsMembersList />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowNotesModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>                  
        <Box>
            <Typography variant="h6" sx={{fontWeight:'bold'}}>Details:</Typography>
        </Box>
        <Box sx={{border:'1px solid #e2e8f0', borderRadius:'5px', p:1}}>        
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Crew Lead:</Typography>
                        <Typography variant="body2" sx={{ml:1}}>{getCrewLeadName()}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2" sx={{fontWeight:'bold'}}>Phone:</Typography>
                        <Typography variant="body2" sx={{ml:1}}>{getCrewLeadPhone()}</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{mt:.5, display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Short Name:</Typography>
                <Typography variant="body2" sx={{ml:1}}>{crew==null?'':crew.shortName}</Typography>
            </Box>
            <Box sx={{mt:.5, display:'flex'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Notes:</Typography>
                <Typography variant="body2" sx={{ml:1}}>{crew==null?'':crew.notes}</Typography>
            </Box>
            <Link onClick={(event) => {setShowMembersModal(true);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                <Box sx={{mt:.5, display:'flex'}}>
                    <Typography variant="body2" sx={{fontWeight:'bold'}}>Members:</Typography>
                    <Typography variant="body2" sx={{ml:1}}>{crew==null?0:crew.crewMembers.length}</Typography>
                </Box>
            </Link>            
        </Box>
        </>
    )
}

