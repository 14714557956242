import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpEditOverview(props) {
    //console.debug('RTS Break HelpEditOverview');

    return (
        <Box>
            <Typography variant="h6">Overview</Typography>
            <Typography>
                The Company Profile page allows you to view and edit settings that may affect the experience
                within the application for all users.  In addition, you have access to audit the users that 
                have been granted access to the data associated with the organization.
            </Typography>

            <Typography variant="h6">Fields</Typography>
            <Typography sx={{mt:1}}>
            <table>
                <tbody>
                <tr>
                    <td>
                        Deferral Notice Email
                    </td>
                    <td>
                        The email address that Deferral NOtices will be sent to when a reel hits the 90, 60, 30, & 0
                        day thresholds for the end of the deferral period.
                    </td>
                </tr>
                <tr>
                    <td>
                        Address
                    </td>
                    <td>
                        The physical address and phone number for the company.
                    </td>
                </tr>
                </tbody>
            </table>     
            </Typography>

           
        </Box>
    );

}
