import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

export default function PalletsList(props){
    //console.debug("RTS Break PalletDashboardList");

    const history = useNavigate();

    function handleClick(e, cellValues, pMode){
        history("/PalletAction", {state:{data:cellValues.row, mode:pMode}});
    }

    function handleDrill(e, cellValues, pMode){
        props.openDetailsFx(cellValues.row);
    }


    const columns = [
        {
            field: 'shortName', 
            headerName: "Plant/Partner", 
            description:"Plant/Partner Name", 
            flex:3,
            renderCell: (cellValues) => {
                return (
                    <Link
                        onClick={(event) => {handleDrill(event, cellValues);}}
                        sx={{cursor:'pointer', fontWeight:'bold', textDecoration:'none'}}
                    >
                        {
                        cellValues.row.idSap!=null
                        ?cellValues.row.shortName + " (" + cellValues.row.idSap + ")"
                        :cellValues.row.shortName
                        }
                    </Link>
                );
            },
        },
        {
            field: 'pallets', 
            headerName: "Pallets", 
            description:"Pallets On Site", 
            flex:1
        },
        {
            field: 'rc1',
            headerName: '',
            description:"Send Pallets", 
            width:200,
            maxWidth:175,
            minWdith:170,
            renderCell: (cellValues) => {
                return (
                    <Button
                        variant='contained'
                        key='Send'
                        onClick={(event) => {handleClick(event, cellValues, 1);}}
                        sx={{ mx: 1 }}
                    >
                        Send Pallets
                    </Button>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
        {
            field: 'rc2',
            headerName: '',
            description:"Receive Pallets", 
            width:200,
            maxWidth:175,
            minWdith:170,
            renderCell: (cellValues) => {
                return (
                    <Button
                        variant='contained'
                        key='Receive'
                        onClick={(event) => {handleClick(event, cellValues, 100);}}
                        sx={{ mx: 1 }}
                    >
                        Receive Pallets
                    </Button>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },          
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
