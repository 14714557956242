import axios from 'axios';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';


import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelsEditTrackerSequenceId(props) {
    //console.debug("RTS Break ReelsEditTrackerSequenceId");

    const {user, userAuthToken, apiRoot} = useSessionContext();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState("");
    const [errorTitle, setErrorTitle] = useState("Validation Error");

    const [idSeq, setIdSeq] = useState(props.tracker.idSeq);
    
    async function handleSubmit(e) {
        e.preventDefault();

        if(idSeq === null || idSeq === ""){
            setErrorTitle("Validation Error");
            setErrorBody("Tracker Sequence is required");
            setShowErrorModal(true);
            return;
        }

        if(idSeq >= 1000000000){
            setErrorTitle("Validation Error");
            setErrorBody("Tracker Sequence is to large");
            setShowErrorModal(true);
            return;
        }
        
        let idSeqCheck = await validateIdSeq();
        if(idSeqCheck === -1){
            return;
        }

        saveData();
    }


    function validateIdSeq(){
        return new Promise(function(resolve, reject){
            let apiURL = apiRoot + "/Trackers/GetTrackerByIdSeq/" + idSeq
            axios
            .get(
                apiURL,
                {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
            )
            .then(response => {
                if (response.data) {
                    if(response.data.idGuid && response.data.idGuid.length > 0 && response.data.idGuid !== props.tracker.idGuid){
                        setErrorTitle("Validation Error");
                        setErrorBody("A Tracker with Sequence " + idSeq + " already exists");
                        console.log("RTS Error", "Tracker Sequence already exists");
                        setShowErrorModal(true);
                        resolve(-1);
                    }
                    else{
                        resolve(0);
                    }                    
                }
                else{
                    resolve(0);
                }
            })
            .catch(error => {
                setErrorTitle("Validation Error");
                setErrorBody("An error has occured.  Please review the console log for details.");
                console.log("RTS Tracker Validation Error", error);
                setShowErrorModal(true);
                reject(-1);
            });
        });
    }


    function saveData(){

        let apiObj = props.tracker;
        apiObj.idSeq = idSeq;
        apiObj.ModifyUser = user.userName

        let apiURL = apiRoot + "/Trackers/UpdateTrackerIdSeq";
        axios
        .put(
            apiURL,
            apiObj, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            props.setTrackerFx(apiObj);
            props.setShowSequenceModalFx(false);
        })
        .catch(error => {
            setErrorTitle("Update Error");
            setErrorBody("An error has occured.  Please review the console log for details.");
            console.log("RTS UpdateTracker Error", error);
            setShowErrorModal(true);
        });
    } 


    function handleCancel(e){
        e.preventDefault();
        props.setShowSequenceModalFx(false);       
    }


    return (
        <>
        <Dialog open={showErrorModal} onClose={()=>{setShowErrorModal(false);}}>
            <DialogTitle>{errorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{errorBody}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setShowErrorModal(false);}}>Close</Button>
            </DialogActions>
        </Dialog>
        <Box>
            <form id="trackerSequenceId" onSubmit={handleSubmit}>
                <TextField
                    id="tbSequenceId"
                    margin="normal"
                    fullWidth
                    label="Tracker Sequence"
                    size="small"
                    value={idSeq}
                    onChange={(e) => {setIdSeq(e.target.value);}}
                />                                               
                <Box sx={{textAlign:'center', mt:3}}>
                    <Button variant="contained"  type="submit" sx={{mr:2}}>Save</Button>
                    <Button variant="contained" onClick={handleCancel}>Cancel</Button>
                </Box>
            </form>
        </Box>
        </>
    );
}
