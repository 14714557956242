import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';

export default function PalletssHeader(props){
    //console.debug("RTS Break PalletssHeader");

    const history = useNavigate();

    function handleClick(e){
        history("/PalletsEdit", {state:{data:{}, mode: 0}});
    }

    return (
        <Grid container sx={{mb:1}}>
            <Grid item xs={6}>
                <Typography variant="h5">Pallets</Typography>
            </Grid>
            <Grid item xs={6} sx={{textAlign:'right'}}>
                <Button type="button" variant="contained" onClick={handleClick}>
                    New Pallet
                </Button>
            </Grid>
        </Grid>
    )
}
