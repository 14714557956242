import PalletTurnDetailsHeader from './PalletTurnDetailsHeader'
import PalletTurnDetailsList from './PalletTurnDetailsList'

export default function PalletTurnDetails(props){
    //console.debug("RTS Break PalletTurnDetails");

    return (
        <>
        <PalletTurnDetailsHeader data={props.data} />
        <PalletTurnDetailsList data={props.data} />
        </>
    );
}
