import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error'; 

export default function PickupRequestLoadsList(props){
    //console.debug("RTS Break PickupRequestLoadsList")

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));


    function handleClick(e, cellValues){
        props.removeLoadFx(cellValues.row);
    }

    const columns = [
        {
            field: "Error",
            headerName: "",
            description:"Error", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                if(cellValues.row.valError){
                    return (
                        <Tooltip title={cellValues.row.valError}>
                            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:1}}>
                                <ErrorIcon sx={{color:"red"}} />
                            </Box>
                        </Tooltip>
                    );
                }
                else{
                    return (
                        ""
                    )
                }
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
        {
            field: 'serialNumber', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:2,
            renderCell: (cellValues) => {
                return cellValues.row.reel.serialNumber;
            },
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Southwire Product Id", 
            flex:3,
            renderCell: (cellValues) => {
                return cellValues.row.product.name;
            },
 
        },
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Reel Ship Date", 
            flex:2,           
            valueFormatter: (value, row) => {
                if(value == null){
                    return '';
                }

                return (
                    format(parseISO(value), "MM-dd-yyyy")
                );
            }, 
        },
        {
            field: '',
            description:"Remove Reel", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                if(cellValues.row.pickupRequestDate==null){
                    return (
                        
                        <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                            <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                                <ClearIcon sx={{color:"red"}} />
                            </Link>
                        </Box>
                    );
                }
                else{
                    return (
                        ""
                    )
                }
            },
            align:"center",
            sortable:false,
            disableColumnMenu:true
        },        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {productName:false, shipDate:false}
        }
        else{
            return {}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }


    return (
        <Box sx={{display:'flex', flexDirection:'column', width:'100%', minHeight:'300px'}}>        
            <DataGridPro
                disableColumnFilter
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },                        
                    }
                }}                
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    density: getDensity() 
                }}                
            />
        </Box>        
    );
}
