import {useEffect} from 'react';
import Box from '@mui/material/Box';

import PalletActionHeader from '../../components/PalletAction/PalletActionHeader';
import PalletActionEdit from '../../components/PalletAction/PalletActionEdit';

export default function PalletActionPage( props ){
    //console.debug("RTS Break PalletActionPage");
 
    useEffect(() => {
        props.setIsModalFx(true);
    }, [props]);  
    

    return(
        <Box>
            <PalletActionHeader data={props.data} />
            <Box sx={{mt:2}}>           
                <PalletActionEdit data={props.data} />
            </Box>
        </Box>
    );
}
