import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpListOverview(props) {
    //console.debug('RTS Break HelpListOverview');

    return (
        <Box>
            <Typography variant="h6">Overview</Typography>
            <Typography>
                Customer Parts allow users to specify company specific attributes for products they purchase 
                from Southwire. 
            </Typography>
            <Typography sx={{mt:1}}>
                To edit an existing record simply find the customer part in the list and click on the 
                pencil icon.   
            </Typography>
            <Typography sx={{mt:1}}>
                To create a new customer part click the New Part button in the upper right hand corner of the 
                screen.   
            </Typography>            
            <Typography variant="h6" sx={{mt:1}}>Filtering</Typography>
            <Typography >
                To filter the list of records you can use the quick filter drop down selections 
                above the list just below the page title.  Once you have entered the desired filter 
                criteria click the Filter button.  These filters will persist if you open any of 
                the records within the list.
            </Typography>
            <Typography sx={{mt:1}}>
                For more advanced control over the list you can use the menu items directly above
                the list of records.  Any options set within these menu options will not persist if 
                you open a record within the list.
            </Typography>
            <Typography sx={{mt:1}}>
                The last option is to use the search panel in the upper right hand corner of the 
                list.  Simply enter search text into the search bar and the table will filter the 
                list of records with any field that contains that text.   
            </Typography>
           
        </Box>
    );

}
