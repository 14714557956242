import {useState, useEffect, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';

import ManuReelsFilterStatus from './ManuReelsFilterStatus';
import ManuReelsFilterReelTypeList from './ManuReelsFilterReelTypeList';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ManuReelsFilter(props){
    //console.debug("RTS Break ManuReelsFilter");

    const {setCurFilter} = useSessionContext();

    const [status, setStatus] = useState();
    const [reelType, setReelType] = useState();
    const [formDirty, setFormDirty] = useState(0); 
    

    useEffect(() => {
        setStatus(props.filter.status);
        setReelType(props.filter.reelType);
    }, []);
    

    function setFilter(){
        setFormDirty(0);
        setCurFilter({key:'ManuReelsPage', filter:{reelType:reelType, status:status}});
        props.setFilterFx({reelType:reelType, status:status});
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <ManuReelsFilterReelTypeList reelType={props.filter.reelType} setReelTypeFx={setReelType} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center',sm:'left'}}}>
                <ManuReelsFilterStatus status={props.filter.status} setStatusFx={setStatus} setFormDirtyFx={setFormDirty} />
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:{xs:'center', sm:'left'}}}>
                <Button type="button" variant="contained" disabled={formDirty===1?false:true} onClick={setFilter}>
                    <FilterListIcon />
                    <Typography sx={{ml:1}}>Filter</Typography>
                </Button>
            </Grid>            
        </Grid>
    );
}
