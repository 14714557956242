import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function CustomersServicesContract(props) {
    //console.debug("RTS Break CustomerServicesContract");

    const [servicesContractFlag, setServicesContractFlag] = useState(props.servicesContractFlag);
    const [repoServicesContract] = useState([{value:0, label:"No"}, {value:1, label:"Yes"}]);


    function valChange(e, val){
        setServicesContractFlag(val.value);
        props.setServicesContractFlagFx(val.value);
    }

 
    return(
        <>
            <Autocomplete
                options={repoServicesContract}
                renderInput={(params) => <TextField {...params} label="Services Contract" margin='normal' required size="small" />}
                value={repoServicesContract.find(v => v.value==servicesContractFlag)}
                onChange={valChange}
                getOptionLabel={(repoServicesContract) => repoServicesContract.label + ' (' + repoServicesContract.value + ')'}
                renderOption={(props, repoServicesContract) => (
                    <Box component="li" {...props} key={repoServicesContract.value}>
                        {repoServicesContract.label + ' (' + repoServicesContract.value + ')'}
                    </Box>
                )}
            />
        </>
    );
}
