import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit'; 

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function CustomersLinkList(props){
    console.debug("RTS Break CustomerLinkList");

    const history = useNavigate();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const {userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState([]);


    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/CompanyLinks/GetCompanyLinksByCustomer/" + pFilter;
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        fetchData(props.idGuid);
    }, [fetchData, props.idGuid]);

    
    function handleClick(e, cellValues){
        history("/CompanyLinksEdit", {state:{idGuid:cellValues.row.idGuid, display:cellValues.row.name, mode:1}});
    }


    const columns = [
        {
            field: '',
            description:"Select Customer", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },        
        {
            field: 'name', 
            headerName: "Name", 
            description:"Customer Name", 
            flex:4,
            valueGetter: (value, row) => {
                return row.name;
            },            
        },
        {
            field: 'shortName', 
            headerName: "Short Name", 
            description:"Short Name", 
            flex:3,
        },
        {
            field: 'mailCity', 
            headerName: "City", 
            description:"City", 
            flex:3
        },
        {
            field: 'mailRegion', 
            headerName: "State", 
            description:"State", 
            flex:1
        },
        {
            field: 'mailZip', 
            headerName: "Zip", 
            description:"Zip Code", 
            flex:1
        },
        {
            field: 'status', 
            headerName: "Status", 
            description:"Reel Status", 
            flex:1,
            valueGetter: (value, row) => {
                if(value === 1){
                    return ('Active');
                }
                else{
                    return('Disabled');
                }
            }, 
        },
        {
            field: 'linkedId', 
            headerName: "Linked Id", 
            description:"Linked SAP Id", 
            flex:1,
            valueGetter: (value, row) => {
                if(row.linkCustomer == null){
                    return '';
                }

                return row.linkCustomer.idSap;
            }, 
        },         
        
    ];



    const setTableHeight = () => {
        let retValue;

        if(smallForm){
            retValue = 'auto';
        }
        else{
            retValue = '60vh';
        }
        return retValue;
    };    



    function getVisibilityModel(){
        if(smallForm){
            return {shortName:false, mailCity:false, mailRegion:false, zip:false, linkedId:false}
        }
        else{
            return {shortName:false, mailCity:false, mailRegion:false}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return (
        <>
        <Box sx={{textAlign:'left', mt:1}}>
            <Typography variant="h6" sx={{display:'inline'}}>Customer:</Typography>
            <Typography inline variant="h6" sx={{display:'inline', ml:1}}>{props.display}</Typography>
        </Box>
        <Box sx={{width:'100%', height:setTableHeight(), minHeight:'400px'}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                    }
                }}
                rows={data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 
                }}                
            />
        </Box>
        </>
    );
}
