import {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";


export default function EditCountry(props) {
    //console.debug("RTS Break EditCountry");

    const [country, setCountry] = useState(props.country);
    const [repoCountrys] = useState(
        [
            {
              "label": "Canada",
              "value": "CA"
            },
            {
              "label": "United States",
              "value": "US"
            },
            {
              "label": "Parent Company",
              "value": "Z0"
            }
          ]
    );


    function valChange(e, val){
        setCountry(val.value);
        props.setCountryFx(val.value);
        props.setFormDirtyFx(1);
    }

 
    return(
        <>
            <Autocomplete
                options={repoCountrys}
                renderInput={(params) => <TextField {...params} label="Country" margin='normal' size="small" />}
                value={repoCountrys.find(v => v.value===country)}
                onChange={valChange}
                getOptionLabel={(repoCountrys) => repoCountrys.label + ' (' + repoCountrys.value + ')'}
                renderOption={(props, repoCountrys) => (
                    <Box component="li" {...props} key={repoCountrys.value}>
                        {repoCountrys.label + ' (' + repoCountrys.value + ')'}
                    </Box>
                )}
            />
        </>
    );
}
