import {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import mapboxgl from 'mapbox-gl';

export default function LoadListMap( props ) {
    //console.debug("RTS Break LoadListMapSpatial");

    const map = useRef(null);

    useEffect(() => {
        if(map.current) return;

        map.current = new mapboxgl.Map({
            container: "mapContainer",
            accessToken: "pk.eyJ1IjoiamltcHRhayIsImEiOiJja3kzMXZyOHIwNnRvMnBwOGxrbWJ4ZDhiIn0.bWHzm3g7ZOvo1wcWf2w8fg",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [-85.0716746, 33.5666694],
            zoom: 9
        });
    });

    
    useEffect(() => {
        if(!map.current) return;
        
        var maxLng = 0, maxLat = 0, minLng = 0, minLat = 0, markerCount = 0;

        props.data.map((ld, idx) => {
            if(ld.currentLocation !== null){
                const coord = {lat:ld.currentLocation.lat, lng:ld.currentLocation.lng};
                markerCount = idx + 1;

                if (markerCount === 1) {
                    minLng = maxLng = ld.currentLocation.lng // longitude
                    minLat = maxLat = ld.currentLocation.lat; // latitude
                }
                else {
                    maxLng = ld.currentLocation.lng > maxLng ? ld.currentLocation.lng : maxLng;
                    minLng = ld.currentLocation.lng < minLng ? ld.currentLocation.lng : minLng;
                    maxLat = ld.currentLocation.lat  > maxLat ? ld.currentLocation.lat  : maxLat;
                    minLat = ld.currentLocation.lat  < minLat ? ld.currentLocation.lat  : minLat;
                }

                const elMarker = document.createElement('div');

                if(ld.tracker !== null){
                    elMarker.className = "rtsTrackerMarker";
                }
                else{
                    elMarker.className = "rtsMarker rtsDarkBackground";
                }
                const elNum = document.createElement('span');
                elNum.className = "rtsLightForeground";
                elNum.innerHTML = markerCount;
                elMarker.appendChild(elNum)

                let markerHTML = '<div><label style="font-size:1.5em; font-Weight:bold;">' + ld.reel.serialNumber + '</label></div>'
                + '<div><label>Product:</label><span>' + ld.product.name + '</span></div>'  
                + '<div><label style="font-Weight:bold; padding-top:5px;">Location Information</span></div>'
                + '<div><label style="padding-left:5px;">Date & Time:</label><span>' + formatDate(ld.currentLocation.locTimeStamp) + '</span></div>'
                + '<div><label style="padding-left:5px;">Latitude:</label><span>' + ld.currentLocation.lat + '</span></div>'
                + '<div><label style="padding-left:5px;">Longitude:</label><span>' + ld.currentLocation.lng + '</span></div>';
                
                if(ld.tracker!==null){
                    markerHTML = markerHTML + '<div style="text-align:center; margin-top:10px;"><span style="font-weight:bold;">' + ld.tracker.esn + '</span></div>';
                } 

                let marker = new mapboxgl.Marker(elMarker)
                    .setLngLat(coord)
                    .setPopup(new mapboxgl.Popup({offset: 30}).setHTML(markerHTML))
                    .addTo(map.current);

                return marker;
            }

            return null;
        });

        var sw = new mapboxgl.LngLat(minLng, minLat);
        var ne = new mapboxgl.LngLat(maxLng, maxLat);
        var llb = new mapboxgl.LngLatBounds(sw, ne);

        if(markerCount > 0){
            if(markerCount === 1){
                map.current.setCenter(sw);
            }
            else{
                map.current.fitBounds(llb,
                    {
                        padding: { top: 50, bottom: 50, left: 50, right: 50 } ,
                        animate: false
                    }
                );   
            }
        }     

    });  
    

    function formatDate(pDate){
        let dateParts = (pDate.split(/[T]/)[0]).split(/[-]/);
        const dateObject = dateParts[0].toString() + '-' + dateParts[1].toString() + '-' + dateParts[2].toString();
        return dateObject;
    }


    return(
        <Box id="mapContainer" style={{minHeight:"600px", maxHeight:"800px"}} ></Box>
    );
}
