import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import { DataGridPro} from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';

export default function CrewsMembersList(props){
    console.debug("RTS Break CrewMembersList")

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);


    function handleClick(e, cellValues){
        props.removeMemberFx(cellValues.row);
    }


    useEffect(() => {
        setData(props.data);
        setIsLoading(false);
    }, [props]); 

    
    const columns = [
        {
            field: 'name', 
            headerName: "Member", 
            description:"Member Name", 
            flex:2,
            valueGetter: (value, row) => {
                return row.appUser.firstName + " " + row.appUser.lastName;
            },
            hideable:false,
        },        
    ];


    function getVisibilityModel(){
        if(smallForm){
            return {}
        }
        else{
            return {}
        }
    }


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    }


    if(isLoading){
        return(
            <Loading />
        );
    };  


    return (
        <Box sx={{display:'flex', flexDirection:'column', width:'100%', minHeight:'300px'}}>        
            <DataGridPro
                disableColumnFilter
                rows={data} 
                columns={columns} 
                getRowId={row => row.appUser.userName}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    density: getDensity() 
                }}                
            />
        </Box>        



    );
}
