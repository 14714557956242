import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import GaugeChart from 'react-gauge-chart'

export default function LoadListListItemIndexLength(props) {
    //console.debug("RTS Break LoadListListItemIndexLength");

    const purchase = props.data.amt;
    const remains = Math.floor(props.data.amt - props.data.pullSum);
    const remainsPercent = remains/purchase;
    const remainsPercentDisp = (remains/purchase)*100;
    const netWeight = Math.floor(remains * props.data.product.weightPerFt);
    const consumedPercent = props.data.pullSum/purchase;
    const consumedPercentDisp = (props.data.pullSum/purchase)*100;


    function setRemnantDisplay(){

        if(props.data.status >= 75){
            return false;
        }

        let remains = Math.floor(props.data.amt - props.data.pullSum);

        if(props.data.product.productAlias.length > 0){
            let limit = props.data.product.productAlias[0].remnantLimit;
            
            if(limit > remains){
                return true;
            }
            else{
                return false;
            }
        }
        else{
            return false;
        }
    }

    
    return (
        <Stack sx={{textAlign:'center'}}>
            <Box sx={{width:'100px'}}>
                <GaugeChart id="lengthIdx" 
                    nrOfLevels={10} 
                    percent={consumedPercent}
                    hideText={true}
                    colors={["#00FF00", "#FF0000"]}
                    animate={!props.hideLinks}
                />
            </Box>
            {/* <Box sx={{mt:-1}}>
                <Typography variant="caption">[{remainsPercentDisp.toLocaleString("en-US", {maximumFractionDigits: 1})}%]</Typography>
            </Box> */}
            <Stack sx={{textAlign:'center'}}>
                {
                setRemnantDisplay()
                ?
                    <Typography variant="body2" sx={{fontWeight:'bold', color:'red'}}>Remnant</Typography>
                :
                    ''
                }
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Consumed</Typography>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2">{props.data.pullSum.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>ft</Typography>
                    </Box>
                </Box>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="caption">{consumedPercentDisp.toLocaleString(undefined, { maximumFractionDigits: 2 })}%</Typography>
                    </Box>
                </Box>                
            </Stack>            
            <Stack sx={{textAlign:'center'}}>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>Remaining</Typography>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="body2">{remains.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                        <Typography variant="body2" sx={{ml:'2px'}}>ft</Typography>
                    </Box>
                </Box>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Box sx={{display:'flex'}}>
                        <Typography variant="caption">{netWeight.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                        <Typography variant="caption" sx={{ml:'2px'}}>lbs</Typography>
                    </Box>
                </Box>                
            </Stack>
        </Stack>
    );
}
