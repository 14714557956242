import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import Error from '../Error';

import EditIcon from '@mui/icons-material/Edit'; 

import { useSessionContext } from '../../contexts/SessionContext';

export default function ProductAliasEditProductLookup(props){
    console.debug("RTS Debug ProductAliasEditProductLookup");

    const {userAuthToken, apiRoot} = useSessionContext();

    const theme = useTheme();
    const smallForm = useMediaQuery(theme.breakpoints.down("sm"));

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const fetchData = useCallback(() => {
        let apiURL = apiRoot + "/Products/GetAllProducts";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    },[apiRoot, userAuthToken.token]);

    
    useEffect(() => {
        fetchData();
    }, [fetchData]); 


    function handleClick(e, cellValues){
        props.setProductFx(cellValues.row);
    }


    const columns = [
        {
            field: "Edit",
            headerName: "",
            description:"Edit Product", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', alignContent:'center', pt:.5}}>
                        <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                            <EditIcon />
                        </Link>
                    </Box>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'idSap', 
            headerName: "Southwire Id", 
            description:"Southwire Product Id", 
            flex:2, 
        },   
        {
            field: 'name', 
            headerName: "Product", 
            description:"Southwire Product", 
            flex:4, 
        },
    ];

    if(hasError){
        return(
            <Error />
        );
    }

    if(isLoading){
        return(
            <Loading />
        );
    }

    function getVisibilityModel(){
        if(smallForm){
            return {}
        }
        else{
            return {}
        }
    };


    function getDensity(){
        if(smallForm){
            return "comfortable";
        }
        else{
            return "standard";
        }
    };


    return (
        <Box sx={{height:"400px"}}>
            <DataGridPro
                pagination={true}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar:{
                        showQuickFilter:true,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },                        
                    }
                }}
                rows={data} 
                columns={columns} 
                getRowId={row => row.idGuid}
                initialState={{
                    columns:{
                        columnVisibilityModel: getVisibilityModel()
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                    density: getDensity() 

                }}                
            />
        </Box>
    );
}
