import axios from 'axios';
import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Loading from '../components/Loading';
import Error from '../components/Error';
import LoadListList from '../components/LoadList/LoadListList';
import LoadListMap from '../components/LoadList/LoadListMap';
import LoadListFilter from '../components/LoadList/LoadListFilter';
import LoadListMode from '../components/LoadList/LoadListMode';
import LoadMarkEdit from '../components/LoadListMark/LoadListMarkEdit';
import LoadSetLocation from '../components/LoadSetLocation/LoadSetLocation';

import { useSessionContext } from '../contexts/SessionContext';


export default function LoadListPage( props ) {
    //console.debug("RTS Break LoadListPage");

    const {curFilter, user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [mode, setMode] = useState(0);
    const [data, setData] = useState([]);
    
    const [filter, setFilter] = useState();


    const fetchData = useCallback((pFilter) => {
        let apiURL = apiRoot + "/LoadList/GetLoadList";
        axios
        .post(
            apiURL,
            pFilter, 
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setData(response.data);
                setIsLoading(false);
                setHasError(false);
            }
            else{
                setIsLoading(false);
                setHasError(true);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    },[apiRoot, userAuthToken.token]);


    useEffect(() => {
        props.setIsModalFx(false);
        if(curFilter != null && curFilter.key === 'LoadListPage'){
            setFilter(curFilter.filter);
            fetchData(curFilter.filter);
        }
        else{
            setFilter({ User:user, 
                        Status:[1,25,50],
                        Customer:sessionCustomer.customer,
                        Orders:[], 
                        Projects:[], 
                        Products:[], 
                        ReelTypes:[],
                        Reels:[],
                        MinLength:0,
                        NamedLocations:[],
                        Pinned:-1,
                        Locked:-1,
                        SortBy:0
                    });

            fetchData({ User:user, 
                        Status:[1,25,50],
                        Customer:sessionCustomer.customer,
                        Orders:[], 
                        Projects:[], 
                        Products:[], 
                        ReelTypes:[],
                        Reels:[],
                        MinLength:0,
                        NamedLocations:[],
                        Pinned:-1,
                        Locked:-1,
                        SortBy:0
                    });

        }
    }, [props, user, sessionCustomer, curFilter, fetchData]); 

    
    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    return(
        <Box id="pageBox">
            <Box id="filterBox">
                <LoadListFilter filter={filter} setFilterFx={setFilter} />            
            </Box>
            <Box id="modeBox">
                <LoadListMode data={data} mode={mode} modeFx={setMode} reelCount={data===null?0:data.length} />            
            </Box>            
            <Box id="contentBox">
                {mode===-1?<Box justifyContent={"center"} alignItems={"center"} display={"flex"}><Typography variant='h4'>No Records Found</Typography></Box>:""}
                {mode===0?<LoadListList data={data} user={user} />:""}
                {mode===1?<LoadListMap data={data} user={user} />:""}
            </Box> 
        </Box>
    );
}

