import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

import ArrowBack from '@mui/icons-material/ArrowBack'; 

import { useSessionContext } from '../../contexts/SessionContext';

export default function EditBack( props ){
    //console.debug("RTS Break EditBack");
    
    const history = useNavigate();
    const {setCurFilter} = useSessionContext();

    const [showConfirmModal, setShowConfirmModal] = useState(false);


    function handleClick(e) {
            e.preventDefault();

        if(props.formDirty===1){
            setShowConfirmModal(true);
        }
        else{
            handleCancel();
        }
    }


    function handleCancel(){
        setCurFilter(null);
        history("/Dashboard");
    }

    return (
        <>
        <Dialog fullWidth={true} open={showConfirmModal}>
            <DialogTitle>Leave Page?</DialogTitle>
            <DialogContent>
                <Typography>Changes will be lost.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleCancel}>Yes</Button>
                <Button variant="contained" onClick={e => {setShowConfirmModal(false);}}>No</Button>
            </DialogActions>
        </Dialog>        
        <Button startIcon={<ArrowBack />} 
                variant="contained" 
                onClick={handleClick} 
                sx={{mr:2}}
        >Dashboard</Button>        
        </>
    );
}



