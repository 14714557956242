import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function LoadPullAdjustmentEditHelp(props) {
    //console.debug('RTS Break LoadPullAdjustmentEditHelp');

    return (
        <Box>
            <Typography>
                Pull Adjustments can be used to correct the current amount of product contained on a Reel.  
                If a previous entry has been made with an incorrect amount a Pull Adjustment can be added 
                with the corresponding difference to correct the amount of product remainging on the reel.
            </Typography>
            <Typography sx={{mt:1}}>
                The Length, Adjustment Date, and Notes fields are all required to be filled in.
            </Typography>
        </Box>
    );

}
