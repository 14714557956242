import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function HelpOverview(props) {
    //console.debug('RTS Break HelpOverview');

    return (
        <Box>
            <Typography>
                By adding a Pin to this load you are highlighting this Reel for a user.  Once pinned this Reel will 
                be listed on the user's dashboard in the Pinned Reel list.
            </Typography>
        </Box>
    );

}
