import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Error(props) {
  return (
    <Box sx={{display:'flex', 
              alignItems:'center', 
              justifyContent:'center', 
              flexDirection:'column', 
              height:'100%', 
              p:0, 
              m:0,
              border:'1px solid grey', 
              borderRadius:1}}>
        <Typography variant='h5' sx={{color:'red'}}>An error has occured</Typography>
        <Typography variant="caption">Please review the browser console for details</Typography>                
    </Box>
  );
}
